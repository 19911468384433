import { legacy_createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { assignmentReducer } from "./assignments/assignmentReducer";
import { authReducer } from "./auth/authReducer";
import { comenReducer } from "./comenapi/comenReducer";
import { liveClassesReducer } from "./liveClasses/liveClassesReducer";
import { quizzesReducer } from "./quizzes/quizzesReducer";
import { batchReducer } from "./batches/batchReducer";
import { prerecordReducer } from "./preRecord/prerecordReducer";
import { examReducer } from "./exam/examReducer";

const rootReducer = combineReducers({
  assignment: assignmentReducer,
  auth: authReducer,
  comenapi:comenReducer,
  liveClass:liveClassesReducer,
  quizzes:quizzesReducer,
  batch:batchReducer,
  prerecordcourse:prerecordReducer,
  exams:examReducer,
});

export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
