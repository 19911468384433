import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import Card from "react-bootstrap/Card";

export default function ProfileClasses({ProfileData}) {
  const [live, setLive] = useState();

  const classdata = [
    {
      topic: "How to Make an Array and it’s Types in C++",
      batch: "Batch 3CO - JVY",
      time: "11:40 P:M",
      date: "25 Jan 2023",
      classstatus: "Absent",
      status: 0,
    },
    {
      topic: "How to Make an Array and it’s Types in React",
      batch: "Batch 3CO - JVY",
      time: "11:40 P:M",
      date: "25 Jan 2023",
      classstatus: "Present",
      status: 1,
    },
    {
      topic: "How to Make an Array and it’s Types in React",
      batch: "Batch 3CO - JVY",
      time: "11:40 P:M",
      date: "25 Jan 2023",
      classstatus: "Absent",
      status: 0,
    },
  ];

  useEffect(() => {
    setLive(classdata);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <div className="row" style={{}}>
            <div className="col-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#3C3C3C",
                }}
              >
                Total Classes : {ProfileData[0]?.total_classes}
              </div>
            </div>
            <div className="col-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#0CBC8B",
                }}
              >
                Passed : 25
              </div>
            </div>
            <div className="col-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#F93333",
                }}
              >
                Failed : 25
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bar3 mt-4">
        <div className="row">
          {live &&
            live.map((item, index) => {
              return <LiveClass item={item} key={index} />;
            })}
        </div>
      </div>
    </div>
  );
}

const LiveClass = (props) => {
  return (
    <div className="col-3">
      <Card
        style={{
          backgroundColor: "#fff",
          border: "1px solid #000000",
          marginBottom: 20,
        }}
      >
        <Card.Body className="text-start">
          <span
            style={{
              fontWeight: 400,
              fontSize: 18,
              textAlign: "start",
              fontFamily: "inter",
            }}
          >
            {props.item.topic}
          </span>
          <div
            className="my-2"
            style={{
              width: "50%",
              height: 30,
              border: "1px solid #D3D3D3",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#989898",
                fontFamily: "inter",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {props.item.batch}
            </span>
          </div>
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <FiClock style={{ color: "#989898" }} />
              <span
                style={{
                  color: "#989898",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.time}
              </span>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-start">
              <FaRegCalendarAlt style={{ color: "#989898" }} />
              <span
                style={{
                  color: "#989898",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.date}
              </span>
            </div>
          </div>
          {props.item.status == 0 ? (
            <div
              className="my-2"
              style={{
                width: "50%",
                height: 30,
                backgroundColor: "#FFDDDD",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#F12222",
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
              >
                Status : <span>{props.item.classstatus}</span>
              </span>
            </div>
          ) : (
            <div
              className="my-2"
              style={{
                width: "50%",
                height: 30,
                backgroundColor: "#DDFFE7",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#0CC740",
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
              >
                Status : <span>{props.item.classstatus}</span>
              </span>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
