import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { FaCalendar } from "react-icons/fa";
import { RiCloseFill } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";
import Card from "react-bootstrap/Card";
import { API_URL } from "../env";
import { Modal, Offcanvas } from "react-bootstrap";
import LoaderComponent from "./LoaderComponent";
import { toast } from "react-toastify";
import { instance } from "../instance";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import { getSchudeledFun } from "../redux/quizzes/quizzesActions";
import { useDispatch, useSelector } from "react-redux";


const initLoader = {
  isLoading: false,
  isError: false,
};
export default function ScheduledQuizzes(props) {
  const { scheduleQuiz,scheduleQuiz_search } = useSelector((store) => store.quizzes);

  const dispatch = useDispatch(); 
  console.log(scheduleQuiz_search,"scheduleQuiz_search"); 
 
  const [Offcanva_data, setOffCanvaData] = useState({});
  const [Offcanva_dataquizrules, setOffCanvaDataquizrules] = useState();
  const [loader, setLoader] = useState(initLoader);
  const [tagdata, setTagdata] = useState({});
  const { isLoading, isError } = loader;

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);

  //function for get quize data for particular quize
  const getParticularQuize = async (id) => {
    console.log(id, "before hit api");
    setLoader({
      ...loader,
      isLoading: true,
    });
    try {
      let res = await instance
        .post(`/instructor/quizes/read/quizbyid/${id}`)
        .then((result) => {
          console.log(result,"result")
          if (result.data.status == 1) {
            // console.log(result.data.parsedrows, "parsedrows data particular quiz");
            setOffCanvaData(result.data.quiz_data);
            setOffCanvaDataquizrules(
              result?.data?.quiz_data?.quizrules
            );
            setTagdata(result?.data?.quiz_data?.tagwithpersentage);
            setLoader({
              ...loader,
              isLoading: false,
            });
            
          } 
        });
    } catch (err) {
      setLoader({
        ...loader,
        isLoading: false,
      });
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(getSchudeledFun());
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  return (
    <div className="bar3">
      <div className="row mt-2 g-0">
        {scheduleQuiz_search?.map((item, index) => (
        
           <><div className="col-4">
                    <ToastContainer />
                    <Card
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #E6E6E6",
                        marginBottom: 10,
                        width: "95%",
                      }}
                    >
                      <Card.Body className="text-start">
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            textAlign: "start",
                            fontFamily: "inter",
                          }}
                        >
                          {item.title}
                        </span>
                        <div
                          className="row g-0 mt-2"
                          style={{ display: "flex", justifyContent: "flex-start" }}
                        >
                          <p
                            className="col-2 m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              color: "#9A9A9A",
                              fontWeight: 400,
                            }}
                          >
                            Course
                          </p>
                          <p
                            className="col-10 m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              color: "#9A9A9A",
                              fontWeight: 400,
                              textAlign: "left ",
                            }}
                          >
                            {" "}
                            :&nbsp;&nbsp;{item.coursename}
                          </p>
                        </div>
                        <div
                          className="row g-0 mt-2"
                          style={{ display: "flex", justifyContent: "flex-start" }}
                        >
                          <p
                            className="col-2 m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              color: "#9A9A9A",
                              fontWeight: 400,
                            }}
                          >
                            Subject
                          </p>
                          <p
                            className="col-10"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              color: "#9A9A9A",
                              fontWeight: 400,
                              textAlign: "left ",
                            }}
                          >
                            {" "}
                            :&nbsp;&nbsp;{item.subjectname}
                          </p>
                        </div>
                        <div className="row g-0">
                          <div className="col-5 d-flex align-items-center justify-content-start">
                            <FaCalendar style={{ color: "#3C3C3C" }} />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                paddingLeft: 5,
                                fontFamily: "inter",
                              }}
                            >
                              {item.start_time?.split("", 10)}
                            </span>
                          </div>
                          <div className="col-7 d-flex align-items-center justify-content-start">
                            <FiClock style={{ color: "#3C3C3C" }} />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                paddingLeft: 5,
                                fontFamily: "inter",
                              }}
                            >
                              {item.start_time?.split("T")[1]}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <p
                            className="col-12 m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 16,
                              color: "#9A9A9A",
                              fontWeight: 400,
                            }}
                          >
                            Questions : {item.numberofquestion}
                          </p>
                        </div>
                        <div className="row mt-2">
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p
                              className="m-0"
                              style={{
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#3C3C3C",
                              }}
                            >
                              Passing Percentage
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#3D70F5",
                              }}
                            >
                              {item.passingpersentage} %
                            </p>
                          </div>
                        </div>
                        <div
                          className="mt-3"
                          style={{
                            width: "100%",
                            height: 8,
                            backgroundColor: "#D9D9D9",
                            borderRadius: 10,
                          }}
                        >
                          <div
                            style={{
                              width: props.passingpersentage + "%",
                              height: 8,
                              backgroundColor: "#3D70F5",
                              borderRadius: 10,
                            }}
                          ></div>
                        </div>
            
                        <div
                          onClick={() =>{ 
                            getParticularQuize(item.id)
                            handleShow()
                          }}
                          className="mt-3"
                          style={{
                            width: "100%",
                            height: 40,
                            backgroundColor: "#3D70F5",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: 400,
                              fontFamily: "inter",
                            }}
                          >
                            View Details
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
            
                  {/* Scheduled details Quiz view Offcanvas */}
                  <Offcanvas show={show} placement="end" style={{ width: "50%" }}>
                    <Modal size="sm" show={show2} onHide={handleClose2}>
                      <Modal.Body
                        style={{ textAlign: "center", width: "280px", alignSelf: "center" }}
                      >
                        Quiz <b>{Offcanva_data?.title}</b> will be Delete{" "}
                      </Modal.Body>
                      <div
                        style={{ display: "flex", justifyContent: "space-around" }}
                        className="pb-5"
                      >
                        <Button
                          variant="danger"
                          onClick={() => {
                            handleClose();
                            handleClose2();
                            props.deleteQuiz(Offcanva_data.id);
                          }}
                        >
                          Yes,Delete it
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleClose2();
                            handleClose();
                          }}
                        >
                          Don't Delete
                        </Button>
                      </div>
                    </Modal>
                    <div
                      className="row px-4"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          handleClose();
                          setOffCanvaData({});
                        }}
                        className="col-3"
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <RiCloseFill />
                      </div>
                      <div
                        onClick={() => {
                          handleShow2();
                        }}
                        className="col-9"
                        style={{
                          width: 100,
                          height: 30,
                          backgroundColor: "#fff",
                          border: "1px solid #E72121",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#E72121",
                          fontFamily: "inter",
                          fontSize: 13,
                          fontWeight: 600,
                          borderRadius: 5,
                          cursor: "pointer",
                        }}
                      >
                        <RiDeleteBin6Line />
                        <span style={{ paddingLeft: 10 }}>Delete</span>
                      </div>
                    </div>
            
                    <Offcanvas.Body>
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LoaderComponent />
                        </div>
                      ) : (
                        <div className="">
                          <div className="row">
                            <div className="col-8 me-auto">
                              <Card className="border-0">
                                <div
                                  className="text-start py-2"
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 600,
                                    fontSize: 25,
                                  }}
                                >
                                  Quiz Details
                                </div>
                                <p
                                  className="text-start"
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 600,
                                    fontSize: 15,
                                  }}
                                >
                                  Quiz Name : {Offcanva_data?.title}
                                </p>
                                <p
                                  className="text-start"
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 500,
                                    fontSize: 15,
                                  }}
                                >
                                  Number of Questions : {Offcanva_data?.numberofquestion}
                                </p>
                                <p
                                  className="text-start"
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 500,
                                    fontSize: 15,
                                  }}
                                >
                                  Course : {Offcanva_data?.coursename}
                                </p>
                                <p
                                  className="text-start"
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 500,
                                    fontSize: 15,
                                  }}
                                >
                                  Subject : {Offcanva_data?.subjectname}
                                </p>
                                <div className="row mt-2">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: "#3C3C3C",
                                      }}
                                    >
                                      Passing Percentage
                                    </p>
                                    <p
                                      className="m-0"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: "#3D70F5",
                                      }}
                                    >
                                      {Offcanva_data?.passingpersentage}%
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="mt-3"
                                  style={{
                                    width: "100%",
                                    height: 8,
                                    backgroundColor: "#D9D9D9",
                                    borderRadius: 10,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: `${Offcanva_data?.passingpersentage}%`,
                                      height: 8,
                                      backgroundColor: "#3D70F5",
                                      borderRadius: 10,
                                    }}
                                  ></div>
                                </div>
                                <div className="row mt-3">
                                  <span
                                    className="px-3 text-start"
                                    style={{
                                      fontFamily: "inter",
                                      fontWeight: 400,
                                      color: "#434343",
                                      fontSize: 14,
                                    }}
                                  >
                                    Quiz Schedule
                                  </span>
                                  <div className="col-4">
                                    <div
                                      style={{
                                        width: "100%",
                                        height: 30,
                                        backgroundColor: "#EDEDF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                      }}
                                    >
                                      <FaCalendar />
                                      <span
                                        style={{
                                          color: "#3C3C3C",
                                          fontWeight: 400,
                                          fontSize: 14,
                                          fontFamily: "inter",
                                          paddingLeft: 10,
                                        }}
                                      >
                                        {Offcanva_data?.start_time?.split("", 10)}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div
                                      style={{
                                        width: "100%",
                                        height: 30,
                                        backgroundColor: "#EDEDF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                      }}
                                    >
                                      <FiClock />
                                      <span
                                        style={{
                                          color: "#3C3C3C",
                                          fontWeight: 400,
                                          fontSize: 14,
                                          fontFamily: "inter",
                                          paddingLeft: 10,
                                        }}
                                      >
                                        {Offcanva_data.start_time?.split("T")[1]} 
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="text-start my-4"
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 600,
                                    fontSize: 20,
                                  }}
                                >
                                  Tags Composition
                                </div>
                                {Object.entries(tagdata)?.map(([key, value]) => (
                                  <>
                                    <div className="row mt-2">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <p
                                          className="m-0"
                                          style={{
                                            fontFamily: "inter",
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: "#3C3C3C",
                                          }}
                                        >
                                         {key}
                                        </p>
                                        <p
                                          className="m-0"
                                          style={{
                                            fontFamily: "inter",
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: "#3D70F5",
                                          }}
                                        >
                                          {value}%
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="mt-3"
                                      style={{
                                        width: "100%",
                                        height: 8,
                                        backgroundColor: "#D9D9D9",
                                        borderRadius: 10,
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: `${value}%`,
                                          height: 8,
                                          backgroundColor: "#3D70F5",
                                          borderRadius: 10,
                                        }}
                                      ></div>
                                    </div>
                                  </>
                                ))}
            
                                <div className="row">
                                  <div
                                    className="text-start mt-3"
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 20,
                                      fontWeight: 600,
                                    }}
                                  >
                                    Rules
                                  </div>
                                  <ul className="list-group text-start border-0">
                                    {Offcanva_dataquizrules?.map((item, index) => {
                                      return (
                                        <li
                                          key={index}
                                          className="list-group-item d-flex align-items-center border-0"
                                          style={{
                                            fontFamily: "inter",
                                            fontWeight: 400,
                                            fontSize: 12,
                                          }}
                                        >
                                          {" "}
                                          <div
                                            className="me-2"
                                            style={{
                                              width: 5,
                                              height: 5,
                                              backgroundColor: "#000",
                                              borderRadius: 10,
                                            }}
                                          ></div>
                                          {item}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                                <div className="row my-4">
                                  <div className="col-8">
                                    <div
                                      style={{
                                        height: 35,
                                        backgroundColor: "#E6E6E6",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontFamily: "inter",
                                        fontWeight: 400,
                                        fontSize: 14,
                                        color: "#9E9E9E",
                                        borderRadius: 5,
                                      }}
                                    >
                                      Time Per Question : {Offcanva_data?.timeperquestion}
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        </div>
                      )}
                    </Offcanvas.Body>
                  </Offcanvas>
                  {/* Scheduled Quiz Offcanvas End  */}
              
                  </>

                      ))}
      </div>
    </div>
  );
}



