import { useState, useEffect } from "react";
import Overview from "./Overview";
import Classes from "./Classes";
import Assign from "./Assign";
import Quiz from "./Quiz";
import ExamTab from "./ExamTab";
import { FiClock } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Details2 from "./Details2";
import { Excel } from "./Svgicon";
import { API_URL } from "../env";
import { useDispatch, useSelector } from "react-redux";
import { getBatchFun } from "../redux/comenapi/comenAction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiImport } from "react-icons/bi";
import { FileUploader } from "react-drag-drop-files";
import { getassign_particularFun, getbatchesFun, getclasses_particularFun, getexam_particularFun, getquiz_particularFun } from "../redux/batches/batchActions";

const fileTypes = ["JPG", "PNG", "GIF"];

export default function MyBatches() {
 
  const { batches } = useSelector((store) => store.batch);

  console.log(batches, "batches");

  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const dispatch = useDispatch();

  const [Batch, setBatch] = useState([]);
  const [batch_id, setBatch_id] = useState("");
  const [next, setNext] = useState();
  const [live, setLive] = useState();
  const [second, setsecond] = useState(0);
  const [State, setState] = useState();
  const [up, setUp] = useState(0);
  const [tbldata, setTbldata] = useState();
  const [absent, setAbsent] = useState();
  const [attendance, setAttendance] = useState();

  const [details, setDetails] = useState([]);
  console.log(details.batch_name, "details state 1");

  const [excel_file, setExcel_file] = useState(null);
  const handleChange = (file) => {
    setExcel_file(file);
  };
  console.log(excel_file, "file");
  const data = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/read/batches/of/instructor`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result.batches, "batches function");
        if (result.status == 1) {
          setBatch(result.batches);
          setBatch_id(result.batches[0].id);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const viewDetail = async (id) => {
    console.log(id,"id")
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/batches/read/batchoverview/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result.batches_overview, "batch over view function");
        if (result.status == 1) {
          setDetails(result.batches_overview);
        }else{
          console.log('something want worng')
        }
      })
      .catch((error) => console.log("error", error));
  };

  const tabledata = [
    {
      id: "TIPSGRM1012223",
      name: " Rajwat",
      remark: "Present",
      status: 0,
    },
    {
      id: "TIPSGRM1012224",
      name: "Himanshu Rajwat",
      remark: "Absent",
      status: 1,
    },
    {
      id: "TIPSGRM1012225",
      name: "Himanshu Rajwat",
      remark: "Present",
      status: 0,
    },
    {
      id: "TIPSGRM1012226",
      name: "Himanshu Rajwat",
      remark: "Absent",
      status: 1,
    },
    {
      id: "TIPSGRM1012227",
      name: "Himanshu Rajwat",
      remark: "Present",
      status: 0,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Present",
      status: 0,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Absent",
      status: 1,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Present",
      status: 0,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Absent",
      status: 1,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Absent",
      status: 1,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Absent",
      status: 1,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Present",
      status: 0,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Absent",
      status: 1,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Present",
      status: 0,
    },
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      remark: "Absent",
      status: 1,
    },
  ];



  const attendencedata = [
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      att: "Absent",
    },
  ];

  useEffect(() => {
    data();

    setTbldata(tabledata);
    setAbsent(attendencedata);
    setAttendance(attendencedata);
    setNext("Overview");
  }, []);

  return (
    <div className="bar3">
      <Modal
        show={modalShow}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ height: "444px" }}>
          <div className="mt-3">
            <label
              style={{
                // width: "100%",
                // height: 40,
                backgroundColor: "#fff",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "90px",
                // border: "1px solid #000",
                // cursor: "pointer",
              }}
              // htmlFor="image-id"
            >
              <FileUploader
                defaultStyle={{ height: "444px", border: "dashed 2px red" }}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
              {/*<span style={{backgroundColor:"#d9d7d7",borderRadius:"50%",padding:"10px",marginRight:"10px",fontWeight:"900"}}>
    <BiImport style={{fontSize:"90px"}}/>
    </span>
      <span
        style={{
          fontSize:26,
          fontWeight: 400,
          fontFamily: "inter",
          color: "gray",
          paddingRight: 10,
         
        }}
      >
      <h3 style={{fontWeight: "bold"}}>Drop anywhere to import</h3>
      <span >Or Select <label style={{color:"blue",cursor: "pointer"}} htmlFor="image-id">files</label></span>
      </span>*/}
            </label>
            {/*<input
      type="file"
      accept=".pdf"
      multiple
      onChange={(e) => {
        setExcel_file(e.target.files[0]);
      }}
      id="image-id"
      hidden
    />*/}
          </div>
          <Button
            variant="primary"
            style={{
              padding: "2px 60px",
              fontSize: "25px",
              margin: "70px 0 0 300px",
              fontWeight: "600",
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
      <div className="row g-0">
        {batches &&
          batches.map((item, index) => {
            return (
              <div className="col-4" key={item.id}>
                <Card
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid #E6E6E6",
                    marginBottom: 20,
                    width: "95%",
                  }}
                >
                  <Card.Body className="text-start">
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        textAlign: "start",
                        fontFamily: "inter",
                      }}
                    >
                      {item.batch_name}
                    </span>
                    <div
                      className="row g-0 mt-2"
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <p
                        className="col-2 m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          color: "#9A9A9A",
                          fontWeight: 400,
                        }}
                      >
                        Course
                      </p>
                      <p
                        className="col-10 m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          color: "#9A9A9A",
                          fontWeight: 400,
                          textAlign: "left ",
                        }}
                      >
                        {" "}
                        :&nbsp;&nbsp;{item.course_name}
                      </p>
                    </div>
                    <div
                      className="mt-3"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasScrolling"
                      aria-controls="offcanvasScrolling"
                      style={{
                        width: "100%",
                        height: 40,
                        backgroundColor: "#3D70F5",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        viewDetail(item.batch_id);
                        dispatch(getassign_particularFun(item.batch_id))
                        dispatch(getquiz_particularFun(item.batch_id))
                        dispatch(getexam_particularFun(item.batch_id))
                        dispatch(getclasses_particularFun(item.batch_id))
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          fontSize: 14,
                          fontWeight: 400,
                          fontFamily: "inter",
                        }}
                      >
                        View Details
                      </span>
                    </div>
                  </Card.Body>
                </Card>

                <div
                  className="offcanvas offcanvas-end"
                  data-bs-scroll="true"
                  data-bs-backdrop="false"
                  tabIndex="-1"
                  id="offcanvasScrolling"
                  aria-labelledby="offcanvasScrollingLabel"
                  style={{ width: next === "Quiz" ? "100%" : "60%" }}
                >
                  <div className="offcanvas-header">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                    <button className="btn btn-primary" onClick={handleShow}>
                      Import Student
                    </button>
                  </div>
                  <div className="offcanvas-body">
                    <div>
                      <h3
                        className="offcanvas-title text-start"
                        id="offcanvasRightLabel"
                        style={{
                          fontFamily: "inter",
                          fontWeight: 600,
                          fontSize: 26,
                        }}
                      >
                        Batch Details : {details?.batch_name}
                      </h3>
                      <div
                        className="row px-3 mt-2"
                        style={{
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid #E4E4E4",
                        }}
                      >
                        <div
                          className="col-12"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div
                              onClick={() => {
                                setNext("Overview");
                              }}
                              className="rounded-0 bg-transparent"
                              style={{
                                border: "none",
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 400,
                                borderBottom:
                                  next == "Overview"
                                    ? "1px solid #3D70F5"
                                    : "none",
                                color: next == "Overview" ? "#3D70F5" : "#000",
                                width: 100,
                                height: 38,
                                cursor: "pointer",
                              }}
                            >
                              Overview
                            </div>
                            <div
                              onClick={() => {
                                setNext("Classes");
                              }}
                              className="rounded-0 bg-transparent"
                              style={{
                                border: "none",
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 400,
                                borderBottom:
                                  next == "Classes"
                                    ? "1px solid #3D70F5"
                                    : "none",
                                color: next == "Classes" ? "#3D70F5" : "#000",
                                width: 100,
                                height: 38,
                                cursor: "pointer",
                              }}
                            >
                              Classes
                            </div>
                            <div
                              onClick={() => {
                                setNext("Assign");
                              
                              }}
                              className="rounded-0 bg-transparent"
                              style={{
                                border: "none",
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 400,
                                borderBottom:
                                  next == "Assign"
                                    ? "1px solid #3D70F5"
                                    : "none",
                                color: next == "Assign" ? "#3D70F5" : "#000",
                                width: 140,
                                height: 38,
                                cursor: "pointer",
                              }}
                            >
                              Assignments
                            </div>
                            <div
                              onClick={() => {
                                setNext("Quiz");
                              }}
                              className="rounded-0 bg-transparent"
                              style={{
                                border: "none",
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 400,
                                borderBottom:
                                  next == "Quiz" ? "1px solid #3D70F5" : "none",
                                color: next == "Quiz" ? "#3D70F5" : "#000",
                                width: 100,
                                height: 38,
                                cursor: "pointer",
                              }}
                            >
                              Quizzes
                            </div>
                            <div
                              onClick={() => {
                                setNext("ExamTab");
                              }}
                              className="rounded-0 bg-transparent"
                              style={{
                                border: "none",
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 400,
                                borderBottom:
                                  next == "ExamTab"
                                    ? "1px solid #3D70F5"
                                    : "none",
                                color: next == "ExamTab" ? "#3D70F5" : "#000",
                                width: 100,
                                height: 38,
                                cursor: "pointer",
                              }}
                            >
                              Exam
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bar2 mt-2">
                        {next === "Overview" ? (
                          <>
                            {" "}
                            <Overview details={details} />{" "}
                          </>
                        ) : (
                          <></>
                        )}
                        {next === "Classes" ? (
                          <>
                            <Classes />
                          </>
                        ) : (
                          <></>
                        )}
                        {next === "Assign" ? (
                          <>
                            {" "}
                            <Assign />{" "}
                          </>
                        ) : (
                          <></>
                        )}
                        {next === "Quiz" ? (
                          <>
                            {" "}
                            <Quiz />{" "}
                          </>
                        ) : (
                          <></>
                        )}
                        {next === "ExamTab" ? (
                          <>
                            {" "}
                            <ExamTab />{" "}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasWithBackdropLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Offcanvas right</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <Card className="border-0">
            <div className="row d-flex align-items-center justify-content-between">
              <div
                className="col-6 text-start"
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
              >
                {" "}
                Attendance Report
              </div>
              <div
                className="col-6"
                style={{
                  height: 25,
                  backgroundColor: "#E5FFF7",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "inter",
                  fontSize: 13,
                  fontWeight: 400,
                  color: "#0CBC8B",
                }}
              >
                Report Submitted on 12:24 AM | 23 December 2022
              </div>
            </div>
            <p
              className="py-2 text-start"
              style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
            >
              How to Make an Array and it’s Types in C++
            </p>
            <div className="row">
              <div className="col-2">
                <div
                  className=""
                  style={{
                    width: "100%",
                    height: 30,
                    border: "1px solid #D3D3D3",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#989898",
                      fontFamily: "inter",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    Batch 3CO - JVY
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col-6 ">
                    <FiClock style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 13,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      11:40 PM
                    </span>
                  </div>
                  <div className="col-6">
                    <FaRegCalendarAlt style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 13,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      10 Feb 2023
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div
                  className=""
                  style={{
                    height: 30,
                    backgroundColor: "#EDEDED",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#989898",
                      fontSize: 12,
                      fontWeight: 400,
                      fontFamily: "inter",
                    }}
                  >
                    Status : <span>Complete</span>
                  </span>
                </div>
              </div>
              <div className="col-3">
                <button
                  type="button"
                  style={{
                    width: "100%",
                    height: 30,
                    backgroundColor: "#fff",
                    border: "1px solid #33C481",
                    borderRadius: 5,
                    color: "#21A366",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Excel />
                  &nbsp;&nbsp;Download Report
                </button>
              </div>
            </div>
            <div className="row mt-4 text-start">
              <div className="col-5">
                <p
                  style={{ fontFamily: "inter", fontWeight: 600, fontSize: 18 }}
                >
                  Total Students : 256
                </p>
                <div className="row">
                  <p
                    className="col-6"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#11A529",
                    }}
                  >
                    Present : 256
                  </p>
                  <p
                    className="col-6"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#F93333",
                    }}
                  >
                    Absent : 256
                  </p>
                </div>
              </div>
            </div>
            <p
              className="py-2 text-start"
              style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
            >
              Attendance Details
            </p>
            <div className="table-scroll">
              <div className="row">
                <div className="col-11">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Student ID
                        </th>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Student Name
                        </th>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Attendance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tbldata &&
                        tbldata.map((item, index) => (
                          <BasicExample key={index} item={item} />
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop2"
        aria-labelledby="offcanvasWithBackdropLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          {/* <h5 id="offcanvasRightLabel">Offcanvas right</h5> */}
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div style={{ display: up == 0 ? "block" : "none" }}>
            <Card className="border-0">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="c text-start m-0"
                  style={{ fontFamily: "inter", fontWeight: 600, fontSize: 26 }}
                >
                  {" "}
                  Attendance Report
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setUp(up + 1);
                  }}
                  className="btn"
                  style={{
                    height: 35,
                    backgroundColor: "#2C62EE",
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "inter",
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#fff",
                  }}
                >
                  Submit Attendance
                </button>
              </div>
              <p
                className="py-2 text-start"
                style={{ fontFamily: "inter", fontSize: 16, fontWeight: 600 }}
              >
                How to Make an Array and it’s Types in C++
              </p>
              <div className="row">
                <div className="col-2">
                  <div
                    className=""
                    style={{
                      width: "100%",
                      height: 30,
                      border: "1px solid #D3D3D3",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#989898",
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      Batch 3CO - JVY
                    </span>
                  </div>
                </div>
                <div className="col-5">
                  <div className="row">
                    <div className="col-6 ">
                      <FiClock style={{ color: "#989898" }} />
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        11:40 PM
                      </span>
                    </div>
                    <div className="col-6">
                      <FaRegCalendarAlt style={{ color: "#989898" }} />
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        10 Feb 2023
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div
                    className=""
                    style={{
                      height: 30,
                      backgroundColor: "#EDEDED",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#989898",
                        fontSize: 12,
                        fontWeight: 400,
                        fontFamily: "inter",
                      }}
                    >
                      Status : <span>Complete</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="table-scroll mt-5">
                <div className="row">
                  <div className="col-11">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th
                            className=""
                            style={{
                              color: "#474747",
                              fontFamily: "inter",
                              fontSize: 15,
                              backgroundColor: "#F0F0F0",
                              fontWeight: 600,
                            }}
                          >
                            Student ID
                          </th>
                          <th
                            className=""
                            style={{
                              color: "#474747",
                              fontFamily: "inter",
                              fontSize: 15,
                              backgroundColor: "#F0F0F0",
                              fontWeight: 600,
                            }}
                          >
                            Student Name
                          </th>
                          <th
                            className=""
                            style={{
                              color: "#474747",
                              fontFamily: "inter",
                              fontSize: 15,
                              backgroundColor: "#F0F0F0",
                              fontWeight: 600,
                            }}
                          >
                            Attendance
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendance &&
                          attendance.map((item, index) => (
                            <StudentAttendence key={index} item={item} />
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div style={{ display: up == 1 ? "block" : "none" }}>
            <Card className="border-0">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="c text-start m-0"
                  style={{ fontFamily: "inter", fontWeight: 600, fontSize: 26 }}
                >
                  {" "}
                  Review Attendance Report
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setUp(up + 1);
                  }}
                  className="btn"
                  style={{
                    height: 35,
                    backgroundColor: "#2C62EE",
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "inter",
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#fff",
                  }}
                >
                  Confirm
                </button>
              </div>
              <p
                className="py-2 text-start"
                style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
              >
                How to Make an Array and it’s Types in C++
              </p>
              <div className="row">
                <div className="col-2">
                  <div
                    className=""
                    style={{
                      width: "100%",
                      height: 30,
                      border: "1px solid #D3D3D3",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#989898",
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      Batch 3CO - JVY
                    </span>
                  </div>
                </div>
                <div className="col-5">
                  <div className="row">
                    <div className="col-6 ">
                      <FiClock style={{ color: "#989898" }} />
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        11:40 P:M
                      </span>
                    </div>
                    <div className="col-6">
                      <FaRegCalendarAlt style={{ color: "#989898" }} />
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        10 Feb 2023
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div
                    className=""
                    style={{
                      height: 30,
                      backgroundColor: "#EDEDED",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#989898",
                        fontSize: 13,
                        fontWeight: 400,
                        fontFamily: "inter",
                      }}
                    >
                      Status : <span>Complete</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mt-4 text-start">
                <div className="col-5">
                  <p
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    Total Students : 256
                  </p>
                  <div className="row">
                    <p
                      className="col-6"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#11A529",
                      }}
                    >
                      Present : 256
                    </p>
                    <p
                      className="col-6"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#F93333",
                      }}
                    >
                      Absent : 256
                    </p>
                  </div>
                </div>
              </div>
              <p
                className="py-2 text-start"
                style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
              >
                List of Absent Students
              </p>
              <div className="table-scroll">
                <div className="row">
                  <div className="col-11">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th
                            className=""
                            style={{
                              color: "#474747",
                              fontFamily: "inter",
                              fontSize: 14,
                              backgroundColor: "#F0F0F0",
                              fontWeight: 600,
                            }}
                          >
                            Student ID
                          </th>
                          <th
                            className=""
                            style={{
                              color: "#474747",
                              fontFamily: "inter",
                              fontSize: 14,
                              backgroundColor: "#F0F0F0",
                              fontWeight: 600,
                            }}
                          >
                            Student Name
                          </th>
                          <th
                            className=""
                            style={{
                              color: "#474747",
                              fontFamily: "inter",
                              fontSize: 14,
                              backgroundColor: "#F0F0F0",
                              fontWeight: 600,
                            }}
                          >
                            Attendance
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {absent &&
                          absent.map((item, index) => (
                            <AbsentList key={index} item={item} />
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div style={{ display: up == 2 ? "block" : "none" }}>
            <div className="row">
              <div className="col-8 m-auto">
                <Card className="border-0">
                  <p
                    className="py-2 text-center"
                    style={{
                      fontFamily: "inter",
                      fontSize: 36,
                      fontWeight: 600,
                    }}
                  >
                    Attendance Report Submitted Successfuly
                  </p>
                  <div className="row">
                    <div className="col-3">
                      <div
                        className=""
                        style={{
                          width: "100%",
                          height: 30,
                          border: "1px solid #D3D3D3",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#989898",
                            fontFamily: "inter",
                            fontWeight: 400,
                            fontSize: 12,
                          }}
                        >
                          Batch 3CO - JVY
                        </span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-6 ">
                          <FiClock style={{ color: "#989898" }} />
                          <span
                            style={{
                              color: "#989898",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 5,
                              fontFamily: "inter",
                            }}
                          >
                            11:40 P:M
                          </span>
                        </div>
                        <div className="col-6">
                          <FaRegCalendarAlt style={{ color: "#989898" }} />
                          <span
                            style={{
                              color: "#989898",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 5,
                              fontFamily: "inter",
                            }}
                          >
                            20 Feb 2023
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className=""
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDED",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#989898",
                            fontSize: 12,
                            fontWeight: 400,
                            fontFamily: "inter",
                          }}
                        >
                          Status : <span>Complete</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 text-start">
                    <div className="col-6">
                      <p
                        style={{
                          fontFamily: "inter",
                          fontWeight: 600,
                          fontSize: 18,
                        }}
                      >
                        Total Students : 256
                      </p>
                      <div className="row">
                        <p
                          className="col-6"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            fontSize: 14,
                            color: "#11A529",
                          }}
                        >
                          Present : 256
                        </p>
                        <p
                          className="col-6"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            fontSize: 14,
                            color: "#F93333",
                          }}
                        >
                          Absent : 256
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-6 m-auto">
                      <button
                        onClick={() => {
                          setUp(up + 1);
                        }}
                        type="button"
                        className="btn"
                        style={{
                          width: "100%",
                          height: 35,
                          backgroundColor: "#2C62EE",
                          color: "#fff",
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div style={{ display: up == 3 ? "block" : "none" }}>
            <div className="row d-flex align-items-center justify-content-between">
              <div
                className="col-6 text-start"
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
              >
                {" "}
                Attendance Report
              </div>
              <div
                className="col-6"
                style={{
                  height: 25,
                  backgroundColor: "#E5FFF7",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "inter",
                  fontSize: 13,
                  fontWeight: 400,
                  color: "#0CBC8B",
                }}
              >
                Report Submitted on 12:24 AM | 23 December 2022
              </div>
            </div>
            <p
              className="py-2 text-start"
              style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
            >
              How to Make an Array and it’s Types in C++
            </p>
            <div className="row">
              <div className="col-2">
                <div
                  className=""
                  style={{
                    width: "100%",
                    height: 30,
                    border: "1px solid #D3D3D3",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#989898",
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  >
                    Batch 3CO - JVY
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col-6">
                    <FiClock style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      11:40 P:M
                    </span>
                  </div>
                  <div className="col-6">
                    <FaRegCalendarAlt style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 13,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      10 Feb 2023
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div
                  className=""
                  style={{
                    height: 30,
                    backgroundColor: "#EDEDED",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#989898",
                      fontSize: 12,
                      fontWeight: 400,
                      fontFamily: "inter",
                    }}
                  >
                    Status : <span>Complete</span>
                  </span>
                </div>
              </div>
              <div className="col-3">
                <button
                  type="button"
                  style={{
                    width: "100%",
                    height: 30,
                    backgroundColor: "#fff",
                    border: "1px solid #33C481",
                    borderRadius: 5,
                    color: "#21A366",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Excel />
                  &nbsp;&nbsp;Download Report
                </button>
              </div>
            </div>
            <div className="row mt-4 text-start">
              <div className="col-5">
                <p
                  style={{ fontFamily: "inter", fontWeight: 600, fontSize: 18 }}
                >
                  Total Students : 256
                </p>
                <div className="row">
                  <p
                    className="col-6"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#11A529",
                    }}
                  >
                    Present : 256
                  </p>
                  <p
                    className="col-6"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#F93333",
                    }}
                  >
                    Absent : 256
                  </p>
                </div>
              </div>
            </div>
            <p
              className="py-2 text-start"
              style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
            >
              Attendance Details
            </p>
            <div className="table-scroll">
              <div className="row">
                <div className="col-11">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Student ID
                        </th>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Student Name
                        </th>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Attendance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tbldata &&
                        tbldata.map((item, index) => (
                          <BasicExample key={index} item={item} />
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* View Profile */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop3"
        aria-labelledby="offcanvasWithBackdropLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          {/* <h5 id="offcanvasRightLabel">Offcanvas right</h5> */}
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">View Profile</div>
      </div>
    </div>
  );
}

function BasicExample(props) {
  return (
    <tr>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.id}
      </td>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.name}
      </td>
      <td
        style={{
          fontFamily: "inter",
          fontSize: 12,
          fontWeight: 600,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: props.item.status == 0 ? "#E5FFF7" : "#FFEFEF",
            color: props.item.status == 0 ? "#0CBC8B" : "#F93333",
            width: "50%",
            height: 25,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
          }}
        >
          {props.item.remark}
        </div>
      </td>
    </tr>
  );
}

function StudentAttendence(props) {
  const [name, setName] = useState("Present");

  return (
    <tr>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.id}
      </td>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.name}
      </td>
      <td style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="border-0"
          onClick={() => {
            setName("Absent");
          }}
          style={{
            color: "#F93333",
            width: "50%",
            height: 25,
            backgroundColor: props.item.status == 0 ? "#E5FFF7" : "#FFEFEF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            fontFamily: "inter",
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          {name}
        </button>
      </td>
    </tr>
  );
}

function AbsentList(props) {
  return (
    <tr>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.id}
      </td>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.name}
      </td>
      <td
        style={{
          fontFamily: "inter",
          fontSize: 12,
          fontWeight: 600,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          type="button"
          className="btn"
          style={{
            color: "#F93333",
            width: "50%",
            height: 25,
            backgroundColor: props.item.status == 0 ? "#E5FFF7" : "#FFEFEF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            fontSize: 14,
            fontFamily: "inter",
            fontWeight: 400,
          }}
        >
          {props.item.att}
        </div>
      </td>
    </tr>
  );
}
