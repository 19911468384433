import { add_Quizess_error, add_Quizess_loading, add_Quizess_success, add_Singlequestion_loading, add_Singlequestion_success, add_Singlequestionl_error, add_questionbyExcel_error, add_questionbyExcel_loading, add_questionbyExcel_success, add_topic_error, add_topic_loading, add_topic_success, delete_question_error, delete_question_loading, delete_question_success, get_HistoryQuizess_error, get_HistoryQuizess_loading, get_HistoryQuizess_success, get_ScheduleQuizess_error, get_ScheduleQuizess_error_search, get_ScheduleQuizess_loading, get_ScheduleQuizess_loading_search, get_ScheduleQuizess_success, get_ScheduleQuizess_success_search, get_topicById_error, get_topicById_loading, get_topicById_success, get_topic_error, get_topic_loading, get_topic_success } from "./quizzestype";

const initState = {
  isLoading: false,
  isError: false,
  topicdata:[],
  topicdata_byid:{},
  questionbyExecl:{},
  singlequestion:[],
  addseduleQuiz:[],
  scheduleQuiz:[],
  scheduleQuiz_search:[],
  historyQuiz:[],
};

export const quizzesReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case add_topic_loading: {
      return {
        ...state,
        isError: true,
      };
    }
    case add_topic_error: {
      return {
        ...state,
        isError: false,
        isLoading:true,
      };
    }
    case add_topic_success: {
      return {
        ...state,
        isError: true,
        topicdata:payload,
      };
    }
    case get_topic_loading: {
        return {
          ...state,
          isError: true,
        };
      }
      case get_topic_error: {
        return {
          ...state,
          isError: false,
          isLoading:true,
        };
      }
      case get_topic_success: {
        return {
          ...state,
          isError: false,
          isLoading:false,
          topicdata:payload,
        };
      }

      case get_topicById_loading: {
        return {
          ...state,
          isError: true,
        };
      }
      case get_topicById_error: {
        return {
          ...state,
          isError: false,
          isLoading:true,
        };
      }
      case get_topicById_success: {
        return {
          ...state,
          isError: false,
          isLoading:false,
          topicdata_byid:payload,
        };
      }
      case add_questionbyExcel_loading: {
        return {
          ...state,
          isError: true,
        };
      }
      case add_questionbyExcel_error: {
        return {
          ...state,
          isError: false,
          isLoading:true,
        };
      }
      case add_questionbyExcel_success: {
        return {
          ...state,
          isError: false,
          isLoading:false,
          questionbyExecl:payload,
        };
      }
      case add_Singlequestion_loading: {
        return {
          ...state,
          isError: true,
        };
      }
      case add_Singlequestionl_error: {
        return {
          ...state,
          isError: false,
          isLoading:true,
        };
      }
      case add_Singlequestion_success: {
        return {
          ...state,
          isError: false,
          isLoading:false,
          singlequestion:payload,
        };
      }
      case add_Quizess_loading: {
        return {
          ...state,
          isError: true,
        };
      }
      case add_Quizess_error: {
        return {
          ...state,
          isError: false,
          isLoading:true,
        };
      }
      case add_Quizess_success: {
        return {
          ...state,
          isError: false,
          isLoading:false,
          addseduleQuiz:payload,
        };
      }
      case get_ScheduleQuizess_loading: {
        return {
          ...state,
          isError: true,
        };
      }
      case get_ScheduleQuizess_error: {
        return {
          ...state,
          isError: false,
          isLoading:true,
        };
      }
      case get_ScheduleQuizess_success: {
        return {
          ...state,
          isError: false,
          isLoading:false,
          scheduleQuiz:payload,
          scheduleQuiz_search:payload
        };
      }
    
      case get_ScheduleQuizess_success_search: {
        return {
          ...state,
          scheduleQuiz_search: [
            ...state.scheduleQuiz.filter((el) =>
            el.title.toLowerCase().includes(payload.toLowerCase())
            ),
          ],
        };
      }
      case get_HistoryQuizess_loading: {
        return {
          ...state,
          isError: true,
        };
      }
      case get_HistoryQuizess_error: {
        return {
          ...state,
          isError: false,
          isLoading:true,
        };
      }
      case get_HistoryQuizess_success: {
        return {
          ...state,
          isError: false,
          isLoading:false,
          historyQuiz:payload,
        };
      }
      case delete_question_loading: {
        return {
          ...state,
          isError: true,
        };
      }
      case delete_question_error: {
        return {
          ...state,
          isError: false,
          isLoading:true,
        };
      }
      case delete_question_success: {
        return {
          ...state,
          isError: false,
          isLoading:false,
          singlequestion:payload,
        };
      }

    default: {
      return state 
    }
  }
};
