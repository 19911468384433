import { useState, useEffect } from "react";
import img1 from "../images/backimg.png";
import Tipsglogo from "../images/tipsglogo.png";
import hand from "../images/hand.png";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../env";
import { useAuth } from "../Context/auth/authContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { LoginFun } from "../redux/auth/authActions";

export default function Login() {
  const { isAuth, token, isLoading, isError, userName } = useSelector(
    (store) => store.auth
  );
 
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [count, setcount] = useState(1);

  const [pass, setPass] = useState();
  const [cpass, setCpass] = useState();

  const [email, setEmail] = useState();
  const [email2, setEmail2] = useState();
  const [hands, setHands] = useState("");
  
  const [passwordInput, setPasswordInput] = useState("");
  const [showA, setShowA] = useState(true);


  const toggleShowA = () => setShowA(!showA);

  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };

 


  //function for logo while render on login page
  const Logo = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${API_URL}/get/logo`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setHands(result.logo);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    Logo();
    if (!localStorage.getItem("token")) {
      navigate("/Login");
    } else {
      
    }
  }, [isAuth]);

  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [];

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    // Move focus to the next input field
    if (e.target.value !== "" && index < inputRefs.length - 1) {
      inputRefs[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      // Move focus to the previous input field on Backspace key press
      inputRefs[index - 1].focus();
    }
  };

  const sendMail = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/forgetpass1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == true) {
          // console.log(result);
          toast(result.message);
        } else {
          toast(result.err);
        }
      })
      .catch((error) => console.log("error", error));
  };

  var processedOtp = otp.reduce(function (acc, val, index) {
    return acc + val;
  }, []);

  const verifyOtp = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email2,
      otp: processedOtp,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/forgetpass2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == true) {
          // console.log(result);
          setcount(count + 1);
        } else {
          // alert(result.err);
          toast(result.err);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const passwordMatch = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email2,
      password: pass,
      cpassword: cpass,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/forgetpass3`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "true") {
          // console.log(result);
          setcount(count + 1);
          // alert(result.message);
          toast(result.err);
        } else {
          // alert(result.err);
          toast(result.err);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {/* login screen start */}
      {

      }
      <div style={{ display: count === 1 ? "block" : "none" }}>
        <div className="container pt-3 pb-4">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-1">{/* fontFamily: 'Poppins' */}</div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "37.3rem" }}
              />
            </div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4 pt-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0  pt-3"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 ">
                      <img
                        src={hands}
                        alt="bb"
                        style={{ height: "50px", width: "250px" }}
                      />
                      <p
                        className="card-text ms-3 mt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          color: "#434343",
                          fontSize: "40px",
                          fontWeight: "600px",
                        }}
                      >
                        Hey, Hello{" "}
                        <span>
                          <img src={hand} alt="nn" style={{ width: "5%" }} />
                        </span>
                      </p>
                      <p
                        className="card-text ms-3 mt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "#BDBDBD",
                        }}
                      >
                        Enter the information you entered while registering
                      </p>
                    </p>
                  </div>
                  <div className="card-body">
                    <div>
                      <p
                        className="card-text display-6 mt-4"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontSize: "32px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        Login
                      </p>
                      <div
                        for="exampleFormControlInput1"
                        className="form-label text-start"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Email address
                      </div>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <svg
                            width="20"
                            height="16"
                            viewBox="0 0 20 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 2.5L8.10764 6.61227L8.10967 6.61396C8.78785 7.11128 9.12714 7.3601 9.49876 7.45621C9.82723 7.54117 10.1725 7.54117 10.501 7.45621C10.8729 7.36001 11.2132 7.11047 11.8926 6.61227L17.5002 2.5M1 11.8002V4.2002C1 3.08009 1 2.51962 1.21799 2.0918C1.40973 1.71547 1.71547 1.40973 2.0918 1.21799C2.51962 1 3.08009 1 4.2002 1H15.8002C16.9203 1 17.4796 1 17.9074 1.21799C18.2837 1.40973 18.5905 1.71547 18.7822 2.0918C19 2.5192 19 3.07899 19 4.19691V11.8036C19 12.9215 19 13.4805 18.7822 13.9079C18.5905 14.2842 18.2837 14.5905 17.9074 14.7822C17.48 15 16.921 15 15.8031 15H4.19691C3.07899 15 2.5192 15 2.0918 14.7822C1.71547 14.5905 1.40973 14.2842 1.21799 13.9079C1 13.4801 1 12.9203 1 11.8002Z"
                              stroke="#555555"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Please Enter Your Email Address"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none" }}
                        />
                      </div>
                      <div
                        for="exampleFormControlInput1"
                        className="form-label text-start"
                        style={{ fontSize: "16px", fontWeight: "400px" }}
                      >
                        Password
                      </div>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9"
                              stroke="#555555"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          type="password"
                          onChange={handlePasswordChange}
                          value={passwordInput}
                          className="form-control"
                          placeholder="Please Enter Your password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none", padding: "17px" }}
                        />
                      </div>
                      <div className="mb-3 form-check">
                        {/* <input type="checkbox" className="form-check-input mt-2" id="exampleCheck1"/> */}
                        {/* <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}> */}
                        {/* <label className="form-check-label" for="exampleCheck1" style={{fontSize:"16px",fontWeight:"400px"}}>Remember me</label> */}
                        <p
                          className="ms-5 text-end"
                          style={{
                            color: "blue",
                            fontSize: "12px",
                            float: "right",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setcount(count + 1);
                          }}
                        >
                          Forgot password ?
                        </p>
                        {/* </div> */}
                      </div>
                      <div></div>
                      <button
                        type="submit"
                        onClick={()=>dispatch(LoginFun(email, passwordInput,navigate))}
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "#8F6DFF",
                          width: "100%",
                          marginTop: "13px",
                        }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
      {/* login screen complete */}

      <div style={{ display: count === 2 ? "block" : "none" }}>
        <div className="container pt-3 pb-4">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "37.3rem" }}
              />
            </div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 pt-4 ">
                      <img
                        src={Tipsglogo}
                        alt="bb"
                        style={{ height: "50px", width: "250px" }}
                      />
                      <p
                        className="card-text ms-3 mt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "40px",
                          lineHeight: "60px",
                          color: "#434343",
                        }}
                      >
                        OTP Verification
                      </p>
                      <p
                        className="card-text ms-3"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "19px",
                          textAlign: "center",
                          color: "#434343",
                        }}
                      >
                        We Have Sent You An OTP On Registered E-mail Address{" "}
                      </p>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          textAlign: "center",
                          color: "#BDBDBD",
                        }}
                      ></p>
                    </p>
                    <input
                      value={email2}
                      onChange={(e) => {
                        setEmail2(e.target.value);
                      }}
                      className="mx-4 ps-2"
                      type="email"
                      placeholder="Enter your email"
                    />
                    <div>
                      {/* <p className='mt-3' style={{fontFamily: 'Poppins',fontStyle:"normal",fontWeight:"400",fontSize:"16px",lineHeight:"24px",textAlign:"center",color:"#BDBDBD",}}>Don’t Receive the OTP ? Resend OTP </p> */}
                    </div>
                  </div>
                  <div className="card-body" style={{ marginTop: "1.5rem" }}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => {
                        sendMail();
                        setcount(count + 1);
                      }}
                      style={{
                        backgroundColor: "#8F6DFF",
                        width: "100%",
                        marginTop: "5px",
                      }}
                    >
                      Request to Otp
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>

      {/*-------------Forgot Mail----------------*/}
      <div style={{ display: count === 3 ? "block" : "none" }}>
        <div className="container pt-3 pb-4">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "37.3rem" }}
              />
            </div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 pt-4 ">
                      <img
                        src={Tipsglogo}
                        alt="bb"
                        style={{ height: "50px", width: "250px" }}
                      />
                      <p
                        className="card-text ms-3 mt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "40px",
                          lineHeight: "60px",
                          color: "#434343",
                        }}
                      >
                        OTP Verification
                      </p>
                      <p
                        className="card-text ms-3"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "19px",
                          textAlign: "center",
                          color: "#434343",
                        }}
                      >
                        We Have Sent You An OTP On Registered E-mail Address{" "}
                      </p>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          textAlign: "center",
                          color: "#BDBDBD",
                        }}
                      >
                        {email2}
                        <span
                          onClick={() => {
                            setcount(count - 1);
                          }}
                          style={{ color: "#8F6DFF", cursor: "pointer" }}
                        >
                          Change E-mail address{" "}
                        </span>
                      </p>
                    </p>
                    <div className="d-flex flex-row mt-4 justify-content-center">
                      {otp.map((digit, index) => (
                        <input
                          className="m-2"
                          key={index}
                          type="number"
                          maxLength={1}
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={(ref) => (inputRefs[index] = ref)}
                          style={{ width: 40 }}
                        />
                      ))}
                    </div>
                    <div>
                      <p
                        className="mt-3"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          textAlign: "center",
                          color: "#BDBDBD",
                        }}
                      >
                        Don’t Receive the OTP ?{" "}
                        <span
                          onClick={() => {
                            sendMail();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          Resend OTP
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="card-body" style={{ marginTop: "1.5rem" }}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => {
                        verifyOtp(); 
                      }}
                      style={{
                        backgroundColor: "#8F6DFF",
                        width: "100%",
                        marginTop: "5px",
                      }}
                    >
                      Verify and Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>

      {/* otpveryfication start */}
      <div style={{ display: count === 5 ? "block" : "none" }}>
        <div className="container pt-3 pb-4">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "37.3rem" }}
              />
            </div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 pt-4 ">
                      <img
                        src={Tipsglogo}
                        alt="bb"
                        style={{ height: "50px", width: "250px" }}
                      />
                      <p
                        className="card-text ms-3 mt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "40px",
                          lineHeight: "60px",
                          color: "#434343",
                        }}
                      >
                        OTP Verification
                      </p>
                      <p
                        className="card-text ms-3"
                        style={{
                          marginTop: "8rem",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "54px",
                          textAlign: "center",
                          color: "#434343",
                        }}
                      >
                        OTP Verification has been successful !
                      </p>
                    </p>
                  </div>
                  <div className="card-body" style={{ marginTop: "9.4rem" }}>
                    <button
                      type="submit"
                      onClick={() => {
                        setcount(1);
                      }}
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#8F6DFF",
                        width: "100%",
                        marginTop: "13px",
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
      {/* otpveryfication end */}

      {/* Forget password start */}
      {/* <div style={{display:count===5?"block":"none"}}>
   <div className="container pt-3 pb-4">
     <div className="row m-0 p-0 g-0">
       <div className="col-lg-1">
       </div>
        <div className="col-lg-5 m-0 p-0 g-0">
                <img src={img1} alt="nn" style={{width:"100%",height: "36rem",}}/>
        </div>
        <div className="col-lg-5 m-0 p-0 g-0">
                <div className="container-fluid m-0 p-0 g-0" style={{backgroundColor:"#FFFFFF"}}>
                <div className="card m-0 p-3 g-0 pb-4 pt-4" style={{backgroundColor:"#FFFFFF",border:"none"}}>
                 <div className="card-header m-0 p-0 g-0  pt-3" style={{backgroundColor:"#FFFFFF",border:"none"}}>
                    <p className="card-title m-0 p-0 g-0 ">
                        <img src={Tipsglogo} alt="bb" style={{height:"50px",width:"250px"}} />
                    </p>
                 </div>
                 <div className="card-body">
                 <div style={{marginTop:"3rem",}}>
                    <p className='card-text display-6 mt-4' style={{fontFamily: 'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"32px",lineHeight:"20px",display:"flex",alignItems:"center",textAlign:"center",letterSpacing:"0.1px",color:"#000000",}}>Forgot Password?</p>
                    <p className="card-text mt-2" style={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"400",fontSize:"16px",color:"#BDBDBD",}}>No worries, We will send you reset instructions.</p>
                    <label for="exampleFormControlInput1" className="form-label" style={{marginTop:"3rem",fontSize:"16px",fontWeight:"400px",fontFamily: 'Poppins'}}>Enter your E-mail here</label>
                 <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent', }}>
                     <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M2.5 2.5L8.10764 6.61227L8.10967 6.61396C8.78785 7.11128 9.12714 7.3601 9.49876 7.45621C9.82723 7.54117 10.1725 7.54117 10.501 7.45621C10.8729 7.36001 11.2132 7.11047 11.8926 6.61227L17.5002 2.5M1 11.8002V4.2002C1 3.08009 1 2.51962 1.21799 2.0918C1.40973 1.71547 1.71547 1.40973 2.0918 1.21799C2.51962 1 3.08009 1 4.2002 1H15.8002C16.9203 1 17.4796 1 17.9074 1.21799C18.2837 1.40973 18.5905 1.71547 18.7822 2.0918C19 2.5192 19 3.07899 19 4.19691V11.8036C19 12.9215 19 13.4805 18.7822 13.9079C18.5905 14.2842 18.2837 14.5905 17.9074 14.7822C17.48 15 16.921 15 15.8031 15H4.19691C3.07899 15 2.5192 15 2.0918 14.7822C1.71547 14.5905 1.40973 14.2842 1.21799 13.9079C1 13.4801 1 12.9203 1 11.8002Z" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                     </svg>
                    </span>
                  <input type="email" className="form-control" placeholder="NarayanMurthy@gmail.com" aria-label="Username" aria-describedby="basic-addon1" style={{borderLeft:'none'}}/>
                </div>
                      <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                        <label className="form-check-label" for="exampleCheck1" style={{fontSize:"16px",fontWeight:"400px"}}>Remember me</label>
                      </div>
                      <button type="submit" className="btn btn-primary" onClick={()=>{setcount(count+1)}}  style={{backgroundColor:"#8F6DFF",width:"100%",marginTop:"6rem"}}>Reset Password
                    </button>
                    </div>
                 </div>
                </div>
            </div>
         </div>
        <div className="col-lg-1">
            
        </div>
     </div>
   </div>
 </div> */}
      {/* Forget password end */}

      {/* password Reset start */}
      {/* <div style={{display:count===6?"block":"none"}}>
  <div className="container pt-3 pb-4">
    <div className="row m-0 p-0 g-0">
            <div className="col-lg-1">

            </div>
            <div className="col-lg-5 m-0 p-0 g-0">
                <img src={img1} alt="nn" style={{width:"100%",height: "37.3rem",}}/>
            </div>
            <div className="col-lg-5 m-0 p-0 g-0">
                <div className="container-fluid m-0 p-0 g-0" style={{backgroundColor:"#FFFFFF"}}>
                <div className="card m-0 p-3 g-0 pb-4" style={{backgroundColor:"#FFFFFF",border:"none"}}>
                 <div className="card-header m-0 p-0 g-0" style={{backgroundColor:"#FFFFFF",border:"none"}}>
                    <p className="card-title m-0 p-0 g-0 pt-4 ">
                        <img src={Tipsglogo} alt="bb" style={{height:"50px",width:"250px"}} />
                        <p className="card-text ms-3 mt-2" style={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"600",fontSize:"40px",lineHeight:"60px",color:"#434343",}}>Password Reset</p>
                        <p className="card-text ms-3" style={{ fontFamily: 'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"19px",lineHeight:"54px",textAlign:"center",color:"#434343",}}>We Have Sent You An One Time Password On E-mail Address </p>
                        <p style={{fontFamily: 'Poppins',fontStyle:"normal",fontWeight:"400",fontSize:"16px",lineHeight:"24px",textAlign:"center",color:"#BDBDBD",}}>NarayanMurthy@gmail.com,<span style={{color:"#8F6DFF",}}>Change E-mail address </span></p></p>
                 </div>
                 <div className='text-center'>
                    <div className="row" style={{width: "10%",margin:"auto",display:"flex",flexFlow: "row nowrap",justifyContent:"center",}}>
                      <input className='input1' id="otp-first" type="number" min="0" max="9" step="1" aria-label="first digit" />
                      <input className='input1' id="otp-second" type="number" min="0" max="9" step="1" aria-label="second digit" />
                      <input className='input1' id="otp-third" type="number" min="0" max="9" step="1" aria-label="third digit" />
                      <input className='input1' id="otp-fourth" type="number" min="0" max="9" step="1" aria-label="fourth digit" />
                    </div>
                    </div>
                     <div>
                       <p className='mt-5' style={{fontFamily: 'Poppins',fontStyle:"normal",fontWeight:"400",fontSize:"16px",lineHeight:"24px",textAlign:"center",color:"#BDBDBD",}}>Don’t Receive the OTP ? <span style={{color:"#8F6DFF",}}>Resend OTP </span>  </p>
                     </div>
                 <div className="card-body">
                   <button type="submit" className="btn btn-primary" onClick={()=>{setcount(count+1)}}  style={{backgroundColor:"#8F6DFF",width:"100%",marginTop:"13px"}}>Verify and Proceed</button>
                 </div>
                </div>
                </div>
            </div>
            <div className="col-lg-1">
                
            </div>
    </div>
  </div>
</div> */}
      {/* password Reset end */}

      {/* passwordriset1 start */}
      <div style={{ display: count === 4 ? "block" : "none" }}>
        <div className="container pt-3 pb-4">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "37.3rem" }}
              />
            </div>
            <div className="col-lg-5 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4 pt-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0  pt-3"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 ">
                      <img
                        src={Tipsglogo}
                        alt="bb"
                        style={{ height: "50px", width: "250px" }}
                      />
                      <p
                        className="card-text ms-3 mt-5"
                        style={{
                          color: "#434343",
                          fontSize: "40px",
                          fontWeight: "600px",
                        }}
                      >
                        Forgot Password?
                      </p>
                    </p>
                  </div>
                  <div className="card-body">
                    <div>
                      <div
                        for="exampleFormControlInput1"
                        className="form-label mt-5 text-start"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Enter your new password
                      </div>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9"
                              stroke="#555555"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          type="password"
                          value={pass}
                          onChange={(e) => {
                            setPass(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Please Enter Your New Password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none", padding: "17px" }}
                        />
                      </div>
                      <div
                        for="exampleFormControlInput1"
                        className="form-label text-start"
                        style={{ fontSize: "16px", fontWeight: "400px" }}
                      >
                        Confirm Password
                      </div>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9"
                              stroke="#555555"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          type="password"
                          value={cpass}
                          onChange={(e) => setCpass(e.target.value)}
                          className="form-control"
                          placeholder="Please Enter Confirm password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none", padding: "17px" }}
                        />
                      </div>
                      {/* <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                <label className="form-check-label" for="exampleCheck1" style={{fontSize:"16px",fontWeight:"400px"}}>Remember me</label>
              </div> */}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => {
                          passwordMatch();
                        }}
                        style={{
                          backgroundColor: "#8F6DFF",
                          width: "100%",
                          marginTop: "13px",
                        }}
                      >
                        Verify and Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
      {/* passwordriset1 end */}

      {/* password riset page start */}
      {/* <div style={{display:count===8?"block":"none"}}>
  <div className="container pt-3 pb-4">
    <div className="row m-0 p-0 g-0">
     <div className="col-lg-1">
     </div>
      <div className="col-lg-5 m-0 p-0 g-0">
       <img src={img1} alt="nn" style={{width:"100%",height: "37.3rem",}}/>
      </div>
      <div className="col-lg-5 m-0 p-0 g-0">
        <div className="container-fluid m-0 p-0 g-0" style={{backgroundColor:"#FFFFFF"}}>
          <div className="card m-0 p-3 g-0 pb-4" style={{backgroundColor:"#FFFFFF",border:"none"}}>
           <div className="card-header m-0 p-0 g-0" style={{backgroundColor:"#FFFFFF",border:"none"}}>
            <p className="card-title m-0 p-0 g-0 pt-4 ">
              <img src={Tipsglogo} alt="bb" style={{height:"50px",width:"250px"}} />
              <p className="card-text ms-3 mt-2" style={{fontFamily:'Poppins',fontStyle:"normal",fontWeight:"600",fontSize:"40px",lineHeight:"60px",color:"#434343",}}>Password Reset</p>
              <p className="card-text ms-3" style={{marginTop:"8rem", fontFamily: 'Poppins',fontStyle:"normal",fontWeight:"500",fontSize:"20px",lineHeight:"54px",textAlign:"center",color:"#434343",}}>Password Reset has been successful ! </p>
             </p>
           </div>
           <div className="card-body" style={{marginTop:"8rem",}}>
            <button type="submit" className="btn btn-primary" onClick={()=>{setcount(count+1)}}  style={{backgroundColor:"#8F6DFF",width:"100%",marginTop:"13px"}}>Login</button>
           </div>
          </div>
        </div>
      </div>
      <div className="col-lg-1">  
      </div>
    </div>
  </div>
</div> */}
      {/* password riset page end */}

      {/* otp Verification Start */}
      {/* <div style={{display:count===7?"block":"none"}}>
  <div className="container pt-3 pb-4">
    <div className="row m-0 p-0 g-0">
      <div className="col-lg-1">

      </div>
      <div className="col-lg-5 m-0 p-0 g-0">
        <img src={img1} alt="nn" style={{width:"100%",height: "37.3rem",}}/>
      </div>
      <div className="col-lg-5 m-0 p-0 g-0">
        <div className="container-fluid m-0 p-0 g-0" style={{backgroundColor:"#FFFFFF"}}>
          <div className="card m-0 p-3 g-0 pb-4" style={{backgroundColor:"#FFFFFF",border:"none"}}>
           <div className="card-header m-0 p-0 g-0" style={{backgroundColor:"#FFFFFF",border:"none"}}>
             <p className="card-title m-0 p-0 g-0 pt-4 ">
                 <img src={Tipsglogo} alt="bb" style={{height:"50px",width:"250px"}} />
                 <p className="card-text ms-3 mt-2" style={{color:"#434343",fontSize:"40px",fontWeight:"600px"}}>OTP Verification</p>
                 <p className="card-text ms-3 mt-2" style={{color:"#999696",fontSize:"18px",fontWeight:"400px"}}>We Have Sent You An OTP On Registered E-mail Address </p>
             </p>
           </div>
           <div className="card-body">
            <div>
             <p className='card-text display-6 mt-2' style={{fontSize:"32px",color:"#000000",fontWeight:"500px"}}>Sign up</p>
              <label for="exampleFormControlInput1" className="form-label" style={{fontSize:"16px",fontWeight:"400px"}}>Email address</label>
                <div className="input-group mb-3">
                 <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent', }}>
                   <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M2.5 2.5L8.10764 6.61227L8.10967 6.61396C8.78785 7.11128 9.12714 7.3601 9.49876 7.45621C9.82723 7.54117 10.1725 7.54117 10.501 7.45621C10.8729 7.36001 11.2132 7.11047 11.8926 6.61227L17.5002 2.5M1 11.8002V4.2002C1 3.08009 1 2.51962 1.21799 2.0918C1.40973 1.71547 1.71547 1.40973 2.0918 1.21799C2.51962 1 3.08009 1 4.2002 1H15.8002C16.9203 1 17.4796 1 17.9074 1.21799C18.2837 1.40973 18.5905 1.71547 18.7822 2.0918C19 2.5192 19 3.07899 19 4.19691V11.8036C19 12.9215 19 13.4805 18.7822 13.9079C18.5905 14.2842 18.2837 14.5905 17.9074 14.7822C17.48 15 16.921 15 15.8031 15H4.19691C3.07899 15 2.5192 15 2.0918 14.7822C1.71547 14.5905 1.40973 14.2842 1.21799 13.9079C1 13.4801 1 12.9203 1 11.8002Z" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
                 </span>
                 <input type="email" className="form-control" placeholder="NarayanMurthy@gmail.com" aria-label="Username" aria-describedby="basic-addon1" style={{borderLeft:'none'}}/>
                </div>
                <label for="exampleFormControlInput1" className="form-label" style={{fontSize:"16px",fontWeight:"400px"}}>Password</label>
                 <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1" style={{ backgroundColor:'transparent', }}>

                    </span>
                    <input type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" style={{borderLeft:'none'}}/>
                 </div>
               <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                <label className="form-check-label" for="exampleCheck1" style={{fontSize:"16px",fontWeight:"400px"}}>I agree to platforms Terms of service and Privacy Policy</label>
               </div>
              <button type="submit" className="btn btn-primary" onClick={()=>{setcount(count+1)}}  style={{backgroundColor:"#8F6DFF",width:"100%",marginTop:"13px"}}>Verify and Proceed</button>
            </div>
           </div>
          </div>
        </div>
      </div>
      <div className="col-lg-1">
                
      </div>
    </div>
  </div>
</div> */}
      {/* otp Verification End */}

      <ToastContainer autoClose={5000} />
    </>
  );
}

// export default Login;
