import React from "react";
import { BsCloudDownload } from "react-icons/bs";

const PersonalDetails = ({ personal_details }) => {
  console.log(personal_details, "personal_details in prs");
  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-lg-12" style={{ textAlign: "left" }}>
            <div
              className="card"
              style={{ height: "40vh", overflowY: "scroll" }}
            >
              <div className="card-body">
                <div className="my-3" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}>Name</p>
                  <h5 style={{ color: "#434343" }}>{personal_details.name}</h5>
                </div>

                <div className="pt-4" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}>Department</p>
                  <h5 style={{ color: "#434343" }}>
                    {personal_details.dpname}
                  </h5>
                </div>

                <div className="pt-4" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}>Role</p>
                  <h5 style={{ color: "#434343" }}>
                    {personal_details.rolname}
                  </h5>
                </div>

                <div className="pt-4" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}>Date Of Joining</p>
                  <h5 style={{ color: "#434343" }}>
                    {personal_details.join_date}
                  </h5>
                </div>

                <div className="pt-4" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}>Date of birth</p>
                  <h5 style={{ color: "#434343" }}>{personal_details.dob}</h5>
                </div>

                {/*<div className='pt-4' style={{lineHeight:'0px'}}>
            <p style={{fontSize:'15px'}}>Mother’s name</p>
            <h5 style={{color:'#434343'}}>{personal_details?.mothers_name}</h5>
            </div>  

            <div className='pt-4' style={{lineHeight:'0px'}}>
            <p style={{fontSize:'15px'}}>Father’s name</p>
            <h5 style={{color:'#434343'}}>{personal_details?.fathers_name}</h5>
    </div>*/}

                <div className="pt-4" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}> Contact Number</p>
                  <h5 style={{ color: "#434343" }}>{personal_details.phone}</h5>
                </div>

                <div className="pt-4" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}>Emergency contact number </p>
                  <h5 style={{ color: "#434343" }}>
                    {personal_details.em_phone}
                  </h5>
                </div>

                <div className="pt-4" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}>Residential address </p>
                  <h5 style={{ color: "#434343" }}>
                    {personal_details.address}
                  </h5>
                </div>

                {/*<div className='pt-4' style={{lineHeight:'0px'}} >
            <p style={{fontSize:'15px'}}>10th marksheet  </p>
            <button className='p-2' style={{borderRadius:'8px'}}>Download <BsCloudDownload className='mx-2'/></button>
            </div>  

            <div className='pt-4' style={{lineHeight:'0px'}} >
            <p style={{fontSize:'15px'}}>12th marksheet  </p>
            <button className='p-2' style={{borderRadius:'8px'}}>Download <BsCloudDownload className='mx-2'/></button>
</div>*/}

                <div className="pt-4" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}>Bank account number </p>
                  <h5 style={{ color: "#434343" }}>
                    {personal_details.acc_num}
                  </h5>
                </div>

                <div className="pt-4" style={{ lineHeight: "0px" }}>
                  <p style={{ fontSize: "15px" }}>IFSC number </p>
                  <h5 style={{ color: "#434343" }}>{personal_details.ifsc}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
