import { useState, useEffect, useRef, forwardRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ScheduledClasses from "./ScheduledClasses";
import ClassesHistory from "./ClassesHistory";
import { BsSearch } from "react-icons/bs";
import { FiClock } from "react-icons/fi";
import { FaCalendar } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { MdOutlineFileUpload } from "react-icons/md";
import { ImageUpload } from "./Svgicon";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../env";
//import Button from 'react-bootstrap/Button';
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import {
  getBatchFun,
  getCourseFun,
  getSubjectFun,
} from "../redux/comenapi/comenAction";
import {
  createClassFun,
  getupcomeClassesFun,
  gethistoryClassesFun_search,
  getupcomeClassesFun_search,
  gethistoryClassesFun,
} from "../redux/liveClasses/liveClassesActions";

import Slide from "@mui/material/Slide";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LiveClasses() {
  const { course, mysubject, batchs } = useSelector((store) => store.comenapi);
  const { upcomingClasses } = useSelector((store) => store.liveClass);


  const dispatch = useDispatch();

  const [sudDate, setDate] = useState("");
  console.log(sudDate,"sudDate")
  const [start_time, setStart_time] = useState("");
  const [end_time, setEnd_time] = useState("");
  const [State, setState] = useState();
  const [topic, setTopic] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [file, setfile] = useState();
  const [courseId, setCourseId] = useState();
  const [SubjectId, setSubjectId] = useState();
  const [selectedBatch, setSelectedBatch] = useState({ id: "", value: "" });

  const [show, setShow] = useState(false);
  const handleCloseRam = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSelect = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = {
      id: event.target.options[selectedIndex].getAttribute("id"),
      value: event.target.options[selectedIndex].value,
    };
    setSelectedBatch(selectedOption);
  };

  useEffect(() => {
    // array of words
    const words = topic.split(" ");
    // update word count
    let wordCount = 0;
    words.forEach((word) => {
      if (word.trim() !== "") {
        wordCount++;
      }
    });
    setWordCount(wordCount);
    // update char count (including whitespaces)
    setCharCount(topic.length);
  }, [topic]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(getCourseFun());
    
    dispatch(getupcomeClassesFun());
    dispatch(gethistoryClassesFun());
    setState("ScheduledClasses");
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  return (
    <>
      <ToastContainer autoClose={5000} />

      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="row g-0">
          <div className="col-lg-2 g-0" style={{}}>
            <Sidebar />
          </div>
          <div className="col-lg-10 g-0 p-0">
            <Header />
            <div
              className="mt-3 px-3"
              style={{
                width: "100%",
                height: 40,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
              >
                Live Classes
              </div>
              <Button
                variant="primary"
                style={{
                  height: 30,
                  backgroundColor: "#3D70F5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "inter",
                  fontWeight: 400,
                  border: "none",
                }}
                onClick={handleShow}
                className="me-2"
              >
                +Add Class
              </Button>
            </div>
            <div
              className="row g-0 px-3 mt-3"
              style={{
                height: 40,
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #E4E4E4",
              }}
            >
              <div
                className="col-8"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <div style={{ display: "flex" }}>
                  <button
                    type="button"
                    onClick={() => {
                      setState("ScheduledClasses");
                    }}
                    className="rounded-0 bg-transparent"
                    style={{
                      border: "none",
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 400,
                      borderBottom:
                        State == "ScheduledClasses"
                          ? "1px solid #3D70F5"
                          : "none",
                      color: State == "ScheduledClasses" ? "#3D70F5" : "#000",
                      width: 200,
                      height: 38,
                    }}
                  >
                    Scheduled Classes
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setState("ClassesHistory");
                    }}
                    className="rounded-0 bg-transparent mx-4"
                    style={{
                      border: "none",
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 400,
                      borderBottom:
                        State == "ClassesHistory"
                          ? "1px solid #3D70F5"
                          : "none",
                      color: State == "ClassesHistory" ? "#3D70F5" : "#000",
                      width: 150,
                      height: 38,
                    }}
                  >
                    Classes History
                  </button>
                </div>
              </div>
              <div className="col-4">
                <div className="input-group flex-nowrap">
                  <span
                    className="input-group-text"
                    id="addon-wrapping"
                    style={{
                      borerRadius: 5,
                      border: "none",
                      backgroundColor: "#F1F1F1",
                    }}
                  >
                    <BsSearch style={{ color: "#6A6A6A" }} />
                  </span>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    style={{
                      border: "none",
                      backgroundColor: "#F1F1F1",
                      boxShadow: "none",
                      borerRadius: 5,
                    }}
                    onKeyUp={(e) =>
                     {
                      State == "ScheduledClasses"
                      ? dispatch(getupcomeClassesFun_search(e.target.value))
                      : dispatch(gethistoryClassesFun_search(e.target.value));
                    
                     }
                    }
                  />
                </div>
              </div>
            </div>
            <div className="bar2 mx-4">
              {State === "ScheduledClasses" ? (
                <>
                  {" "}
                  <ScheduledClasses />{" "}
                </>
              ) : (
                <></>
              )}
              {State === "ClassesHistory" ? (
                <>
                  {" "}
                  <ClassesHistory />{" "}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/*   Add Class Offcanvas Start     */}

          <Offcanvas 
            show={show}
            className="w-50"
            onHide={handleCloseRam}
            placement="end"
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <div className="row g-0">
                <div className="col-7 m-auto">
                  <p
                    className="text-center"
                    style={{
                      fontFamily: "inter",
                      fontSize: 24,
                      fontWeight: 600,
                    }}
                  >
                    Add New Class
                  </p>
                  <Card className="rounded-0 border-0">
                    <textarea
                      className="p-2 shadow-none"
                      value={topic}
                      maxLength="50"
                      onChange={(e) => setTopic(e.target.value)}
                      placeholder="Enter topic of Class..."
                      style={{
                        width: "100%",
                        height: 80,
                        backgroundColor: "#F5F5F5",
                        border: "none",
                        borderRadius: 5,
                        boxShadow: "none",
                      }}
                    ></textarea>
                    <div>
                      <p
                        className="char-count text-end"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#B1B1B1",
                        }}
                      >
                        {charCount} / 50
                      </p>
                    </div>
                    <div className="row">
                      <label>
                        <div
                          className=""
                          style={{
                            width: "100%",
                            height: 40,
                            backgroundColor: "#fff",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #000",
                          }}
                        >
                          <ImageUpload style={{ fontSize: 20 }} />
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              fontFamily: "inter",
                              color: "#000",
                              paddingLeft: 10,
                              cursor: "pointer",
                            }}
                          >
                            Upload Class Material
                          </span>
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={(e) => {
                              setfile(e.target.files[0]);
                            }}
                          />
                        </div>
                      </label>
                    </div>
                    <div className="row g-0 mt-3">
                      <select
                        onChange={(e) => {
                          setCourseId(e.target.value);
                          dispatch(getSubjectFun(e.target.value));
                          dispatch(getBatchFun(e.target.value));
                        }}
                        className="form-select"
                        aria-label="Default select example"
                        style={{ border: "1px solid #000", borderRadius: 0 }}
                      >
                        <option style={{ borderRadius: 0 }} selected>
                          Select Course
                        </option>
                        {course?.map((item, res) => {
                          return (
                            <>
                              <option value={item.id}>{item.name}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div className="row g-0 mt-3">
                      <select
                        onChange={(e) => {
                          setSubjectId(e.target.value);
                        }}
                        className="form-select"
                        aria-label="Default select example"
                        style={{ border: "1px solid #000", borderRadius: 0 }}
                      >
                        <option selected>Select Subject</option>
                        {mysubject?.map((item, res) => {
                          return (
                            <>
                              <option value={item.id}>{item.name}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div className="row g-0 mt-3">
                      <select
                        onChange={handleSelect}
                        className="form-select"
                        aria-label="Default select example"
                        style={{ border: "1px solid #000", borderRadius: 0 }}
                      >
                        <option selected>Select Batch</option>
                        {batchs?.map((item, res) => {
                          return (
                            <option id={item.id} value={item.batch_name}>
                              {item.batch_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="row mt-2 mx-1">
                      <span
                        className="p-0 text-start"
                        style={{
                          fontFamily: "inter",
                          fontWeight: 400,
                          color: "#434343",
                          fontSize: 14,
                        }}
                      >
                        Exam  Schedule
                      </span>
                      <div className="my-4">
                        <span
                          className="col-4 d-flex rounded-1"
                          style={{ backgroundColor: "#EDEDF5", height: "30px" }}
                        >
                          <input
                          type="date"
                            value={sudDate}
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                            className="border-0 bg-transparent"
                            style={{ color: "#8A8A8A" }}
                          />
                        </span>
                      </div>
                      <span
                        className="col-3 d-flex rounded-1"
                        style={{ backgroundColor: "#EDEDF5", height: "30px" }}
                      >
                        <input
                          type="time"
                          className="border-0 bg-transparent"
                          style={{ color: "#8A8A8A" }}
                          value={start_time}
                          onChange={(e) => {
                            setStart_time(e.target.value);
                          }}
                        />
                      </span>
                      <span className="col-1 d-flex  mx-2">to</span>
                      <span
                        className="col-3 d-flex rounded-1 "
                        style={{ backgroundColor: "#EDEDF5", height: "30px" }}
                      >
                        <input
                          value={end_time}
                          onChange={(e) => {
                            setEnd_time(e.target.value);
                          }}
                          type="time"
                          className="border-0 bg-transparent"
                          style={{ color: "#8A8A8A" }}
                        />
                      </span>
                    </div>
                    <div className="row g-0 mt-4">
                      <button
                        type="button"
                        onClick={() => {
                          dispatch(
                            createClassFun(
                              {
                                class_topic: topic,
                                cou_id: courseId,
                                sub_id: SubjectId,
                                bat_id: +selectedBatch.id,
                                batch_name: selectedBatch.value,
                                start_time: sudDate + "T" + start_time,
                                end_time: sudDate + "T" + end_time,
                                attachments: file,
                              },
                              handleCloseRam,
                              handleClickOpen,
                              setTopic,
                              setCourseId,
                              setSubjectId,
                              setSelectedBatch,
                              setDate,
                              setStart_time,
                              setEnd_time
                            )
                          );
                        }}
                        className="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{
                          backgroundColor: "#2C62EE",
                          color: "#fff",
                          fontFamily: "inter",
                          fontSize: 15,
                          fontWeight: 400,
                        }}
                      >
                        +&nbsp;Add Class
                      </button>
                    </div>
                  </Card>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            disableEnforceFocus
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              style={{ fontFamily: "inter", fontSize: 30, fontWeight: 600 }}
            >
              New Class Added Successfully
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="row g-0 text-start">
                  <p
                    className="col-3 p-0 text-end"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#6A6A6A",
                    }}
                  >
                    Topic :
                  </p>
                  <p
                    className="col-9 text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#6A6A6A",
                    }}
                  >
                    &nbsp;{upcomingClasses?.class_topic}
                  </p>
                </div>
              </div>
              <div className="row">
                <p
                  className="col-5"
                  style={{
                    fontFamily: "inter",
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Class Material
                </p>
                <div className="col-7">
                  <div
                    style={{
                      height: 30,
                      border: "1px solid #D7D7D7",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#434343",
                      }}
                    >
                      {upcomingClasses?.attachments?.split("/")[4]}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row text-start">
                <span
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Course
                </span>
                <span
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#434343",
                  }}
                >
                  {upcomingClasses?.course_name}
                </span>
              </div>
              <div className="row text-start mt-2">
                <span
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Subject
                </span>
                <span
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#434343",
                  }}
                >
                  {upcomingClasses?.subject_name}
                </span>
              </div>
              <div className="row g-0 mt-2">
                <span
                  className="text-start py-2"
                  style={{
                    fontFamily: "inter",
                    fontWeight: 600,
                    color: "#434343",
                    fontSize: 14,
                  }}
                >
                  Class Schedule
                </span>
                <div className="col-4">
                  <div
                    style={{
                      width: "100%",
                      height: 30,
                      backgroundColor: "#EDEDF5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <FaCalendar />
                    <span
                      style={{
                        color: "#3C3C3C",
                        fontWeight: 600,
                        fontSize: 14,
                        fontFamily: "inter",
                        paddingLeft: 10,
                      }}
                    >
                      {upcomingClasses?.date}
                    </span>
                  </div>
                </div>
                <div className="col-4 mx-4">
                  <div
                    style={{
                      width: "100%",
                      height: 30,
                      backgroundColor: "#EDEDF5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <FiClock />
                    <span
                      style={{
                        color: "#3C3C3C",
                        fontWeight: 600,
                        fontSize: 14,
                        fontFamily: "inter",
                        paddingLeft: 10,
                      }}
                    >
                      {upcomingClasses?.s_time}
                    </span>
                  </div>
                </div>
              </div>

              <button
              className="btn btn-success mt-3"
              style={{float: "right"}}
                onClick={() => {
                  dispatch(getupcomeClassesFun());
                  handleClose();
                }}
              >
                Done
              </button>
            </DialogContent>
          </Dialog>

          {/* Add Class Offcanvas Start End */}
        </div>
      </div>
    </>
  );
}

const LiveClass = (props) => {
  return (
    <Card
      style={{
        backgroundColor:
          props.item.status == 0
            ? "#FFECEC"
            : props.item.status == 1
            ? "#ECFFEE"
            : "#fff",
        border: "1px solid #000000",
        marginBottom: 20,
      }}
    >
      <Card.Body className="text-start">
        <span
          style={{
            fontWeight: 600,
            fontSize: 18,
            textAlign: "start",
            fontFamily: "inter",
          }}
        >
          {props.item.topic}
        </span>
        <div
          className="my-2"
          style={{
            width: "50%",
            height: 30,
            border: "1px solid #D3D3D3",
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              color: "#989898",
              fontFamily: "inter",
              fontWeight: "bold",
              fontSize: 12,
            }}
          >
            {props.item.batch}
          </span>
        </div>
        <div className="row">
          <div className="col-6 d-flex align-items-center">
            <FiClock style={{ color: "#989898" }} />
            <span
              style={{
                color: "#989898",
                fontWeight: 600,
                fontSize: 14,
                paddingLeft: 5,
                fontFamily: "inter",
              }}
            >
              {props.item.time}
            </span>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-start">
            <FaRegCalendarAlt style={{ color: "#989898" }} />
            <span
              style={{
                color: "#989898",
                fontWeight: 600,
                fontSize: 14,
                paddingLeft: 5,
                fontFamily: "inter",
              }}
            >
              {props.item.date}
            </span>
          </div>
        </div>
        {props.item.status == 0 ? (
          <div
            className="my-2"
            style={{
              width: "50%",
              height: 30,
              backgroundColor: "#FFDDDD",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#F12222",
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "inter",
              }}
            >
              Status : <span>{props.item.classstatus}</span>
            </span>
          </div>
        ) : props.item.status == 1 ? (
          <div
            className="my-2"
            style={{
              width: "80%",
              height: 30,
              backgroundColor: "#DDFFE7",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#0CC740",
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "inter",
              }}
            >
              Status : <span>{props.item.classstatus}</span>
            </span>
          </div>
        ) : (
          <div
            className="my-2"
            style={{
              width: "50%",
              height: 30,
              backgroundColor: "#F1F1F1",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#989898",
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "inter",
              }}
            >
              Status : <span>{props.item.classstatus}</span>
            </span>
          </div>
        )}
        <div
          className="mt-2"
          style={{
            width: "100%",
            height: 40,
            backgroundColor: "#000000",
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              color: "#fff",
              fontSize: 16,
              fontWeight: 600,
              fontFamily: "inter",
            }}
          >
            Join Now
          </span>
        </div>
      </Card.Body>
    </Card>
  );
};

const ParticipantsList = (props) => {
  return (
    <div
      className="row g-0 mx-2 my-3 p-1"
      style={{
        height: 40,
        backgroundColor: "#434343",
        borderRadius: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="col-6 text-start">
        <div
          style={{
            fontFamily: "inter",
            fontWeight: 600,
            color: "#fff",
            fontSize: 15,
          }}
        >
          {props.item.name}
        </div>
      </div>
      <div className="col-6">
        <span
          style={{
            fontFamily: "inter",
            fontWeight: 600,
            color: "#737373",
            fontSize: 13,
          }}
        >
          {" "}
          {props.item.status == 0 ? (
            <span>joined at</span>
          ) : (
            <span>left at</span>
          )}{" "}
          {props.item.time}
        </span>
      </div>
    </div>
  );
};

const Doubt = (props) => {
  return (
    <div
      className="row g-0 mx-2 my-3 p-1"
      style={{
        backgroundColor: "#434343",
        borderRadius: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="col-8 px-2 text-start">
        <div
          style={{
            fontFamily: "inter",
            fontWeight: 600,
            color: "#fff",
            fontSize: 15,
          }}
        >
          {props.item.name}{" "}
          <span
            style={{
              fontFamily: "inter",
              fontWeight: 600,
              color: "#737373",
              fontSize: 12,
            }}
          >
            {props.item.time}
          </span>
        </div>
      </div>
      <div className="col-4 d-flex justify-content-end">
        <div className="dropdown">
          <button
            className=""
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <HiDotsVertical style={{ fontSize: "1em", color: "#54536E" }} />
          </button>
          <ul className="dropdown-menu">
            <li className="">
              <a
                className="dropdown-item d-inline-flex justify-content-between"
                href="#"
              >
                Edit <MdModeEdit />
              </a>
            </li>
            <li>
              <a
                className="dropdown-item d-inline-flex justify-content-between"
                href="#"
              >
                Delete
                <MdDelete style={{ color: "red" }} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="p-2 py-2">
        <p
          style={{
            fontFamily: "inter",
            fontSize: 14,
            color: "#fff",
            fontWeight: 600,
            textAlign: "justify",
          }}
        >
          {props.item.doubt}
        </p>
      </div>
    </div>
  );
};

// handleClickOpen;
