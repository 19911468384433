import { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ScheduledQuizzes from "./ScheduledQuizzes";
import Quizzbank from "./Quizzbank";
import History from "./History";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BsSearch } from "react-icons/bs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { FaCalendar } from "react-icons/fa";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import { API_URL } from "../env";
import Slider from "./Slider";
import { toast } from "react-toastify";
import { instance } from "../instance";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  getBatchFun,
  getBatchFun_search,
  getCourseFun,
  getCourseFun_search,
  getSubjectFun,
  getSubjectFun_search,
} from "../redux/comenapi/comenAction";
import {
  addQuizessFun,
  getHistoryFun,
  getScheduleFun_search,
  getSchudeledFun,
  getTopicFun,
} from "../redux/quizzes/quizzesActions";
import { HiOutlineArrowSmRight } from "react-icons/hi";

export default function Quizzes({ ...props }) {
  const { course_search, mysubject_search, mybatchs_search, mysubject } =
    useSelector((store) => store.comenapi);

  const dispatch = useDispatch();

  console.log(mybatchs_search, "mybatchs_search");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const colors = ["#2188E7", "#C922E4", "#CC1313", "#d7ac2d"];
  const [TotalData, setTotalData] = useState([]);
  console.log(TotalData, "TotalData");
  const [state, setState] = useState(0);
  const [State2, setState2] = useState("Scheduled Quizzes");

  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);

  const [value, setValue] = useState(0);

  const [tag, setTag] = useState([]);
  console.log(tag, "tag");

  const [batchnamearr, setBtachNamearr] = useState([]);
  const [queTime, setqueTime] = useState("");
  const [noofque, setnoofque] = useState(null);
  const [Percentage, setPercentage] = useState(33);
  const [start_time, setStart_time] = useState("");
  const [title, settitle] = useState("");
  console.log(title, "title");
  const [CourseName, setCourseName] = useState("");
  const [SubjectName, setSubjectName] = useState("");
  const [rules, setRules] = useState("");
  const [DataForRules, setDataForRules] = useState([]);
  // const [course_search, setCourse_search] = useState([]);
  // const [subject_search, setSubject_search] = useState([]);
  // const [batch_search, setBatch_search] = useState([]);
  const [tag_searchs, setTag_search] = useState([]);
  const [BatchId, setBatchId] = useState([]);
  const [slected_batches, setSelectedBatches] = useState([]);
  const [live, setLive] = useState();
  const [TagId, setTagId] = useState([]);

  //handling of offcanvas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const removeTag = (name, id) => {
    setBatchId([...BatchId.filter((el) => el !== id)]);
    setSelectedBatches([...slected_batches.filter((res) => res.name !== name)]);
  };

  const removeTagforTags = (name, id) => {
    setTagId([...TagId.filter((el) => el !== id)]);
    setTotalData([...TotalData.filter((res) => res.name !== name)]);
  };

  function handleChangeName(id, value) {
    const newtag = TotalData.map((tag) => {
      if (tag.id === id) {
        return { ...tag, value };
      } else {
        return tag;
      }
    });

    setTotalData(newtag);
  }

  const [ArrForm, setArrForm] = useState();
  const OnClickfun = () => {
    const outputObject = {};
    TotalData.forEach((item) => {
      outputObject[item.id] = Number(item.value);
    });

    setArrForm(outputObject);
  };

  //function for hnadling checked batceh ids
  const handleChange1 = (name, id) =>
    slected_batches.some((el) => el.name === name)
      ? toast("batch is already selected")
      : (setSelectedBatches([...slected_batches, { name, id }]),
        setBatchId([...BatchId, id]));

  //function for hnadling checked tags ids
  const handleChangeTag = (name, id) => {
    return TotalData.some((el) => el.name === name)
      ? toast("Tag is already selected")
      : (setTotalData([
          ...TotalData,
          { name, id, value: TotalData.length === 0 ? 100 : 0 },
        ]),
        setTagId([...TagId, id]));
  };

  useEffect(() => {
    // array of words
    const words = rules.split(" ");

    // update word count
    let wordCount = 0;
    words.forEach((word) => {
      if (word.trim() !== "") {
        wordCount++;
      }
    });
    setWordCount(wordCount);

    // update char count (including whitespaces)
    setCharCount(rules.length);
  }, [rules]);

  const getTag = (id) => {
    console.log(id, "subject id in getTag");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/formfill/get/quiz/tags/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          setTag(result.topic_data);
          setTag_search(result.topic_data);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const searched_tag = (toSearch) => {
    try {
      const new_data = tag?.filter((el) =>
        el.topic_name.toLowerCase().includes(toSearch.toLowerCase())
      );

      setTag_search(new_data);
    } catch (err) {
      console.log(err);
    }
  };

  //function for getting upcoming quize dart
  const upcomingQuizz = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/read/quiz/upcom`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          // console.log(result.quizall, "up coming quizes fun");
          setLive(result.quizall);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for delete particular quize
  const deleteQuiz = async (id) => {
    console.log(id, "id delete");
    try {
      let res = await instance.post(`/instructor/quizes/delete/quiz/by/id`, {
        quiz_id: id,
      })
      
        // console.log("res",res)
        if (res.data.status == 1) {
          toast(res.data.message);
          dispatch(getSchudeledFun());
        } else {
          // toast(res.message);
          console.log("err delet")
        }
    
      
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(getCourseFun());
    dispatch(getTopicFun());
    dispatch(getHistoryFun());

    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  return (
    <>
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="row g-0">
          <div className="col-lg-2 g-0" style={{}}>
            <Sidebar />
          </div>
          <div className="col-lg-10 g-0 p-0">
            <Header />
            <div
              style={{
                display: State2 === "Scheduled Quizzes" ? "block" : "none",
              }}
            >
              <div
                className="px-3"
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className="pt-2"
                  style={{ fontFamily: "inter", fontSize: 24, fontWeight: 600 }}
                >
                  Quizzes
                </p>
                <button
                  type="button"
                  onClick={handleShow}
                  className="btn"
                  style={{
                    height: 30,
                    backgroundColor: "#3D70F5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                    color: "#fff",
                    fontSize: 14,
                    fontFamily: "inter",
                    fontWeight: 400,
                    border: "none",
                  }}
                >
                  + Add Quiz
                </button>
              </div>
            </div>
            <div
              style={{ display: State2 === "Question Bank" ? "block" : "none" }}
            >
              <div
                className="px-3"
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className="pt-2"
                  style={{ fontFamily: "inter", fontSize: 24, fontWeight: 800 }}
                >
                  Quizzes
                </p>

                <NavLink to="/AddTag" className="text-decoration-none">
                  <button
                    type="button"
                    className="btn"
                    style={{
                      height: 30,
                      backgroundColor: "#3D70F5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      color: "#fff",
                      fontSize: 14,
                      fontFamily: "inter",
                      fontWeight: 400,
                      border: "none",
                    }}
                  >
                    + Add Tag
                  </button>
                </NavLink>
              </div>
            </div>
            <div style={{ display: State2 === "History" ? "block" : "none" }}>
              <div
                className="px-3"
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className="pt-2"
                  style={{ fontFamily: "inter", fontSize: 24, fontWeight: 800 }}
                >
                  Quizzes
                </p>
              </div>
            </div>
            <div
              className="row px-3 mt-4 g-0"
              style={{
                height: 40,
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #E4E4E4",
              }}
            >
              <div
                className="col-9"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <div style={{ display: "flex" }}>
                  <button
                    type="button"
                    onClick={() => {
                      setState2("Scheduled Quizzes");
                    }}
                    className="rounded-0 bg-transparent"
                    style={{
                      border: "none",
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 400,
                      borderBottom:
                        State2 == "Scheduled Quizzes"
                          ? "1px solid #3D70F5"
                          : "none",
                      color: State2 == "Scheduled Quizzes" ? "#3D70F5" : "#000",
                      width: 200,
                      height: 38,
                    }}
                  >
                    Scheduled Quizzes
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      setState2("Question Bank");
                    }}
                    className="rounded-0 bg-transparent mx-4"
                    style={{
                      border: "none",
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 400,
                      borderBottom:
                        State2 == "Question Bank"
                          ? "1px solid #3D70F5"
                          : "none",
                      color: State2 == "Question Bank" ? "#3D70F5" : "#000",
                      width: 200,
                      height: 38,
                    }}
                  >
                    Question Bank
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      setState2("History");
                    }}
                    className="rounded-0 bg-transparent mx-4"
                    style={{
                      border: "none",
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 400,
                      borderBottom:
                        State2 == "History" ? "1px solid #3D70F5" : "none",
                      color: State2 == "History" ? "#3D70F5" : "#000",
                      width: 100,
                      height: 38,
                    }}
                  >
                    History
                  </button>
                </div>
              </div>
              {State2 === "Scheduled Quizzes" ? (
                <div className="col-3">
                  <div className="input-group flex-nowrap">
                    <span
                      className="input-group-text"
                      id="addon-wrapping"
                      style={{
                        borderRadius: 0,
                        border: "none",
                        backgroundColor: "#F1F1F1",
                      }}
                    >
                      <BsSearch style={{ color: "#6A6A6A" }} />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search..."
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                      style={{
                        borderRadius: 0,
                        border: "none",
                        backgroundColor: "#F1F1F1",
                        boxShadow: "none",
                      }}
                      onKeyUp={(e) => {
                        dispatch(getScheduleFun_search(e.target.value));
                      }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="row g-0 d-flex justify-content-between m-auto">
              <div className="col-12">
                <div className="bar2 mt-2 mx-4">
                  {State2 === "Scheduled Quizzes" ? (
                    <>
                      <ScheduledQuizzes deleteQuiz={deleteQuiz} />
                    </>
                  ) : (
                    <></>
                  )}
                  {State2 === "Question Bank" ? (
                    <>
                      <Quizzbank />
                    </>
                  ) : (
                    <></>
                  )}
                  {State2 === "History" ? (
                    <>
                      {" "}
                      <History />
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {/* Add Quizz Offcanvas Start  */}
                <Offcanvas
                  show={show}
                  onHide={handleClose}
                  {...props}
                  placement={"end"}
                  style={{ width: "50%" }}
                >
                  <Offcanvas.Header
                    closeButton
                    onClick={() => {
                      setCourseName("");
                      setSubjectName("");
                      setTotalData([]);
                      setTagId([]);
                      setBatchId([]);
                      setSelectedBatches([]);
                      setqueTime("");
                      setRules("");
                      settitle("");
                      setStart_time("");
                      setPercentage(33);
                      setnoofque(null);
                      setState(0);
                    }}
                  ></Offcanvas.Header>
                  <Offcanvas.Body>
                    <div style={{ display: state === 0 ? "block" : "none" }}>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontSize: 22,
                          fontWeight: 600,
                        }}
                      >
                        Create New Quiz
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#787878",
                        }}
                      >
                        <span style={{ color: "#3D70F5" }}>1</span>/4 Steps
                      </p>
                      <div
                        className="progress"
                        style={{ height: "8px", borderRadius: 0 }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "25%",
                            background: "#3D70F5",
                            borderRadius: 0,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-8 m-auto">
                          <Card className="">
                            <p
                              className="px-3 pt-2 mb-2"
                              id="demo-radio-buttons-group-label"
                              style={{
                                fontFamily: "inter",
                                fontSize: 20,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Select Course
                            </p>
                            <div className="px-2">
                              <div className="input-group flex-nowrap">
                                <span
                                  className="input-group-text"
                                  id="addon-wrapping"
                                  style={{
                                    borderRadius: 0,
                                    border: "none",
                                    backgroundColor: "#F1F1F1",
                                  }}
                                >
                                  <BsSearch style={{ color: "#6A6A6A" }} />
                                </span>
                                <input
                                  type="search"
                                  className="form-control"
                                  placeholder="search Course"
                                  aria-label="Username"
                                  aria-describedby="addon-wrapping"
                                  style={{
                                    borderRadius: 0,
                                    border: "none",
                                    backgroundColor: "#F1F1F1",
                                    boxShadow: "none",
                                  }}
                                  onKeyUp={(e) =>
                                    // searched_courses(e.target.value)
                                    dispatch(
                                      getCourseFun_search(e.target.value)
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="barsubject">
                              {course_search?.map((itemd, res) => {
                                return (
                                  <>
                                    <div
                                      key={res}
                                      className="form-check d-flex align-items-center mx-3 m-1"
                                    >
                                      <label
                                        className="form-check-label p-0"
                                        htmlFor={itemd.id}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <input
                                          checked={itemd.name === CourseName}
                                          className="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id={itemd.id}
                                          style={{
                                            boxShadow: "none",
                                            cursor: "pointer",
                                          }}
                                          onChange={(e) => {
                                            setCourseName(itemd.name);
                                            dispatch(getBatchFun(itemd.id));
                                            dispatch(getSubjectFun(itemd.id));
                                          }}
                                        />

                                        <div
                                          style={{
                                            fontFamily: "inter",
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: "#6A6A6A",
                                            paddingLeft: 10,
                                          }}
                                        >
                                          {itemd.name}
                                        </div>
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </Card>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="mt-2"
                              disabled={CourseName === ""}
                              onClick={() => {
                                CourseName?.length === 0
                                  ? toast("coure")
                                  : setState(state + 1);
                              }}
                              style={{
                                width: 64,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                                border: "none",
                                background: "none",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "inter",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  color: "#6B6B6B",
                                }}
                              >
                                Next
                              </div>
                              <HiOutlineArrowSmRight
                                style={{ color: "#6B6B6B" }}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: state === 1 ? "block" : "none" }}>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontSize: 22,
                          fontWeight: 600,
                        }}
                      >
                        Create New Quiz
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#787878",
                        }}
                      >
                        <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                      </p>
                      <div
                        className="progress"
                        style={{ height: "8px", borderRadius: 0 }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "50%",
                            background: "#3D70F5",
                            borderRadius: 0,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-8 m-auto">
                          <Card className="">
                            <p
                              className="px-3 pt-2 mb-2"
                              id="demo-radio-buttons-group-label"
                              style={{
                                fontFamily: "inter",
                                fontSize: 20,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Select Subject
                            </p>
                            <div className="px-2">
                              <div className="input-group flex-nowrap">
                                <span
                                  className="input-group-text"
                                  id="addon-wrapping"
                                  style={{
                                    borderRadius: 0,
                                    border: "none",
                                    backgroundColor: "#F1F1F1",
                                  }}
                                >
                                  <BsSearch style={{ color: "#6A6A6A" }} />
                                </span>
                                <input
                                  type="search"
                                  className="form-control"
                                  placeholder="search Subject"
                                  aria-label="Username"
                                  aria-describedby="addon-wrapping"
                                  style={{
                                    borderRadius: 0,
                                    border: "none",
                                    backgroundColor: "#F1F1F1",
                                    boxShadow: "none",
                                  }}
                                  onKeyUp={(e) =>
                                    // searched_subject(e.target.value)
                                    dispatch(
                                      getSubjectFun_search(e.target.value)
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="barsubject">
                              {mysubject_search?.map((item, res) => {
                                return (
                                  <div
                                    key={res}
                                    className="form-check d-flex align-items-center mx-3 m-1"
                                  >
                                    <label
                                      className="form-check-label p-0"
                                      htmlFor={item.name}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <input
                                        // checked={item.name === SubjectName}
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefaul"
                                        id={item.name}
                                        value={SubjectName}
                                        style={{
                                          boxShadow: "none",
                                          cursor: "pointer",
                                        }}
                                        onChange={(e) => {
                                          getTag(item.id);

                                          setSubjectName(item.name);
                                        }}
                                      />

                                      <div
                                        style={{
                                          fontFamily: "inter",
                                          fontSize: 14,
                                          fontWeight: 400,
                                          color: "#6A6A6A",
                                          paddingLeft: 10,
                                        }}
                                      >
                                        {item.name}
                                      </div>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </Card>
                          <div
                            className="mt-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="mt-2"
                              onClick={() => {
                                setState(state - 1);
                              }}
                              style={{
                                width: 50,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                            >
                              <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                              <div
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "#6B6B6B",
                                  cursor: "pointer",
                                }}
                              >
                                Back
                              </div>
                            </div>

                            <button
                              className="mt-2"
                              disabled={SubjectName === ""}
                              onClick={() => {
                                // CourseName?.length === 0?toast("coure"):
                                setState(state + 1);
                              }}
                              style={{
                                width: 64,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                                border: "none",
                                background: "none",
                              }}
                            >
                              <div
                                style={{
                                  fontFamily: "inter",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  color: "#6B6B6B",
                                }}
                              >
                                Next
                              </div>
                              <HiOutlineArrowSmRight
                                style={{ color: "#6B6B6B" }}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: state === 2 ? "block" : "none" }}>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontSize: 22,
                          fontWeight: 600,
                        }}
                      >
                        Create New Quiz
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#787878",
                        }}
                      >
                        <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
                      </p>
                      <div
                        className="progress"
                        style={{ height: "8px", borderRadius: 0 }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "75%",
                            background: "#3D70F5",
                            borderRadius: 0,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-8 m-auto">
                          <Card className="p-2">
                            <p
                              className="px-3 pt-2 mb-2"
                              id="demo-radio-buttons-group-label"
                              style={{
                                fontFamily: "inter",
                                fontSize: 20,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Select Tag
                            </p>
                            <div className="input-group flex-nowrap">
                              <span
                                className="input-group-text"
                                id="addon-wrapping"
                                style={{
                                  borderRadius: 0,
                                  border: "none",
                                  backgroundColor: "#F1F1F1",
                                }}
                              >
                                <BsSearch style={{ color: "#6A6A6A" }} />
                              </span>
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Search Tags"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                style={{
                                  borderRadius: 0,
                                  border: "none",
                                  backgroundColor: "#F1F1F1",
                                  boxShadow: "none",
                                }}
                                onKeyUp={(e) => searched_tag(e.target.value)}
                              />
                            </div>
                            <div
                              style={{
                                overflowX: "scroll",
                                height: "50px",
                                maxHeight: "100%",
                                display: "flex",
                                alignItems: "center",
                                color: "green",
                              }}
                            >
                              {TotalData?.map((el) => (
                                <div
                                  key={el.id}
                                  className="px-2 me-2"
                                  style={{
                                    height: 35,
                                    backgroundColor: "#daffda",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 5,
                                  }}
                                >
                                  <span style={{ marginRight: "20px" }}>
                                    {el?.name}
                                  </span>

                                  <AiOutlineClose
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      removeTagforTags(el?.name, el.id)
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="barsubject">
                              {tag_searchs?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="form-check d-flex align-items-center  my-2 "
                                  >
                                    <label
                                      onClick={(e) => {
                                        handleChangeTag(
                                          item.topic_name,
                                          item.id
                                        );
                                      }}
                                      className="form-check-label p-0"
                                      htmlFor={`flexRadioDefault${index}`}
                                      style={{
                                        backgroundColor: "rgb(234 237 234)",
                                        border: "1px solid green",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontFamily: "inter",
                                          fontSize: 14,
                                          fontWeight: 400,
                                          color: "#6A6A6A",
                                          cursor: "pointer",
                                          padding: "5px 20px",
                                        }}
                                      >
                                        {item.topic_name}
                                      </div>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </Card>
                          <div
                            className="mt-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="mt-2"
                              onClick={() => {
                                setState(state - 1);
                              }}
                              style={{
                                width: 50,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                            >
                              <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                              <div
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#6B6B6B",
                                }}
                              >
                                Back
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                TagId?.length === 0 
                                  ? toast("please select Tag name")
                                  : setState(state + 1);
                              }}
                              style={{
                                width: "25%",
                                height: 35,
                                backgroundColor: "#3D70F5",
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                              >
                                Proceed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: state === 3 ? "block" : "none" }}>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontSize: 22,
                          fontWeight: 600,
                        }}
                      >
                        Create New Quiz
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#787878",
                        }}
                      >
                        <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                      </p>
                      <div
                        className="progress"
                        style={{ height: "8px", borderRadius: 0 }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "50%",
                            background: "#3D70F5",
                            borderRadius: 0,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-8 m-auto">
                          <Card className="p-2">
                            <p
                              className="px-3 pt-2 mb-2"
                              id="demo-radio-buttons-group-label"
                              style={{
                                fontFamily: "inter",
                                fontSize: 20,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Select Batch
                            </p>
                            <div className="input-group flex-nowrap">
                              <span
                                className="input-group-text"
                                id="addon-wrapping"
                                style={{
                                  borderRadius: 0,
                                  border: "none",
                                  backgroundColor: "#F1F1F1",
                                }}
                              >
                                <BsSearch style={{ color: "#6A6A6A" }} />
                              </span>
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Search Tags"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                style={{
                                  borderRadius: 0,
                                  border: "none",
                                  backgroundColor: "#F1F1F1",
                                  boxShadow: "none",
                                }}
                                onKeyUp={(e) =>
                                  dispatch(getBatchFun_search(e.target.value))
                                }
                              />
                            </div>
                            <div
                              style={{
                                overflowX: "scroll",
                                height: "50px",
                                maxHeight: "100%",
                                display: "flex",
                                alignItems: "center",
                                color: "green",
                              }}
                            >
                              {slected_batches?.map((el) => (
                                <div
                                  key={el.id}
                                  className="px-2 me-2"
                                  style={{
                                    height: 35,
                                    backgroundColor: "#daffda",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 5,
                                  }}
                                >
                                  <span style={{ marginRight: "20px" }}>
                                    {el?.name}
                                  </span>

                                  <AiOutlineClose
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removeTag(el?.name, el.id)}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="barsubject">
                              {mybatchs_search &&
                                mybatchs_search.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="form-check d-flex align-items-center  my-2 "
                                    >
                                      <label
                                        onClick={() =>
                                          handleChange1(
                                            item.batch_name,
                                            item.id
                                          )
                                        }
                                        className="form-check-label p-0"
                                        htmlFor={`flexRadioDefault${index}`}
                                        style={{
                                          backgroundColor: "rgb(234 237 234)",
                                          border: "1px solid green",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontFamily: "inter",
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: "#6A6A6A",
                                            cursor: "pointer",
                                            padding: "5px 20px",
                                          }}
                                        >
                                          {item.batch_name}
                                        </div>
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </Card>
                          <div
                            className="mt-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="mt-2"
                              onClick={() => {
                                setState(state - 1);
                              }}
                              style={{
                                width: 50,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                            >
                              <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                              <div
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#6B6B6B",
                                  cursor: "pointer",
                                }}
                              >
                                Back
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                BatchId?.length === 0
                                  ? toast("please select Batch name")
                                  : setState(state + 1);
                              }}
                              style={{
                                width: "25%",
                                height: 35,
                                backgroundColor: "#3D70F5",
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                              >
                                Proceed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: state === 4 ? "block" : "none" }}>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontSize: 22,
                          fontWeight: 600,
                        }}
                      >
                        Create New Quiz
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#787878",
                        }}
                      >
                        <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                      </p>
                      <div
                        className="progress"
                        style={{ height: "8px", borderRadius: 0 }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "50%",
                            background: "#3D70F5",
                            borderRadius: 0,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-8 m-auto">
                          <p
                            className="px-3 pt-2 mb-2"
                            id="demo-radio-buttons-group-label"
                            style={{
                              fontFamily: "inter",
                              fontSize: 20,
                              fontWeight: 600,
                              color: "#000",
                            }}
                          >
                            Select Question Composition
                          </p>

                          <Card
                            className="p-2"
                            style={{ height: "300px", overflowY: "scroll" }}
                          >
                            {TotalData?.map((Value, index) => {
                              return (
                                <>
                                  <div
                                    className="mt-3"
                                    style={{ textAlign: "start" }}
                                  >
                                    <div
                                      className="d-flex"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span
                                        className="ms-3 fw-bolder"
                                        style={{ fontSize: 14 }}
                                      >
                                        {Value.name}
                                      </span>
                                      <span style={{ marginLeft: "190px" }}>
                                        {parseInt(Value.value)}%
                                      </span>
                                      <br />
                                    </div>

                                    <Slider
                                      style={{
                                        accentColor: colors[index % 4],
                                        cursor: "pointer",
                                      }}
                                      key={Value.id}
                                      id={Value.id}
                                      name={Value.name}
                                      value={Value.value}
                                      total={TotalData.reduce(
                                        (total, Value) => total + Value.value,
                                        0
                                      )}
                                      onChange={handleChangeName}
                                    />
                                  </div>
                                </>
                              );
                            })}
                          </Card>
                          <div
                            className="mt-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              className="mt-2"
                              onClick={() => {
                                setState(state - 1);
                              }}
                              style={{
                                width: 50,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                              <div
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#6B6B6B",
                                  cursor: "pointer",
                                }}
                              >
                                Back
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                setState(state + 1);
                                OnClickfun();
                              }}
                              style={{
                                width: "25%",
                                height: 35,
                                backgroundColor: "#3D70F5",
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                              >
                                Proceed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: state === 5 ? "block" : "none" }}>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontSize: 22,
                          fontWeight: 600,
                        }}
                      >
                        Create New Quiz
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#787878",
                        }}
                      >
                        <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                      </p>
                      <div
                        className="progress"
                        style={{ height: "8px", borderRadius: 0 }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "50%",
                            background: "#3D70F5",
                            borderRadius: 0,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-8 m-auto">
                          <Card className="p-2">
                            <p
                              className="px-3 pt-2 mb-2"
                              id="demo-radio-buttons-group-label"
                              style={{
                                fontFamily: "inter",
                                fontSize: 20,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Quiz Rules
                            </p>
                            <textarea
                              className="p-2 shadow-none"
                              maxLength="5000"
                              onChange={(e) => setRules(e.target.value)}
                              placeholder="Start Typing the Rules"
                              style={{
                                width: "100",
                                height: 120,
                                backgroundColor: "#F5F5F5",
                                border: "none",
                                borderRadius: 5,
                                boxShadow: "none",
                              }}
                            ></textarea>
                            <div>
                              <p
                                className="char-count text-end"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#B1B1B1",
                                }}
                              >
                                {charCount} / 5000
                              </p>
                            </div>
                            <div className="row">
                              <div className="col-9 px-2">
                                <div className="input-group flex-nowrap">
                                  <input
                                    type="text"
                                    value={queTime}
                                    onChange={(e) => {
                                      setqueTime(e.target.value);
                                    }}
                                    className="form-control"
                                    placeholder="Enter Time Per Question"
                                    aria-label="Username"
                                    aria-describedby="addon-wrapping"
                                    style={{
                                      borderRadius: 5,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                      boxShadow: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="input-group flex-nowrap">
                                  <div
                                    className="form-control"
                                    style={{
                                      borderRadius: 5,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                      color: "#B1B1B1",
                                    }}
                                  >
                                    Second
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                          <div
                            className="mt-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="mt-2"
                              onClick={() => {
                                setState(state - 1);
                              }}
                              style={{
                                width: 50,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                            >
                              <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                              <div
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#6B6B6B",
                                  cursor: "pointer",
                                }}
                              >
                                Back
                              </div>
                            </div>
                            <div
                              style={{
                                width: "25%",
                                height: 35,
                                backgroundColor: "#3D70F5",
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {/* need to work here */}
                              <span
                                onClick={() => {
                                  rules === "" || rules?.length >= 5000
                                    ? toast("please create Rules ")
                                    : queTime === ""
                                    ? toast("please Enter time parQuestion")
                                    : queTime < 10
                                    ? toast(
                                        "please Enter minimam time 10sec parQuestion"
                                      )
                                    : setState(state + 1);
                                  setDataForRules(rules?.split("#"));
                                }}
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                              >
                                Proceed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: state === 6 ? "block" : "none" }}>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontSize: 22,
                          fontWeight: 600,
                        }}
                      >
                        Create New Quiz
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#787878",
                        }}
                      >
                        <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
                      </p>
                      <div
                        className="progress"
                        style={{ height: "8px", borderRadius: 0 }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "50%",
                            background: "#3D70F5",
                            borderRadius: 0,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-8 m-auto">
                          <Card className="p-2">
                            <p
                              className="px-3 pt-2 mb-2"
                              id="demo-radio-buttons-group-label"
                              style={{
                                fontFamily: "inter",
                                fontSize: 20,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Quiz Specifications
                            </p>
                            <div className="row mt-3">
                              <div className="col-12 px-2">
                                <div className="input-group flex-nowrap">
                                  <input
                                    type="text"
                                    value={noofque}
                                    onChange={(e) => {
                                      setnoofque(e.target.value);
                                    }}
                                    className="form-control"
                                    placeholder="Number of questions"
                                    aria-label="Username"
                                    aria-describedby="addon-wrapping"
                                    style={{
                                      borderRadius: 5,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                      boxShadow: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row px-2 mt-3">
                              <div
                                className="mt-2"
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className="text-start"
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 400,
                                    color: "#434343",
                                    fontSize: 14,
                                  }}
                                >
                                  Passing Percentage
                                </div>
                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 600,
                                    color: "#3D70F5",
                                    fontSize: 14,
                                  }}
                                >
                                  {Percentage}%
                                </div>
                              </div>
                              <div>
                                <input
                                  type="range"
                                  min="0"
                                  max="100"
                                  value={Percentage}
                                  onChange={(e) => {
                                    setPercentage(e.target.value);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                            <div className="row mt-2 mx-1">
                              <span
                                className="p-0 text-start"
                                style={{
                                  fontFamily: "inter",
                                  fontWeight: 400,
                                  color: "#434343",
                                  fontSize: 14,
                                }}
                              >
                                Quiz Schedule
                              </span>
                              <span
                                className="col-5 d-flex rounded-1 me-2"
                                style={{
                                  backgroundColor: "#EDEDF5",
                                  height: "30px",
                                }}
                              >
                                <input
                                  type="datetime-local"
                                  className="border-0 bg-transparent"
                                  style={{ color: "#8A8A8A" }}
                                  value={start_time}
                                  onChange={(e) => {
                                    setStart_time(e.target.value);
                                  }}
                                />
                              </span>
                            </div>
                            <div className="row mt-3">
                              <p
                                className="px-3 pt-2 mb-2"
                                id="demo-radio-buttons-group-label"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#000",
                                }}
                              >
                                Quiz Title
                              </p>
                              <div className="col-12 px-2">
                                <div className="input-group flex-nowrap">
                                  <input
                                    type="text"
                                    value={title}
                                    onChange={(e) => {
                                      settitle(e.target.value);
                                    }}
                                    className="form-control"
                                    placeholder="Title of Quiz"
                                    aria-label="Username"
                                    aria-describedby="addon-wrapping"
                                    style={{
                                      borderRadius: 5,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                      boxShadow: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              onClick={() => {
                                noofque === null
                                  ? toast("please Enter Number of Question")
                                  : Percentage < 33
                                  ? toast(
                                      "please slecte percentage minimam 33 %  "
                                    )
                                  : start_time === ""
                                  ? toast("please slecte Quiz Schedule time")
                                  : title === ""
                                  ? toast("Please Enter Title Name")
                                  : setState(state + 1);
                              }}
                              style={{
                                width: "100%",
                                height: 35,
                                backgroundColor: "#3D70F5",
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px",
                                cursor: "pointer",
                              }}
                            >
                              {/* need to work here */}
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                              >
                                Continue
                              </span>
                            </div>
                          </Card>
                          <div
                            className="mt-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="mt-2"
                              onClick={() => {
                                setState(state - 1);
                              }}
                              style={{
                                width: 50,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                            >
                              <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                              <div
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#6B6B6B",
                                  cursor: "pointer",
                                }}
                              >
                                Back
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: state === 7 ? "block" : "none" }}>
                      <p
                        style={{
                          fontFamily: "inter",
                          fontSize: 22,
                          fontWeight: 600,
                        }}
                      >
                        Create New Quiz
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#787878",
                        }}
                      >
                        <span style={{ color: "#3D70F5" }}>4</span>/4 Steps
                      </p>
                      <div
                        className="progress"
                        style={{ height: "8px", borderRadius: 0 }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "100%",
                            background: "#3D70F5",
                            borderRadius: 0,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-8 m-auto">
                          <Card className="p-2 ">
                            <p
                              className="px-0 pt-2 mb-2"
                              id="demo-radio-buttons-group-label"
                              style={{
                                fontFamily: "inter",
                                fontSize: 24,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Preview
                            </p>
                            <div
                              className="barsubject"
                              style={{ height: "380px" }}
                            >
                              <div
                                className="row g-0 mt-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <p
                                  className="col-2 m-0"
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 16,
                                    color: "#434343",
                                    fontWeight: 600,
                                  }}
                                >
                                  Course :
                                </p>
                                <p
                                  className="col-10 m-0"
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 16,
                                    color: "#434343",
                                    fontWeight: 600,
                                    textAlign: "left ",
                                  }}
                                >
                                  {CourseName}
                                </p>
                              </div>
                              <div
                                className="row g-0 mt-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <p
                                  className="col-2 m-0"
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 16,
                                    color: "#434343",
                                    fontWeight: 600,
                                  }}
                                >
                                  Subject :
                                </p>
                                <p
                                  className="col-10"
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 16,
                                    color: "#434343",
                                    fontWeight: 600,
                                    textAlign: "left ",
                                  }}
                                >
                                  {SubjectName}
                                </p>
                              </div>
                              <div
                                className="row g-0 mt-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <p
                                  className=" m-0"
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 16,
                                    color: "#434343",
                                    fontWeight: 600,
                                  }}
                                >
                                  Number of Questions : {noofque}
                                </p>
                              </div>
                              <div
                                className="mt-4"
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 400,
                                    color: "#434343",
                                    fontSize: 14,
                                  }}
                                >
                                  Passing Percentage
                                </div>
                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 600,
                                    color: "#3D70F5",
                                    fontSize: 14,
                                  }}
                                >
                                  {Percentage}%
                                </div>
                              </div>
                              <div className="">
                                <span>
                                  <input
                                    readOnly
                                    className="w-100 input-range"
                                    type="range"
                                    min="10"
                                    max="100"
                                    value={Percentage}
                                  />
                                </span>
                              </div>
                              <div className="row">
                                <span
                                  className="px-3 text-start"
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 600,
                                    color: "#434343",
                                    fontSize: 14,
                                  }}
                                >
                                  Quiz Schedule
                                </span>
                                <div className="col-6">
                                  <div
                                    style={{
                                      width: "100%",
                                      height: 30,
                                      backgroundColor: "#EDEDF5",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: 5,
                                    }}
                                  >
                                    <FaCalendar />
                                    <span
                                      style={{
                                        color: "#3C3C3C",
                                        fontWeight: 400,
                                        fontSize: 14,
                                        fontFamily: "inter",
                                        paddingLeft: 10,
                                      }}
                                    >
                                      {start_time}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <p
                                className="py-2"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: " #303030",
                                }}
                              >
                                Tags Composition
                              </p>
                              <div
                                className="mt-2"
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                {TotalData?.map((el, ind) => (
                                  <div className="mb-3">
                                    <div
                                      className=""
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>{el.name}</div>
                                      <div style={{}}>
                                        {parseInt(el.value)}%
                                      </div>
                                    </div>

                                    <span>
                                      <Slider
                                        disabled={true}
                                        style={{
                                          accentColor: colors[ind % 4],
                                        }}
                                        key={el.id}
                                        id={el.id}
                                        name={el.name}
                                        value={el.value}
                                        total={TotalData.reduce(
                                          (total, Value) => total + Value.value,
                                          0
                                        )}
                                        onChange={handleChangeName}
                                      />
                                    </span>
                                  </div>
                                ))}
                              </div>

                              <p
                                className="py-2"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: " #303030",
                                }}
                              >
                                Batches
                              </p>
                              <div
                                className="mt-2"
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                {batchnamearr?.map((el, ind) => (
                                  <div className="mb-3">
                                    <div>{el}</div>
                                  </div>
                                ))}
                              </div>

                              <div className="row g-0">
                                {DataForRules?.map((res) => {
                                  return (
                                    <>
                                      <ul>
                                        <li style={{ width: "30vw" }}>{res}</li>
                                      </ul>
                                    </>
                                  );
                                })}
                              </div>
                              <div className="row g-0 my-3">
                                <div
                                  className="col-6"
                                  style={{
                                    height: 40,
                                    backgroundColor: "#F1F1F1",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 5,
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#707070",
                                    }}
                                  >
                                    Time per Question : {queTime} Second
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Card>
                          <div
                            className="mt-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="mt-2"
                              onClick={() => {
                                setState(state - 1);
                              }}
                              style={{
                                width: 50,
                                height: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                            >
                              <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                              <div
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#6B6B6B",
                                  cursor: "pointer",
                                }}
                              >
                                Back
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                dispatch(
                                  addQuizessFun(
                                    title,
                                    noofque,
                                    Percentage,
                                    queTime,
                                    DataForRules,
                                    CourseName,
                                    SubjectName,
                                    start_time,
                                    BatchId,
                                    ArrForm
                                  )
                                );
                                setState(state + 1);
                              }}
                              style={{
                                width: "25%",
                                height: 35,
                                backgroundColor: "#3D70F5",
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                              >
                                Confirm
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: state === 8 ? "block" : "none" }}>
                      <div className="row mt-5">
                        <p
                          className="col-10 m-auto mt-5"
                          style={{
                            fontFamily: "inter",
                            fontSize: 24,
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Quiz Created Successfully
                        </p>
                      </div>
                      <div className="row">
                        <p
                          className="col-8 m-auto mt-4"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          New Quiz within {CourseName} with subject{" "}
                          {SubjectName} Added Succesfully with the following
                          Tags :{" "}
                          {TotalData?.map((el, ind) => (
                            <>{el.name},</>
                          ))}
                        </p>
                      </div>
                      <div className="row mt-4">
                        <div className="col-4 m-auto">
                          <div
                            style={{
                              width: "100%",
                              height: 35,
                              backgroundColor: "#3D70F5",
                              borderRadius: 5,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCourseName();
                              setSubjectName();
                              setTotalData([]);
                              setTagId([]);
                              setBatchId([]);
                              setSelectedBatches([]);
                              setqueTime("");
                              setRules("");
                              settitle("");
                              setStart_time("");
                              setPercentage(33);
                              setnoofque(null);
                              handleClose();
                              setState(0);
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#fff",
                              }}
                            >
                              Back to Dashboard
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
                {/* Add Quizz Offcanvas End  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
