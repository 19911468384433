import { toast } from "react-toastify";
import { instance } from "../../instance";
import {
  add_course_Error,
  add_course_Loading,
  add_course_Success,
  get_course_Error,
  get_course_Loading,
  get_course_Success,
  get_course_Success_search,
  get_particular_course_Error,
  get_particular_course_Loading,
  get_particular_course_Success,
} from "./prerecordtype";

export const addCourse = (form, handleClickOpen) => async (dispatch) => {
  dispatch({ type: add_course_Loading });
  const formdata = new FormData();
  Object.entries(form).map(([key, value]) => formdata.append(key, value));
  try {
    let res = await instance
      .post(`/instructor/PreRecordedCourse/create/pre_rec_course`, formdata)
      .then((res) => {
        console.log(res, "add course");
        if (res.data.status == 1) {
          dispatch({ type: add_course_Success, payload: res.data });
          toast(res.data.msg);
          handleClickOpen();
          dispatch(getpreCoursefun());
        } else {
          toast(res.data.Backend_Error);
        }
      });
  } catch (err) {
    dispatch({ type: add_course_Error });
  }
};
export const getpreCoursefun = () => async (dispatch) => {
  dispatch({ type: get_course_Loading });

  try {
    let res = await instance
      .get(`/instructor/PreRecordedCourse/view/list/pre_rec_course`)
      .then((res) => {
        console.log(res, "get course");
        if (res.data.status == 1) {
          dispatch({ type: get_course_Success, payload: res.data.courses });
          toast(res.data.msg);
        
        } else {
          toast(res.data.Backend_Error);
        }
      });
  } catch (err) {
    dispatch({ type: get_course_Error });
  }
};

export const getpreCoursefun_search = (toSearch) =>(dispatch)=>{
    console.log(toSearch, "hello", "iam here");
    dispatch({ type: get_course_Success_search, payload: toSearch });
}

export const getParticularpreCoursefun = (pre_rec_cou_id) => async (dispatch) => {
  dispatch({ type: get_particular_course_Loading });

  try {
    let res = await instance
      .post(`/instructor/PreRecordedCourse/view/particular/pre_rec_course/byid`,{pre_rec_cou_id}

      )
      .then((res) => {
        console.log(res, "get particolar course");
        if (res.data.status == 1) {
          dispatch({ type: get_particular_course_Success, payload: res.data.courses });
          toast(res.data.msg);
        
        } else {
          toast(res.data.Backend_Error);
        }
      });
  } catch (err) {
    dispatch({ type: get_particular_course_Error });
  }
};