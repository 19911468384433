import { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import All from "./All";
import Notstarted from "./Notstarted";
import Ongoing from "./Ongoing";
import Complete from "./Complete";
import Missed from "./Missed";
import { RiShareBoxFill } from "react-icons/ri";
import { FiClock } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import { API_URL } from "../env";
import { useDispatch, useSelector } from "react-redux";
import { instance } from "../instance";
import { getupcomeClassesFun } from "../redux/liveClasses/liveClassesActions";

export default function Dashboard() {
  const { upcomingClasses } = useSelector((store) => store.liveClass);

  const dispatch = useDispatch();
  const [live, setLive] = useState([]);
  const [allTask, setAllTask] = useState([]);

  const [batch, setBatch] = useState([]);
  const [State, setState] = useState("");
  const [Not_Completed, setNot_Completed] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [ongoing, setOngoing] = useState([]);
  const [missed, setMissed] = useState([]);
  console.log(Not_Completed, "Not_Completed");

  const classdata = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      mode: "no-cors",
    };

    fetch(`${API_URL}/read/class/upcom`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          console.log("result.....", result);
          setLive(result.parsedResults);
          // console.log('yyyyy',result.parsedResults);
        }
      })
      .catch((error) => console.log("error", error));
  };

  console.log(live, "classes");
  const getAllTask = () => {
    instance
      .get(`${API_URL}/instructor/schedule/all/task/dashboard`)
      .then((result) => {
        console.log(result, "getall Task");
        if (result.data.status == 1) {
          setAllTask(result.data.tasks);
        } else {
          console.log(result.data.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getnotCompletedTask = () => {
    instance
      .get(`${API_URL}/instructor/schedule/notstarted/task/dashboard`)
      .then((result) => {
        console.log(result, "get not start Task");
        if (result.data.status == 1) {
          setNot_Completed(result.data.selectedtasks);
        } else {
          console.log(result.data.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getCompletedTask = () => {
    instance
      .get(`${API_URL}/instructor/schedule/completed/task/dashboard`)
      .then((result) => {
        console.log(result, "get complite Task");
        if (result.data.status == 1) {
          setCompleted(result.data.selectedtasks);
        } else {
          console.log(result.data.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getOngoingTask = () => {
    instance
      .get(`${API_URL}/instructor/schedule/ongoing/task/dashboard`)
      .then((result) => {
        console.log(result, "get ongoing Task");
        if (result.data.status == 1) {
          setOngoing(result.data.selectedtasks);
        } else {
          console.log(result.data.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getmissedTask = () => {
    instance
      .get(`${API_URL}/instructor/schedule/missed/task/dashboard`)
      .then((result) => {
        console.log(result, "get missed Task");
        if (result.data.status == 1) {
          setMissed(result.data.selectedtasks);
        } else {
          console.log(result.data.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };

 
  const BatchDetails = async () => {
    instance
      .get(`${API_URL}/instructor/batches/view/batches/list/on/dashboard`)
      .then((result) => {
        console.log(result, "batch in dashbord");
        if (result.data.status == 1) {
          setBatch(result.data.batches);
        }
      })
      .catch((error) => console.log("error", error));
  };

  console.log(batch, "batch");

 

  useEffect(() => {
    dispatch(getupcomeClassesFun());
    setState("All");
    BatchDetails();
    classdata();
    getAllTask();

  }, []);

  return (
    <>
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="row g-0 px-auto">
          <div className="col-lg-2" style={{}}>
            <Sidebar />
          </div>
          <div className="col-lg-10">
            <Header />
            <div className="row mt-2 d-flex align-items-center justify-content-center">
              <div className="col-lg-3">
                <div className="row d-flex justify-content-between my-3">
                  <div className="col-6 d-flex justify-content-start">
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: 20,
                        fontFamily: "inter",
                      }}
                    >
                      Live Classes
                    </span>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <NavLink
                      to="/LiveClasses"
                      className="text-decoration-none border-0"
                    >
                      <button
                        type="button"
                        className="btn border-0"
                        style={{
                          height: 30,
                          backgroundColor: "#ECFFEF",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            color: "#11A529",
                            paddingRight: 10,
                            fontSize: 12,
                            fontFamily: "inter",
                            fontWeight: 400,
                          }}
                        >
                          View All
                        </div>
                        <RiShareBoxFill style={{ color: "#11A529" }} />
                      </button>
                    </NavLink>
                  </div>
                </div>
                <div className="bar3">
                  {upcomingClasses.length > 0 ? (
                    upcomingClasses &&
                    upcomingClasses.map((item, index) => {
                      return (
                        <Card
                          style={{
                            backgroundColor:
                              item.status == "ongoing"
                                ? "#FFECEC"
                                : item.status == "upcoming"
                                ? "#ECFFEE"
                                : "#fff",
                            border: "1px solid #000000",
                            marginBottom: 20,
                          }}
                        >
                          <Card.Body className="text-start">
                            <span
                              style={{
                                fontWeight: 600,
                                fontSize: 18,
                                textAlign: "start",
                                fontFamily: "inter",
                              }}
                            >
                              {item.class_topic}
                            </span>
                            <div
                              className="my-2"
                              style={{
                                width: "50%",
                                height: 30,
                                border: "1px solid #D3D3D3",
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  color: "#989898",
                                  fontFamily: "inter",
                                  fontWeight: "bold",
                                  fontSize: 12,
                                }}
                              >
                                {item.batch_name}
                              </span>
                            </div>
                            <div className="row">
                              <div className="col-6 d-flex align-items-center">
                                <FiClock style={{ color: "#989898" }} />
                                <span
                                  style={{
                                    color: "#989898",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    paddingLeft: 5,
                                    fontFamily: "inter",
                                  }}
                                >
                                  {item.s_time}
                                </span>
                              </div>
                              <div className="col-6 d-flex align-items-center justify-content-start">
                                <FaRegCalendarAlt
                                  style={{ color: "#989898" }}
                                />
                                <span
                                  style={{
                                    color: "#989898",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    paddingLeft: 5,
                                    fontFamily: "inter",
                                  }}
                                >
                                  {item.date}
                                </span>
                              </div>
                            </div>
                            {item.status == "ongoing" ? (
                              <div
                                className="my-2"
                                style={{
                                  width: "50%",
                                  height: 30,
                                  backgroundColor: "#FFDDDD",
                                  borderRadius: 5,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#F12222",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "inter",
                                  }}
                                >
                                  Status : <span>{item.status}</span>
                                </span>
                              </div>
                            ) : item.status == "upcoming" ? (
                              <div
                                className="my-2"
                                style={{
                                  width: "80%",
                                  height: 30,
                                  backgroundColor: "#DDFFE7",
                                  borderRadius: 5,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#0CC740",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "inter",
                                  }}
                                >
                                  Status : <span>{item.status}</span>
                                </span>
                              </div>
                            ) : (
                              <div
                                className="my-2"
                                style={{
                                  width: "50%",
                                  height: 30,
                                  backgroundColor: "#F1F1F1",
                                  borderRadius: 5,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#989898",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "inter",
                                  }}
                                >
                                  Status : <span>{item.status}</span>
                                </span>
                              </div>
                            )}
                            <div
                              className="mt-2"
                              style={{
                                width: "100%",
                                height: 40,
                                backgroundColor: "#000000",
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  color: "#fff",
                                  fontSize: 16,
                                  fontWeight: 400,
                                  fontFamily: "inter",
                                }}
                              >
                                Join Now
                              </span>
                            </div>
                          </Card.Body>
                        </Card>
                      );
                    })
                  ) : (
                    <>
                      <Card
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #9E9E9E",
                          marginTop: "50px",
                        }}
                      >
                        <Card.Body className="">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: 18,
                              fontFamily: "inter",
                              color: "red",
                            }}
                          >
                            No data available
                          </span>
                        </Card.Body>
                      </Card>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="row d-flex justify-content-between my-3">
                  <div className="col-6 d-flex justify-content-start">
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: 20,
                        fontFamily: "inter",
                      }}
                    >
                      My Tasks
                    </span>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <NavLink to="/Schedule" className="text-decoration-none">
                      <button
                        type="button"
                        className="btn border-0"
                        style={{
                          height: 30,
                          backgroundColor: "#ECF1FF",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#2C62EE",
                            paddingRight: 10,
                            fontSize: 14,
                            fontFamily: "inter",
                            fontWeight: 400,
                          }}
                        >
                          Open Kan Ban
                        </span>
                        <RiShareBoxFill style={{ color: "#2C62EE" }} />
                      </button>
                    </NavLink>
                  </div>
                </div>
                <div
                  className="my-2"
                  style={{
                    width: "100%",
                    height: 40,
                    borderBottom: "1px solid #EBEBEB",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className=""
                    style={{
                      with: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="">
                      <div
                        style={{
                          width: 100,
                          height: 30,
                          backgroundColor: State === "All" ? "#F0F1F7" : "",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          className=""
                          onClick={() => {
                            setState("All");
                            getAllTask();
                          }}
                          style={{
                            fontSize: 13,
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: State === "All" ? "#2C62EE" : "#7C7C7C",
                          }}
                        >
                          All
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <div
                        style={{
                          width: 100,
                          height: 30,
                          backgroundColor:
                            State === "NotStarted" ? "#F0F1F7" : "",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          className=""
                          onClick={() => {
                            setState("NotStarted");
                            getnotCompletedTask();
                          }}
                          style={{
                            fontSize: 13,
                            fontFamily: "inter",
                            fontWeight: 400,
                            color:
                              State === "NotStarted" ? "#2C62EE" : "#7C7C7C",
                          }}
                        >
                          Not Started
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <div
                        style={{
                          width: 100,
                          height: 30,
                          backgroundColor: State === "Ongoing" ? "#F0F1F7" : "",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          className=""
                          onClick={() => {
                            setState("Ongoing");
                            getOngoingTask();
                          }}
                          style={{
                            fontSize: 13,
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: State === "Ongoing" ? "#2C62EE" : "#7C7C7C",
                          }}
                        >
                          Ongoing
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <div
                        style={{
                          width: 100,
                          height: 30,
                          backgroundColor:
                            State === "Completed" ? "#F0F1F7" : "",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          className=""
                          onClick={() => {
                            setState("Completed");
                            getCompletedTask();
                          }}
                          style={{
                            fontSize: 13,
                            fontFamily: "inter",
                            fontWeight: 400,
                            color:
                              State === "Completed" ? "#2C62EE" : "#7C7C7C",
                          }}
                        >
                          Completed
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <div
                        style={{
                          width: 100,
                          height: 30,
                          backgroundColor: State === "Missed" ? "#F0F1F7" : "",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          className=""
                          onClick={() => {
                            setState("Missed");
                            getmissedTask();
                          }}
                          style={{
                            fontSize: 13,
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: State === "Missed" ? "#2C62EE" : "#7C7C7C",
                          }}
                        >
                          Missed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bar2">
                  {State === "All" ? (
                    <>
                      {" "}
                      <All allTask={allTask} />{" "}
                    </>
                  ) : (
                    <></>
                  )}
                  {State === "NotStarted" ? (
                    <>
                      {" "}
                      <Notstarted Not_Completed={Not_Completed} />{" "}
                    </>
                  ) : (
                    <></>
                  )}
                  {State === "Ongoing" ? (
                    <>
                      {" "}
                      <Ongoing ongoing={ongoing} />{" "}
                    </>
                  ) : (
                    <></>
                  )}
                  {State === "Completed" ? (
                    <>
                      {" "}
                      <Complete completed={completed} />{" "}
                    </>
                  ) : (
                    <></>
                  )}
                  {State === "Missed" ? (
                    <>
                      {" "}
                      <Missed missed={missed} />{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="row d-flex justify-content-between my-3">
                  <div className="col-6 d-flex justify-content-start">
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: 20,
                        fontFamily: "inter",
                      }}
                    >
                      My Batches
                    </span>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <NavLink to="/Batches" className="text-decoration-none">
                      <button
                        type="button"
                        className="btn border-0"
                        style={{
                          height: 30,
                          backgroundColor: "#FFECF9",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#EE2CB8",
                            paddingRight: 10,
                            fontSize: 12,
                            fontFamily: "inter",
                            fontWeight: 400,
                          }}
                        >
                          View All
                        </span>
                        <RiShareBoxFill style={{ color: "#EE2CB8" }} />
                      </button>
                    </NavLink>
                  </div>
                </div>

                <div className="bar3">
                  {batch.length > 0 ? (
                    batch &&
                    batch.map((item, index) => {
                      return (
                        <Card
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #9E9E9E",
                            marginBottom: 20,
                          }}
                        >
                          <Card.Body className="text-start">
                            <p
                              className="m-0 py-1"
                              style={{
                                fontWeight: 600,
                                fontSize: 18,
                                textAlign: "start",
                                fontFamily: "inter",
                              }}
                            >
                              {item.batch_name}
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontWeight: 400,
                                fontSize: 13,
                                textAlign: "start",
                                fontFamily: "inter",
                                color: "#888888",
                              }}
                            >
                              {item.subjects} Subjects
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontWeight: 400,
                                fontSize: 13,
                                textAlign: "start",
                                fontFamily: "inter",
                                color: "#888888",
                              }}
                            >
                              {item.students} Students
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontWeight: 400,
                                fontSize: 13,
                                textAlign: "start",
                                fontFamily: "inter",
                                color: "#888888",
                              }}
                            >
                              {item.quizes} Quizzes
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontWeight: 400,
                                fontSize: 13,
                                textAlign: "start",
                                fontFamily: "inter",
                                color: "#888888",
                              }}
                            >
                              {item.exams} Exams
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontWeight: 400,
                                fontSize: 13,
                                textAlign: "start",
                                fontFamily: "inter",
                                color: "#888888",
                              }}
                            >
                              {item.assignments} Assignments
                            </p>
                            <div
                              className="mt-2"
                              style={{
                                width: "100%",
                                height: 40,
                                backgroundColor: "#F3F3F3",
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  color: "#565656",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  fontFamily: "inter",
                                }}
                              >
                                Batch Ends at {item.end_date}
                              </span>
                            </div>
                          </Card.Body>
                        </Card>
                      );
                    })
                  ) : (
                    <>
                      <Card
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #9E9E9E",
                          marginTop: "50px",
                        }}
                      >
                        <Card.Body className="">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: 18,
                              fontFamily: "inter",
                              color: "red",
                            }}
                          >
                            No data available
                          </span>
                        </Card.Body>
                      </Card>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
