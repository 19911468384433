import {
  getBatch_error,
  getBatch_loading,
  getBatch_search,
  getBatch_success,
  getCourse_error,
  getCourse_loading,
  getCourse_search,
  getCourse_success,
  getLogo_error,
  getLogo_loading,
  getLogo_success,
  getSubject_error,
  getSubject_loading,
  getSubject_search,
  getSubject_success,
} from "./comentypes";

const initstate = {
  isLoading: false,
  isError: false,
  course: [],
  course_search: [],
  mysubject: [],
  mysubject_search: [],
  batchs: [],
  mybatchs_search: [],
  mylogo: "",
};
export const comenReducer = (state = initstate, { type, payload }) => {
  switch (type) {
    case getCourse_loading:{
      return{
        ...state,
        isLoading:true,
      }
    }
    case getCourse_error:{
      return{
        ...state,
        isError:true,
      }
    }
    case getCourse_success: {
      return {
        ...state,
        isLoading: false,
        course: payload,
        course_search: payload,
      };
    }
    case getCourse_search: {
      return {
        ...state,
        course_search: [
          ...state.course.filter((el) =>
            el.name.toLowerCase().includes(payload.toLowerCase())
          ),
        ],
      };
    }
    case getSubject_loading:{
      return{
        ...state,
        isLoading:true,
      }
    }
    case getSubject_error:{
      return{
        ...state,
        isError:true,
      }
    }

    case getSubject_success: {
      return {
        ...state,
        mysubject: payload,
        mysubject_search: payload,
      };
    }
    case getSubject_search: {
      console.log(payload, "onsearch of subject", state.mysubject);
      return {
        ...state,
        mysubject_search: [
          ...state.mysubject.filter((el) =>
            el.name.toLowerCase().includes(payload.toLowerCase())
          ),
        ],
      };
    }

    case getBatch_loading:{
      return{
        ...state,
        isLoading:true,
      }
    }
    case getBatch_error:{
      return{
        ...state,
        isError:true,
      }
    }
    case getBatch_success: {
      return {
        ...state,
        batchs: payload,
        mybatchs_search: payload,
      };
    }
    case getBatch_search: {
      return {
        ...state,
        mybatchs_search: [
          ...state.batchs.filter((el) =>
            el.batch_name.toLowerCase().includes(payload.toLowerCase())
          ),
        ],
      };
    }

    case getLogo_loading:{
      return{
        ...state,
        isLoading:true,
      }
    }
    case getLogo_error:{
      return{
        ...state,
        isError:true,
      }
    }
    case getLogo_success: {
      return {
        ...state,
        isLoading:false,
        mylogo: payload,
      };
    }
    
    default: {
      return state;
    }
  }
};
