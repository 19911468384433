import React from 'react';
import {BsDownload} from 'react-icons/bs';
import { API_URL } from "../env";
const Salary = () => {

  const get_salary =()=>{
    var myHeaders = new Headers();
myHeaders.append("Authorization",     `Bearer ${localStorage.getItem("token")}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${API_URL}/myprofile/salary`, requestOptions)
  .then(response => response.json())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
  }
  return (
    <>
     <div className='container'>
        <div className='row'>
            <div className='col-lg-12 mt-4'>
 
 <div style={{height:'40vh', overflowY:'scroll'}}> 
            <table class="table table-striped " >
  <thead>
    <tr style={{color:'#989898', fontWeight:'400'}}>
      <th scope="col">Date</th>
      <th scope="col">Amount</th>
      <th scope="col">Account Number</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr style={{color:'#989898', fontWeight:'400'}}>
      <td > 10-12-2022</td>
      <td>401521</td>
      <td>201245784512</td>
      <td><button style={{backgroundColor:'#3d70f52b', borderRadius:'5px', padding:'4px 8px',}}>salary slip <BsDownload/></button></td>
    </tr> 
    
  </tbody>
</table>
            </div>
            </div>
        </div>
     </div>
    </>
  )
}

export default Salary