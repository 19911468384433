import { useState, useEffect } from "react";
import { BsCheckLg } from "react-icons/bs";
import { ImArrowLeft2 } from "react-icons/im";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import Card from "react-bootstrap/Card";
import { ImageFile } from "./Svgicon";
import { useNavigate } from "react-router-dom";

export default function EditQuestion() {
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [next, setNext] = useState(0);
  const [question, setQuestion] = useState([]);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setSelectedImage(imageUrl);
  };

  const quedata = [
    {
      q: 1,
      que: "Who was elected as the prime minister of Britain in November 2022 ?",
    },
    {
      q: 2,
      que: "Who was elected as the prime minister of Britain in November 2022 ?",
    },
  ];

  useEffect(() => {
    setQuestion(quedata);
  }, []);

  return (
    <>
      <div style={{ display: next == 0 ? "block" : "none" }}>
        <div className="container-fluid p-5">
          <div className="row">
            <div
              className="col-2 text-start"
              style={{ fontFamily: "inter", fontSize: 24, fontWeight: 600 }}
            >
              Q1
            </div>
            <div
              onClick={() => setNext(next + 1)}
              className="col-2 ms-auto"
              style={{
                width: 100,
                height: 30,
                backgroundColor: "#EDF7FF",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#2188E7",
                }}
              >
                Save
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-8 me-auto">
              <p
                className="text-start"
                style={{ fontFamily: "inter", fontSize: 16, fontWeight: 500 }}
              >
                Who was elected as the prime minister of Britain in November
                2022 ?
              </p>
              <div className="row">
                <div className="col-6 me-auto">
                  <div style={{ width: "100%" }}>
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="Selected"
                        style={{ width: "100%", height: 200, borderRadius: 10 }}
                      />
                    ) : (
                      <p>No image selected</p>
                    )}
                    <label>
                      <div
                        className="px-4 mt-2"
                        onChange={handleImageChange}
                        style={{
                          width: "100%",
                          height: 35,
                          backgroundColor: "#EFEFEF",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ImageFile style={{}} />
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            fontFamily: "inter",
                            color: "#8A8A8A",
                            paddingLeft: 10,
                          }}
                        >
                          Add Attachment
                        </span>
                        <input type="file" hidden />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-8">
                  <ul className="list-group">
                    <li className="list-group-item text-start border-0">
                      <div
                        className="p-2"
                        style={{
                          width: "100%",
                          height: 40,
                          backgroundColor: "#F5F5F5",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            <span style={{ fontFamily: "inter", fontSize: 15 }}>
                              a. Rishi Sunak
                            </span>
                          </label>
                        </div>
                        <div
                          className="px-3 py-1"
                          style={{
                            color: "#2E8760",
                            fontFamily: "inter",
                            fontSize: 12,
                            backgroundColor: "#E9FFF5",
                            borderRadius: 5,
                          }}
                        >
                          <BsCheckLg style={{ marginRight: 10 }} />
                          Marked as Correct
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item text-start border-0">
                      <div
                        className="p-2"
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid #EFEFEF",
                        }}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            <span
                              style={{
                                fontFamily: "inter",
                                fontSize: 15,
                                fontWeight: 500,
                                color: "#8A8A8A",
                              }}
                            >
                              b. Bill Harry
                            </span>
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item text-start border-0">
                      <div
                        className="p-2"
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid #EFEFEF",
                        }}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            <span
                              style={{
                                fontFamily: "inter",
                                fontSize: 15,
                                fontWeight: 500,
                                color: "#8A8A8A",
                              }}
                            >
                              c. Prince Charles
                            </span>
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item text-start border-0">
                      <div
                        className="p-2"
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid #EFEFEF",
                        }}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            <span
                              style={{
                                fontFamily: "inter",
                                fontSize: 15,
                                fontWeight: 500,
                                color: "#8A8A8A",
                              }}
                            >
                              d. Lady Diana
                            </span>
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: next == 1 ? "block" : "none" }}>
        <div className="container-fluid p-5">
          <div
            className="text-start"
            style={{
              fontFamily: "inter",
              fontSize: 28,
              fontWeight: 600,
              color: "#474747",
            }}
          >
            Add New Questions
          </div>
          <div className="row my-2">
            <div
              className="col-2 text-start"
              style={{ fontFamily: "inter", fontSize: 16, fontWeight: 500 }}
            >
              Tag : Mathematics
            </div>
            <div
              onClick={() => setNext(next + 1)}
              className="col-2 ms-auto"
              style={{
                width: 150,
                height: 30,
                backgroundColor: "#3D70F5",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#fff",
                }}
              >
                Save & Publish
              </span>
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: "",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => navigate(-1)}
                className=""
                style={{
                  width: 35,
                  height: 35,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderRadius: 40,
                  border: "1px solid #D9D9D9",
                  cursor: "pointer",
                }}
              >
                <ImArrowLeft2 style={{ fontSize: 18, color: "#8A8A8A" }} />
              </div>
              <span
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 24,
                  paddingLeft: 20,
                }}
              >
                Preview Questions
              </span>
            </div>
          </div>
          <div className="que-scroll">
            {question.map((item, key) => {
              return (
                <>
                  <div
                    className="row mt-5"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="col-6">
                      <div className="row">
                        <span
                          className="col-2 text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            color: "#474747",
                            fontWeight: 400,
                          }}
                        >
                          Q{item.q}.
                        </span>
                        <div className="col-10">
                          <div className="row d-flex justify-content-end">
                            <div className="col-6">
                              <div className="row">
                                <div
                                  className="col-5 ms-auto"
                                  style={{
                                    height: 30,
                                    backgroundColor: "#3D70F5",
                                    borderRadius: 5,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <MdEdit style={{ color: "#fff" }} />
                                  <span
                                    className="px-2"
                                    style={{
                                      color: "#fff",
                                      fontFamily: "inter",
                                      fontWeight: 500,
                                      fontSize: 13,
                                    }}
                                  >
                                    Edit
                                  </span>
                                </div>
                                <div
                                  className="col-5 ms-auto"
                                  style={{
                                    height: 30,
                                    backgroundColor: "#ffff",
                                    border: "1px solid #3D70F5",
                                    borderRadius: 5,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <RiDeleteBin6Line
                                    style={{ color: "#3D70F5" }}
                                  />
                                  <span
                                    className="px-1"
                                    style={{
                                      color: "#3D70F5",
                                      fontFamily: "inter",
                                      fontWeight: 500,
                                      fontSize: 13,
                                    }}
                                  >
                                    Delete
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p
                          className="mt-3 text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            color: "#474747",
                            fontWeight: 400,
                          }}
                        >
                          {item.que}
                        </p>
                        <ul className="list-group">
                          <li className="list-group-item text-start border-0">
                            <div
                              className="rounded-0"
                              style={{
                                width: "100%",
                                height: 40,
                                backgroundColor: "#ffff",
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #EFEFEF",
                              }}
                            >
                              <span
                                style={{ fontFamily: "inter", fontSize: 15 }}
                              >
                                a. Rishi Sunak
                              </span>
                              <div
                                className="px-3 py-1"
                                style={{
                                  color: "#2E8760",
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  backgroundColor: "#E9FFF5",
                                  borderRadius: 5,
                                }}
                              >
                                <BsCheckLg style={{ marginRight: 10 }} />
                                Marked as Correct
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item text-start border-0">
                            <div
                              className="rounded-0"
                              style={{
                                width: "100%",
                                height: 40,
                                backgroundColor: "#ffff",
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #EFEFEF",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 15,
                                  color: "#8A8A8A",
                                }}
                              >
                                b. Bill Harry
                              </span>
                            </div>
                          </li>
                          <li className="list-group-item text-start border-0">
                            <div
                              className="rounded-0"
                              style={{
                                width: "100%",
                                height: 40,
                                backgroundColor: "#ffff",
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #EFEFEF",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 15,
                                  color: "#8A8A8A",
                                }}
                              >
                                c. Prince Charles
                              </span>
                            </div>
                          </li>
                          <li className="list-group-item text-start border-0">
                            <div
                              className="rounded-0"
                              style={{
                                width: "100%",
                                height: 40,
                                backgroundColor: "#ffff",
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #EFEFEF",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 15,
                                  color: "#8A8A8A",
                                }}
                              >
                                d. Lady Diana
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div style={{ display: next == 2 ? "block" : "none" }}>
        <div className="container">
          <div className="row mt-5">
            <div className="col-4 mt-5 mx-auto">
              <Card className="mt-5 p-4 border-0">
                <div
                  style={{ fontFamily: "inter", fontWeight: 600, fontSize: 22 }}
                >
                  5698{" "}
                  <span
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 22,
                      color: "#6D6D6D",
                    }}
                  >
                    Questions will be uploaded to
                  </span>
                  "Mathematics"
                </div>
                <div className="row mt-4">
                  <div className="col-10 m-auto">
                    <div className="row">
                      <div
                        className="col-4 me-auto"
                        style={{
                          height: 30,
                          backgroundColor: "#0CBC8B",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            color: "#fff",
                            fontSize: 12,
                            fontFamily: "inter",
                            fotWeight: 500,
                          }}
                        >
                          Confirm
                        </span>
                      </div>
                      <div
                        className="col-4 ms-auto"
                        style={{
                          height: 30,
                          backgroundColor: "#EFEFEF",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            color: "#8A8A8A",
                            fontSize: 12,
                            fontFamily: "inter",
                            fotWeight: 500,
                          }}
                        >
                          Cancel
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
