import "./App.css";
import Dashboard from "./Components/Dashboard";
import LiveClasses from "./Components/LiveClasses";
import Schedule from "./Components/Schedule";
import Quizzes from "./Components/Quizzes";
import Exam from "./Components/Exam";
import Assignments from "./Components/Assignments";
import Batches from "./Components/Batches";
import Login from "./Components/Login";
import PreRecorded from "./Components/PreRecorded";
import StudentProfile from "./Components/StudentProfile";
import EditQuestion from "./Components/EditQuestion";
import Doubt from "./Components/Doubt";
import TagDetail from "./Components/TagDetail";
import AddTag from "./Components/AddTag";
import { Routes, Route, Navigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyProfile from "./Components/MyProfile";
import ProtectedRoute from "./routes/ProtectedRoute";
import EnteryNumber from "./Components/EnteryNumber";

function App() {
  return (
    <div className="App" style={{ padding: 0 }}>
      <ToastContainer />
      <Routes>
      
      <Route index element={<Navigate to="/code" replace />} />
      <Route path="/code" element={<EnteryNumber />} />
      
      {/*<Route index element={<Navigate to="/Login" replace />} />*/}
      <Route path="/" element={<ProtectedRoute />}>
      <Route path="/Login" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/LiveClasses" element={<LiveClasses />} />
          <Route path="/Schedule" element={<Schedule />} />
          <Route path="/Quizzes" element={<Quizzes />} />
          <Route path="/Exam" element={<Exam />} />
          <Route path="/Assignments" element={<Assignments />} />
          <Route path="/Batches" element={<Batches />} />
          <Route path="/StudentProfile" element={<StudentProfile />} />
          <Route path="/PreRecorded" element={<PreRecorded />} />
          <Route path="/Doubt" element={<Doubt />} />
          <Route path="/EditQuestion" element={<EditQuestion />} />
          <Route path="/TagDetail/:id" element={<TagDetail />} />
          <Route path="/AddTag" element={<AddTag />} />
          <Route exact path="/myprofile" element={<MyProfile />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
