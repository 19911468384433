import React, { useState, useEffect } from "react";
import { RiShareBoxFill } from "react-icons/ri";
import { FiClock } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import { API_URL } from "../env";
import { instance } from "../instance";

export default function All({ allTask }) {
  const [live, setLive] = useState([]);
  const [pdf, setPdf] = useState([]);

  const pdfdata = [
    {
      pdfname: "Networking Mid Sem Question Paper.pdf",
    },
    {
      pdfname: "Networking Mid Sem Question Paper.pdf",
    },
    {
      pdfname: "Networking Mid Sem Question Paper.pdf",
    },
    {
      pdfname: "Networking Mid Sem Question Paper.pdf",
    },
    {
      pdfname: "Networking Mid Sem Question Paper.pdf",
    },
    {
      pdfname: "Networking Mid Sem Question Paper.pdf",
    },
  ];

  useEffect(() => {
    setLive([]);
    setPdf(pdfdata);
  }, []);

  return (
    <>
      <div className="bar2">
        {allTask.length > 0 ? (
          allTask &&
          allTask.map((item, index) => {
            return (
              <Card
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #9E9E9E",
                  marginBottom: 20,
                }}
              >
                <Card.Body className="text-start">
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      textAlign: "start",
                      fontFamily: "inter",
                    }}
                  >
                    {item.title}
                  </span>
                  {/* <div className='my-3' style={{ width:"50%", height:35, border:'1px solid #D3D3D3', borderRadius:5,display:"flex",alignItems:"center",justifyContent:"center" }} >
              <span style={{color:"#989898"}}>{item.batch}</span>
           </div> */}
                  <div className="row mt-2">
                    <div className="col-4 d-flex align-items-center">
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 16,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        Assigned by
                      </span>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-start">
                      {/*<FiClock style={{ color: "#989898" }} />*/}
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        {item.assigned_by}
                      </span>
                    </div>
                    {/*<div className="col-5 d-flex align-items-center justify-content-start">
                      <FaRegCalendarAlt style={{ color: "#989898" }} />
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        {item.assigndate}
                      </span>
                      </div>*/}
                  </div>
                  <div className="row mt-2">
                    <div className="col-3 d-flex align-items-center">
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 16,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        Due
                      </span>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-start">
                      <FiClock style={{ color: "#989898" }} />
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        {item.due_date?.split("T")[1]}
                      </span>
                    </div>
                    <div className="col-5 d-flex align-items-center justify-content-start">
                      <FaRegCalendarAlt style={{ color: "#989898" }} />
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        {item.due_date?.split("", 10)}
                      </span>
                    </div>
                  </div>
                  <div
                    className="my-3"
                    style={{
                      width: "21%",
                      height: 30,
                      backgroundColor:  item.status == "completed"
                      ? "#ECFFEE"
                      : item.status == "not completed"
                      ? "#ECFFEE"
                      : item.status == "ongoing"
                      ? "#ECF1FF"
                      : item.status == "missed"
                      ? "#FFECEC"
                      : "",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #E6E6E6",
                    }}
                  >
                    <span
                      style={{
                        color:
                          item.status == "completed"
                            ? "#0CC740"
                            : item.status == "not completed"
                            ? "green"
                            : item.status == "ongoing"
                            ? "#3D70F5"
                            : item.status == "missed"
                            ? "#F93333"
                            : "",
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "inter",
                      }}
                    >
                      {item.status}
                    </span>
                  </div>
                  {/*item.status == 0 ? (
                    <div
                      className="my-3"
                      style={{
                        width: "21%",
                        height: 30,
                        backgroundColor: "#fff",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #E6E6E6",
                      }}
                    >
                      <span
                        style={{
                          color: "#989898",
                          fontSize: 14,
                          fontWeight: 400,
                          fontFamily: "inter",
                        }}
                      >
                        {item.examstatus}
                      </span>
                    </div>
                  ) : item.status == 1 ? (
                    <div
                      className="my-3"
                      style={{
                        width: "18%",
                        height: 30,
                        backgroundColor: "#ECF1FF",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#3D70F5",
                          fontSize: 14,
                          fontWeight: 400,
                          fontFamily: "inter",
                        }}
                      >
                        {item.examstatus}
                      </span>
                    </div>
                  ) : item.status == 2 ? (
                    <div
                      className="my-3"
                      style={{
                        width: "23%",
                        height: 30,
                        backgroundColor: "#ECFFEE",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#0CC740",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {item.examstatus}
                      </span>
                    </div>
                  ) : (
                    <div
                      className="my-3"
                      style={{
                        width: "23%",
                        height: 30,
                        backgroundColor: "#ECF1FF",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#F93333",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {item.examstatus}
                      </span>
                    </div>
                      )*/}
                  {/*<button
                    type="button"
                    className="mt-3 border-0"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    style={{
                      width: "50%",
                      height: 40,
                      backgroundColor: "#3D70F5",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "inter",
                      }}
                    >
                      View Details
                    </span>
                    </button>*/}
                </Card.Body>
              </Card>
            );
          })
        ) : (
          <>
            {" "}
            <Card
              style={{
                backgroundColor: "#fff",
                border: "1px solid #9E9E9E",
                marginBottom: 20,
              }}
            >
              <Card.Body className="">
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    fontFamily: "inter",
                    color: "red",
                  }}
                >
                  No data available
                </span>
              </Card.Body>
            </Card>
          </>
        )}
      </div>
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "60%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <div className="row">
              <div
                className="col-3 text-start m-auto"
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
              >
                Task Details
              </div>
              <div className="col-2 m-auto">
                <select
                  className="form-select border-0"
                  aria-label="Default select example"
                  style={{
                    height: 35,
                    backgroundColor: "#EFEFEF",
                    borderRadius: 5,
                    fontSize: 12,
                    fontFamily: "inter",
                    fontWeight: 400,
                    color: "#707070",
                  }}
                >
                  <option
                    value="1"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: 12,
                      color: "red",
                    }}
                  >
                    Not Started
                  </option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div
                className="col-4 m-auto"
                style={{
                  height: 35,
                  backgroundColor: "#FFEBEB",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#FD2727",
                }}
              >
                Due Date : 12:00 PM | 12 Sep 2023
              </div>
              <div className="col-2 m-auto">
                <button
                  type="button"
                  className="border-0"
                  style={{
                    width: "100%",
                    height: 35,
                    backgroundColor: "#3D70F5",
                    borderRadius: 5,
                    color: "#fff",
                    fontFamily: "inter",
                    fontWeight: 400,
                    fontSize: 14,
                  }}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="row">
              <div
                className="text-start mt-4"
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#787878",
                }}
              >
                Title
              </div>
              <p
                className="text-start"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: 20,
                  color: "#3C3C3C",
                }}
              >
                Add a Quiz for Students of 3CO - JVY on the subject Fundamentals
                of Programming
              </p>
              <div
                className="text-start"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#787878",
                }}
              >
                Assigned by
              </div>
              <p
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 20,
                  color: "#3C3C3C",
                }}
              >
                Yogesh Jangid
              </p>
              <p
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 20,
                  color: "#787878",
                }}
              >
                Description
              </p>
              <p
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#3C3C3C",
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
                Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                Curabitur pellentesque nibh nibh, at maximus ante fermentum sit
                amet. Pellentesque commodo lacus at sodales sodales. Quisque
                sagittis orci ut diam condimentum, vel euismod erat placerat. In
                iaculis arcu eros, eget tempus orci facilisis id. Praesent lorem
                orci, mattis non efficitur id, ultricies vel nibh. Sed volutpat
                lacus vitae gravida viverra. Fusce vel tempor elit. Proin
                tempus, magna id scelerisque vestibulum, nulla ex pharetra
                sapien, tempor posuere massa neque nec felis. Aliquam sem ipsum,
                vehicula ac tortor vel, egestas ullamcorper dui. Curabitur at
                risus sodales, tristique est id, euismod justo. Mauris nec leo
                non libero sodales lobortis. Quisque a neque pretium, dictum
                tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies
                dignissim nibh ut cursus. Nam et quam sit amet turpis finibus
                maximus tempor eget augue. Aenean at ultricies lorem. Sed
                egestas ligula tortor, sit amet mattis ex feugiat non. Duis
                purus diam, dictum et ante at, commodo iaculis urna. Aenean
                lacinia, nisl id vehicula condimentum, enim massa tempor nibh,
                vitae volutpat sapien metus aliquet nisl. Etiam dui eros,
                tincidunt tristique blandit id, gravida vitae augue. Proin
                imperdiet mi nec justo convallis gravida. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. Mauris consectetur nibh in mattis commodo. Etiam in
                pellentesque eros. Etiam tempus.
              </p>
            </div>
            <div
              className="text-start mt-4"
              style={{
                fontFamily: "inter",
                fontWeight: 600,
                fontSize: 16,
                color: "#787878",
              }}
            >
              Attachments
            </div>
            <div className="row mt-2 g-0">
              {pdf.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-4 m-auto mb-2"
                    style={{
                      width: "30%",
                      height: 30,
                      border: "1px solid #D7D7D7",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      {item.pdfname}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const LiveClass = (props) => {};
