import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import { API_URL } from "../env";
import { useDispatch, useSelector } from "react-redux";
import { getupcomeClassesFun } from "../redux/liveClasses/liveClassesActions";

export default function ScheduledClasses() {
  const { upcomingClasses,upcomingClasses_search } = useSelector((store) => store.liveClass);
  // console.log(upcomingClasses," upcomingClasses",upcomingClasses_search);
const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getupcomeClassesFun());
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  return (
    <div className="bar3">
      <div className="row mt-4">
        {upcomingClasses_search?.length > 0 ? (
          upcomingClasses_search &&
          upcomingClasses_search?.map((item, index) => {
            return <LiveClass item={item} key={index} />;
          })
        ) : (
          <>
            <div className="mt-5" style={{ color: "red" }}>
              <h6>No Data Founds</h6>
            </div>
          </>
        )}
        </div>
    </div>
  );
}

const LiveClass = (props) => {
  return (
    <div className="col-4">
      <Card
        style={{
          backgroundColor:
            props.item.status == "ongoing"
              ? "#FFECEC"
              : props.item.status == "upcoming"
              ? "#ECFFEE"
              : "#fff",
          border: "1px solid #000000",
          marginBottom: 20,
        }}
      >
        <Card.Body className="text-start">
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
              textAlign: "start",
              fontFamily: "inter",
            }}
          >
            {props.item.class_topic} 
          </span>
          <div
            className="my-2"
            style={{
              width: "50%",
              height: 30,
              border: "1px solid #D3D3D3",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#989898",
                fontFamily: "inter",
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {props.item.batch_name}
            </span>
          </div>
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <FiClock style={{ color: "#989898" }} />
              <span
                style={{
                  color: "#989898",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.s_time}
              </span>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-start">
              <FaRegCalendarAlt style={{ color: "#989898" }} />
              <span
                style={{
                  color: "#989898",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.date}
              </span>
            </div>
          </div>
          {props.item.status == "ongoing" ? (
            <div
              className="my-2"
              style={{
                width: "50%",
                height: 30,
                backgroundColor: "#FFDDDD",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#F12222",
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
              >
                Status : <span>{props.item.status}</span>
              </span>
            </div>
          ) : props.item.status == "upcoming" ? (
            <div
              className="my-2"
              style={{
                width: "80%",
                height: 30,
                backgroundColor: "#DDFFE7",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#0CC740",
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
              >
                Status : <span>{props.item.status}</span>
              </span>
            </div>
          ) : (
            <div
              className="my-2"
              style={{
                width: "50%",
                height: 30,
                backgroundColor: "#F1F1F1",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#989898",
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
              >
                Status : <span>{props.item.status}</span>
              </span>
            </div>
          )}
          <div
            className="mt-2"
            style={{
              width: "100%",
              height: 40,
              backgroundColor: "#000000",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#fff",
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "inter",
              }}
            >
              Join Now
            </span>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
