import { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import MyBatches from "./MyBatches";
import Courses from "./Courses";
import { BsSearch } from "react-icons/bs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FaCalendar } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineFileUpload } from "react-icons/md";
import Card from "react-bootstrap/Card";
import { NavLink, useLocation } from "react-router-dom";
import { getbatchesFun } from "../redux/batches/batchActions";
import { useDispatch } from "react-redux";

export default function Batches() {
  const [State, setState] = useState();
  const [data, setData] = useState();
  const [next, setNext] = useState(0);
  const [subject, setSubject] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [Times, setTimes] = useState("");
  const [TimePerQuestion, setTimePerQuestion] = useState("");
  const [file, setFile] = useState();

  const dispatch = useDispatch()

  

  useEffect(() => {
    dispatch(getbatchesFun());
    setState("MyBatches");
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-lg-2 g-0" style={{}}>
          <Sidebar />
        </div>
        <div className="col-lg-10 g-0" style={{}}>
          <Header />
          <div
            className="mt-3 px-3"
            style={{
              width: "100%",
              height: 40,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "inter", fontSize: 20, fontWeight: 600 }}>
              My Batches 
            </div>
          </div>
          <div
            className="row px-3 g-0"
            style={{
              height: 40,
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #E4E4E4",
            }}
          >
            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <div style={{ display: "flex" }}>
                <button
                  type="button"
                  onClick={() => {
                    setState("MyBatches");
                  }}
                  className="rounded-0 bg-transparent"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    borderBottom:
                      State == "MyBatches" ? "1px solid #3D70F5" : "none",
                    color: State == "MyBatches" ? "#3D70F5" : "#000",
                    width: 100,
                    height: 38,
                  }}
                >
                  Batches
                </button>
                {/* date<button type="button" onClick={()=>{setState("Courses")}} className="rounded-0 bg-transparent mx-4" style={{border:"none", fontFamily:"inter", fontSize:16, fontWeight:400, borderBottom:State == "Courses" ? '1px solid #3D70F5' : "none", color:State == "Courses" ? "#3D70F5" : '#000', width:100, height:38 }}>Course</button> */}
              </div>
            </div>
          </div>
          <div className="bar2 mt-2 mx-4">
            {State === "MyBatches" ? (
              <>
                {" "}
                <MyBatches />{" "}
              </>
            ) : (
              <></>
            )}
            {/* { State === "Courses" ? <> <Courses /> </> : <></> } */}
          </div>
        </div>
      </div>
    </div>
  );
}
