import { useState, useEffect } from "react";
import { FaCalendar } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import Card from "react-bootstrap/Card";
import { NavLink, useLocation } from "react-router-dom";

export default function QuizHisDetail({Offcanva_data,Offcanva_dataquizrules,tagdata}) {
 const {numberofquestion,passingpersentage,start_time,timeperquestion} = Offcanva_data
 
  return (
    <div className="table-scroll">
      <div className="row">
        <div className="col-6 me-auto">
          <Card className="border-0">
            <p
              className="text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 18 }}
            >
              Number of Questions : {numberofquestion}
            </p>
            <div className="row mt-2">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#3C3C3C",
                  }}
                >
                  Passing Percentage
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#3D70F5",
                  }}
                >
                  {passingpersentage} %
                </p>
              </div>
            </div>
            <div
              className="mt-3"
              style={{
                width: "100%",
                height: 8,
                backgroundColor: "#D9D9D9",
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  width: "45%",
                  height: 8,
                  backgroundColor: "#3D70F5",
                  borderRadius: 10,
                }}
              ></div>
            </div>
            <div className="row mt-3">
              <span
                className="px-3 text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  color: "#434343",
                  fontSize: 14,
                }}
              >
                Quiz Schedule
              </span>
              <div className="col-4">
                <div
                  style={{
                    width: "100%",
                    height: 30,
                    backgroundColor: "#EDEDF5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  <FaCalendar />
                  <span
                    style={{
                      color: "#3C3C3C",
                      fontWeight: 400,
                      fontSize: 12,
                      fontFamily: "inter",
                      paddingLeft: 10,
                    }}
                  >
                    {start_time?.split("", 10)}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div
                  style={{
                    width: "100%",
                    height: 30,
                    backgroundColor: "#EDEDF5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  <FiClock />
                  <span
                    style={{
                      color: "#3C3C3C",
                      fontWeight: 400,
                      fontSize: 12,
                      fontFamily: "inter",
                      paddingLeft: 10,
                    }}
                  >
                    {start_time?.split("T")[1]}
                  </span>
                </div>
              </div>
            </div>
            <div
            className="text-start my-4"
            style={{
              fontFamily: "inter",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Tags Composition
          </div>
          {Object?.entries(tagdata)?.map(([key, value]) => (
            <>
              <div className="row mt-2">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    className="m-0"
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#3C3C3C",
                    }}
                  >
                  {key}
                  </p>
                  <p
                    className="m-0"
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#3D70F5",
                    }}
                  >
                    {value}%
                  </p>
                </div>
              </div>
              <div
                className="mt-3"
                style={{
                  width: "100%",
                  height: 8,
                  backgroundColor: "#D9D9D9",
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    width: `${value}%`,
                    height: 8,
                    backgroundColor: "#3D70F5",
                    borderRadius: 10,
                  }}
                ></div>
              </div>
            </>
          ))}

          
            <div className="row">
              <ul className="list-group text-start border-0">
                {Offcanva_dataquizrules?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="list-group-item d-flex align-items-center border-0"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      {" "}
                      <div
                        className="me-2"
                        style={{
                          width: 7,
                          height: 7,
                          backgroundColor: "#000",
                          borderRadius: 10,
                        }}
                      ></div>
                      {item}
                    </li>
                  );
                })}
             
              </ul>
            </div>
            <div className="row my-4">
              <div className="col-8">
                <div
                  style={{
                    height: 35,
                    backgroundColor: "#E6E6E6",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "inter",
                    fontWeight: 400,
                    fontSize: 14,
                    color: "#9E9E9E",
                    borderRadius: 5,
                  }}
                >
                  Time Per Question : {timeperquestion} Seconds
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
