import { useState, useEffect } from "react";
import { RiHome2Line } from "react-icons/ri";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BsFillShareFill } from "react-icons/bs";
import { GiNotebook } from "react-icons/gi";
import { FaLayerGroup } from "react-icons/fa";
import { HiStatusOnline } from "react-icons/hi";
import { BiLogOutCircle } from "react-icons/bi";
import { MdOutlineAssignment } from "react-icons/md";
import { RiQuestionMark } from "react-icons/ri";
import { BsPlayBtn } from "react-icons/bs";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Tipsglogo from "../images/tipsglogo.png";
import { API_URL } from "../env";
import { useLogo } from "../Context/instituteContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { getLogoFun } from "../redux/comenapi/comenAction";
import { LogoutFun } from "../redux/auth/authActions";

export default function SideBar() {
  const { mylogo } = useSelector((store) => store.comenapi);
  const dispatch = useDispatch();

  
  // const [logo, setLogo] = useState("");

  const location = useLocation();
  const Navigate = useNavigate();


  // const showLogo = async () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append(
  //     "Authorization",
  //     `Bearer ${localStorage.getItem("token")}`
  //   );

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(`${API_URL}/get/logo`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result, "in side bar before status");
  //       if (result.status == 1) {
  //         setLogo(result.logo);
  //         console.log(result.logo, "in side bar after status 1");
  //       } else {
  //         console.log(result.logo, "in side bar else");
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  useEffect(() => {
    dispatch(getLogoFun());
    // showLogo();
   
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  return (
    <div className="row g-0">
      <ul
        className="list-group text-start list"
        style={{
          borderRight: "1px solid #B1AFC8",
          borderRadius: 0,
          height: 748,
          width: "100%",
        }}
      >
        <div
          className="g-0"
          style={{
            width: "100%",
            height: 65,
            borderBottom: "1px solid #B1AFC8",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={mylogo}
            className="header"
            style={{ width: "70%" }}
            alt="logo"
          />
        </div>

        <div>
          <li></li>
          <NavLink to="/Dashboard" className="text-decoration-none text-dark ">
            <li
              className={
                "list-group-item px-4 text-decoration-none side_list" +
                (location.pathname == "/Dashboard" ? " active" : " ")
              }
              aria-current="true"
              style={{ fontSize: "16px", margin: 15 }}
            >
              <span style={{ fontFamily: "inter", fontWeight: 400 }}>
                <RiHome2Line
                  style={{ fontSize: "20px", marginBottom: "5px" }}
                />
                &emsp;Dashboard
              </span>
            </li>
          </NavLink>
          <NavLink to="/LiveClasses" className="text-decoration-none text-dark">
            <li
              className={
                "list-group-item px-4 text-decoration-none side_list" +
                (location.pathname == "/LiveClasses" ? " active" : "")
              }
              aria-current="true"
              style={{ fontSize: "16px", margin: 15 }}
            >
              <span style={{ fontFamily: "inter", fontWeight: 400 }}>
                <HiStatusOnline
                  style={{ fontSize: "20px", marginBottom: "5px" }}
                />
                &emsp;Live Classes
              </span>
            </li>
          </NavLink>
          <NavLink to="/Schedule" className="text-decoration-none text-dark">
            <li
              className={
                "list-group-item px-4 text-decoration-none side_list" +
                (location.pathname == "/Schedule" ? " active" : " ")
              }
              aria-current="true"
              style={{ fontSize: "16px", margin: 15 }}
            >
              <span style={{ fontFamily: "inter", fontWeight: 400 }}>
                <FaRegCalendarAlt
                  style={{ fontSize: "20px", marginBottom: "5px" }}
                />
                &emsp;Schedule
              </span>
            </li>
          </NavLink>
          <NavLink to="/Quizzes" className="text-decoration-none text-dark">
            <li
              className={
                "list-group-item px-4 text-decoration-none side_list" +
                (location.pathname == "/Quizzes" ? " active" : " ")
              }
              aria-current="true"
              style={{ fontSize: "16px", margin: 15 }}
            >
              <span style={{ fontFamily: "inter", fontWeight: 400 }}>
                <BsFillShareFill
                  style={{ fontSize: "20px", marginBottom: "5px" }}
                />
                &emsp;Quizzes
              </span>
            </li>
          </NavLink>
          <NavLink to="/Exam" className="text-decoration-none text-dark">
            <li
              className={
                "list-group-item px-4 text-decoration-none side_list" +
                (location.pathname == "/Exam" ? " active" : " ")
              }
              aria-current="true"
              style={{ fontSize: "16px", margin: 15 }}
            >
              <span style={{ fontFamily: "inter", fontWeight: 400 }}>
                <GiNotebook style={{ fontSize: "20px", marginBottom: "5px" }} />
                &emsp;Exams
              </span>
            </li>
          </NavLink>
          <NavLink to="/Assignments" className="text-decoration-none text-dark">
            <li
              className={
                "list-group-item px-4 text-decoration-none side_list" +
                (location.pathname == "/Assignments" ? " active" : " ")
              }
              aria-current="true"
              style={{ fontSize: "16px", margin: 15 }}
            >
              <span style={{ fontFamily: "inter", fontWeight: 400 }}>
                <MdOutlineAssignment
                  style={{ fontSize: "20px", marginBottom: "5px" }}
                />
                &emsp;Assignments
              </span>
            </li>
          </NavLink>
          <NavLink to="/Batches" className="text-decoration-none text-dark">
            <li
              className={
                "list-group-item px-4 text-decoration-none side_list" +
                (location.pathname == "/Batches" ? " active" : " ")
              }
              aria-current="true"
              style={{ fontSize: "16px", margin: 15 }}
            >
              <span style={{ fontFamily: "inter", fontWeight: 400 }}>
                <FaLayerGroup
                  style={{ fontSize: "20px", marginBottom: "5px" }}
                />
                &emsp;Batches
              </span>
            </li>
          </NavLink>
          <NavLink to="/Doubt" className="text-decoration-none text-dark">
            <li
              className={
                "list-group-item px-4 text-decoration-none side_list" +
                (location.pathname == "/Doubt" ? " active" : " ")
              }
              aria-current="true"
              style={{ fontSize: "16px", margin: 15 }}
            >
              <span style={{ fontFamily: "inter", fontWeight: 400 }}>
                <RiQuestionMark
                  style={{ fontSize: "20px", marginBottom: "5px" }}
                />
                &emsp;Doubts
              </span>
            </li>
          </NavLink>
          <NavLink to="/PreRecorded" className="text-decoration-none text-dark">
            <li
              className={
                "list-group-item px-4 text-decoration-none side_list" +
                (location.pathname == "/PreRecorded" ? " active" : " ")
              }
              aria-current="true"
              style={{ fontSize: "16px", margin: 15 }}
            >
              <span style={{ fontFamily: "inter", fontWeight: 400 }}>
                <BsPlayBtn style={{ fontSize: "20px", marginBottom: "5px" }} />
                &emsp;Pre - Recorded
              </span>
            </li>
          </NavLink>
        </div>

        <div
          onClick={() => {
            dispatch(LogoutFun(Navigate))
            // Navigate("/Login");
          }}
          style={{
            height: 40,
            backgroundColor: "#FFEFEF",
            margin: 20,
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <BiLogOutCircle style={{ fontSize: 20, color: "#F93333" }} />
          <span
            style={{
              color: "#F93333",
              fontFamily: "inter",
              fontWeight: 400,
              fontSize: 15,
              paddingLeft: 15,
            }}
          >
            Log Out
          </span>
        </div>
      </ul>
    </div>
  );
}
