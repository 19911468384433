import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { FaCalendar } from "react-icons/fa";
import { RiCloseFill } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import { instance } from "../instance";


const initLoader = {
  isLoading: false,
  isError: false,
};
export default function Quiz() {
const {quiz_particular} = useSelector((store)=>store.batch)
console.log(quiz_particular,"quiz_particular in quiz")
  const [live, setLive] = useState();
  const [affairs, setAffairs] = useState();
  const [Reasoning, setReasoning] = useState();
  const [Computer, setComputer] = useState();
  const [rules, setRules] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Offcanva_data, setOffCanvaData] = useState({});
  const [Offcanva_dataquizrules, setOffCanvaDataquizrules] = useState();
  const [loader, setLoader] = useState(initLoader);
  const [tagdata, setTagdata] = useState({});



  //   const frontend_url = Window.location.href;
  console.log(Offcanva_data, "in Offcanva_data");

    //function for get quize data for particular quize
    const getParticularQuize = async (id) => {
      console.log(id, "before hit api");
      setLoader({
        ...loader,
        isLoading: true,
      });
      try {
        let res = await instance
          .post(`/instructor/quizes/read/quizbyid/${id}`)
          .then((result) => {
            console.log(result,"result")
            if (result.data.status == 1) {
              // console.log(result.data.parsedrows, "parsedrows data particular quiz");
              setOffCanvaData(result.data.quiz_data);
              setOffCanvaDataquizrules(
                result?.data?.quiz_data?.quizrules
              );
              setTagdata(result?.data?.quiz_data?.tagwithpersentage);
              setLoader({
                ...loader,
                isLoading: false,
              });
              
            } 
          });
      } catch (err) {
        setLoader({
          ...loader,
          isLoading: false,
        });
        console.log(err);
      }
    };

  const classdata = [
    {
      topic:
        "Add a Quiz for Students of 3CO - JVY on the subject Fundamentals of Programming",
      course: "B.Tech Specialization in Health Informatics",
      subject: "Network Engineering",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      question: 50,
      width: 70,
    },
    {
      topic:
        "Add a Quiz for Students of 3CO - JVY on the subject Fundamentals of Programming",
      course: "B.Tech Specialization in Health Informatics",
      subject: "Cyber Security",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      question: 50,
      width: 50,
    },
    {
      topic:
        "Add a Quiz for Students of 3CO - JVY on the subject Fundamentals of Programming",
      course: "B.Tech Specialization in Health Informatics",
      subject: "Cyber Security",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      question: 50,
      width: 50,
    },
    {
      topic:
        "Add a Quiz for Students of 3CO - JVY on the subject Fundamentals of Programming",
      course: "B.Tech Specialization in Health Informatics",
      subject: "Cyber Security",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      question: 50,
      width: 50,
    },
  ];


  useEffect(() => {
    setLive(classdata);
    // setRules(ruleslist);
  }, []);

  return (

    <div className="bar3">
   
     <div className="row mt-2 g-0">
     
        {quiz_particular &&
          quiz_particular?.map((item, index) => {
            return (
              <LiveClass item={item} key={index} handleShow={handleShow} getParticularQuize={getParticularQuize}/>
            );
          })}
      </div>
       <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ width: "60%" }}
      >
        <Offcanvas.Header>
          <button
            type="button"
            onClick={handleClose}
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          {/*<div
            className="px-3"
            style={{
              height: 30,
              backgroundColor: "#fff",
              border: "1px solid #E72121",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#E72121",
              fontFamily: "inter",
              fontSize: 13,
              fontWeight: 600,
              borderRadius: 5,
            }}
          >
            <RiDeleteBin6Line />
            <span style={{ paddingLeft: 10 }}>Delete</span>
          </div>*/}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-body">
            <div className="">
              <div className="row">
                <div className="col-8 me-auto">
                  <Card className="border-0">
                    <div
                      className="text-start py-2"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        fontSize: 25,
                      }}
                    >
                      Quiz Details
                    </div>
                    <p
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        fontSize: 15,
                      }}
                    >
                      Quiz Name :{Offcanva_data?.title}
                    </p>
                    <p
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 500,
                        fontSize: 15,
                      }}
                    >
                      Number of Questions : {Offcanva_data?.numberofquestion}
                    </p>
                    <p
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 500,
                        fontSize: 15,
                      }}
                    >
                      Course : {Offcanva_data?.coursename}
                    </p>
                    <p
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 500,
                        fontSize: 15,
                      }}
                    >
                      Subject :{Offcanva_data?.subjectname}
                    </p>
                    <div className="row mt-2">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          className="m-0"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#3C3C3C",
                          }}
                        >
                          Passing Percentage
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#3D70F5",
                          }}
                        >
                          {Offcanva_data?.passingpersentage} %
                        </p>
                      </div>
                    </div>
                    <div
                      className="mt-3"
                      style={{
                        width: "100%",
                        height: 8,
                        backgroundColor: "#D9D9D9",
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          width: Offcanva_data?.passingpersentage + "%",
                          height: 8,
                          backgroundColor: "#3D70F5",
                          borderRadius: 10,
                        }}
                      ></div>
                    </div>
                    <div className="row mt-3">
                      <span
                        className="px-3 text-start"
                        style={{
                          fontFamily: "inter",
                          fontWeight: 400,
                          color: "#434343",
                          fontSize: 14,
                        }}
                      >
                        Quiz Schedule
                      </span>
                      <div className="col-4">
                        <div
                          style={{
                            width: "100%",
                            height: 30,
                            backgroundColor: "#EDEDF5",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                        >
                          <FaCalendar />
                          <span
                            style={{
                              color: "#3C3C3C",
                              fontWeight: 400,
                              fontSize: 14,
                              fontFamily: "inter",
                              paddingLeft: 10,
                            }}
                          >
                          {Offcanva_data?.start_time?.split("",10)}
                          </span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div
                          style={{
                            width: "100%",
                            height: 30,
                            backgroundColor: "#EDEDF5",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                        >
                          <FiClock />
                          <span
                            style={{
                              color: "#3C3C3C",
                              fontWeight: 400,
                              fontSize: 14,
                              fontFamily: "inter",
                              paddingLeft: 10,
                            }}
                          >
                          {Offcanva_data?.start_time?.split("T")[1]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="text-start my-4"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        fontSize: 20,
                      }}
                    >
                      Tags Composition
                    </div>
                    {Object.entries(tagdata)?.map(([key, value]) => (
                      <>
                        <div className="row mt-2">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              className="m-0"
                              style={{
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#3C3C3C",
                              }}
                            >
                             {key}
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#3D70F5",
                              }}
                            >
                              {value}%
                            </p>
                          </div>
                        </div>
                        <div
                          className="mt-3"
                          style={{
                            width: "100%",
                            height: 8,
                            backgroundColor: "#D9D9D9",
                            borderRadius: 10,
                          }}
                        >
                          <div
                            style={{
                              width: `${value}%`,
                              height: 8,
                              backgroundColor: "#3D70F5",
                              borderRadius: 10,
                            }}
                          ></div>
                        </div>
                      </>
                    ))}
                    
                   
                   
                    <div className="row">
                      <div
                        className="text-start"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                        }}
                      >
                        Rules
                      </div>
                      <ul className="list-group text-start border-0">
                      {Offcanva_dataquizrules?.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="list-group-item d-flex align-items-center border-0"
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            <div
                              className="me-2"
                              style={{
                                width: 5,
                                height: 5,
                                backgroundColor: "#000",
                                borderRadius: 10,
                              }}
                            ></div>
                            {item}
                          </li>
                        );
                      })}
                      </ul>
                    </div>
                    <div className="row my-4">
                      <div className="col-8">
                        <div
                          style={{
                            height: 35,
                            backgroundColor: "#E6E6E6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "inter",
                            fontWeight: 400,
                            fontSize: 14,
                            color: "#9E9E9E",
                            borderRadius: 5,
                          }}
                        >
                          Time Per Question : {Offcanva_data?.timeperquestion} Seconds
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
                        </Offcanvas>
                        </div>
  );
}

const LiveClass = (props) => {
  console.log(props, "in live class component");
  return (
    <div className="col-3">
      <Card
        style={{
          backgroundColor: "#fff",
          border: "1px solid #E6E6E6",
          marginBottom: 10,
          width: "95%",
        }}
      >
        <Card.Body className="text-start">
          <span
            style={{
              fontWeight: 600,
              fontSize: 16,
              textAlign: "start",
              fontFamily: "inter",
            }}
          >
            {props.item.title}
          </span>
          <div
            className="row g-0 mt-2"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p
              className="col-2 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Course
            </p>
            <p
              className="col-10 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                color: "#9A9A9A",
                fontWeight: 400,
                textAlign: "left ",
              }}
            >
              {" "}
              :&nbsp;&nbsp;{props.item.coursename}
            </p>
          </div>
          <div
            className="row g-0 mt-2"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p
              className="col-2 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Subject
            </p>
            <p
              className="col-10"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                color: "#9A9A9A",
                fontWeight: 400,
                textAlign: "left ",
              }}
            >
              {" "}
              :&nbsp;&nbsp;{props.item.subjectname}
            </p>
          </div>
          <div className="row g-0">
            <div className="col-5 d-flex align-items-center justify-content-start">
              <FaCalendar style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.end_time?.split("",10)}
              </span>
            </div>
            <div className="col-7 d-flex align-items-center justify-content-start">
              <FiClock style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.end_time?.split("T")[1]}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <p
              className="col-12 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Questions : {props.item.numberofquestion}
            </p>
          </div>
          <div className="row mt-2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#3C3C3C",
                }}
              >
                Passing Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3D70F5",
                }}
              >
                {props.item.passingpersentage} %
              </p>
            </div>
          </div>
          <div
            className="mt-3"
            style={{
              width: "100%",
              height: 8,
              backgroundColor: "#D9D9D9",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                width: props.item.passingpersentage + "%",
                height: 8,
                backgroundColor: "#3D70F5",
                borderRadius: 10,
              }}
            ></div>
          </div>
          <div
            onClick={()=>{props.handleShow();
              props.getParticularQuize(props.item.id)}}
            className="mt-3"
            style={{
              width: "100%",
              height: 40,
              backgroundColor: "#3D70F5",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                color: "#fff",
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "inter",
              }}
            >
              View Details
            </span>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
