import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { FaCalendar, FaRegCalendarAlt } from "react-icons/fa";
import { RiCheckDoubleLine, RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import myimg from "../images/profile-avatar-account-icon.png";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { API_URL } from "../env";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getreadHistoryAssignmentFun,
  getresultStudentFun,
  getScheduleassignmentFunById,
  getStudentprofileFun
} from "../redux/assignments/assignmentActions";
import { Document, Page } from 'react-pdf';

export default function ScheduledAssignments() {
  const { historyassignment_search, scheduleassignmetnbyid, getStudentresult,getStudentprofile } =
    useSelector((store) => store.assignment);
  const dispatch = useDispatch();
  console.log(getStudentprofile, "getStudentprofile");
const getStudentresult_data = getStudentresult.data
const navigate = useNavigate()

const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

const onDocumentLoadSuccess=()=> {
    setNumPages(numPages);
  }

  const [State2, setState2] = useState();
  const [details, setDetails] = useState([]);
  const [student_in_exam, setStudentsInExam] = useState([]);
  const [exam_id, setExamId] = useState("");
  const [response, setResponse] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [mark, setmark] = useState();
  const [assignment_id, setAssignment_id] = useState();

  const [addnote, setaddnote] = useState("");
 

  //handling for modal
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  

  const AddMark = (assignment_id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var raw = JSON.stringify({
      stu_id: getStudentresult_data[0]?.stu_id,
      assignment_id: assignment_id,
      marks: mark,
      note: addnote,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/assignments/give/marks/tostu/assignment`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.status == "VAL_ERR"){
          toast(result.Backend_Error)
        }
        if(result.status == 1){
          console.log(result, "Add mark function");
          toast(result.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };


  const publishAssignment = () =>{
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

var raw = JSON.stringify({
  "assignment_id":assignment_id,
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/instructor/assignments/assignment/result/declare`, requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result,"publish assignment")
    handleClose();
  })
  .catch(error => console.log('error', error));
  }
  useEffect(() => {
    dispatch(getreadHistoryAssignmentFun());

    setState2("Details1");
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  return (
    <>
      <div className="bar3">
        <div className="row">
          {historyassignment_search?.length > 0 ? (
            historyassignment_search &&
            historyassignment_search?.map((item, index) => {
              return (
                <>
                  <div className="col-4" key={index}>
                    <div
                      className="card"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #E6E6E6",
                        marginBottom: 20,
                        width: "100%",
                      
                      }}
                    >
                      <div className="text-start p-3">
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            textAlign: "start",
                            fontFamily: "inter",
                          }}
                        >
                          {item.assignment_name}
                        </span>
                        <div
                          className="row g-0 mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <p
                            className="col-2 m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              color: "#9A9A9A",
                              fontWeight: 400,
                            }}
                          >
                            Course{" "}
                          </p>
                          <p
                            className="col-10 m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              color: "#9A9A9A",
                              fontWeight: 400,
                              textAlign: "left ",
                            }}
                          >
                            {" "}
                            :&nbsp;&nbsp;{item.cou_name}
                          </p>
                        </div>
                        <div
                          className="row g-0 mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <p
                            className="col-2 m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              color: "#9A9A9A",
                              fontWeight: 400,
                            }}
                          >
                            Subject
                          </p>
                          <p
                            className="col-10"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              color: "#9A9A9A",
                              fontWeight: 400,
                              textAlign: "left ",
                            }}
                          >
                            {" "}
                            :&nbsp;&nbsp;{item.sub_name}
                          </p>
                        </div>
                        <div className="row g-0">
                          <div className="col-8 d-flex align-items-center justify-content-start">
                            <FaCalendar style={{ color: "#3C3C3C" }} />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                paddingLeft: 5,
                                fontFamily: "inter",
                              }}
                            >
                              {item.startTime?.split("T")?.join(" ")}
                            </span>
                          </div>
                          {/*<div className="col-7 d-flex align-items-center justify-content-start">
                            <FiClock style={{ color: "#3C3C3C" }} />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                paddingLeft: 5,
                                fontFamily: "inter",
                              }}
                            >
                              {item.sttime} - {item.entime}
                            </span>
                            </div>*/}
                        </div>
                        <div className="row mt-2">
                          <p
                            className="col-12 m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 16,
                              color: "#9A9A9A",
                              fontWeight: 400,
                            }}
                          >
                            Total marks : {item.tot_mark}
                          </p>
                        </div>
                        <div className="row mt-2">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              className="m-0"
                              style={{
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#3C3C3C",
                              }}
                            >
                              Passing Percentage
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#3D70F5",
                              }}
                            >
                              {item.pass_pers} %
                            </p>
                          </div>
                        </div>
                        <div
                          className="mt-3"
                          style={{
                            width: "100%",
                            height: 8,
                            backgroundColor: "#D9D9D9",
                            borderRadius: 10,
                          }}
                        >
                          <div
                            style={{
                              width: item.pass_pers + "%",
                              height: 8,
                              backgroundColor: "#3D70F5",
                              borderRadius: 10,
                            }}
                          ></div>
                        </div>

                        {item.res_dec_date == null ? (
                          <button
                            onClick={() => {
                              // viewDetail(item.id);
                              // handle2(item.id);
                              // setExamId(item.id);
                              setAssignment_id(item?.id);
                              // setState1("result");
                              dispatch(getScheduleassignmentFunById(item?.id));
                              dispatch(
                                getresultStudentFun({ assign_id: +item?.id })
                              );
                            }}
                            type="button"
                            className="btn mt-3"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightPublish"
                            aria-controls="offcanvasRight"
                            style={{
                              width: "100%",
                              height: 40,
                              backgroundColor: "#3D70F5",
                              borderRadius: 5,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              style={{
                                color: "#fff",
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: "inter",
                              }}
                            >
                              Result
                            </span>
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setAssignment_id(item?.id);
                              // getDeclaredresultByID(item.id);
                              // setState1("declered");
                              dispatch(getScheduleassignmentFunById(item.id));
                              dispatch(
                                getresultStudentFun({ assign_id: +item?.id })
                              );
                            }}
                            className="mt-3"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightResult"
                            aria-controls="offcanvasRight"
                            style={{
                              width: "100%",
                              height: 40,
                              backgroundColor:
                                item.res_dec_date == null ? "#3D70F5" : "#fff",
                              borderRadius: 5,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid #3D70F5",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              style={{
                                color:
                                  item.res_dec_date == null
                                    ? "#fff"
                                    : "#3D70F5",
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: "inter",
                              }}
                            >
                              Result Declared
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              {" "}
              <div className="mt-5">
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    fontFamily: "inter",
                    color: "red",
                  }}
                >
                  No data available
                </h3>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Result offcanvas Right Publish Start  */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRightPublish"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="d-flex justify-content-between">
            <p style={{ fontFamily: "inter", fontSize: 28, fontWeight: 600 }}>
              Declare Result
            </p>
            <button
              onClick={handleClickOpen}
              data-bs-toggle="modal"
              data-bs-target="#saveModal"
              type="button"
              className="btn px-4"
              style={{
                height: 35,
                backgroundColor: "#3D70F5",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontFamily: "inter",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              Publish Result
            </button>
          </div>
          <div className="row my-2">
            <div className="col-5">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 35,
                  backgroundColor: "#EEEFF9",
                  borderRadius: 5,
                  display: "flex",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    setState2("Details1");
                  }}
                  className=""
                  style={{
                    width: "100%",
                    height: 35,
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor:
                      State2 == "Details1" ? "#3D70F5" : "#EEEFF9",
                    color: State2 == "Details1" ? "#fff" : "#000",
                    borderRadius: 5,
                  }}
                >
                  Details
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState2("Result");
                  }}
                  className=""
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor: State2 == "Result" ? "#3D70F5" : "#EEEFF9",
                    color: State2 == "Result" ? "#fff" : "#000",
                    width: "100%",
                    height: 35,
                    borderRadius: 5,
                  }}
                >
                  Result
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            {State2 === "Details1" ? (
              <>
                <p
                  className="text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                >
                  {scheduleassignmetnbyid?.assignment_name}
                </p>
                <p
                  className="text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Course : <span>{scheduleassignmetnbyid?.cou_name}</span>
                </p>
                <p
                  className="text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Subject : <span>{scheduleassignmetnbyid?.sub_name}</span>
                </p>
                <div
                  className="row mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 18,
                      color: "#434343",
                      fontWeight: 800,
                    }}
                  >
                    Total Marks : {scheduleassignmetnbyid?.tot_mark}
                  </span>
                </div>
                <div className="mt-2">
                  <div
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#434343",
                    }}
                  >
                    Uploaded Files
                  </div>
                  <div className="row mt-2">
                    <div
                      className="col-4 me-auto mb-2 mx-3"
                      style={{
                        width: "30%",
                        height: 30,
                        border: "1px solid #D7D7D7",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "inter",
                          fontWeight: 400,
                          fontSize: 12,
                        }}
                      >
                        {scheduleassignmetnbyid?.inst_attach?.split("/")[4]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-4">
                  <span
                    className="px-3 text-start"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      color: "#434343",
                      fontSize: 14,
                    }}
                  >
                    Exam Schedule
                  </span>
                  <div className="col-5">
                    <div
                      style={{
                        width: "100%",
                        height: 30,
                        backgroundColor: "#EDEDF5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                      }}
                    >
                      <FaCalendar />
                      <span
                        style={{
                          color: "#3C3C3C",
                          fontWeight: 400,
                          fontSize: 14,
                          fontFamily: "inter",
                          paddingLeft: 10,
                        }}
                      >
                        {scheduleassignmetnbyid?.startTime
                          ?.split("T")
                          ?.join(" ")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-4">
                  <span
                    className="px-3 text-start"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      color: "#434343",
                      fontSize: 14,
                    }}
                  >
                    Due Date
                  </span>
                  <div className="col-5">
                    <div
                      style={{
                        width: "100%",
                        height: 30,
                        backgroundColor: "#EDEDF5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                      }}
                    >
                      <FaCalendar />
                      <span
                        style={{
                          color: "#3C3C3C",
                          fontWeight: 400,
                          fontSize: 14,
                          fontFamily: "inter",
                          paddingLeft: 10,
                        }}
                      >
                        {scheduleassignmetnbyid?.dueTime?.split("T")?.join(" ")}
                      </span>
                    </div>
                  </div>
                </div>
                <p
                  className="text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Batches
                </p>
                <ul className="list-grou border-0 text-start">
                  {scheduleassignmetnbyid?.batches_name?.length > 0 ? (
                    scheduleassignmetnbyid?.batches_name?.map((res, index) => {
                      return (
                        <li
                          key={index}
                          className="list-group-item"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          {res}
                        </li>
                      );
                    })
                  ) : (
                    <>
                      {" "}
                      <Card
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #9E9E9E",
                          marginBottom: 20,
                        }}
                      >
                        <Card.Body className="">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: 18,
                              fontFamily: "inter",
                              color: "red",
                            }}
                          >
                            No data available
                          </span>
                        </Card.Body>
                      </Card>
                    </>
                  )}
                </ul>
              </>
            ) : (
              <></>
            )}

            {State2 === "Result" ? (
              <>
                <div className="table-scroll">
                  <div className="row mt-3">
                    <div className="col-12" style={{ width: "100%" }}>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Student ID
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Name
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Attachements
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Marks
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getStudentresult_data.length > 0 ? (
                            getStudentresult_data?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item.stu_id}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item.stu_name}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                  <a  href={item.stu_res} style={{color:"black",  textDecoration:"none"}} target="_blank" rel="noopener noreferrer">
                                    {item.stu_res == ""
                                      ? "no data"
                                  : item.stu_res?.split("/")[4]}
                                  </a>
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                    <input
                                      type="number"
                                      onChange={(e) => {
                                        setmark(e.target.value);
                                      }}
                                      style={{ width: 50 }}
                                    />{" "}
                                    /{scheduleassignmetnbyid?.tot_mark}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                      }}
                                    >
                                      <button
                                        className="px-2"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        href="#collapseExample"
                                        // role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseExample"
                                        style={{
                                          backgroundColor: "#F2F2F2",
                                          color: "#2C62EE",
                                          fontFamily: "inter",
                                          fontSize: 12,
                                          fontWeight: 600,
                                          border: "none",
                                          borderRadius: 5,
                                        }}
                                      >
                                        + Add Note
                                      </button>
                                      <button
                                        onClick={() => AddMark(assignment_id)}
                                        className="px-2"
                                        type="button"
                                        style={{
                                          backgroundColor: "#ECF1FF",
                                          color: "#2C62EE",
                                          fontFamily: "inter",
                                          fontSize: 12,
                                          fontWeight: 600,
                                          border: "none",
                                          borderRadius: 5,
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <div className="mt-5">
                                <h3
                                  className="text-center"
                                  style={{
                                    fontWeight: 600,
                                    fontSize: 18,
                                    fontFamily: "inter",
                                    color: "red",
                                  }}
                                >
                                  No data available
                                </h3>
                              </div>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="collapse mt-2" id="collapseExample">
                  <div
                    className="card card-body"
                    style={{ backgroundColor: "red", width: "50%" }}
                  >
                    <input
                      type="text"
                      value={addnote}
                      onChange={(e) => {
                        setaddnote(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Result offcanvas Right Publish  End  */}

      {/* Exam offcanvas Right Result  Start  */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRightResult"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <div className="d-flex justify-content-between">
            <p style={{ fontFamily: "inter", fontSize: 28, fontWeight: 600 }}>
              Exam Report
            </p>
            <div
              className="col-6"
              style={{
                height: 25,
                backgroundColor: "#ECF2FF",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "inter",
                fontSize: 13,
                fontWeight: 400,
                color: "#3D70F5",
              }}
            >
              Result Declared on {getStudentresult?.res_dec_date?.split("T")?.join(" ")}&nbsp;
              <RiCheckDoubleLine style={{ fontSize: 18 }} />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-5">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 35,
                  backgroundColor: "#EEEFF9",
                  borderRadius: 5,
                  display: "flex",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    setState2("Details1");
                  }}
                  className=""
                  style={{
                    width: "100%",
                    height: 35,
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor:
                      State2 == "Details1" ? "#3D70F5" : "#EEEFF9",
                    color: State2 == "Details1" ? "#fff" : "#000",
                    borderRadius: 5,
                  }}
                >
                  Details
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState2("Result");
                  }}
                  className=""
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor: State2 == "Result" ? "#3D70F5" : "#EEEFF9",
                    color: State2 == "Result" ? "#fff" : "#000",
                    width: "100%",
                    height: 35,
                    borderRadius: 5,
                  }}
                >
                  Result
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            {State2 === "Details1" ? (
              <>
                <p
                  className="text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                >
                  {scheduleassignmetnbyid?.assignment_name}
                </p>
                <p
                  className="text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Course : <span>{scheduleassignmetnbyid?.cou_name}</span>
                </p>
                <p
                  className="text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Subject : <span>{scheduleassignmetnbyid?.sub_name}</span>
                </p>
                <div
                  className="row mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 18,
                      color: "#434343",
                      fontWeight: 800,
                    }}
                  >
                    Total Marks : {scheduleassignmetnbyid?.tot_mark}
                  </span>
                </div>
                <div className="mt-2">
                  <div
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#434343",
                    }}
                  >
                    Uploaded Files
                  </div>
                  <div className="row mt-2">
                    <div
                      className="col-4 me-auto mb-2 mx-3"
                      style={{
                        width: "30%",
                        height: 30,
                        border: "1px solid #D7D7D7",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "inter",
                          fontWeight: 400,
                          fontSize: 12,
                        }}
                      >
                        {scheduleassignmetnbyid?.inst_attach?.split("/")[4]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-4">
                  <span
                    className="px-3 text-start"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      color: "#434343",
                      fontSize: 14,
                    }}
                  >
                    Exam Schedule
                  </span>
                  <div className="col-5">
                    <div
                      style={{
                        width: "100%",
                        height: 30,
                        backgroundColor: "#EDEDF5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                      }}
                    >
                      <FaCalendar />
                      <span
                        style={{
                          color: "#3C3C3C",
                          fontWeight: 400,
                          fontSize: 14,
                          fontFamily: "inter",
                          paddingLeft: 10,
                        }}
                      >
                        {scheduleassignmetnbyid?.startTime
                          ?.split("T")
                          ?.join(" ")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-4">
                  <span
                    className="px-3 text-start"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      color: "#434343",
                      fontSize: 14,
                    }}
                  >
                    Due Date
                  </span>
                  <div className="col-5">
                    <div
                      style={{
                        width: "100%",
                        height: 30,
                        backgroundColor: "#EDEDF5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                      }}
                    >
                      <FaCalendar />
                      <span
                        style={{
                          color: "#3C3C3C",
                          fontWeight: 400,
                          fontSize: 14,
                          fontFamily: "inter",
                          paddingLeft: 10,
                        }}
                      >
                        {scheduleassignmetnbyid?.dueTime?.split("T")?.join(" ")}
                      </span>
                    </div>
                  </div>
                </div>
                <p
                  className="text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Batches
                </p>
                <ul className="list-grou border-0 text-start">
                  {scheduleassignmetnbyid?.batches_name?.length > 0 ? (
                    scheduleassignmetnbyid?.batches_name?.map((res, index) => {
                      return (
                        <li
                          key={index}
                          className="list-group-item"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          {res}
                        </li>
                      );
                    })
                  ) : (
                    <>
                      {" "}
                      <Card
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #9E9E9E",
                          marginBottom: 20,
                        }}
                      >
                        <Card.Body className="">
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: 18,
                              fontFamily: "inter",
                              color: "red",
                            }}
                          >
                            No data available
                          </span>
                        </Card.Body>
                      </Card>
                    </>
                  )}
                </ul>
              </>
            ) : (
              <></>
            )}

            {State2 === "Result" ? (
              <>
                <div className="table-scroll">
                  <div className="row mt-3">
                    <div className="col-12" style={{ width: "100%" }}>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Student ID
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Name
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Result Status
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Marks
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        { getStudentresult_data?.map((item, index) =>{
                          return(
                        <tr>
                              <td
                                style={{
                                  backgroundColor: "transparent",
                                  color: "#474747",
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                {item?.stu_id}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "transparent",
                                  color: "#474747",
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                {item?.stu_name}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "transparent",
                                  color: "#474747",
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                <span
                                  style={{
                                    //  border:
                                    //  item.status == "fail"
                                    //  ? "1px solid red"
                                    //  : "1px solid green",
                                    //  color:
                                    //  item.status == "fail"
                                    //  ? "red"
                                    //  : "green",
                                    paddingLeft: ".35rem",
                                    paddingRight: ".35rem",
                                    borderRadius: "5px",
                                    //  backgroundColor:
                                    //    item.status == "fail"
                                    //      ? "#ffc9bb"
                                    //      : "lightgreen",
                                  }}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td
                                style={{
                                  backgroundColor: "transparent",
                                  color: "#474747",
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                {item.marks}/{getStudentresult?.tot_mark}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "transparent",
                                  color: "#474747",
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                <div
                                  style={{
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                  onClick={()=>dispatch(getStudentprofileFun(assignment_id,item?.stu_id))}
                                    className="px-2"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasWithBackdrop"
                                    aria-controls="offcanvasWithBackdrop"
                                    style={{
                                      backgroundColor: "#F2F2F2",
                                      color: "#2C62EE",
                                      fontFamily: "inter",
                                      fontSize: 12,
                                      fontWeight: 400,
                                      border: "none",
                                      borderRadius: 5,
                                    }}
                                  >
                                    <AiOutlineEye style={{ fontSize: 16 }} />{" "}
                                    View Details
                                  </button>
                                </div>
                              </td>
                            </tr>
                                  )})}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Exam  offcanvas Right Result  End  */}

      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div className="row">
            <p
              className="text-center"
              style={{ fontFamily: "inter", fontSize: 20, fontWeight: 600 }}
            >
              Publish Result of “{scheduleassignmetnbyid?.assignment_name}” ?
            </p>
          </div>
          <div className="row">
            <p
              className="text-center"
              style={{ fontFamily: "inter", fontSize: 16, fontWeight: 600 }}
            >
              Result once published cannot be altered/reversed
            </p>
          </div>
          <div className="row mt-2">
            <div className="col-6 m-auto">
              <div className="row">
                <div className="col-6">
                  <button
                    onClick={handleClose}
                    type="button"
                    className="btn"
                    style={{
                      width: "100%",
                      height: 40,
                      backgroundColor: "#fff",
                      border: "1px solid #E9E9E9",
                      fontFamily: "inter",
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    No
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={() => {
                      publishAssignment();
                     
                    }}
                    type="button"
                    className="btn"
                    style={{
                      width: "100%",
                      height: 40,
                      backgroundColor: "#3D70F5",
                      color: "#fff",
                      border: "1px solid #E9E9E9",
                      fontFamily: "inter",
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Exam Result View student profile Offcanvas Start  */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="row d-flex align-items-center justify-content-between">
            <div
              className="col-6 text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
            >
              Exam Result Details
            </div>
            <div
              className="col-6"
              style={{
                height: 25,
                backgroundColor: "#ECF2FF",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "inter",
                fontSize: 13,
                fontWeight: 400,
                color: "#3D70F5",
              }}
            >
              Result Declared on {getStudentprofile?.res_dec_date?.split("T")?.join(" ")} &nbsp;
              <RiCheckDoubleLine style={{ fontSize: 18 }} />
            </div>
          </div>
          <div className="row d-flex align-items-center mt-4">
            <div className="col-2">
              <div style={{ width: 80, height: "auto", borderRadius: 100 }}>
                <img
                  src={myimg}
                  className="header"
                  style={{ width: "100%", borderRadius: 100 }}
                  alt="img"
                />
              </div>
            </div>
            <div className="col-4 text-start">
              <div
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 18 }}
              >
                {getStudentprofile?.name}
              </div>
              <div
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 14 }}
              >
                Student ID : {getStudentprofile?.id}
              </div>
            <NavLink to="/StudentProfile">
                <button
                // onClick={()=>navigate("/StudentProfile")}
                  className="text-start"
                  type="button"
                  style={{
                    backgroundColor: "transparent",
                    color: "#2C62EE",
                    fontFamily: "inter",
                    fontSize: 12,
                    fontWeight: 400,
                    border: "none",
                    borderRadius: 5,
                  }}
                >
                  <AiOutlineEye style={{ fontSize: 16 }} /> View Details
                </button>
                </NavLink>
            </div>
          </div>
          <p
            className="text-start my-2"
            style={{ fontFamily: "inter", fontSize: 18, fontWeight: 400 }}
          >
            Articulate structure of C++ and Java in Semester 1
          </p>
          <div className="row">
            <div className="col-3">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 30,
                  border: "1px solid #D3D3D3",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "#989898",
                    fontFamily: "inter",
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  Batch 3CO - JVY
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-6 ">
                  <FiClock style={{ color: "#989898" }} />
                  <span
                    style={{
                      color: "#989898",
                      fontWeight: 400,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                    {getStudentprofile?.sch_time?.split("T")[1]}
                  </span>
                </div>
                <div className="col-6">
                  <FaRegCalendarAlt style={{ color: "#989898" }} />
                  <span
                    style={{
                      color: "#989898",
                      fontWeight: 400,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                    {getStudentprofile?.sch_time?.split("",10)}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div
                className=""
                style={{
                  height: 30,
                  backgroundColor: "#ECFFEE",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "#44CCA3",
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "inter",
                  }}
                >
                  Status : <span>{getStudentprofile?.status}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div
              className="col-12 text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#474747",
                fontWeight: 400,
              }}
            >
              Course :{" "}
              <span>{getStudentprofile?.cou_name}</span>{" "}
            </div>
            <div
              className="col-12 text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#474747",
                fontWeight: 400,
              }}
            >
              Subject : <span style={{}}>{getStudentprofile?.sub_name}</span>{" "}
            </div>
            <div
              className="col-12 text-start mt-2"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#474747",
                fontWeight: 600,
              }}
            >
              Total Marks : <span style={{}}>{getStudentprofile?.tot_mark}</span>{" "}
            </div>
          </div>
          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#3C3C3C",
                }}
              >
                Passing Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3D70F5",
                }}
              >
              {getStudentprofile?.pass_pers} %
              </p>
            </div>
          </div>
          <div className="mt-2">
            <div
              className="text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
                color: "#434343",
              }}
            >
              Question Paper
            </div>
            <div className="row mt-2">
           
                  <div
                    
                    className="col-4 m-auto mb-2"
                    style={{
                      width: "30%",
                      height: 30,
                      border: "1px solid #D7D7D7",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      {getStudentprofile?.inst_attach?.split("/")[4]}
                    </div>
                  </div>
              
            </div>
          </div>
          <p
            className="text-start"
            style={{
              fontFamily: "inter",
              fontSize: 30,
              fontWeight: 600,
              color: "#3C3C3C",
            }}
          >
            Result
          </p>
          <div className="">
            <div
              className="text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
                color: "#434343",
              }}
            >
              Student’s Response
            </div>
            <div className="row mt-1">
             
                  <div
                
                    className="col-4 m-auto mb-2"
                    style={{
                      width: "30%",
                      height: 30,
                      border: "1px solid #D7D7D7",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      {getStudentprofile?.stu_res?.split("/")[4]}
                    </div>
                  </div>
             
            </div>
          </div>
          <p
            className="text-start"
            style={{
              fontFamily: "inter",
              fontSize: 16,
              fontWeight: 400,
              color: "#3C3C3C",
            }}
          >
            Student’s Score
          </p>
          <div
            style={{
              width: "15%",
              height: 40,
              border: "1px solid #D7D7D7",
              borderRadius: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontFamily: "inter",
                fontSize: 18,
                fontWeight: 400,
                color: "#3C3C3C",
              }}
            >
              {getStudentprofile?.marks}/{getStudentprofile?.tot_mark}
            </div>
          </div>
          <div className="row mt-4">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#3C3C3C",
                }}
              >
                Student’s Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#44CCA3",
                }}
              >
                {getStudentprofile?.stu_pers} %
              </p>
            </div>
          </div>
          <p
            className="text-start mt-2"
            style={{
              fontFamily: "inter",
              fontSize: 28,
              fontWeight: 600,
              color: "#3C3C3C",
            }}
          >
            Instructor’s Notes
          </p>
          <p
            className="text-start"
            style={{
              fontFamily: "inter",
              fontWeight: 400,
              fontSize: 14,
              color: "#3C3C3C",
            }}
          >
            {getStudentprofile?.note}
          </p>
        </div>
      </div>
    </>
  );
}
