import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { ImArrowLeft2 } from "react-icons/im";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import humbsuBirdie from "../images/thumbs-up-birdie.json";
import Lottie from "lottie-react";
import { API_URL } from "../env";
import { toast } from "react-toastify";
import {
  getBatchFun,
  getBatchFun_search,
  getCourseFun,
  getCourseFun_search,
  getSubjectFun,
  getSubjectFun_search,
} from "../redux/comenapi/comenAction";
import { useDispatch, useSelector } from "react-redux";
import { addTopicFun, getTopicFun } from "../redux/quizzes/quizzesActions";

export default function AddTag() {
  const { course_search, mysubject_search, mybatchs_search,mysubject } = useSelector(
    (store) => store.comenapi
  );
  
  const dispatch = useDispatch();
  const [state, setState] = useState(0);
  const [tagName, settagName] = useState("");
 
  const [CourseName, setCourseName] = useState("");
  const [SubjectId, setSubjectId] = useState("");
  const [subjectname, setSubjectname] = useState("");
 
  console.log(tagName,"tagName",CourseName,"CourseName",SubjectId,"SubjectId")




  useEffect(() => {
    dispatch(getCourseFun())
    
    
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  

  const navigate = useNavigate();

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: "",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                onClick={() => {
                  navigate(-1);
                  settagName("");
                  setSubjectname("");
                  setSubjectId("");
                  setCourseName("");
                }}
                className="btn border-0"
                style={{
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImArrowLeft2 style={{ fontSize: 24 }} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div style={{ display: state === 0 ? "block" : "none" }}>
          <p
            className="text-start"
            style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
          >
            Create New Tag
          </p>
          <p
            className="m-0 text-start"
            style={{
              fontFamily: "inter",
              fontSize: 14,
              fontWeight: 400,
              color: "#787878",
            }}
          >
            <span style={{ color: "#3D70F5" }}>1</span>/4 Steps
          </p>
          <div className="progress" style={{ height: "8px", borderRadius: 0 }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "25%",
                background: "#3D70F5",
                borderRadius: 0,
              }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div className="row mt-5">
            <div className="col-4 mt-5 m-auto">
              <Card className="p-2 border-0">
                <div
                  className="text-start"
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Name
                </div>
                <div className="input-group flex-nowrap mt-3">
                  <input
                    type="text"
                    value={tagName}
                    onChange={(e) => {
                      settagName(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Enter the name of Tag"
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    style={{
                      borderRadius: 5,
                      border: "none",
                      backgroundColor: "#F4F4F4",
                      boxShadow: "none",
                    }}
                  />
                </div>
                <div className="col-10 m-auto">
                  <div
                    className="mt-3 col-2"
                    onClick={() => {
                      tagName === ""
                        ? toast("Please Enter Tag Name")
                        : setState(state + 1);
                    }}
                    style={{
                      width: "100%",
                      height: 35,
                      backgroundColor: "#3D70F5",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "inter",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#fff",
                      }}
                    >
                      Proceed
                    </span>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div style={{ display: state === 1 ? "block" : "none" }}>
          <p
            className="text-start"
            style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
          >
            Create New Tag
          </p>
          <p
            className="m-0 text-start"
            style={{
              fontFamily: "inter",
              fontSize: 14,
              fontWeight: 400,
              color: "#787878",
            }}
          >
            <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
          </p>
          <div className="progress" style={{ height: "8px", borderRadius: 0 }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "50%",
                background: "#3D70F5",
                borderRadius: 0,
              }}
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div className="row mt-5">
            <div className="col-3 mt-5 m-auto">
              <Card className="p-2 border-0">
                <div
                  className="text-start"
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Select Tag Type
                </div>
                <div className="row my-4">
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                        checked
                        readOnly
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 18,
                            fontWeight: 600,
                          }}
                        >
                          Private
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios2"
                        value="option1"
                        checked
                        readOnly
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 18,
                            fontWeight: 600,
                          }}
                        >
                          Public
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-10 m-auto">
                  <div
                    className="mt-3 col-2"
                    onClick={() => {
                      setState(state + 1);
                    }}
                    style={{
                      width: "100%",
                      height: 35,
                      backgroundColor: "#3D70F5",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "inter",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#fff",
                      }}
                    >
                      Proceed
                    </span>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div style={{ display: state === 2 ? "block" : "none" }}>
          <p
            className="text-start"
            style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
          >
            Create New Tag
          </p>
          <p
            className="m-0 text-start"
            style={{
              fontFamily: "inter",
              fontSize: 14,
              fontWeight: 400,
              color: "#787878",
            }}
          >
            <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
          </p>
          <div className="progress" style={{ height: "8px", borderRadius: 0 }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "75%",
                background: "#3D70F5",
                borderRadius: 0,
              }}
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div className="row mt-5">
            <div className="col-5 m-auto">
              <Card className="rounded-0">
                <p
                  className="px-3 pt-2 mb-2 text-start"
                  id="demo-radio-buttons-group-label"
                  style={{
                    fontFamily: "inter",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#000",
                  }}
                >
                  Select Course
                </p>
                <div className="px-2">
                  <div className="input-group flex-nowrap">
                    <span
                      className="input-group-text"
                      id="addon-wrapping"
                      style={{
                        borderRadius: 0,
                        border: "none",
                        backgroundColor: "#F1F1F1",
                      }}
                    >
                      <BsSearch style={{ color: "#6A6A6A" }} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Course"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                      style={{
                        borderRadius: 0,
                        border: "none",
                        backgroundColor: "#F1F1F1",
                        boxShadow: "none",
                      }}
                      onKeyUp={(e) => dispatch(getCourseFun_search(e.target.value))}
                    />
                  </div>
                </div>
                <div className="barsubject">
                  {course_search?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="form-check d-flex align-items-center mx-3 my-2 m-1"
                      >
                        <label
                          className="form-check-label p-0"
                          htmlFor={item.id}
                          style={{ cursor: "pointer" }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id={item.id}
                            style={{
                              boxShadow: "none",
                              cursor: "pointer",
                            }}
                            onChange={(e) => {
                              // getSubject(item.id);
                              setCourseName(item.name);
                              dispatch(getSubjectFun(item?.id,setState,state))
                              console.log(item.id,"couree id")
                            }}
                          />

                          <div
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#6A6A6A",
                              paddingLeft: 10,
                            }}
                          >
                            {item.name}
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Card>
              <div className="row">
                <div
                  className="mt-3 col-5 m-auto"
                  onClick={() => {
                    CourseName === ""
                      ? toast("please select course name")
                      : setState(state + 1);
                  }}
                  style={{
                    height: 35,
                    backgroundColor: "#3D70F5",
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#fff",
                    }}
                  >
                    Proceed
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: state === 3 ? "block" : "none" }}>
          <p
            className="text-start"
            style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
          >
            Create New Tag
          </p>
          <p
            className="m-0 text-start"
            style={{
              fontFamily: "inter",
              fontSize: 14,
              fontWeight: 400,
              color: "#787878",
            }}
          >
            <span style={{ color: "#3D70F5" }}>4</span>/4 Steps
          </p>
          <div className="progress" style={{ height: "8px", borderRadius: 0 }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "100%",
                background: "#3D70F5",
                borderRadius: 0,
              }}
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div className="row mt-5">
            <div className="col-5 m-auto">
              <Card className="">
                <p
                  className="px-3 pt-2 mb-2 text-start"
                  id="demo-radio-buttons-group-label"
                  style={{
                    fontFamily: "inter",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#000",
                  }}
                >
                  Select Subjects
                </p>
                <div className="px-2">
                  <div className="input-group flex-nowrap">
                    <span
                      className="input-group-text"
                      id="addon-wrapping"
                      style={{
                        borderRadius: 0,
                        border: "none",
                        backgroundColor: "#F1F1F1",
                      }}
                    >
                      <BsSearch style={{ color: "#6A6A6A" }} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Subjects"
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                      style={{
                        borderRadius: 0,
                        border: "none",
                        backgroundColor: "#F1F1F1",
                        boxShadow: "none",
                      }}
                      onKeyUp={(e) => dispatch(getSubjectFun_search(e.target.value))}
                    />
                  </div>
                </div>
                <div className="barsubject">
                  {mysubject_search?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="form-check d-flex align-items-center mx-3 my-2 m-1"
                      >
                        <label
                          className="form-check-label p-0"
                          htmlFor={item.name}
                          style={{ cursor: "pointer" }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id={item.name}
                            style={{
                              boxShadow: "none",
                              cursor: "pointer",
                            }}
                            onChange={(e) => {
                              setSubjectId(item.id);
                              setSubjectname(item.name);
                            }}
                          />

                          <div
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#6A6A6A",
                              paddingLeft: 10,
                            }}
                          >
                            {item.name}
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
                {/*<div
                key={index}
                className="form-check d-flex align-items-center mx-3 my-2 m-1"
              >
                <input
                  id={item.id}
                  onClick={(e) => {
                    handleCheckboxChangeId(e);
                    setSubjectname(item.name)
                  }}
                  className="form-check-input"
                  type="radio"
                  name="same"
                  style={{ boxShadow: "none" }}
                />
                <label
                  className="form-check-label p-0"
                  htmlFor="flexRadioDefault2"
                >
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#6A6A6A",
                      paddingLeft: 10,
                    }}
                  >
                    {item.name}
                  </div>
                </label>
                  </div>*/}
              </Card>
              <div className="row">
                <div
                  className="mt-3 col-5 m-auto"
                  onClick={() => {
                    subjectname === ""
                      ? toast("please select Subject name")
                      : setState(state + 1);
                  }}
                  style={{
                    height: 35,
                    backgroundColor: "#3D70F5",
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#fff",
                    }}
                  >
                    Proceed
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: state === 4 ? "block" : "none" }}>
          <Card className="p-2 border-0">
            <div className="row mt-5">
              <div className="col-5 m-auto">
                <p
                  className=" mt-5"
                  style={{
                    fontFamily: "inter",
                    fontSize: 34,
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  New Tag “{tagName}”
                </p>
                <p
                  className=" mt-4"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  Will be create {CourseName} with subjects {subjectname} etc.{" "}
                </p>
                <div
                  className="mt-2"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    onClick={() => {
                      navigate(-1);
                      settagName("");
                      setSubjectname("");
                      setSubjectId("");
                      setCourseName("");
                    }}
                    style={{
                      width: "25%",
                      height: 35,
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #FF0B0B",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "inter",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#FF0B0B",
                      }}
                    >
                      Cancel
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      // addTag();
                      dispatch(addTopicFun(SubjectId,CourseName,tagName,setState,state))
                    }}
                    style={{
                      width: "25%",
                      height: 35,
                      backgroundColor: "#3D70F5",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "inter",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#fff",
                      }}
                    >
                      Confirm
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div
          style={{
            display: state === 5 ? "block" : "none",
            marginTop: "-100px",
          }}
        >
          <div className="row d-flex justify-content-center">
            <div className="col-5 m-auto">
              <Lottie animationData={humbsuBirdie} style={{ width: "100%" }} />
              <p
                className="m-auto mt-5"
                style={{
                  fontFamily: "inter",
                  fontSize: 30,
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                New Tag “{tagName}” Created Succesfully
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4 m-auto">
              <div
                style={{
                  width: "100%",
                  height: 35,
                  backgroundColor: "#3D70F5",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  settagName("");
                  setSubjectname("");
                  setSubjectId("");
                  setCourseName("");
                }}
              >
                <NavLink to="/Quizzes" className="text-decoration-none">
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#fff",
                    }}
                  >
                    Back to Dashboard
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
