import { useState, useEffect } from "react";
import { MdModeEdit } from "react-icons/md";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiPlus } from "react-icons/hi";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import { Delete } from "./Svgicon";
import Lottie from "lottie-react";
import animationData from "../images/graphmobile.json";
import fileData from "../images/file.json";
import { API_URL } from "../env";
import { instance } from "../instance";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import { BsCloudDownload } from "react-icons/bs";
import { TiUploadOutline } from "react-icons/ti";
export default function Topic({ ...props }) {
  const { topic, getTopic } = props;
  const [value, setValue] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [Times, setTimes] = useState("");
  const [data, setData] = useState();
  const [subject, setSubject] = useState();
  const [state, setState] = useState(0);
  const [TimePerQuestion, setTimePerQuestion] = useState("");
  const [type, setType] = useState(1);
  const [preview, setPreview] = useState([]);
  const [selecttag, setSelecttag] = useState();
  // const [topic, setTopic] = useState([]);
  const [file, setFile] = useState();
  const [click, setclick] = useState(false);
  const [Hadding, setHadding] = useState("Please Wait While We");
  const [Hadding1, setHadding1] = useState("Analyse Your Questions");
  const [Pra, setPra] = useState("Do not referesh or Close this Screen");
  const [Lotties, setLotties] = useState("");
  const [Da, setDa] = useState(0);
  const [topicdtl, settopicdtl] = useState([]);
  const [topic_name, settopicName] = useState("");
  const [que, setque] = useState([]);
  const [questions, setQuestions] = useState([{ question: "", marks: "" }]);
  const [selected_topic_id, setSelectedTopic] = useState("");
  // console.log(selected_topic_id,"selected_topic_id")
  const [state2, setState2] = useState(0);
  const [excelfile, setExcelFile] = useState();
  console.log(excelfile, "excelfile in topic");
  click ? (
    setTimeout(() => {
      if (Da === 0) {
        setPra("Do not referesh or Close this Screen");
        setHadding("Please wait, while we");
        setHadding1("Translate Questions");
        setLotties(animationData);
        setDa(1);
        // setTimeout
      } else if (Da === 1) {
        setPra("This can take a while, do not refresh or close this tab.");
        setHadding("Questions Analysed");
        setHadding1("Successfully, Preparing Report");
        setLotties(fileData);
        setDa(2);
      } else {
        setState(3);
        setclick(false);
        handleClose();
      }
    }, 3000)
  ) : (
    <></>
  );

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [inputList, setInputList] = useState([{ question: "", marks: "" }]);
  const [inputBox, setInputBox] = useState([]);
  console.log(topic_name, "topic name");
  //function for add inputs for one question
  const Hans = () => {
    var update = [...inputList];
    update = [...inputList, { question: "", marks: "" }];
    setInputList(update);
  };

  //function for handle changes for questions
  const handleChangeQuestions = (e, i) => {
    const { name, value } = e.target;
    setInputList([
      ...inputList.map((el, ind) =>
        ind === i ? { ...el, [name]: value } : { ...el }
      ),
    ]);
  };

  const Addmore = () => {
    var update = [...inputBox];
    update = [...inputBox, "opation"];
    setInputBox(update);
  };

  //function for get details of the particular topic
  const topicDetail = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/exams/read/exams/topics/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          console.log(result, "topicDetail");
          settopicdtl([result.topics]);
          setque(result.question);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for add questions
  const addQuestions = async (examtopic_id, inputList) => {
    try {
      let res = await instance.post(
        `${API_URL}/instructor/exams/examquestion/create`,
        {
          topic_name,
          examtopic_id,
          questions: inputList,
        }
      );
      toast(res.data);
      getTopic();
      setInputList([{ question: "", marks: "" }]);
    } catch (err) {
      toast("something went wrong");
      console.log(err);
    }
  };
  const addExcelQuestions = async (excelfile) => {
    // console.log(selected_topic_id,"selected_topic_id")

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var formdata = new FormData();
    formdata.append("topic_id", selected_topic_id);
    formdata.append("exl", excelfile);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${API_URL}/instructor/exams/upload/question/inexamtopic/byexcel`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "add excal question");
        if (result.status == 1) {
          toast(result.msg);
          getTopic();
          setState2(state2 + 1);
        } else {
          toast(result.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for particular delete question
  const deleteQuestion = async (id) => {
    try {
      let res = await instance.post(
        `instructor/exams/delete/examquestion/byid`,
        {
          question_id: id,
        }
      );
      console.log(res, "delete Question");
      if (res.data.status == 1) {
        toast(res.data.msg);
        getTopic();
      } else {
        toast(res.data.Backend_Error);
      }
    } catch (err) {
      toast("Internal Server Error");
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        placement={"end"}
        style={{ width: "50%" }}
      >
        <Offcanvas.Header
          closeButton
          onClick={() => {
            setState(0);
          }}
        ></Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ display: state === 0 ? "block" : "none" }}>
            {topicdtl.length > 0 ? (
              topicdtl?.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className="row"
                      style={{
                        height: 60,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        className="col-4"
                        style={{
                          fontFamily: "inter",
                          fontSize: 26,
                          fontWeight: 600,
                        }}
                      >
                        Tag Details
                      </p>
                      <div className="col-8 me-auto d-flex justify-content-end">
                        {/*<button
                          type="button"
                          onClick={() => {
                            setState2(state2 + 1);
                          
                          }}
                          className="btn mx-2 px-4"
                          style={{
                            height: 30,
                            backgroundColor: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                            color: "#3D70F5",
                            fontSize: 14,
                            fontFamily: "inter",
                            fontWeight: 400,
                            border: "1px solid #3D70F5",
                            cursor: "pointer",
                          }}
                        >
                          Excel file upload
                        </button> */}
                        <button
                          type="button"
                          onClick={() => {
                            setState(state + 1);
                            setState2(state2 + 1);
                          }}
                          className="btn"
                          style={{
                            height: 30,
                            backgroundColor: "#3D70F5",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                            color: "#fff",
                            fontSize: 14,
                            fontFamily: "inter",
                            fontWeight: 400,
                            border: "none",
                          }}
                        >
                          +Add Questions
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-12"
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          color: "#474747",
                          fontWeight: 400,
                        }}
                      >
                        Name of Tag : <span>{item.topic_name}</span>{" "}
                      </div>
                      <div
                        className="col-12"
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          color: "#474747",
                          fontWeight: 400,
                        }}
                      >
                        Questions :{" "}
                        <span style={{ color: "#FF0E0E" }}>
                          {item.totalNumberOfQues}
                        </span>{" "}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div
                        className="col-12"
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          color: "#474747",
                          fontWeight: 400,
                        }}
                      >
                        Course : <span>{item.cou_name}</span>{" "}
                      </div>
                      <div
                        className="col-12"
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          color: "#474747",
                          fontWeight: 400,
                        }}
                      >
                        Subject : <span style={{}}>{item.sub_name}</span>{" "}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 m-auto">
                        <div className="d-flex justify-content-between">
                          <p
                            className="p-0 m-0 g-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 18,
                              fontWeight: 400,
                            }}
                          >
                            Questions
                          </p>
                          <p
                            className="p-0 m-0 g-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 18,
                              fontWeight: 400,
                            }}
                          >
                            Action
                          </p>
                        </div>
                        <Card className="rounded-1">
                          <div className="bar-question">
                            {que.length > 0 ? (
                              que?.map((item, index) => {
                                return (
                                  <>
                                    <div className="row">
                                      <div
                                        className="col-10 pt-2"
                                        style={{
                                          borderRight: "1px solid #DDDDDD",
                                        }}
                                      >
                                        <div className="px-2">
                                          <div
                                            className=""
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span
                                              className="p-0"
                                              style={{
                                                fontFamily: "inter",
                                                fontSize: 16,
                                                color: "#474747",
                                                fontWeight: 400,
                                              }}
                                            >
                                              Q {index + 1}.
                                            </span>
                                            <div
                                              className="p-0"
                                              style={{
                                                width: 100,
                                                height: 30,
                                                border: "1px solid #DDDDDD",
                                                borderRadius: 5,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontFamily: "inter",
                                                  fontSize: 14,
                                                  color: "#474747",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Mark :
                                                <span
                                                  style={{
                                                    fontFamily: "inter",
                                                    fontSize: 14,
                                                    color: "#59E356",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  {item.marks}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <p
                                            className="p-0"
                                            style={{
                                              fontFamily: "inter",
                                              fontSize: 16,
                                              color: "#474747",
                                              fontWeight: 400,
                                            }}
                                          >
                                            {item.question}
                                          </p>
                                        </div>
                                        <div className="row px-3">
                                          <div className="col-8"></div>
                                        </div>
                                      </div>
                                      <div className="col-2 d-flex justify-content-center">
                                        <div
                                          style={{
                                            width: "100%",
                                            height: 35,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                          }}
                                        >
                                          {/*<MdModeEdit
                                            style={{ fontSize: 20 }}
                                        />*/}
                                          <MdOutlineDeleteOutline
                                            onClick={() => {
                                              deleteQuestion(item.id);
                                              console.log(item.id);
                                            }}
                                            style={{ fontSize: 20 }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <div className="mt-5">
                                  <h3
                                    className="text-center"
                                    style={{
                                      fontWeight: 600,
                                      fontSize: 18,
                                      fontFamily: "inter",
                                      color: "red",
                                    }}
                                  >
                                    No data available
                                  </h3>
                                </div>
                              </>
                            )}
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="mt-5">
                  <h3
                    style={{
                      fontWeight: 600,
                      fontSize: 18,
                      fontFamily: "inter",
                      color: "red",
                    }}
                  >
                    No data available
                  </h3>
                </div>
              </>
            )}
          </div>

          <div
            style={{
              display: state === 1 && state2 === 1 ? "block" : "none",
            }}
          >
            <div className="row d-flex justify-content-between">
              <p
                className="col-5 text-start"
                style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
              >
                Add New Question
              </p>
            </div>
            <p
              className="text-start"
              style={{ fontFamily: "inter", fontSize: 16, fontWeight: 400 }}
            >
              Tag : {topicdtl[0]?.topic_name}
            </p>
            <hr />
            <div className="row">
              <div className="col-5">
                <button
                  type="button"
                  onClick={() => {
                    setState(state + 1);
                  }}
                  className="btn col-4 ms-auto"
                  style={{
                    height: 70,
                    width: 230,
                    backgroundColor: "#3D70F5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                    color: "#fff",
                    fontSize: 14,
                    fontFamily: "inter",
                    fontWeight: 400,
                    border: "none",
                  }}
                >
                  Add single Question
                </button>
              </div>
              <div className="col-5">
                <button
                  type="button"
                  onClick={() => {
                    setState2(state2 + 1);
                  }}
                  className="btn col-4 ms-auto"
                  style={{
                    height: 70,
                    width: 230,
                    backgroundColor: "#3D70F5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                    color: "#fff",
                    fontSize: 14,
                    fontFamily: "inter",
                    fontWeight: 400,
                    border: "none",
                  }}
                >
                  Excel file upload
                </button>
              </div>
            </div>
          </div>

          {/* for single question upload */}
          <div style={{ display: state === 2 ? "block" : "none" }}>
            <div className="row d-flex justify-content-between">
              <p
                className="col-5"
                style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
              >
                Create New Topic
              </p>
              {/* <div className='col-7'>
                   <button type="button" onClick={()=>{setclick(true);setState(state+1);}} className="btn col-4 ms-auto" style={{ height:30, backgroundColor:"#3D70F5", display:"flex", justifyContent:"center", alignItems:'center', borderRadius:5, color:'#fff', fontSize:14, fontFamily:'inter', fontWeight:400, border:"none" }}>Proceed</button>
                   </div> */}
            </div>
            <p
              className="m-0 text-start"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                fontWeight: 400,
                color: "#787878",
              }}
            >
              <span style={{ color: "#3D70F5" }}>1</span>/3 Steps
            </p>
            <div
              className="progress"
              style={{ height: "8px", borderRadius: 0 }}
            >
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "75%",
                  background: "#3D70F5",
                  borderRadius: 0,
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p
              className="text-start pt-5"
              style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
            >
              Enter Questions
            </p>
            <hr />
            <div className="que-sec">
              <div className="row p-0 mb-4">
                <div className="col-11">
                  <Card className="">
                    <div className="row pe-2 g-0 pt-2">
                      <div className="col-12">
                        <div className="row g-0">
                          <div className="col-12">
                            {inputList.map((res, ind) => {
                              return (
                                <>
                                  <div key={ind}>
                                    <div className="row ps-2 g-0">
                                      <div className="col-12 p-2">
                                        <div
                                          className=""
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#F3F3F3",
                                            border: "1px solid #F3F3F3",
                                            borderRadius: 5,
                                          }}
                                        >
                                          <div className="input-group flex-nowrap">
                                            <input
                                              name="question"
                                              onChange={(e) =>
                                                handleChangeQuestions(e, ind)
                                              }
                                              type="text"
                                              className="form-control"
                                              placeholder="Type Here..."
                                              aria-label="Username"
                                              aria-describedby="addon-wrapping"
                                              style={{
                                                borderRadius: 0,
                                                border: "none",
                                                boxShadow: "none",
                                                backgroundColor: "#DDDDDD",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="row d-flex align-items-center">
                                            <div
                                              className="col-5"
                                              style={{
                                                color: "#4E4E4E",
                                                fontFamily: "inter",
                                                fontSize: 14,
                                              }}
                                            >
                                              Enter Marks
                                            </div>
                                            <div
                                              className="col-5"
                                              style={{
                                                width: 80,
                                                height: 40,
                                                backgroundColor: "#fff",
                                                border: "1px solid #F3F3F3",
                                                borderRadius: 5,
                                              }}
                                            >
                                              <input
                                                name="marks"
                                                onChange={(e) =>
                                                  handleChangeQuestions(e, ind)
                                                }
                                                type="text"
                                                className="form-control px-0"
                                                placeholder="marks"
                                                aria-label="Username"
                                                aria-describedby="addon-wrapping"
                                                style={{
                                                  borderRadius: 0,
                                                  border: "none",
                                                  boxShadow: "none",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-1 p-0">
                  <div style={{ width: "100%", height: 40 }}>
                    <button
                      type="button"
                      className="btn"
                      onClick={Hans}
                      style={{
                        width: 40,
                        height: 40,
                        backgroundColor: "#F3F3F3",
                        borderRadius: 50,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <HiPlus style={{ color: "#696969" }} />
                    </button>
                    <button
                      type="button"
                      className="btn mt-1"
                      style={{
                        width: 40,
                        height: 40,
                        backgroundColor: "#F3F3F3",
                        borderRadius: 50,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Delete />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <button
                type="button"
                onClick={() => {
                  setclick(true);
                  setState(state + 1);
                  addQuestions(selected_topic_id, inputList);
                }}
                className="btn col-3 mx-auto"
                style={{
                  height: 30,
                  backgroundColor: "#3D70F5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "inter",
                  fontWeight: 400,
                  border: "none",
                }}
              >
                Proceed
              </button>
            </div>
          </div>

          <div style={{ display: state === 3 ? "block" : "none" }}>
            <div className="row mt-5">
              <div className="col-6 m-auto">
                <Card className="border-0 rounded-0">
                  <p
                    className="text-center"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#6D6D6D",
                    }}
                  >
                    Questions will be uploaded to “{" "}
                    <span style={{ color: "#000" }}>
                      {topicdtl[0]?.topic_name}
                    </span>
                    ”
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Lottie
                    animationData={humbsuBirdie}
                    style={{ width: "50%" }}
                  /> */}
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="btn"
                      style={{
                        width: "50%",
                        height: 35,
                        backgroundColor: "#0CBC8B",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                        color: "#fff",
                        fontSize: 14,
                        fontFamily: "inter",
                        fontWeight: 600,
                        border: "none",
                      }}
                      onClick={() => {
                        handleClose();
                        setState2(0);
                        setState(0);
                        settopicName("");
                        setInputList([{ question: "", marks: "" }]);
                      }}
                    >
                      Back to Dashboard
                    </button>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          {/*Excel file upload start*/}
          <div style={{ display: state2 === 2 ? "block" : "none" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 m-auto mt-5">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span>
                      <label htmlFor="upload-file"> </label>

                      <label>
                        <input
                          type="file"
                          hidden
                          onChange={(e) => {
                            setExcelFile(e.target.files[0]);
                          }}
                        />
                       
                        <span style={{ backgroundColor:"#f0ebeb", padding:"20px",border:"1px solid gray",borderRadius:"5px" }}> select excal file</span>
                      </label>
                    </span>
                  </div>
                  <div>
                    <h5 className="text-center my-3">
                      Upload Bulk <br />
                      Questions
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {excelfile?.length === 0 ? (
                        <span className="  " style={{ fontSize: "16px" }}>
                          Download Sample .xlx
                          <BsCloudDownload
                            className="mx-4"
                            style={{ fontSize: "20px", color: "#363673" }}
                          />
                        </span>
                      ) : (
                        <span>{excelfile?.name}</span>
                      )}
                    </div>
                  </div>

                  

                  <div className="mt-4 d-flex justify-content-center">
                    <button
                      disabled={excelfile === ""}
                      onClick={() => {
                        addExcelQuestions(excelfile);
                      }}
                      style={{
                        backgroundColor: "#2188E7",
                        color: "#fff",
                        padding: "8px 20px",
                        border: "none",
                        borderRadius: "8px",
                        fontWeight: "500",
                        width: "80%",
                      }}
                    >
                      + Add Questions
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: state2 === 3 ? "block" : "none" }}>
            <div className="row mt-5">
              <div className="col-6 m-auto">
                <Card className="border-0 rounded-0">
                  <p
                    className="text-center"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#6D6D6D",
                    }}
                  >
                    Questions will be uploaded to “{" "}
                    <span style={{ color: "#000" }}>
                      {topicdtl[0]?.topic_name}
                    </span>
                    ”
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Lottie
                        animationData={humbsuBirdie}
                        style={{ width: "50%" }}
                      /> */}
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="btn"
                      style={{
                        width: "50%",
                        height: 35,
                        backgroundColor: "#0CBC8B",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                        color: "#fff",
                        fontSize: 14,
                        fontFamily: "inter",
                        fontWeight: 600,
                        border: "none",
                      }}
                      onClick={() => {
                        handleClose();
                        setState2(0);
                        setState(0);
                        setExcelFile("");
                      }}
                    >
                      Back to Dashboard
                    </button>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="bar3">
        <div className="row">
          {topic.length > 0 ? (
            topic?.map((item, index) => {
              return (
                <LiveClass
                  item={item}
                  key={index}
                  handleShow={handleShow}
                  handle={topicDetail}
                  setSelectedTopic={setSelectedTopic}
                  settopicName={settopicName}
                />
              );
            })
          ) : (
            <>
              {" "}
              <div className="mt-5">
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    fontFamily: "inter",
                    color: "red",
                  }}
                >
                  No data available
                </h3>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

const LiveClass = (props) => {
  return (
    <>
      <div className="col-4 mt-3">
        <Card
          style={{
            backgroundColor: "#fff",
            border: "1px solid #E6E6E6",
            marginBottom: 20,
            width: "95%",
          }}
        >
          <Card.Body className="text-start">
            <div className="row">
              <span
                className="col-6 bg-light py-2 text-center ms-2"
                style={{
                  fontWeight: 400,
                  fontSize: 18,
                  textAlign: "start",
                  fontFamily: "inter",
                }}
              >
                {props.item.topic_name}
              </span>
            </div>
            <div className="row mt-2">
              <div
                className="col-12 m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  color: "#9A9A9A",
                  fontWeight: 400,
                }}
              >
                Questions :{" "}
                <span
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    color: "#F93333",
                    fontWeight: 600,
                  }}
                >
                  {props.item.number_of_ques}
                </span>{" "}
              </div>
            </div>
            <div
              className="row g-0 mt-2"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <p
                className="col-2 m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 13,
                  color: "#9A9A9A",
                  fontWeight: 400,
                }}
              >
                Course
              </p>
              <p
                className="col-10 m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 13,
                  color: "##000000",
                  fontWeight: 400,
                  textAlign: "left ",
                }}
              >
                {" "}
                :&nbsp;&nbsp;{props.item.cou_name}
              </p>
            </div>
            <div
              className="row g-0 mt-2"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <p
                className="col-2 m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 13,
                  color: "#9A9A9A",
                  fontWeight: 400,
                }}
              >
                Subject
              </p>
              <p
                className="col-10"
                style={{
                  fontFamily: "inter",
                  fontSize: 13,
                  color: "##000000",
                  fontWeight: 400,
                  textAlign: "left ",
                }}
              >
                {" "}
                :&nbsp;&nbsp;{props.item.sub_name}
              </p>
            </div>
            <div
              className="mt-3"
              onClick={() => {
                props.handleShow();
                props.handle(props.item.id);
                props.setSelectedTopic(props.item.id);
                props.settopicName(props.item.topic_name);
              }}
              style={{
                
                width: "100%",
                height: 40,
                backgroundColor: "#3D70F5",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor:"pointer"
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
              >
                View Details
              </span>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
