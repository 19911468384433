import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { AiOutlineEye } from "react-icons/ai";
import { Sort } from "./Svgicon";
import { NavLink, useNavigate } from "react-router-dom";
import { API_URL } from "../env";
import StudentProfile from "./StudentProfile";
import Offcanvas from "react-bootstrap/Offcanvas";

import { ImArrowLeft2 } from "react-icons/im";

export default function Overview({ details }) {
  console.log(details,"details in overview")
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [state, setState] = useState();
  const [data, setData] = useState([]);
  const [subject, setSubject] = useState([]);
  const [tbldata, setTbldata] = useState([]);

  const [ProfileData, setProfileData] = useState([]);

  const ViewProfile = async (id) => {
    console.log(id, "view profile id");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/student/Profile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          // console.log(result.data, " view profile function");
          setProfileData(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };



  

 
  const navigate = useNavigate();
  useEffect(() => {
    setData();
    setSubject();
    setTbldata();
  }, []);

  return (
    <>
      <div className="row">
        <span
          className="col-10 text-start"
          style={{
            fontFamily: "inter",
            fontSize: 16,
            fontWeight: 400,
            color: "#7E7E7E",
          }}
        >
          Course
        </span>
        <span
          className="col-12 text-start"
          style={{
            fontFamily: "inter",
            fontSize: 16,
            fontWeight: 400,
            color: "#000",
          }}
        >
          {details?.course_name}
        </span>
      </div>
      {/*<span
        className="row g-0 mt-4 text-start"
        style={{
          fontFamily: "inter",
          fontSize: 16,
          fontWeight: 400,
          color: "#7E7E7E",
        }}
      >
        My Subjects
      </span>
      <div className="row mt-1 d-flex justify-content-between text-start">
        {data &&
          data.map((item, index) => {
            return <div key={item.id}><SubjectList item={item}  /></div>;
          })}
        </div>*/}
      <span
        className="row g-0 mt-3 text-start"
        style={{
          fontFamily: "inter",
          fontSize: 16,
          fontWeight: 600,
          color: "#7E7E7E",
        }}
      >
        Subjects
      </span>
      <div className="row mt-1 d-flex justify-content-between text-start">
        {details?.subject_names &&
          details?.subject_names?.map(( value,index) => {
            return (
              <div>
              <div className="col-6">
              <ol className="border-0">
                <li
                  className="list-group-item"
                  style={{
                    fontFamily: "inter",
                    fontWeight: 400,
                    fontSize: 14,
                    color: "#3C3C3C",
                  }}
                >{`${index + 1}.  `}
                  {value}
                </li>
              </ol>
            </div>
              </div>
            );
          })}
      </div>
      <div
        className="text-start"
        style={{
          fontFamily: "inter",
          fontSize: 16,
          fontWeight: 400,
          color: "#989898",
        }}
      >
        Batch Details
      </div>
      <div className="my-2">
        <div
          className="text-start"
          style={{
            fontFamily: "inter",
            fontSize: 13,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Batch Start Date : {details?.start_date}
        </div>
        <div
          className="text-start"
          style={{
            fontFamily: "inter",
            fontSize: 13,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Batch Expected End Date : {details?.end_date}
        </div>

        <div
          className="text-start"
          style={{
            fontFamily: "inter",
            fontSize: 13,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Course Duration : {details?.duration} Days
        </div>
        <div
          className="text-start"
          style={{
            fontFamily: "inter",
            fontSize: 13,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Total Subjects : {details?.total_subjects}
        </div>
        <div
          className="text-start"
          style={{
            fontFamily: "inter",
            fontSize: 13,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Total Students : {details?.total_students}
        </div>
      </div>
      <p
        className="py-2 text-start"
        style={{
          fontFamily: "inter",
          fontSize: 16,
          fontWeight: 400,
          color: "#989898",
        }}
      >
        My Students
      </p>
      <div className="table-scroll">
        <div className="row">
          <div className="col-12">
            <Card>
              <Table>
                <thead>
                  <tr>
                    <th
                      className=""
                      style={{
                        color: "#474747",
                        fontFamily: "inter",
                        fontSize: 15,
                        backgroundColor: "#F0F0F0",
                        fontWeight: 600,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div>Name</div>
                        <div>
                          Sort <Sort />
                        </div>
                      </div>
                    </th>
                    <th
                      className=""
                      style={{
                        color: "#474747",
                        fontFamily: "inter",
                        fontSize: 15,
                        backgroundColor: "#F0F0F0",
                        fontWeight: 600,
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {details?.students &&
                    details?.students.map((item) => (
                      <BasicExample
                        item={item}
                        ViewProfile={ViewProfile}
                        setState={setState}
                        handleShow={handleShow}
                      />
                    ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </div>
      </div>

      {state === "StudentProfile" ? (
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          style={{ width: "100%" }}
          backdrop="static"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "",
                        alignItems: "center",
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          handleClose();
                        }}
                        className="btn border-0"
                        style={{
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ImArrowLeft2 style={{ fontSize: 24 }} />
                      </button>
                      <span
                        className="text-start"
                        style={{
                          fontFamily: "inter",
                          fontWeight: 400,
                          fontSize: 34,
                          paddingLeft: 20,
                        }}
                      >
                        Student Profile
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <StudentProfile ProfileData={ProfileData} />
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <></>
      )}
    </>
  );
}



function BasicExample(props) {
  return (
    <>
      <tr key={props.item.id}>
        <td
          style={{
            backgroundColor: "transparent",
            color: "#474747",
            fontFamily: "inter",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          {props.item.name}
        </td>
        <td
          style={{
            backgroundColor: "transparent",
            color: "#474747",
            fontFamily: "inter",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          <div style={{ justifyContent: "space-around", alignItems: "center" }}>
            <button
              className="px-2"
              type="button"
              style={{
                color: "#2C62EE",
                fontFamily: "inter",
                fontSize: 14,
                fontWeight: 400,
                border: "none",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                props.handleShow();
                props.setState("StudentProfile");
                props.ViewProfile(props.item.id);
              }}
            >
              <AiOutlineEye style={{ fontSize: 18 }} /> View Profile{" "}
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}
