import {
  login_error,
  login_pending,
  login_success,
  logout_error,
  logout_pending,
  logout_success,
} from "./auth.actions";

const authReducer = (state, { type, payload }) => {
  switch (type) {
    case login_pending: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case login_error: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: payload.message,
        token: "",
      };
    }
    case login_success: {
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        isAuth: true,
        isLoading: false,
        userName: payload.userName,
        token: payload.token,
      };
    }
    case logout_pending: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case logout_error: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        message: payload.message,
      };
    }
    case logout_success: {
      localStorage.removeItem("token");
      return {
        ...state,
        isAuth: false,
        token: "",
      };
    }
    default:
      return state;
  }
};

export default authReducer;
