import { redirect } from "react-router-dom";
import {
  login_error,
  login_pending,
  login_success,
  logout_error,
  logout_pending,
  logout_success,
  url_error,
  url_pending,
  url_success,
} from "./authtypes";

const token = localStorage.getItem("token") || "";

const initState = {
  url : '',
  isAuth: token ? true : false,
  isError: false,
  token: token,
  isLoading: false,
  message: "",
  userName: "",
};
export const authReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case url_success: {
      localStorage.setItem("url", payload.url);
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return {
        ...state,
        url: payload.url,
       };
    }
    case url_pending: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case url_error: {
      return {
        ...state,
        isError: true,
      };
    }
    case login_success: {
      localStorage.setItem("token", payload.token);
      localStorage.setItem("name", payload.userName);
      setTimeout(() => {
          window.location.reload();
        }, 1000);
      return {
        ...state,
        token: payload.token,
        userName: payload.userName,
        isAuth: true,
      };
    }
    case login_pending: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case login_error: {
      return {
        ...state,
        isError: true,
      };
    }
    case logout_success: {
      // redirect('/Login')
      localStorage.removeItem("token", payload.token);
      localStorage.removeItem("name", payload.name);
      localStorage.removeItem("url", payload.url);
      return {
        ...state,
        token: "",
        isAuth: false,
      };
    }
    case logout_pending: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case logout_error: {
      return {
        ...state,
        isError: true,
      };
    }

    default: {
      return state;
    }
  }
};
