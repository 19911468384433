import { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiCheckDouble } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { NavLink, useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Doubt({ ...props }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [type, setType] = useState(1);
  const [value, setValue] = useState(0);
  const [user, setUser] = useState([]);
  const [attendance, setAttendance] = useState();
  //  const [msg, setMsg] = useState([]);

  //  console.log(msg);

  const userdata = [
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
    {
      name: "Yogesh Jangid",
      batch: "Batch - AAf0123",
    },
  ];
  const attendencedata = [
    {
      id: "TIPSGRM1012223",
      name: "Himanshu Rajwat",
      // att : 'Absent',
    },
  ];

  useEffect(() => {
    setUser(userdata);
    setAttendance(attendencedata);
  });

  const [name, setName] = useState("Mark as Solved");

  return (
    <>
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="row g-0">
          <div className="col-lg-2 g-0" style={{}}>
            <Sidebar />
          </div>
          <div className="col-lg-10 g-0 p-0">
            <Header />
            <div className="row g-0 d-flex justify-content-between m-auto">
              <div className="col-12">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      onClick={() => setType(1)}
                      label="Unsolved"
                      {...a11yProps(0)}
                    />
                    <Tab
                      onClick={() => setType(2)}
                      label="Solved"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div className="row g-0">
                    <div
                      className="col-3"
                      style={{ borderRight: "2px solid #B1AFC8" }}
                    >
                      <div
                        className="p-3"
                        style={{
                          width: "100%",
                          height: 60,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: 38,
                            border: "1px solid #E4E4E4",
                            borderRadius: 5,
                            boxShadow:
                              "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                          }}
                        >
                          <div className="input-group flex-nowrap">
                            <span
                              className="input-group-text rounded-0 border-0"
                              id="addon-wrapping"
                              style={{ backgroundColor: "#FAFAFA" }}
                            >
                              <BsSearch style={{ color: "#B1AFC8" }} />
                            </span>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              placeholder="Search..."
                              aria-label="Username"
                              aria-describedby="addon-wrapping"
                              style={{
                                border: "none",
                                boxShadow: "none",
                                backgroundColor: "#FAFAFA",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="msg-search">
                        {user.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="row mb-3 g-0 d-flex align-items-center"
                            >
                              <div className="col-4">
                                <div
                                  className="ms-3"
                                  style={{
                                    width: 55,
                                    height: 55,
                                    backgroundColor: "#D9D9D9",
                                    borderRadius: 50,
                                  }}
                                ></div>
                              </div>
                              <div className="col-8 text-start">
                                <div className="">
                                  <div
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                  <div
                                    style={{
                                      fontFamily: "inter",
                                      fontWeight: 600,
                                      color: "#797979",
                                    }}
                                  >
                                    {item.batch}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-9">
                      <div
                        className="row g-0"
                        style={{
                          width: "100%",
                          height: 60,
                          backgroundColor: "#fff",
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        }}
                      >
                        <div className="row d-flex align-items-center">
                          <div className="col-5">
                            <div className="row" style={{}}>
                              <div className="col-4 p-0">
                                <div
                                  className="ms-3"
                                  style={{
                                    width: 50,
                                    height: 50,
                                    backgroundColor: "#D9D9D9",
                                    borderRadius: 50,
                                  }}
                                ></div>
                              </div>
                              <div className="col-8 p-0 text-start">
                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 16,
                                    fontWeight: 500,
                                  }}
                                >
                                  Yogesh Jangid
                                </div>
                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 600,
                                    color: "#797979",
                                  }}
                                >
                                  Batch - AAf0123
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-7 ms-auto">
                            <div className="row d-flex align-items-center">
                              <div className="col-8 ms-auto">
                                <div
                                  className="text-end"
                                  onClick={() => {
                                    setName(
                                      "Mark as solved on 13:06 PM 12 Apr 2023"
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontFamily: "inter",
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: "#8D8D8D",
                                  }}
                                >
                                  <BiCheckDouble style={{ fontSize: 20 }} />
                                  {name}
                                </div>
                              </div>
                              <div className="col-4">
                                <NavLink to="/StudentProfile">
                                  <button
                                    type="button"
                                    className="btn border-0"
                                    style={{
                                      backgroundColor: "#fff",
                                      fontFamily: "inter",
                                      fontWeight: 400,
                                      fontSize: 12,
                                      color: "#2C62EE",
                                    }}
                                  >
                                    <AiOutlineEye style={{ fontSize: 18 }} />
                                    &nbsp;View Profile
                                  </button>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="chat-sec">
                        <div className="row">
                          <div className="col-6 m-2 me-auto">
                            <div
                              className=""
                              style={{
                                width: "100%",
                                backgroundColor: "#FAFAFA",
                                border: "1px solid #E0E0E0",
                                borderRadius: 5,
                              }}
                            >
                              <div
                                className="text-start py-2 px-1"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  color: "#434343",
                                }}
                              >
                                This is a sample message
                              </div>
                              <div
                                className="text-end px-1"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  color: "#797979",
                                }}
                              >
                                2 march 2023/21:21
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 m-2 me-auto">
                            <div
                              className=""
                              style={{
                                width: "100%",
                                backgroundColor: "#FAFAFA",
                                border: "1px solid #E0E0E0",
                                borderRadius: 5,
                              }}
                            >
                              <div
                                className="text-start py-2 px-1"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  color: "#434343",
                                }}
                              >
                                This is a sample message
                              </div>
                              <div
                                className="text-end px-1"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  color: "#797979",
                                }}
                              >
                                2 march 2023/21:21
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 m-2 me-auto">
                            <div
                              className=""
                              style={{
                                width: "100%",
                                backgroundColor: "#FAFAFA",
                                border: "1px solid #E0E0E0",
                                borderRadius: 5,
                              }}
                            >
                              <div
                                className="text-start py-2 px-1"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  color: "#434343",
                                }}
                              >
                                This is a sample message
                              </div>
                              <div
                                className="text-end px-1"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  color: "#797979",
                                }}
                              >
                                2 march 2023/21:21
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-6 ms-auto">
                            <div
                              className=""
                              style={{
                                width: "100%",
                                backgroundColor: "#67A9F6",
                                borderRadius: 5,
                              }}
                            >
                              <div
                                className="text-start py-2 px-1"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  color: "#fff",
                                }}
                              >
                                This is a sample message
                              </div>
                              <div
                                className="text-end px-1"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  color: "#fff",
                                }}
                              >
                                2 march 2023/21:21
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-0">
                        <div
                          className=""
                          style={{
                            width: "100%",
                            height: 50,
                            backgroundColor: "#F3F3F3",
                          }}
                        >
                          <div className="input-group flex-nowrap">
                            <span
                              className="input-group-text rounded-0 border-0"
                              id="addon-wrapping"
                              style={{
                                width: 60,
                                height: 50,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#F3F3F3",
                              }}
                            >
                              <AiOutlinePlusCircle
                                style={{ color: "#434343", fontSize: 25 }}
                              />
                            </span>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              placeholder="Start typing here"
                              aria-label="Username"
                              aria-describedby="addon-wrapping"
                              style={{
                                border: "none",
                                boxShadow: "none",
                                backgroundColor: "#F3F3F3",
                              }}
                            />
                            <span
                              className="input-group-text rounded-0 border-0"
                              id="addon-wrapping"
                              style={{
                                width: 60,
                                height: 50,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#F3F3F3",
                              }}
                            >
                              <BsArrowRight
                                style={{ color: "#434343", fontSize: 20 }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}></TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
