import { redirect } from "react-router-dom";
import { instance } from "../../instance";
import {
  login_error,
  login_pending,
  login_success,
  logout_error,
  logout_pending,
  logout_success,
  url_error,
  url_pending,
  url_success,
} from "./authtypes";
import axios from "axios";
import { toast } from "react-toastify";

export const UrlFun = (schCode, navigate, setSchCode) => async (dispatch) => {
  dispatch({ type: url_pending });
  try {
    let res = axios
      .post(`https://masterapiv2.intellix360.in/get/url/by/school_code`, {
        school_code: schCode,
      })
      .then((res) => {
        if (res.data.status == 1) {
          dispatch({ type: url_success, payload: res.data });
          navigate("/Login");
          setSchCode("");
        } else {
          toast(res.data.msg);
          navigate("/code");
        }
      })
      .catch((err) => {
        dispatch({ type: url_error });
      });
  } catch (err) {
    dispatch({ type: url_error });
  }
};

export const LoginFun = (email, password, navigate) => async (dispatch) => {
  dispatch({ type: login_pending });
  try {
    let res = instance
      .post(`/instructor/authentication/login`, { email, password })
      .then((res) => {
        if (res.data.status == 1) {
          console.log(res, "res in login");
          dispatch({ type: login_success, payload: res.data });
          navigate("/Dashboard");
        } else {
          toast(res.data.Backend_Error);
        }
      })
      .catch((err) => {
        dispatch({ type: login_error });
      });
  } catch (err) {
    dispatch({ type: login_error });
  }
};
export const LogoutFun = (Navigate) => async (dispatch) => {
  dispatch({ type: logout_pending });
  try {
    let res = instance
      .post(`/instructor/authentication/logout`)
      .then((res) => {
        console.log(res, "res in logout");
        if (res.data.status == 1) {
          dispatch({ type: logout_success, payload: res.data });
          Navigate("/code");
        }
      })
      .catch((err) => {
        dispatch({ type: logout_error });
      });
  } catch (err) {
    dispatch({ type: logout_error });
  }
};
