import { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ScheduledAssignments from "./ScheduledAssignments";
import AssignmentsHistory from "./AssignmentsHistory";
import { BsSearch } from "react-icons/bs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FaCalendar } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineFileUpload } from "react-icons/md";
import Card from "react-bootstrap/Card";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../env";
import { useDispatch, useSelector } from "react-redux";
import {
  getScheduleassignmentFun_search,
  getHistoryassignmentFun_search,
  assignmentCreateFun,
  getScheduleassignmentFun,
} from "../redux/assignments/assignmentActions";
import {
  getBatchFun,
  getBatchFun_search,
  getCourseFun,
  getCourseFun_search,
  getSubjectFun,
  getSubjectFun_search,
} from "../redux/comenapi/comenAction";

export default function Assignments() {
  const { course_search, mysubject_search, mybatchs_search } = useSelector(
    (store) => store.comenapi
  );

  const dispatch = useDispatch();
console.log(mysubject_search[0]?.name,"mysubject_search in assin")
  const [State, setState] = useState();
  const [next, setNext] = useState(0);
  const [start_date, setStart_Date] = useState("");
  const [start_time, setStart_time] = useState("");
  const [Due_date, setDue_Date] = useState("");
  const [Due_time, setDue_time] = useState("");
  // console.log(start_date + "T" + start_time, "start time");
  // console.log(Due_date + "T" + Due_time, "End time");
  const [TimePerQuestion, setTimePerQuestion] = useState("0");
  const [SubId, setSubId] = useState();
  const [courseid, setCourseid] = useState();
  const [CourseName, setCourseName] = useState("");
  const [SubjectName, setSubjectName] = useState("");
  const [marks, setMarks] = useState("");
  const [assign_file, setAssign_file] = useState("");
  const [BatchId, setBatchId] = useState([]);
  const [Name, setName] = useState("");
  const [slected_batches, setSelectedBatches] = useState([]);

  const removeTag = (name, id) => {
    setBatchId([...BatchId.filter((el) => el !== id)]);
    setSelectedBatches([...slected_batches.filter((res) => res.name !== name)]);
  };

  //function for hnadling checked bvacked ids
  const handleChange1 = (name, id) =>
    slected_batches.some((el) => el.name === name)
      ? toast("batch is already selected")
      : (setSelectedBatches([...slected_batches, { name, id }]),
        setBatchId([...BatchId, id]));

  useEffect(() => {
    dispatch(getCourseFun());
 
    setState("ScheduledAssignments");
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-lg-2 g-0" style={{}}>
          <Sidebar />
        </div>
        <div className="col-lg-10 g-0" style={{}}>
          <Header />
          <div
            className="mt-3 px-3"
            style={{
              width: "100%",
              height: 40,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "inter", fontSize: 20, fontWeight: 600 }}>
              Assignments
            </div>
            <button
              type="button"
              className="btn"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRightAssign"
              aria-controls="offcanvasRight"
              style={{
                height: 30,
                backgroundColor: "#3D70F5",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                color: "#fff",
                fontSize: 14,
                fontFamily: "inter",
                fontWeight: 400,
                border: "none",
              }}
              onClick={() => {}}
            >
              + Add Assignments
            </button>
          </div>
          <div
            className="row px-3 mt-4 g-0"
            style={{
              height: 40,
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #E4E4E4",
            }}
          >
            <div
              className="col-8"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <div style={{ display: "flex" }}>
                <button
                  type="button"
                  onClick={() => {
                    setState("ScheduledAssignments");
                  }}
                  className="rounded-0 bg-transparent"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    borderBottom:
                      State == "ScheduledAssignments"
                        ? "1px solid #3D70F5"
                        : "none",
                    color: State == "ScheduledAssignments" ? "#3D70F5" : "#000",
                    width: 200,
                    height: 38,
                  }}
                >
                  Scheduled Assignments
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setState("AssignmentsHistory");
                  }}
                  className="rounded-0 bg-transparent mx-4"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    borderBottom:
                      State == "AssignmentsHistory"
                        ? "1px solid #3D70F5"
                        : "none",
                    color: State == "AssignmentsHistory" ? "#3D70F5" : "#000",
                    width: 100,
                    height: 38,
                  }}
                >
                  History
                </button>
              </div>
            </div>
            {State === "ScheduledAssignments" ? (
              <div className="col-4">
                <div className="input-group flex-nowrap">
                  <span
                    className="input-group-text"
                    id="addon-wrapping"
                    style={{
                      borderRadius: 0,
                      border: "none",
                      backgroundColor: "#F1F1F1",
                    }}
                  >
                    <BsSearch style={{ color: "#6A6A6A" }} />
                  </span>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    style={{
                      borderRadius: 0,
                      border: "none",
                      backgroundColor: "#F1F1F1",
                      boxShadow: "none",
                    }}
                    onKeyUp={(e) => {
                      dispatch(getScheduleassignmentFun_search(e.target.value));
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="col-4">
                <div className="input-group flex-nowrap">
                  <span
                    className="input-group-text"
                    id="addon-wrapping"
                    style={{
                      borderRadius: 0,
                      border: "none",
                      backgroundColor: "#F1F1F1",
                    }}
                  >
                    <BsSearch style={{ color: "#6A6A6A" }} />
                  </span>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    style={{
                      borderRadius: 0,
                      border: "none",
                      backgroundColor: "#F1F1F1",
                      boxShadow: "none",
                    }}
                    onKeyUp={(e) => {
                      // searched_readHistoryAssignment(e.target.value)
                      dispatch(getHistoryassignmentFun_search(e.target.value));
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="bar2 mt-2 mx-4">
            {State === "ScheduledAssignments" ? (
              <>
                {" "}
                <ScheduledAssignments />{" "}
              </>
            ) : (
              <></>
            )}
            {State === "AssignmentsHistory" ? (
              <>
                {" "}
                <AssignmentsHistory />{" "}
              </>
            ) : (
              <></>
            )}
          </div>


          
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasRightAssign"
            aria-labelledby="offcanvasRightLabel"
            style={{ width: "50%" }}
          >
            <div className="offcanvas-header">
              <button
                type="button"
                onClick={() => {
                  setNext(0);
                  setCourseid("");
                  setCourseName("");
                  setSubId();
                  setSubjectName("");
                  setBatchId();
                  setMarks("");
                  setName("");
                  setAssign_file();
                  setTimePerQuestion("0");
                  setDue_Date("");
                  setDue_time("");
                  setStart_Date("");
                  setStart_time("");
                  setSelectedBatches([]);
                }}
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div style={{ display: next === 0 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Assignment
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>1</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "25%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Course
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Select Course search"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            onKeyUp={(e) => {
                              dispatch(getCourseFun_search(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                      {course_search &&
                        course_search?.map((itemd, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center mx-3 m-1"
                            >
                              <label
                                className="form-check-label p-0"
                                htmlFor={itemd.id}
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  checked={itemd.name === CourseName}
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id={itemd.id}
                                  style={{
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }}
                                  onChange={(e) => {
                                    setCourseName(itemd.name);
                                    setCourseid(itemd.id);
                                    dispatch(getSubjectFun(itemd.id));
                                    dispatch(getBatchFun(itemd.id));
                                  }}
                                />

                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {itemd.name}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        className="mt-2"
                        disabled={CourseName?.length === 0}
                        onClick={() => {
                          // CourseName?.length === 0?toast("coure"):
                          setNext(next + 1);
                        }}
                        style={{
                          width: 64,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          border: "none",
                          background: "none",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Next
                        </div>
                        <HiOutlineArrowSmRight style={{ color: "#6B6B6B" }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 1 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Assignment
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <p
                      className="pt-2 mb-2 text-start"
                      id="demo-radio-buttons-group-label"
                      style={{
                        fontFamily: "inter",
                        fontSize: 18,
                        fontWeight: 600,
                        color: "#000",
                      }}
                    >
                      Optional
                    </p>
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Subject
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Select Subject search"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            onKeyUp={(e) => {
                            

                              dispatch(getSubjectFun_search(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                      {mysubject_search &&
                        mysubject_search.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center mx-3 m-1"
                            >
                              <label
                                className="form-check-label p-0"
                                htmlFor={item.name}
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  checked={item.name === SubjectName}
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefaul"
                                  id={item.name}
                                  value={SubjectName}
                                  style={{
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => {
                                    setSubId(item.id);
                                    setSubjectName(item.name);
                                  }}
                                />

                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {item.name}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <button
                        className="mt-2"
                        disabled={SubjectName?.length === 0}
                        onClick={() => {
                          setNext(next + 1);
                        }}
                        style={{
                          width: 64,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          border: "none",
                          background: "none",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Next
                        </div>
                        <HiOutlineArrowSmRight style={{ color: "#6B6B6B" }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 2 ? "block" : "none" }}>
                <p
                  className="text-start"
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Assignment
                </p>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Batch
                      </p>
                      <div className="input-group flex-nowrap">
                        <span
                          className="input-group-text"
                          id="addon-wrapping"
                          style={{
                            borderRadius: 0,
                            border: "none",
                            backgroundColor: "#F1F1F1",
                          }}
                        >
                          <BsSearch style={{ color: "#6A6A6A" }} />
                        </span>
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search Tags "
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          style={{
                            borderRadius: 0,
                            border: "none",
                            backgroundColor: "#F1F1F1",
                            boxShadow: "none",
                          }}
                          onKeyUp={(e) => {
                           
                            dispatch(getBatchFun_search(e.target.value));
                          }}
                        />
                      </div>
                      <div
                        style={{
                          overflowX: "scroll",
                          height: "50px",
                          maxHeight: "100%",
                          display: "flex",
                          alignItems: "center",
                          color: "green",
                        }}
                      >
                        {slected_batches?.map((el) => (
                          <div
                            key={el.id}
                            className="px-2 me-2"
                            style={{
                              height: 35,
                              backgroundColor: "#daffda",
                              display: "flex",
                              alignItems: "center",
                             
                              justifyContent: "center",
                              borderRadius: 5,
                            }}
                          >
                            <span style={{ marginRight: "20px",width:"103px" }}>
                              {el?.name}
                            </span>

                            <AiOutlineClose
                              style={{ cursor: "pointer" }}
                              onClick={() => removeTag(el?.name, el.id)}
                            />
                          </div>
                        ))}
                      </div>

                      <div className="barsubject">
                        {mybatchs_search &&
                          mybatchs_search.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="form-check d-flex align-items-center  my-2 "
                              >
                                <label
                                  onClick={() =>
                                    handleChange1(item.batch_name, item.id)
                                  }
                                  className="form-check-label p-0"
                                  htmlFor={`flexRadioDefault${index}`}
                                  style={{
                                    backgroundColor: "rgb(234 237 234)",
                                    border: "1px solid green",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#6A6A6A",
                                      cursor: "pointer",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    {item.batch_name}
                                  </div>
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </span>
                      </div>
                      <button
                        disabled={BatchId?.length === 0}
                        onClick={() => {
                          setNext(next + 1);
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          opacity: BatchId?.length === 0 ? 0.6 : 1,
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                          }}
                        >
                          Proceed
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 3 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Assignment
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "75%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Assignment Specifications
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Total Marks"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            value={marks}
                            onChange={(e) => {
                              setMarks(e.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row px-2 mt-4">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            Passing Percentage
                          </div>
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 600,
                              color: "#3D70F5",
                              fontSize: 14,
                            }}
                          >
                            {TimePerQuestion}%
                          </div>
                        </div>
                        <div className="px-2">
                          <span>
                            <input
                              style={{ cursor: "pointer" }}
                              className="w-100 input-range"
                              type="range"
                              min="10"
                              max="100"
                              value={TimePerQuestion}
                              onChange={(e) => {
                                setTimePerQuestion(e.target.value);
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="input-group flex-nowrap">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Assignment name"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          style={{
                            borderRadius: 0,
                            border: "none",
                            backgroundColor: "#F1F1F1",
                            boxShadow: "none",
                          }}
                          value={Name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>

                      <div className="row mt-2 mx-1">
                        <span
                          className="p-0 text-start"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          Schedule
                        </span>
                        <span style={{ display: "flex" }}>
                          <span
                            className="col-4 rounded-1 me-5"
                            style={{
                              backgroundColor: "#EDEDF5",
                              height: "30px",
                            }}
                          >
                            <input
                              type="date"
                              className="border-0 bg-transparent"
                              style={{ color: "#8A8A8A" }}
                              value={start_date}
                              onChange={(e) => {
                                setStart_Date(e.target.value);
                              }}
                            />
                          </span>
                          <span
                            className="col-3 rounded-1 "
                            style={{
                              backgroundColor: "#EDEDF5",
                              height: "30px",
                            }}
                          >
                            <input
                              type="time"
                              className="border-0 bg-transparent"
                              style={{ color: "#8A8A8A" }}
                              value={start_time}
                              onChange={(e) => {
                                setStart_time(e.target.value);
                              }}
                            />
                          </span>
                        </span>
                      </div>
                      <div className="row mt-3 mx-1">
                        <span
                          className="p-0 text-start"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          Due Date
                        </span>
                        <span style={{ display: "flex" }}>
                          <span
                            className="col-4 rounded-1 me-5"
                            style={{
                              backgroundColor: "#EDEDF5",
                              height: "30px",
                            }}
                          >
                            <input
                              type="date"
                              className="border-0 bg-transparent"
                              style={{ color: "#8A8A8A" }}
                              value={Due_date}
                              onChange={(e) => {
                                setDue_Date(e.target.value);
                              }}
                            />
                          </span>
                          <span
                            className="col-3 rounded-1"
                            style={{
                              backgroundColor: "#EDEDF5",
                              height: "30px",
                            }}
                          >
                            <input
                              type="time"
                              className="border-0 bg-transparent"
                              style={{ color: "#8A8A8A" }}
                              value={Due_time}
                              onChange={(e) => {
                                setDue_time(e.target.value);
                              }}
                            />
                          </span>
                        </span>
                      </div>

                      <div className="mt-3">
                        <label
                          style={{
                            width: "100%",
                            height: 40,
                            backgroundColor: "#fff",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #000",
                            cursor: "pointer",
                          }}
                          htmlFor="image-id"
                        >
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              fontFamily: "inter",
                              color: "#000",
                              paddingRight: 10,
                            }}
                          >
                            Upload Files
                            <MdOutlineFileUpload style={{ fontSize: 20 }} />
                          </span>
                        </label>
                        <input
                          type="file"
                          accept=".pdf"
                          multiple
                          onChange={(e) => {
                            setAssign_file(e.target.files[0]);

                            // e.target.files[0].type.includes("application/pdf")
                            // ? setAssign_file(e.target.files[0])
                            // : toast("Please Select pdf file only");
                          }}
                          id="image-id"
                          hidden
                        />
                      </div>
                      <div
                        className="  mt-3 "
                        style={{
                          height: 35,
                          backgroundColor: "#E9E9E9",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 5,
                        }}
                      >
                        <span style={{ marginRight: "20px" }}>
                          {assign_file?.name}
                        </span>
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <button
                        disabled={
                          marks === "" ||
                          TimePerQuestion === "0" ||
                          Name === "" ||
                          start_time === "" ||
                          Due_time === "" ||
                          assign_file === ""
                        }
                        onClick={() => {
                          setNext(next + 1);
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          cursor: "pointer",
                          opacity:
                            marks === ""
                              ? 0.6
                              : Name === ""
                              ? 0.6
                              : TimePerQuestion === "0"
                              ? 0.6
                              : start_time === ""
                              ? 0.6
                              : Due_time === ""
                              ? 0.6
                              : assign_file === ""
                              ? 0.6
                              : 1,
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                          }}
                        >
                          Continue
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 4 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Assignment
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>4</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "100%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="px-0 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 24,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Preview
                      </p>
                      <div>
                        <div
                          className="text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#434343",
                          }}
                        >
                          Name :{" "}
                        </div>
                        <div
                          className="text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#434343",
                          }}
                        >
                          {Name}
                        </div>
                      </div>
                      <div
                        className="row g-0 text-start mt-2"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          className="col-2"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            color: "#434343",
                            fontWeight: 400,
                          }}
                        >
                          Course{" "}
                        </span>
                        <span
                          className="col-10 text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            color: "#434343",
                            fontWeight: 400,
                          }}
                        >
                          : {CourseName}
                        </span>
                      </div>
                      <div
                        className="row g-0 text-start"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          className="col-2 m-0"
                          style={{
                            fontFamily: "inter",
                            fontSize: 15,
                            color: "#434343",
                            fontWeight: 400,
                          }}
                        >
                          Subject{" "}
                        </span>
                        <span
                          className="col-10 m-0"
                          style={{
                            fontFamily: "inter",
                            fontSize: 15,
                            color: "#434343",
                            fontWeight: 400,
                            textAlign: "left ",
                          }}
                        >
                          : {SubjectName}
                        </span>
                      </div>
                      <div
                        className="row g-0 mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          className="m-0 text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 15,
                            color: "#434343",
                            fontWeight: 600,
                          }}
                        >
                          Total Marks : {marks}
                        </span>
                      </div>
                      <div
                        className="mt-2"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          Passing Percentage :
                        </div>
                        <div
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            color: "#3D70F5",
                            fontSize: 14,
                          }}
                        >
                          &nbsp;&nbsp;{TimePerQuestion} %
                        </div>
                      </div>
                      <div className="mt-2">
                        <div
                          className="text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#434343",
                          }}
                        >
                          Uploaded Files
                        </div>
                        <div
                          className="  mt-3 "
                          style={{
                            height: 35,
                            backgroundColor: "#E9E9E9",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 5,
                          }}
                        >
                          <span style={{ marginRight: "20px" }}>
                            {assign_file?.name}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <span
                          className="px-3 text-start"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          Schedule
                        </span>
                        <div className="col-4">
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              backgroundColor: "#EDEDF5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <FaCalendar />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                fontFamily: "inter",
                                paddingLeft: 10,
                              }}
                            >
                              {start_date}
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              backgroundColor: "#EDEDF5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <FaCalendar />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                fontFamily: "inter",
                                paddingLeft: 10,
                              }}
                            >
                              {start_time}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <span
                          className="px-3 text-start"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          Due Date
                        </span>
                        <div className="col-4">
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              backgroundColor: "#EDEDF5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <FaCalendar />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                fontFamily: "inter",
                                paddingLeft: 10,
                              }}
                            >
                              {Due_date}
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              backgroundColor: "#EDEDF5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <FaCalendar />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                fontFamily: "inter",
                                paddingLeft: 10,
                              }}
                            >
                              {Due_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          // Add();
                          dispatch(
                            assignmentCreateFun(
                              {
                                cou_name: CourseName,
                                sub_name: SubjectName,
                                tot_mark: marks,
                                pass_pers: TimePerQuestion,
                                inst_attach: assign_file,
                                sub_id: SubId,
                                batchids: JSON.stringify(BatchId),
                                assignment_name: Name,
                                sch_time: start_date + "T" + start_time,
                                due_time: Due_date + "T" + Due_time,
                                cou_id: courseid,
                              },
                              setNext,
                              next
                            )
                          );
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                          }}
                        >
                          Confirm
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 5 ? "block" : "none" }}>
                <div className="row mt-5">
                  <p
                    className="col-10 m-auto mt-5"
                    style={{
                      fontFamily: "inter",
                      fontSize: 30,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Assignment Created Successfully
                  </p>
                </div>
                <div className="row">
                  <div
                    className="col-8 m-auto mt-4 px-5"
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    New Assignment within {CourseName} with subject{" "}
                    {SubjectName} Added Succesfully with the following Name :{" "}
                    <span style={{ color: "#000", fontWeight: "bold" }}>
                      {Name}
                    </span>
                  </div>
                </div>
                <div className="row mt-4">
                  <div
                    className="col-4 m-auto"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(getScheduleassignmentFun());
                      setNext(0);
                      setCourseid("");
                      setCourseName("");
                      setSubId();
                      setSubjectName("");
                      setBatchId();
                      setMarks("");
                      setName("");
                      setAssign_file();
                      setTimePerQuestion("0");
                      setDue_Date("");
                      setDue_time("");
                      setStart_Date("");
                      setStart_time("");
                      setSelectedBatches([]);
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 35,
                        backgroundColor: "#3D70F5",
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#fff",
                        }}
                      >
                        Back to Dashboard
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
