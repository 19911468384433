import React from "react";

export const Calendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.509"
      height="26.121"
      viewBox="0 0 23.509 26.121"
    >
      <path
        id="Icon_material-event-note"
        data-name="Icon material-event-note"
        d="M22.785,13.255H9.724v2.612H22.785ZM25.4,4.112H24.091V1.5H21.479V4.112H11.03V1.5H8.418V4.112H7.112a2.6,2.6,0,0,0-2.6,2.612L4.5,25.009a2.611,2.611,0,0,0,2.612,2.612H25.4a2.62,2.62,0,0,0,2.612-2.612V6.724A2.62,2.62,0,0,0,25.4,4.112Zm0,20.9H7.112V10.642H25.4Zm-6.53-6.53H9.724v2.612h9.142Z"
        transform="translate(-4.5 -1.5)"
        fill="#40997e"
      />
    </svg>
  );
};

export const Comp = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33335 13.3333H10.6667V9.83333H14.1667V12.1667L17.6667 8.66667L14.1667 5.16667V7.5H8.33335V13.3333ZM1.33335 18V0.5H24.6667V18H1.33335ZM3.66669 15.6667H22.3334V2.83333H3.66669V15.6667ZM0.166687 21.5V19.1667H25.8334V21.5H0.166687Z"
        fill="white"
      />
    </svg>
  );
};

export const Video = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.375 17.75H2.5C2.03587 17.75 1.59075 17.5656 1.26256 17.2374C0.934374 16.9092 0.75 16.4641 0.75 16V2C0.75 1.53587 0.934374 1.09075 1.26256 0.762563C1.59075 0.434374 2.03587 0.25 2.5 0.25H17.375C17.8391 0.25 18.2842 0.434374 18.6124 0.762563C18.9406 1.09075 19.125 1.53587 19.125 2V5.5525L23.8675 2.16625C23.9981 2.07326 24.1517 2.01795 24.3116 2.00633C24.4715 1.99472 24.6315 2.02725 24.7742 2.10039C24.9168 2.17353 25.0367 2.28445 25.1206 2.42105C25.2045 2.55766 25.2493 2.71469 25.25 2.875V15.125C25.2493 15.2853 25.2045 15.4423 25.1206 15.5789C25.0367 15.7155 24.9168 15.8265 24.7742 15.8996C24.6315 15.9727 24.4715 16.0053 24.3116 15.9937C24.1517 15.9821 23.9981 15.9267 23.8675 15.8337L19.125 12.4475V16C19.125 16.4641 18.9406 16.9092 18.6124 17.2374C18.2842 17.5656 17.8391 17.75 17.375 17.75Z"
        fill="white"
      />
    </svg>
  );
};

export const Mice = () => {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99998 14.3333C8.02776 14.3333 7.20137 13.993 6.52081 13.3125C5.84026 12.6319 5.49998 11.8055 5.49998 10.8333V3.83331C5.49998 2.86109 5.84026 2.0347 6.52081 1.35415C7.20137 0.673591 8.02776 0.333313 8.99998 0.333313C9.9722 0.333313 10.7986 0.673591 11.4791 1.35415C12.1597 2.0347 12.5 2.86109 12.5 3.83331V10.8333C12.5 11.8055 12.1597 12.6319 11.4791 13.3125C10.7986 13.993 9.9722 14.3333 8.99998 14.3333ZM7.83331 22.5V18.9125C5.81109 18.6403 4.13887 17.7361 2.81665 16.2C1.49442 14.6639 0.833313 12.875 0.833313 10.8333H3.16665C3.16665 12.4472 3.73559 13.8227 4.87348 14.9598C6.01059 16.0977 7.38609 16.6666 8.99998 16.6666C10.6139 16.6666 11.9898 16.0977 13.1276 14.9598C14.2648 13.8227 14.8333 12.4472 14.8333 10.8333H17.1666C17.1666 12.875 16.5055 14.6639 15.1833 16.2C13.8611 17.7361 12.1889 18.6403 10.1666 18.9125V22.5H7.83331Z"
        fill="white"
      />
    </svg>
  );
};

export const Call = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7167 22C18.0889 22 15.5275 21.4194 13.0326 20.2583C10.5368 19.0972 8.32659 17.5491 6.402 15.6139C4.47659 13.6787 2.92844 11.4632 1.75756 8.96744C0.585852 6.47248 0 3.91111 0 1.28333V0.641667C0 0.417593 0.0203703 0.203704 0.0611111 0H7.21111L8.34167 6.14167L4.85833 9.65555C5.71389 11.1222 6.78863 12.4972 8.08256 13.7806C9.37567 15.0639 10.7963 16.1741 12.3444 17.1111L15.8889 13.5667L22 14.7889V21.9389C21.7963 21.9593 21.5824 21.9747 21.3583 21.9853C21.1343 21.9951 20.9204 22 20.7167 22Z"
        fill="white"
      />
    </svg>
  );
};

export const Download = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z" fill="white" />
    </svg>
  );
};

export const Delete = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91602 25.75C4.13685 25.75 3.47007 25.4728 2.91568 24.9184C2.36035 24.3631 2.08268 23.6958 2.08268 22.9167V4.5C1.68129 4.5 1.3446 4.36447 1.0726 4.09342C0.801544 3.82142 0.666016 3.48472 0.666016 3.08333C0.666016 2.68194 0.801544 2.34525 1.0726 2.07325C1.3446 1.80219 1.68129 1.66667 2.08268 1.66667H7.74935C7.74935 1.26528 7.88535 0.928583 8.15735 0.656583C8.4284 0.385528 8.76463 0.25 9.16602 0.25H14.8327C15.2341 0.25 15.5708 0.385528 15.8428 0.656583C16.1138 0.928583 16.2493 1.26528 16.2493 1.66667H21.916C22.3174 1.66667 22.6536 1.80219 22.9247 2.07325C23.1967 2.34525 23.3327 2.68194 23.3327 3.08333C23.3327 3.48472 23.1967 3.82142 22.9247 4.09342C22.6536 4.36447 22.3174 4.5 21.916 4.5V22.9167C21.916 23.6958 21.6388 24.3631 21.0844 24.9184C20.5291 25.4728 19.8618 25.75 19.0827 25.75H4.91602ZM4.91602 4.5V22.9167H19.0827V4.5H4.91602ZM7.74935 18.6667C7.74935 19.0681 7.88535 19.4043 8.15735 19.6753C8.4284 19.9473 8.76463 20.0833 9.16602 20.0833C9.5674 20.0833 9.9041 19.9473 10.1761 19.6753C10.4472 19.4043 10.5827 19.0681 10.5827 18.6667V8.75C10.5827 8.34861 10.4472 8.01192 10.1761 7.73992C9.9041 7.46886 9.5674 7.33333 9.16602 7.33333C8.76463 7.33333 8.4284 7.46886 8.15735 7.73992C7.88535 8.01192 7.74935 8.34861 7.74935 8.75V18.6667ZM13.416 18.6667C13.416 19.0681 13.552 19.4043 13.824 19.6753C14.0951 19.9473 14.4313 20.0833 14.8327 20.0833C15.2341 20.0833 15.5708 19.9473 15.8428 19.6753C16.1138 19.4043 16.2493 19.0681 16.2493 18.6667V8.75C16.2493 8.34861 16.1138 8.01192 15.8428 7.73992C15.5708 7.46886 15.2341 7.33333 14.8327 7.33333C14.4313 7.33333 14.0951 7.46886 13.824 7.73992C13.552 8.01192 13.416 8.34861 13.416 8.75V18.6667Z"
        fill="black"
      />
    </svg>
  );
};

export const ImageUpload = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 16C3.98 16 2.68333 15.4767 1.61 14.43C0.536667 13.3767 0 12.0933 0 10.58C0 9.28 0.39 8.12 1.17 7.1C1.95667 6.08 2.98333 5.43 4.25 5.15C4.67 3.61667 5.50333 2.37667 6.75 1.43C8.00333 0.476667 9.42 0 11 0C12.9533 0 14.6067 0.68 15.96 2.04C17.32 3.39333 18 5.04667 18 7C19.1533 7.13333 20.1067 7.63333 20.86 8.5C21.62 9.35333 22 10.3533 22 11.5C22 12.7533 21.5633 13.8167 20.69 14.69C19.8167 15.5633 18.7533 16 17.5 16H12C11.4533 16 10.9833 15.8033 10.59 15.41C10.1967 15.0233 10 14.5533 10 14V8.85L8.4 10.4L7 9L11 5L15 9L13.6 10.4L12 8.85V14H17.5C18.2 14 18.79 13.7567 19.27 13.27C19.7567 12.79 20 12.2 20 11.5C20 10.8 19.7567 10.21 19.27 9.73C18.79 9.24333 18.2 9 17.5 9H16V7C16 5.62 15.5133 4.44 14.54 3.46C13.5667 2.48667 12.3867 2 11 2C9.62 2 8.44 2.48667 7.46 3.46C6.48667 4.44 6 5.62 6 7H5.5C4.53333 7 3.71 7.34333 3.03 8.03C2.34333 8.71 2 9.53333 2 10.5C2 11.4667 2.34333 12.3 3.03 13C3.71 13.6667 4.53333 14 5.5 14H8V16"
        fill="black"
      />
    </svg>
  );
};

export const Sort = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 12V10H11V12H7ZM3 7V5H15V7H3ZM0 2V0H18V2H0Z" fill="#474747" />
    </svg>
  );
};

export const Excel = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2384 9.59375L5.32031 8.375V17.3806C5.32039 17.4785 5.33975 17.5753 5.37726 17.6657C5.41478 17.756 5.46972 17.8381 5.53896 17.9072C5.6082 17.9764 5.69037 18.0312 5.78079 18.0685C5.87121 18.1059 5.9681 18.1251 6.06594 18.125H18.0034C18.1014 18.1252 18.1984 18.1062 18.289 18.0689C18.3795 18.0316 18.4619 17.9768 18.5312 17.9077C18.6006 17.8385 18.6557 17.7564 18.6932 17.666C18.7308 17.5755 18.7502 17.4786 18.7503 17.3806V14.0625L12.2384 9.59375Z"
        fill="#185C37"
      />
      <path
        d="M12.2384 1.875H6.06594C5.9681 1.87492 5.87121 1.89411 5.78079 1.93147C5.69037 1.96883 5.6082 2.02364 5.53896 2.09276C5.46972 2.16189 5.41478 2.24397 5.37726 2.33432C5.33975 2.42468 5.32039 2.52154 5.32031 2.61938V5.9375L12.2384 10L15.9016 11.2187L18.7503 10V5.9375L12.2384 1.875Z"
        fill="#21A366"
      />
      <path d="M5.32031 5.9375H12.2384V10H5.32031V5.9375Z" fill="#107C41" />
      <path
        opacity="0.1"
        d="M10.2716 5.125H5.32031V15.2812H10.2716C10.4689 15.2803 10.6578 15.2016 10.7975 15.0623C10.9372 14.9229 11.0163 14.7342 11.0178 14.5369V5.86937C11.0163 5.67209 10.9372 5.48333 10.7975 5.34399C10.6578 5.20466 10.4689 5.12598 10.2716 5.125Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M9.86469 5.53125H5.32031V15.6875H9.86469C10.062 15.6865 10.2509 15.6078 10.3906 15.4685C10.5303 15.3292 10.6095 15.1404 10.6109 14.9431V6.27562C10.6095 6.07834 10.5303 5.88958 10.3906 5.75024C10.2509 5.61091 10.062 5.53223 9.86469 5.53125Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M9.86469 5.53125H5.32031V14.875H9.86469C10.062 14.874 10.2509 14.7953 10.3906 14.656C10.5303 14.5167 10.6095 14.3279 10.6109 14.1306V6.27563C10.6095 6.07834 10.5303 5.88958 10.3906 5.75024C10.2509 5.61091 10.062 5.53223 9.86469 5.53125Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M9.45781 5.53125H5.32031V14.875H9.45781C9.6551 14.874 9.84406 14.7953 9.98375 14.656C10.1234 14.5167 10.2026 14.3279 10.2041 14.1306V6.27563C10.2026 6.07834 10.1234 5.88958 9.98375 5.75024C9.84406 5.61091 9.6551 5.53223 9.45781 5.53125Z"
        fill="black"
      />
      <path
        d="M1.99625 5.53125H9.4575C9.65514 5.53109 9.84477 5.6094 9.9847 5.74898C10.1246 5.88856 10.2034 6.07798 10.2038 6.27563V13.7244C10.2034 13.922 10.1246 14.1114 9.9847 14.251C9.84477 14.3906 9.65514 14.4689 9.4575 14.4688H1.99625C1.89836 14.4689 1.8014 14.4498 1.71091 14.4125C1.62042 14.3751 1.53817 14.3203 1.46887 14.2512C1.39956 14.1821 1.34456 14.1 1.30701 14.0096C1.26945 13.9192 1.25008 13.8223 1.25 13.7244V6.27563C1.25008 6.17774 1.26945 6.08083 1.30701 5.99043C1.34456 5.90003 1.39956 5.81792 1.46887 5.74879C1.53817 5.67966 1.62042 5.62487 1.71091 5.58754C1.8014 5.55021 1.89836 5.53109 1.99625 5.53125Z"
        fill="url(#paint0_linear_550_4892)"
      />
      <path
        d="M3.5625 12.4208L5.13188 9.99334L3.69437 7.57959H4.84875L5.63312 9.12522C5.70562 9.27147 5.75813 9.38021 5.78188 9.45271H5.7925C5.84375 9.33521 5.89813 9.22209 5.955 9.11146L6.79375 7.58209H7.85625L6.38188 9.98209L7.89375 12.4227H6.76313L5.85688 10.7283C5.8148 10.6555 5.77904 10.5792 5.75 10.5002H5.735C5.70857 10.5771 5.67334 10.6508 5.63 10.7196L4.69688 12.4208H3.5625Z"
        fill="white"
      />
      <path
        d="M18.0039 1.875H12.2383V5.9375H18.7502V2.61938C18.7501 2.52149 18.7307 2.42458 18.6931 2.33418C18.6556 2.24378 18.6006 2.16167 18.5313 2.09254C18.462 2.02341 18.3797 1.96862 18.2892 1.93129C18.1988 1.89396 18.1018 1.87484 18.0039 1.875Z"
        fill="#33C481"
      />
      <path d="M12.2383 10H18.7502V14.0625H12.2383V10Z" fill="#107C41" />
      <defs>
        <linearGradient
          id="paint0_linear_550_4892"
          x1="2.80875"
          y1="4.94625"
          x2="8.645"
          y2="15.0538"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#18884F" />
          <stop offset="0.5" stop-color="#117E43" />
          <stop offset="1" stop-color="#0B6631" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Noname = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9"
        stroke="#555555"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const ImageFile = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 5V7.99C16 7.99 14.01 8 14 7.99V5H11C11 5 11.01 3.01 11 3H14V0H16V3H19V5H16ZM13 9V6H10V3H0V19H16V9H13ZM2 17L5 13L7 16L10 12L14 17H2Z"
        fill="#8A8A8A"
      />
    </svg>
  );
};
