import { createContext, useContext, useReducer } from "react";
import authReducer from "./authReducer";
import {
  login_error,
  login_pending,
  login_success,
  logout_success,
} from "./auth.actions";
import { instance } from "../../instance";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const initState = {
  isAuth: false,
  token: "",
  loading: "",
  message: "",
  userName: "",
};

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initState);
  const navigate = useNavigate();

  const LoginFun = async (creds) => {
    dispatch({ type: login_pending });
    try {
      let res = await instance.post("/login", creds);

      console.log(res, "before dispatch");
      if (res.data.status) {
        console.log("i am in succes mode");
        dispatch({
          type: login_success,
          payload: { token: res.data.token, userName: res.data.userName },
        });
        // navigate("/Dashboard");
      } else {
        dispatch({ type: login_error, payload: { message: res.data.message } });
      }
    } catch (err) {
      dispatch({
        type: login_error,
        payload: { message: "Internal Server Error" },
      });
      console.log(err);
    }
  };

  const LogOutFun = () => {
    dispatch({ type: logout_success });
  };

  return (
    <AuthContext.Provider value={{ state, LogOutFun, LoginFun }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContextProvider;
