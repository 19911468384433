import { FiClock } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import Card from "react-bootstrap/Card";

export default function Missed({missed}) {
  


  return (
    <div className="bar3">
      {missed.length > 0 ? (
        missed.map((item, index) => {
          return (
            <Card
              style={{
                backgroundColor: "#fff",
                border: "1px solid #9E9E9E",
                marginBottom: 20,
              }}
            >
              <Card.Body className="text-start" key={index}>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    textAlign: "start",
                    fontFamily: "inter",
                  }}
                >
                  {item.title}
                </span>
                {/* <div className='my-3' style={{ width:"50%", height:35, border:'1px solid #D3D3D3', borderRadius:5,display:"flex",alignItems:"center",justifyContent:"center" }} >
                    <span style={{color:"#989898"}}>{props.item.batch}</span>
                 </div> */}
                <div className="row mt-2">
                  <div className="col-3 d-flex align-items-center">
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 16,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      Assigned
                    </span>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-start">
                    <FiClock style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      {item.created_at.split("T")[1].split(".")[0]}
                    </span>
                  </div>
                  <div className="col-5 d-flex align-items-center justify-content-start">
                    <FaRegCalendarAlt style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      {item.created_at.split("", 10)}
                    </span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-3 d-flex align-items-center">
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 16,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      Due
                    </span>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-start">
                    <FiClock style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      {item.due_date.split("T")[1].split(".")[0]}
                    </span>
                  </div>
                  <div className="col-5 d-flex align-items-center justify-content-start">
                    <FaRegCalendarAlt style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      {item.due_date.split("", 10)}
                    </span>
                  </div>
                </div>
                <div
                  className="my-3"
                  style={{
                    width: "21%",
                    height: 30,
                    backgroundColor: "#FFECEC",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E6E6E6",
                  }}
                >
                  <span
                    style={{
                      color: "#F93333",
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: "inter",
                    }}
                  >
                    {item.status}
                  </span>
                </div>
                {/*<div
                  className="mt-3"
                  style={{
                    width: "50%",
                    height: 40,
                    backgroundColor: "#3D70F5",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: "inter",
                    }}
                  >
                    View Details
                  </span>
                  </div>*/}
              </Card.Body>
            </Card>
          );
        })
      ) : (
        <>
          {" "}
          <Card
            style={{
              backgroundColor: "#fff",
              border: "1px solid #9E9E9E",
              marginBottom: 20,
            }}
          >
            <Card.Body className="">
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 18,
                  fontFamily: "inter",
                  color: "red",
                }}
              >
                No data available
              </span>
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  );
}
