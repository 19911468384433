import axios from "axios";
import { store } from "./redux/store";
import { API_URL } from "./env";

// const token = localStorage.getItem("token");
const frontend_url = window.location.hostname;
console.log(window.location.hostname,"window.location.hostname")


const {token} =  store.getState().auth;
console.log(API_URL, "url ");

//function for instances created using axios
export const instance = axios.create({
  baseURL:
    API_URL,
  // timeout: 5000,
  headers: {
    "X-Custom-Header": "foobar",
    Authorization: `Bearer ${token}`,
  },
});

//https://insapitipsg.intellix360.in/

//function for image generator by their file name
// export const imageGenertor = (filename) => {
//   let image =
//     frontend_url === "localhost"
//       ? `http://localhost:8080/public/institutes/${filename}`
//       : `https://masterapi.intellix360.in/public/institutes/${filename}`;
//   return image;
// };

// Add a response interceptor
// instance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // Check if the response status code is 111
//     if (error.response && error.response.status === 111) {
//       // Navigate to the login page
//       return <Navigate to="/Login" />;
//     }
//     return Promise.reject(error);
//   }
// );
// console.log(token, "in instance page");

// const TokenGetter = async () => {
//   console.log("before");
//   const xyz = await store.getState().auth.token;
//   console.log("after");
//   console.log(xyz);
//   return xyz;
// };

// const abc = TokenGetter();