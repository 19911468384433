import {
  add_assignment_error,
  add_assignment_loading,
  add_assignment_success,
  get_assignment_result_error,
  get_assignment_result_loading,
  get_assignment_result_success,
  get_studentprofile_error,
  get_studentprofile_loading,
  get_studentprofile_success,
  gethistoryassignment,
  gethistoryassignment_search,
  getscheduleassignment,
  getscheduleassignment_search,
  getscheduleassignmentbyid,
} from "./assignmenttypes";

const initState = {
  isLoading: false,
  isError: false,
  scheduleassignmetn: [],
  scheduleassignmetn_search: [],
  scheduleassignmetnbyid: {},
  historyassignment: [],
  historyassignment_search: [],
  getStudentresult:{},
  getStudentprofile:{},
};

export const assignmentReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case add_assignment_loading:{
return{
  ...state,
  isLoading:true,
}
    }
    case add_assignment_error:{
      return{
        ...state,
        isError:true,
      }
    }
    case add_assignment_success:{
      return{
        ...state,
        scheduleassignmetn:payload
      }
    }

    case getscheduleassignment: {
      return {
        ...state,
        scheduleassignmetn: payload,
        scheduleassignmetn_search: payload,
      };
    }
    case getscheduleassignment_search: {
      return {
        ...state,
        scheduleassignmetn_search: [
          ...state.scheduleassignmetn.filter((el) =>
            el.assignment_name.toLowerCase().includes(payload.toLowerCase())
          ),
        ],
      };
    }
    case getscheduleassignmentbyid: {
      return {
        ...state,
        scheduleassignmetnbyid: payload,
      };
    }
    case gethistoryassignment: {
      return {
        ...state,
        historyassignment: payload,
        historyassignment_search:payload,
      };
    }
    case gethistoryassignment_search: {
      return {
        ...state,
        historyassignment_search: [
          ...state.historyassignment.filter((el) =>
          el.assignment_name.toLowerCase().includes(payload.toLowerCase())
          ),
        ],
      };
    }
    case get_assignment_result_error: {
      return{
        ...state,
        isError:true,
        isLoading:false,
      }
    }
    case get_assignment_result_loading: {
      return{
        ...state,
        isLoading:true,
        isError:false,
      }
    }
      
    case get_assignment_result_success: {
      return {
        ...state,
        getStudentresult: payload,
        isLoading:false,
        };
    }

    case get_studentprofile_error: {
      return{
        ...state,
        isError:true,
        isLoading:false,
      }
    }
    case get_studentprofile_loading: {
      return{
        ...state,
        isLoading:true,
        isError:false,
      }
    }
    case get_studentprofile_success:{
      return{
        ...state,
        getStudentprofile: payload,
        isLoading:false,
      }
    }

    default: {
      return state;
    }
  }
};
