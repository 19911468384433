export const get_batch_loading = 'get/batch/loading';
export const get_batch_error = 'get/batch/error';
export const get_batch_success = 'get/batch/success';

export const get_assign_particular_loading = 'get/assign/particular/loading';
export const get_assign_particular_error = 'get/assign/particular/error';
export const get_assign_particular_success = 'get/assign/particular/success';

export const get_quiz_particular_loading = 'get/quiz/particular/loading';
export const get_quiz_particular_error = 'get/quiz/particular/error';
export const get_quiz_particular_success = 'get/quiz/particular/success';

export const get_exam_particular_loading = 'get/exam/particular/loading';
export const get_exam_particular_error = 'get/exam/particular/error';
export const get_exam_particular_success = 'get/exam/particular/success';

export const get_classes_particular_loading = 'get/classes/particular/loading';
export const get_classes_particular_error = 'get/classes/particular/error';
export const get_classes_particular_success = 'get/classes/particular/success';