import { toast } from "react-toastify";
import { instance } from "../../instance";
import {
  add_classes_error,
  add_classes_loading,
  add_classes_success,
  get_history_classes_error,
  get_history_classes_loading,
  get_history_classes_success,
  get_history_classes_success_search,
  get_upcoming_classes_error,
  get_upcoming_classes_loading,
  get_upcoming_classes_success,
  get_upcoming_classes_success_search,
} from "./liveClassestype";

export const createClassFun =
  (
    form,
    handleCloseRam,
    handleClickOpen,
    setTopic,
    setCourseId,
    setSubjectId,
    setSelectedBatch,
    setDate,
    setStart_time,
    setEnd_time
  ) =>
  async (dispatch) => {
    dispatch({ type: add_classes_loading });
    const formdata = new FormData();
    Object.entries(form).map(([key, value]) => formdata.append(key, value));
    try {
      let res = await instance
        .post(`/instructor/classes/create/class`, formdata)
        .then((res) => {
          if (res.data.status == 1) {
            console.log("response:", res);
            dispatch({ type: add_classes_success, payload: res.data.classe });
            handleCloseRam();
            handleClickOpen();
            setTopic("");
              setCourseId();
              setSubjectId();
              setSelectedBatch({ id: "", value: "" });
              setDate("");
              setStart_time("");
              setEnd_time("");
          } else {
            toast(res.data.Backend_Error);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      dispatch({ type: add_classes_error });
    }
  };

// get data upcomeingclass
export const getupcomeClassesFun = () => async (dispatch) => {
  dispatch({ type: get_upcoming_classes_loading });
  try {
    let res = instance
      .get(`/instructor/classes/read/class/upcom`)
      .then((res) => {
        console.log(res, "get upcomeing");
        dispatch({
          type: get_upcoming_classes_success,
          payload: res.data.classes,
        });
      })
      .catch((err) => console.log(err));
  } catch (err) {
    dispatch({ type: get_upcoming_classes_error });
  }
};

export const getupcomeClassesFun_search = (toSearch) => (dispatch) => {
  dispatch({ type: get_upcoming_classes_success_search, payload: toSearch });
};

// get data upcomeingclass
export const gethistoryClassesFun = () => async (dispatch) => {
  dispatch({ type: get_history_classes_loading });
  try {
    let res = instance
      .get(`/instructor/classes/read/class/comp`)
      .then((res) => {
        console.log(res, "get history class");
        dispatch({
          type: get_history_classes_success,
          payload: res.data.classes,
        });
      })
      .catch((err) => console.log(err));
  } catch (err) {
    dispatch({ type: get_history_classes_error });
  }
};

export const gethistoryClassesFun_search = (toSearch) => (dispatch) => {
  dispatch({ type: get_history_classes_success_search, payload: toSearch });
};
