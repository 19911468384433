import { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import RecordedCourses from "./RecordedCourses";
import Quizzbank from "./Quizzbank";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BsSearch } from "react-icons/bs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import { NavLink, useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { addCourse, getpreCoursefun_search, getpreCoursefun } from "../redux/preRecord/prerecordActions";
import { useDispatch, useSelector } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Quizzes({ ...props }) {
  const [formData, setFormData] = useState({
    coursename: "",
    duration: "",
    price: "",
    discount: "",
    final_price: "",
    durationtime:""
  });
  const [banerimage, setBanerimage] = useState(" ");

  console.log(
    "coursename",
    banerimage,
    formData.coursename,
    formData.duration,
    formData.durationtime,
    formData.price,
    formData.discount,
    formData.final_price
  );
  const dispatch = useDispatch();

 
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const finelprice = formData.price - formData.discount;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState(new Date());
  const [Times, setTimes] = useState("");
  const [data, setData] = useState();
  const [subject, setSubject] = useState();
  const [state, setState] = useState(0);
  const [TimePerQuestion, setTimePerQuestion] = useState("");
  const [type, setType] = useState(1);
  const [question, setQuestion] = useState();
  const [selecttag, setSelecttag] = useState();
  const [math, setMath] = useState();
  const [batch, setBatch] = useState();
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const [rules, setRules] = useState();
  const [affairs, setAffairs] = useState("");
  const [reasoning, setReasoning] = useState("");
  const [computer, setComputer] = useState("");
  const [science, setScience] = useState("");
  const [value, setValue] = useState(0);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCross = () => {
    setOpen(false);
  };

  // console.log(discount);

  const changeHandler = (event) => {
    setText(event.target.value);
  };

  useEffect(() => {
    // array of words
    const words = text.split(" ");

    // update word count
    let wordCount = 0;
    words.forEach((word) => {
      if (word.trim() !== "") {
        wordCount++;
      }
    });
    setWordCount(wordCount);

    // update char count (including whitespaces)
    setCharCount(text.length);
  }, [text]);

  const course = [
    {
      name: "B.Tech Specialization in Health Informatics-1",
    },
    {
      name: "B.Tech Specialization in Health Informatics-2",
    },
    {
      name: "B.Tech Specialization in Health Informatics-3",
    },
    {
      name: "B.Tech Specialization in Health Informatics-4",
    },
    {
      name: "B.Tech Specialization in Health Informatics-5",
    },
    {
      name: "B.Tech Specialization in Health Informatics-6",
    },
  ];
  const selbatch = [
    {
      name: "BY-300",
    },
    {
      name: "BY-300",
    },
    {
      name: "BY-300",
    },
    {
      name: "BY-300",
    },
    {
      name: "BY-300",
    },
    {
      name: "BY-300",
    },
    {
      name: "BY-300",
    },
    {
      name: "BY-300",
    },
    {
      name: "BY-300",
    },
  ];
  const selsub = [
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
  ];
  const subjectdata = [
    {
      name: "B.Tech-1",
    },
    {
      name: "B.Tech-2",
    },
    {
      name: "B.Tech Specialization in Health Informatics-3",
    },
    {
      name: "B.Tech Specialization in Health Informatics-4",
    },
    {
      name: "B.Tech Specialization in Health Informatics-5",
    },
    {
      name: "B.Tech Specialization in Health Informatics-6",
    },
  ];
  const selectexam = [
    {
      name: "B.Tech Specialization in Health Informatics-3",
    },
    {
      name: "B.Tech Specialization in Health Informatics-4",
    },
    {
      name: "B.Tech Specialization in Health Informatics-5",
    },
    {
      name: "B.Tech Specialization in Health Informatics-6",
    },
  ];
  const quedata = [
    {
      que: "Which of the following is not a networking protocol ?",
      op1: "ICP",
      op2: "DCP",
      op3: "BCP",
      op4: "CCP",
      ans: 0,
    },
    {
      que: "Which of the following is not a networking protocol ?",
      op1: "ICP",
      op2: "DCP",
      op3: "BCP",
      op4: "CCP",
      ans: 0,
    },
  ];
  const rulesdata = [
    {
      name: "Semester 1",
      key: [
        "Fundamentals of Programming",
        "Networking",
        "Python Language",
        "C++ Langauge",
        "OOPS using C#",
        "Introduction of Bootstarp",
        "Graphics Designing",
      ],
    },
    {
      name: "Semester 2",
      key: [
        "Fundamentals of Programming",
        "Networking",
        "Python Language",
        "C++ Langauge",
        "OOPS using C#",
        "Introduction of Bootstarp",
        "Graphics Designing",
      ],
    },
    {
      name: "Semester 3",
      key: [
        "Fundamentals of Programming",
        "Networking",
        "Python Language",
        "C++ Langauge",
        "OOPS using C#",
        "Introduction of Bootstarp",
        "Graphics Designing",
      ],
    },
    {
      name: "Semester 4",
      key: [
        "Fundamentals of Programming",
        "Networking",
        "Python Language",
        "C++ Langauge",
        "OOPS using C#",
        "Introduction of Bootstarp",
        "Graphics Designing",
      ],
    },
  ];

  useEffect(() => {
    dispatch(getpreCoursefun())
    
    setData(course);
    setSubject(subjectdata);
    setQuestion(quedata);
    setSelecttag(selectexam);
    setMath(selsub);
    setRules(rulesdata);
    setBatch(selbatch);
  }, []);

  // const HandleFun= () =>{
  //     setType(1);setTabdata(0)
  // }

  return (
    <>
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="row g-0">
          <div className="col-lg-2 g-0" style={{}}>
            <Sidebar />
          </div>
          <div className="col-lg-10 g-0 p-0">
            <Header />
            <div style={{ display: type === 1 ? "block" : "none" }}>
              <div
                className="px-3"
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className="pt-2"
                  style={{ fontFamily: "inter", fontSize: 24, fontWeight: 600 }}
                >
                  Pre - recorded
                </p>
                <button
                  type="button"
                  onClick={handleShow}
                  className="btn"
                  style={{
                    height: 30,
                    backgroundColor: "#3D70F5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                    color: "#fff",
                    fontSize: 14,
                    fontFamily: "inter",
                    fontWeight: 400,
                    border: "none",
                  }}
                >
                  + Add Course
                </button>
              </div>
            </div>
            <div style={{ display: type === 2 ? "block" : "none" }}>
              <div
                className="px-3"
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className="pt-2"
                  style={{ fontFamily: "inter", fontSize: 24, fontWeight: 800 }}
                >
                  Pre - recorded
                </p>
                {/* <button type="button" onClick={handleShow} className="btn" style={{ height:30, backgroundColor:"#3D70F5", display:"flex", justifyContent:"center", alignItems:'center', borderRadius:5, color:'#fff', fontSize:14, fontFamily:'inter', fontWeight:400, border:"none" }}>+ Add Tag</button> */}
              </div>
            </div>
            <div style={{ display: type === 3 ? "block" : "none" }}>
              <div
                className="px-3"
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className="pt-2"
                  style={{ fontFamily: "inter", fontSize: 24, fontWeight: 800 }}
                >
                  Pre - recorded
                </p>
                {/* <button type="button" className="btn" style={{ height:30, backgroundColor:"#3D70F5", display:"flex", justifyContent:"center", alignItems:'center', borderRadius:5, color:'#fff', fontSize:14, fontFamily:'inter', fontWeight:400, border:"none" }}>+ Add Quiz--</button> */}
              </div>
            </div>
            <div className="row g-0 d-flex justify-content-between m-auto">
              <div className="col-12">
                <div
                  className="row g-0"
                  style={{ borderBottom: "1px solid #eee" }}
                >
                  <div className="col-8">
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          onClick={() => setType(1)}
                          label="Pre - recorded courses"
                          {...a11yProps(0)}
                        />
                        <Tab
                          onClick={() => setType(2)}
                          label="Question Bank"
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Box>
                  </div>
                  <div className="col-4">
                    <div className="px-2">
                      <div className="input-group flex-nowrap">
                        <span
                          className="input-group-text"
                          id="addon-wrapping"
                          style={{
                            borderRadius: 0,
                            border: "none",
                            backgroundColor: "#F1F1F1",
                          }}
                        >
                          <BsSearch style={{ color: "#6A6A6A" }} />
                        </span>
                        <input
                          type="text"
                          onChange={(e)=>dispatch(getpreCoursefun_search(e.target.value))}
                          className="form-control"
                          placeholder="search Course"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          style={{
                            borderRadius: 0,
                            border: "none",
                            backgroundColor: "#F1F1F1",
                            boxShadow: "none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <TabPanel value={value} index={0}>
                  <RecordedCourses />
                  <Offcanvas
                    show={show}
                    onHide={handleClose}
                    {...props}
                    placement={"end"}
                    style={{ width: "50%" }}
                  >
                    <Offcanvas.Header
                      closeButton
                      onClick={() => {
                        setState(0);
                        handleClose();

                        setFormData({
                          coursename: "",
                          duration: "",
                          price: "",
                          discount: "",
                          final_price: "",
                        });
                        setBanerimage("");
                      }}
                    ></Offcanvas.Header>
                    <Offcanvas.Body>
                      <div>
                        <p
                          className=""
                          style={{
                            fontFamily: "inter",
                            fontSize: 26,
                            fontWeight: 600,
                            borderBottom: "2px solid #A5A5A5",
                          }}
                        >
                          Add New Course
                        </p>
                        <div className="row">
                          <div className="col-5">
                            <div
                              style={{
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 400,
                              }}
                            >
                              Add Banner Image
                            </div>

                            <div className="mt-3 ms-3">
                              <label
                                className="row p-5 text-center"
                                style={{
                                  backgroundColor: "#F0F0F0",
                                  width: "100%",
                                  height: 35,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px solid #878787",
                                  borderRadius: 5,
                                  cursor: "pointer",
                                }}
                                htmlFor="image-id"
                              >
                                <span
                                  className="px-2 "
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "inter",
                                    color: "#878787",
                                  }}
                                >
                                  Choose Image
                                </span>

                                <div
                                  className="text-center"
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: "#878787",
                                  }}
                                >
                                  340*190
                                </div>
                              </label>
                              <input
                                type="file"
                                // accept=".pdf"
                                multiple
                                onChange={(e) => {
                                  setBanerimage(e.target.files[0]);

                                  // e.target.files[0].type.includes("application/pdf")
                                  // ? setAssign_file(e.target.files[0])
                                  // : toast("Please Select pdf file only");
                                }}
                                id="image-id"
                                hidden
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          Enter Course name
                        </div>
                        <div className="row mt-2">
                          <div className="col-8">
                            <div
                              style={{
                                width: "100%",
                                border: "1px solid #BFBFBF",
                                borderRadius: 5,
                              }}
                            >
                              <div className="input-group">
                                <input
                                  name="coursename"
                                  value={formData.coursename}
                                  onChange={handleInput}
                                  type="text"
                                  className="form-control border-0 rounded-1 shadow-none"
                                  placeholder="Start Typing Course Name..."
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">
                            <div
                              style={{
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 400,
                              }}
                            >
                              Enter Course Duration
                            </div>
                            <div className="row mt-2">
                              <div className="col-4">
                                <div
                                  style={{
                                    width: "100%",
                                    border: "1px solid #BFBFBF",
                                    borderRadius: 5,
                                  }}
                                >
                                  <div className="input-group">
                                    <input
                                      name="duration"
                                      value={formData.duration}
                                      onChange={handleInput}
                                      type="text"
                                      className="form-control border-0 rounded-1 shadow-none"
                                      placeholder=""
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-5">
                                <select
                                name="durationtime"
                                value={formData.durationtime}
                                onChange={handleInput}
                                  className="form-select border-0"
                                  aria-label="Default select example"
                                  style={{
                                    height: 35,
                                    backgroundColor: "#EFEFEF",
                                    borderRadius: 5,
                                    fontSize: 12,
                                    fontFamily: "inter",
                                    fontWeight: 400,
                                    color: "#707070",
                                  }}
                                >
                                  <option
                                    value="hours"
                                    style={{
                                      fontFamily: "inter",
                                      fontWeight: 400,
                                      fontSize: 12,
                                      color: "#707070",
                                    }}
                                  >
                                    Hours
                                  </option>
                                  <option value="minutes">Minutes</option>
                                  <option value="days">Days</option>
                                  <option value="weeks">Weeks</option>
                                  <option value="months">Months</option>
                                  <option value="years">Years</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {/*<div className="col-7">
                            <div
                              style={{
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 400,
                              }}
                            >
                              Enter Course Live Date
                            </div>
                            <div className="row mt-2">
                              <div className="col-5">
                                <div
                                  style={{
                                    width: "100%",
                                    height: 35,
                                    border: "1px solid #BFBFBF",
                                    borderRadius: 5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <input
                                    type="date"
                                    className="border-0 bg-transparent"
                                    style={{ color: "#8A8A8A" }}
                                    value={date}
                                    onChange={(e) => {
                                      setDate(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-5">
                                <div
                                  style={{
                                    width: "100%",
                                    height: 35,
                                    border: "1px solid #BFBFBF",
                                    borderRadius: 5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <input
                                    type="time"
                                    className="border-0 bg-transparent"
                                    style={{ color: "#8A8A8A" }}
                                    value={time}
                                    onChange={(e) => {
                                      setTime(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                                  </div>*/}
                        </div>
                        <div className="row mt-2">
                          <div
                            style={{
                              fontFamily: "inter",
                              fontSize: 16,
                              fontWeight: 400,
                            }}
                          >
                            Enter Price
                          </div>
                          <div className="col-4 mt-2">
                            <div
                              style={{
                                width: "100%",
                                border: "1px solid #BFBFBF",
                                borderRadius: 5,
                              }}
                            >
                              <div className="input-group">
                                <span
                                  className="input-group-text bg-transparent border-0 rounded-0"
                                  id="basic-addon1"
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 18,
                                    fontWeight: 600,
                                  }}
                                >
                                  ₹
                                </span>
                                <input
                                  name="price"
                                  value={formData.price}
                                  onChange={handleInput}
                                  type="number"
                                  className="form-control border-0 rounded-1 shadow-none"
                                  placeholder=""
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div
                            style={{
                              fontFamily: "inter",
                              fontSize: 16,
                              fontWeight: 400,
                            }}
                          >
                            Enter Discount percentage
                          </div>
                          <div className="col-2 mt-2">
                            <div
                              style={{
                                width: "100%",
                                border: "1px solid #BFBFBF",
                                borderRadius: 5,
                              }}
                            >
                              <div className="input-group">
                                <input
                                  name="discount"
                                  value={formData.discount}
                                  onChange={handleInput}
                                  type="number"
                                  className="form-control border-0 rounded-1 shadow-none"
                                  placeholder=""
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 22,
                            fontWeight: 500,
                          }}
                        >
                          Final amount :{" "}
                          <span
                            style={{
                              fontFamily: "inter",
                              fontSize: 24,
                              fontWeight: 600,
                            }}
                          >
                            ₹ {finelprice}
                          </span>
                        </div>
                        <div className="row">
                          <div className="col-4 m-auto mt-3">
                            <div
                              onClick={() => {
                                dispatch(
                                  addCourse(
                                    {
                                      course_name: formData.coursename,
                                      duration: formData.duration + formData.durationtime,
                                      discount: formData.discount,
                                      raw_price: formData.price,
                                      final_price: finelprice,
                                      banner: banerimage,
                                    },
                                    handleClickOpen
                                  )
                                );
                              }}
                              style={{
                                width: "100%",
                                height: 35,
                                backgroundColor: "#2C62EE",
                                borderRadius: 5,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 500,
                                  color: "#fff",
                                }}
                              >
                                Add Course
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Dialog
                        open={open}
                        //  TransitionComponent={Transition}
                        keepMounted
                        onClose={() => {
                          handleCross();
                          setFormData({
                            coursename: "",
                            duration: "",
                            price: "",
                            discount: "",
                            final_price: "",
                          });
                          setBanerimage("");
                        }}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogActions>
                          <Button
                            onClick={() => {
                              handleCross();
                              setFormData({
                                coursename: "",
                                duration: "",
                                price: "",
                                discount: "",
                                final_price: "",
                              });
                              setBanerimage("");
                            }}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            <MdOutlineClose style={{ fontSize: 20 }} />
                          </Button>
                        </DialogActions>
                        <DialogTitle
                          className=""
                          style={{
                            fontFamily: "inter",
                            fontSize: 25,
                            fontWeight: 600,
                          }}
                        ></DialogTitle>
                        <DialogContent>
                          <div className="row g-0">
                            <p
                              className="col-11 text-center m-auto"
                              style={{
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              New course has been successfully added !
                            </p>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </Offcanvas.Body>
                  </Offcanvas>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Quizzbank />
                  <Offcanvas
                    show={show}
                    onHide={handleClose}
                    {...props}
                    placement={"end"}
                    style={{ width: "50%" }}
                  >
                    <Offcanvas.Header
                      closeButton
                      onClick={() => {
                        setState(0);
                      }}
                    ></Offcanvas.Header>
                    <Offcanvas.Body>
                      <div style={{ display: state === 0 ? "block" : "none" }}>
                        <p
                          style={{
                            fontFamily: "inter",
                            fontSize: 26,
                            fontWeight: 600,
                          }}
                        >
                          Create New Tag
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#787878",
                          }}
                        >
                          <span style={{ color: "#3D70F5" }}>1</span>/4 Steps
                        </p>
                        <div
                          className="progress"
                          style={{ height: "8px", borderRadius: 0 }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: "25%",
                              background: "#3D70F5",
                              borderRadius: 0,
                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-5 mt-5 m-auto">
                            <Card className="p-2 border-0">
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 600,
                                }}
                              >
                                Name
                              </span>
                              <div className="input-group flex-nowrap mt-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter the name of Tag"
                                  aria-label="Username"
                                  aria-describedby="addon-wrapping"
                                  style={{
                                    borderRadius: 5,
                                    border: "none",
                                    backgroundColor: "#F4F4F4",
                                    boxShadow: "none",
                                  }}
                                />
                              </div>
                              <div
                                className="mt-3"
                                onClick={() => {
                                  setState(state + 1);
                                }}
                                style={{
                                  width: "100%",
                                  height: 35,
                                  backgroundColor: "#3D70F5",
                                  borderRadius: 5,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#fff",
                                  }}
                                >
                                  Proceed
                                </span>
                              </div>
                            </Card>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: state === 1 ? "block" : "none" }}>
                        <p
                          style={{
                            fontFamily: "inter",
                            fontSize: 26,
                            fontWeight: 600,
                          }}
                        >
                          Create New Tag
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#787878",
                          }}
                        >
                          <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                        </p>
                        <div
                          className="progress"
                          style={{ height: "8px", borderRadius: 0 }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: "50%",
                              background: "#3D70F5",
                              borderRadius: 0,
                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-8 m-auto">
                            <Card className="">
                              <p
                                className="px-3 pt-2 mb-2"
                                id="demo-radio-buttons-group-label"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 20,
                                  fontWeight: 600,
                                  color: "#000",
                                }}
                              >
                                Select Course
                              </p>
                              <div className="px-2">
                                <div className="input-group flex-nowrap">
                                  <span
                                    className="input-group-text"
                                    id="addon-wrapping"
                                    style={{
                                      borderRadius: 0,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                    }}
                                  >
                                    <BsSearch style={{ color: "#6A6A6A" }} />
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Select Course"
                                    aria-label="Username"
                                    aria-describedby="addon-wrapping"
                                    style={{
                                      borderRadius: 0,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                      boxShadow: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="barsubject">
                                {data &&
                                  data.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="form-check d-flex align-items-center mx-3 my-2 m-1"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          style={{ boxShadow: "none" }}
                                          onClick={() => {
                                            setState(state + 1);
                                          }}
                                        />
                                        <label
                                          className="form-check-label p-0"
                                          for="flexRadioDefault1"
                                        >
                                          <div
                                            style={{
                                              fontFamily: "inter",
                                              fontSize: 14,
                                              fontWeight: 400,
                                              color: "#6A6A6A",
                                              paddingLeft: 10,
                                            }}
                                          >
                                            {item.name}
                                          </div>
                                        </label>
                                      </div>
                                    );
                                  })}
                              </div>
                            </Card>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: state === 2 ? "block" : "none" }}>
                        <p
                          style={{
                            fontFamily: "inter",
                            fontSize: 26,
                            fontWeight: 600,
                          }}
                        >
                          Create New Tag
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#787878",
                          }}
                        >
                          <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
                        </p>
                        <div
                          className="progress"
                          style={{ height: "8px", borderRadius: 0 }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: "100%",
                              background: "#3D70F5",
                              borderRadius: 0,
                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-8 m-auto">
                            <Card className="">
                              <p
                                className="px-3 pt-2 mb-2"
                                id="demo-radio-buttons-group-label"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 20,
                                  fontWeight: 600,
                                  color: "#000",
                                }}
                              >
                                Select Subjects
                              </p>
                              <div className="px-2">
                                <div className="input-group flex-nowrap">
                                  <span
                                    className="input-group-text"
                                    id="addon-wrapping"
                                    style={{
                                      borderRadius: 0,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                    }}
                                  >
                                    <BsSearch style={{ color: "#6A6A6A" }} />
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Select Subjects"
                                    aria-label="Username"
                                    aria-describedby="addon-wrapping"
                                    style={{
                                      borderRadius: 0,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                      boxShadow: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="barsubject">
                                {math &&
                                  math.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="form-check d-flex align-items-center mx-3 my-2 m-1"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          style={{ boxShadow: "none" }}
                                        />
                                        <label
                                          className="form-check-label p-0"
                                          for="flexRadioDefault1"
                                        >
                                          <div
                                            style={{
                                              fontFamily: "inter",
                                              fontSize: 14,
                                              fontWeight: 400,
                                              color: "#6A6A6A",
                                              paddingLeft: 10,
                                            }}
                                          >
                                            {item.name}
                                          </div>
                                        </label>
                                      </div>
                                    );
                                  })}
                              </div>
                            </Card>
                            <div
                              className="mt-2"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className="mt-2"
                                onClick={() => {
                                  setState(state - 1);
                                }}
                                style={{
                                  width: 50,
                                  height: 20,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6B6B6B",
                                  }}
                                >
                                  Back
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  setState(state + 1);
                                }}
                                style={{
                                  width: "25%",
                                  height: 35,
                                  backgroundColor: "#3D70F5",
                                  borderRadius: 5,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#fff",
                                  }}
                                >
                                  Proceed
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: state === 3 ? "block" : "none" }}>
                        <Card className="p-2 border-0">
                          <div className="row mt-5">
                            <div className="col-8 m-auto">
                              <p
                                className=" mt-5"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 34,
                                  fontWeight: 600,
                                  textAlign: "center",
                                }}
                              >
                                New Tag “ Mathematics for Batch 1”
                              </p>
                              <p
                                className=" mt-4"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 14,
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                Will be created within B.Tech spcl. in Health
                                Informatics with subjects Network Engineering,
                                Mathematics, English etc.{" "}
                              </p>
                              <div
                                className="mt-2"
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    setState(state + 1);
                                  }}
                                  style={{
                                    width: "25%",
                                    height: 35,
                                    borderRadius: 5,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "1px solid #FF0B0B",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#FF0B0B",
                                    }}
                                  >
                                    Cancel
                                  </span>
                                </div>
                                <div
                                  onClick={() => {
                                    setState(state + 1);
                                  }}
                                  style={{
                                    width: "25%",
                                    height: 35,
                                    backgroundColor: "#3D70F5",
                                    borderRadius: 5,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#fff",
                                    }}
                                  >
                                    Proceed
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                      <div style={{ display: state === 4 ? "block" : "none" }}>
                        <div className="row mt-5">
                          <p
                            className="col-8 m-auto mt-5"
                            style={{
                              fontFamily: "inter",
                              fontSize: 30,
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            New Tag “ Mathematics for Batch 1” Created
                            Succesfully
                          </p>
                        </div>
                        <div className="row mt-4">
                          <div className="col-4 m-auto">
                            <div
                              style={{
                                width: "100%",
                                height: 35,
                                backgroundColor: "#3D70F5",
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <NavLink
                                to="/Quizzes"
                                className="text-decoration-none"
                              >
                                <span
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#fff",
                                  }}
                                >
                                  Back to Dashboard
                                </span>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
