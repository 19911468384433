import { useState, useEffect, Suspense } from "react";
import Details from "./Details";
import Result from "./Result";
import Details2 from "./Details2";
import Result2 from "./Result2";
import { FiClock } from "react-icons/fi";
import { FaCalendar } from "react-icons/fa";
import { RiCheckDoubleLine } from "react-icons/ri";
import Card from "react-bootstrap/Card";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AiOutlineEye } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import myimg from "../images/myimage.jpg";
import { FaRegCalendarAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { API_URL } from "../env";
import { Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import { instance } from "./../instance";
import { HiDotsVertical } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  getExamViewdetailfun,
  gethistoryexamfun,
} from "../redux/exam/examActions";

export default function ExamHistory() {
  const { historyexam_search, exam_viewdetail } = useSelector(
    (store) => store.exams
  );

  const dispatch = useDispatch();
  const [State, setState] = useState();
  const [State1, setState1] = useState();
  const [tbldata, setTbldata] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [response, setResponse] = useState([]);
  const [list, setList] = useState([]);
  const [batch, setBatch] = useState([]);
  const [data, setData] = useState([]);
  const [resData, setresData] = useState([]);
  const [tot_marks, settot_marks] = useState();
  const [resultdecleredDate, setresultdecleredDate] = useState();
  const [addnote, setaddnote] = useState();
  const [mark, setmark] = useState();
  const [student_in_exam, setStudentsInExam] = useState([]);

  const [showDec, setShowDec] = useState(false);
  const handleShowDec = () => setShowDec(true);
  const handleCloseDec = () => setShowDec(false);

  const [showDecd, setShowDecd] = useState(false);
  const handleShowDecd = () => setShowDecd(true);
  const handleCloseDecd = () => setShowDecd(false);

  const [exam_id, setExamId] = useState("");
  const [totalMarks, setTotalmarks] = useState("");

  const getStudentsDetails = async (id) => {

    try {
      let res = await instance(`/instructor/exams/read/result/ofexam/${id}`);
   
      if (res.data.status == 1) {
        setStudentsInExam(res?.data?.data);
        settot_marks(res?.data?.tot_marks);
        setresultdecleredDate(res?.data?.res_dec_date);
      } else {
        toast("Somethinf went erong!");
      }
    } catch (err) {
      toast("Internal Server Error");
    }
  };



  //function for get exam results
  const getExamResult = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var raw = JSON.stringify({
      exam_id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/read/exams/result`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
    
        if (result.status == "1") {
       
          setresData(result.data);
          settot_marks(result.tot_marks);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for adding marks to particular std
  const AddMarkToStd = async (marks, item) => {
    const { stu_id, exam_id } = item;
    try {
      let res = await instance.post("/instructor/exams/giveamarks/tostu/inexam", {
        stu_id,
        exam_id,
        marks: marks,
        note:addnote,
      });
      if (res.data.status == 1) {
   
        toast(res.data.msg);
      } else {
        toast(res.data.Backend_Error);
       
      }
    } catch (err) {
      console.log(err);
    }
  };

  //function for declare result
  const declareResult = async (id) => {
    try {
      let res = await instance.post("/instructor/exams/declare/examresult", {
        exam_id: id,
      });
   
      if (res.data.status == 1) {
        toast(res.data.message);

        handleClose();
        handleCloseDec();
      } else {
        toast(res.data.Backend_Error);
      }
    } catch (err) {
      toast("Internal Server Error!");
    }
  };

  //function for getdeclaredExamResult
  const getDeclaredresultByID = async (id) => {
    try {
      let res = await instance(`${API_URL}/read/exams/result/${id}`);
  
      if (res.data.status == 1) {
        setTbldata(res.data.data);
        setTotalmarks(res.data.tot_marks);
      } else {
        toast("something wnt wrong");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  useEffect(() => {
    setState1("Details");
    setState("Details2");
  }, []);

  //handling for modal
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
   
      <div className="bar3">
        <div className="row g-0">
          {historyexam_search?.length > 0 ? (
            historyexam_search?.map((item, index) => {
              return (
               
  
                <LiveClass
                getStudentsDetails={getStudentsDetails}
                handleShowDec={handleShowDec}
                item={item}
                key={index}
                handle2={getExamResult}
                setExamId={setExamId}
                setState={setState}
                getDeclaredresultByID={getDeclaredresultByID}
                />
             
                );
            })
          ) : (
            <>
              {" "}
              <div className="mt-5">
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    fontFamily: "inter",
                    color: "red",
                  }}
                >
                  No data available
                </h3>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Declare Result Offcanvas Start  */}
      <Offcanvas show={showDec} placement="end" style={{ width: "60%" }}>
        {/* <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "60%" }}
      > */}
        <div className="offcanvas-header">
          <button
            onClick={() => {
              handleCloseDec();
              dispatch(gethistoryexamfun());
            }}
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="d-flex justify-content-between">
            <p style={{ fontFamily: "inter", fontSize: 28, fontWeight: 600 }}>
              Declare Result
            </p>
            <button
              onClick={()=>{handleClickOpen()
                dispatch(gethistoryexamfun())}}
              data-bs-toggle="modal"
              data-bs-target="#saveModal"
              type="button"
              className="btn px-4"
              style={{
                height: 35,
                backgroundColor: "#3D70F5",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontFamily: "inter",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              Publish Result
            </button>
          </div>
          <div className="row my-2">
            <div className="col-5">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 35,
                  backgroundColor: "#EEEFF9",
                  borderRadius: 5,
                  display: "flex",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    setState("Details");
                  }}
                  className=""
                  style={{
                    width: "100%",
                    height: 35,
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor: State == "Details" ? "#3D70F5" : "#EEEFF9",
                    color: State == "Details" ? "#fff" : "#000",
                    borderRadius: 5,
                  }}
                >
                  Details
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState("Result");
                  }}
                  className=""
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor: State == "Result" ? "#3D70F5" : "#EEEFF9",
                    color: State == "Result" ? "#fff" : "#000",
                    width: "100%",
                    height: 35,
                    borderRadius: 5,
                  }}
                >
                  Result
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            {State === "Details" ? (
              <>
                <div>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {exam_viewdetail?.exam_name}
                  </p>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Course : <span>{exam_viewdetail?.cou_name}</span>
                  </p>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Subject : <span>{exam_viewdetail?.sub_name}</span>
                  </p>
                  <div
                    className="row mt-4"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontSize: 18,
                        color: "#434343",
                        fontWeight: 800,
                      }}
                    >
                      Total Marks : {exam_viewdetail?.tot_marks}
                    </span>
                    <div
                      className="mt-4"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "inter",
                          fontWeight: 400,
                          color: "#434343",
                          fontSize: 14,
                        }}
                      >
                        Passing Percentage{" "}
                      </div>
                      <div
                        style={{
                          fontFamily: "inter",
                          fontWeight: 600,
                          color: "#3D70F5",
                          fontSize: 14,
                          paddingLeft: 40,
                        }}
                      >
                        &nbsp;&nbsp;{exam_viewdetail?.pass_pers} %
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#434343",
                      }}
                    >
                      Question Paper
                    </div>
                    <div className="text-start mt-2">
                      <span
                        className="text-start p-2"
                        style={{
                          fontFamily: "inter",
                          fontSize: 12,
                          fontWeight: 400,
                          border: "1px solid #D7D7D7",
                          borderRadius: 5,
                        }}
                      >
                        {exam_viewdetail?.ques_pap?.split("/")[4]}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-3 mb-4">
                    <span
                      className="px-3 text-start"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        color: "#434343",
                        fontSize: 14,
                      }}
                    >
                      Exam Schedule
                    </span>
                    <div className="col-3">
                      <div
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDF5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <FaCalendar />
                        <span
                          style={{
                            color: "#3C3C3C",
                            fontWeight: 400,
                            fontSize: 14,
                            fontFamily: "inter",
                            paddingLeft: 10,
                          }}
                        >
                          {exam_viewdetail?.start_time?.split("T")[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDF5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <FiClock />
                        <span
                          style={{
                            color: "#3C3C3C",
                            fontWeight: 400,
                            fontSize: 14,
                            fontFamily: "inter",
                            paddingLeft: 10,
                          }}
                        >
                          {exam_viewdetail?.start_time?.split("", 10)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      Batches
                    </p>
                    <ul className="list-grou border-0 text-start">
                      {exam_viewdetail?.batchnames?.length > 0 ? (
                        exam_viewdetail?.batchnames?.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="list-group-item"
                              style={{
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 400,
                              }}
                            >
                              {item}
                            </li>
                          );
                        })
                      ) : (
                        <>
                          {" "}
                          <Card
                            style={{
                              backgroundColor: "#fff",
                              border: "1px solid #9E9E9E",
                              marginBottom: 20,
                            }}
                          >
                            <Card.Body className="">
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: 18,
                                  fontFamily: "inter",
                                  color: "red",
                                }}
                              >
                                No data available
                              </span>
                            </Card.Body>
                          </Card>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {State === "Result" ? (
              <>
                <div className="table-scroll">
                  <div className="row mt-3">
                    <div className="col-12" style={{ width: "100%" }}>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Student ID
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Name
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Attachements
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Marks
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* come back */}
                          {student_in_exam?.length > 0 ? (
                            student_in_exam?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item.stu_id}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item.stu_name}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item.stu_res == ""
                                      ? "no data"
                                      : item.stu_res?.split("/")[4]}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                    <input
                                    
                                      type="number"
                                      onChange={(e) => {
                                        setmark(e.target.value);
                                      }}
                                      style={{ width: 50 }}
                                    />{" "}
                                    /{item?.tot_marks}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#474747",
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                      }}
                                    >
                                      <button
                                        className="px-2"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        href="#collapseExample"
                                        // role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseExample"
                                        style={{
                                          backgroundColor: "#F2F2F2",
                                          color: "#2C62EE",
                                          fontFamily: "inter",
                                          fontSize: 12,
                                          fontWeight: 600,
                                          border: "none",
                                          borderRadius: 5,
                                        }}
                                      >
                                        + Add Note
                                      </button>
                                      <button
                                        onClick={() => AddMarkToStd(mark, item)}
                                        className="px-2"
                                        type="button"
                                        style={{
                                          backgroundColor: "#ECF1FF",
                                          color: "#2C62EE",
                                          fontFamily: "inter",
                                          fontSize: 12,
                                          fontWeight: 600,
                                          border: "none",
                                          borderRadius: 5,
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <div className="mt-5">
                                <h3
                                  className="text-center"
                                  style={{
                                    fontWeight: 600,
                                    fontSize: 18,
                                    fontFamily: "inter",
                                    color: "red",
                                  }}
                                >
                                  No data available
                                </h3>
                              </div>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="collapse mt-2" id="collapseExample">
                  <div
                    className="card card-body"
                    style={{ backgroundColor: "red", width: "50%" }}
                  >
                    <input
                      type="text"
                      value={addnote}
                      onChange={(e) => {
                        setaddnote(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* </div> */}
      </Offcanvas>
      {/* Declare Result Offcanvas End  */}

      {/* Declared Offcanvas Start  */}
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="declareResult"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "60%" }}
      >
        <div className="offcanvas-header">
          <button
          onClick={()=>dispatch(gethistoryexamfun())}
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="row d-flex align-items-center justify-content-between">
            <div
              className="col-6 text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
            >
              {" "}
              Exam Report
            </div>
            <div
              className="col-6"
              style={{
                height: 25,
                backgroundColor: "#ECF2FF",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "inter",
                fontSize: 13,
                fontWeight: 400,
                color: "#3D70F5",
              }}
            >
              Result Declared on {resultdecleredDate}&nbsp;
              <RiCheckDoubleLine style={{ fontSize: 18 }} />
            </div>
          </div>
          {/* <p className='text-start my-2' style={{ fontFamily:"inter", fontSize:18, fontWeight:400 }} >BHI Health Informatics mid semester Exam.</p> */}
          <div className="row my-2">
            <div className="col-5">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 35,
                  backgroundColor: "#EEEFF9",
                  borderRadius: 5,
                  display: "flex",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    setState("Details2");
                  }}
                  className=""
                  style={{
                    width: "100%",
                    height: 35,
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor:
                      State == "Details2" ? "#3D70F5" : "#EEEFF9",
                    color: State == "Details2" ? "#fff" : "#000",
                    borderRadius: 5,
                  }}
                >
                  Details
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState("Result2");
                  }}
                  className=""
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor: State == "Result2" ? "#3D70F5" : "#EEEFF9",
                    color: State == "Result2" ? "#fff" : "#000",
                    width: "100%",
                    height: 35,
                    borderRadius: 5,
                  }}
                >
                  Result
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            {State === "Details2" ? (
              <>
                <div>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {exam_viewdetail?.exam_name}
                  </p>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Course : <span>{exam_viewdetail?.cou_name}</span>
                  </p>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Subject : <span>{exam_viewdetail?.sub_name}</span>
                  </p>
                  <div
                    className="row mt-4"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontSize: 18,
                        color: "#434343",
                        fontWeight: 800,
                      }}
                    >
                      Total Marks : {exam_viewdetail?.tot_marks}
                    </span>
                    <div
                      className="mt-4"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "inter",
                          fontWeight: 400,
                          color: "#434343",
                          fontSize: 14,
                        }}
                      >
                        Passing Percentage{" "}
                      </div>
                      <div
                        style={{
                          fontFamily: "inter",
                          fontWeight: 600,
                          color: "#3D70F5",
                          fontSize: 14,
                          paddingLeft: 40,
                        }}
                      >
                        &nbsp;&nbsp;{exam_viewdetail?.pass_pers} %
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#434343",
                      }}
                    >
                      Question Paper
                    </div>
                    <div className="text-start mt-2">
                      <span
                        className="text-start p-2"
                        style={{
                          fontFamily: "inter",
                          fontSize: 12,
                          fontWeight: 400,
                          border: "1px solid #D7D7D7",
                          borderRadius: 5,
                        }}
                      >
                        {exam_viewdetail?.ques_pap?.split("/")[4]}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-3 mb-4">
                    <span
                      className="px-3 text-start"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        color: "#434343",
                        fontSize: 14,
                      }}
                    >
                      Exam Schedule
                    </span>
                    <div className="col-3">
                      <div
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDF5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <FaCalendar />
                        <span
                          style={{
                            color: "#3C3C3C",
                            fontWeight: 400,
                            fontSize: 14,
                            fontFamily: "inter",
                            paddingLeft: 10,
                          }}
                        >
                          {exam_viewdetail?.start_time?.split("T")[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDF5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <FiClock />
                        <span
                          style={{
                            color: "#3C3C3C",
                            fontWeight: 400,
                            fontSize: 14,
                            fontFamily: "inter",
                            paddingLeft: 10,
                          }}
                        >
                          {exam_viewdetail?.start_time?.split("", 10)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      Batches
                    </p>
                    <ul className="list-grou border-0 text-start">
                      {exam_viewdetail?.batchnames?.length > 0 ? (
                        exam_viewdetail?.batchnames?.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="list-group-item"
                              style={{
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 400,
                              }}
                            >
                              {item}
                            </li>
                          );
                        })
                      ) : (
                        <>
                          {" "}
                          <Card
                            style={{
                              backgroundColor: "#fff",
                              border: "1px solid #9E9E9E",
                              marginBottom: 20,
                            }}
                          >
                            <Card.Body className="">
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: 18,
                                  fontFamily: "inter",
                                  color: "red",
                                }}
                              >
                                No data available
                              </span>
                            </Card.Body>
                          </Card>
                        </>
                      )}
                    </ul>
                  </div>
                </div>{" "}
              </>
            ) : (
              <></>
            )}
            {State === "Result2" ? (
              <>
                <div className="table-scroll">
                  <div className="row mt-3">
                    <div className="col-12" style={{ width: "100%" }}>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Student ID
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Name
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Result Status
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Marks
                            </th>
                            <th
                              className=""
                              style={{
                                color: "#474747",
                                fontFamily: "inter",
                                fontSize: 15,
                                backgroundColor: "#F0F0F0",
                                fontWeight: 600,
                              }}
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {student_in_exam &&
                            student_in_exam?.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#474747",
                                        fontFamily: "inter",
                                        fontSize: 14,
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item.stu_id}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#474747",
                                        fontFamily: "inter",
                                        fontSize: 14,
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item.stu_name}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#474747",
                                        fontFamily: "inter",
                                        fontSize: 14,
                                        fontWeight: 400,
                                      }}
                                    >
                                      <span
                                        style={{
                                          border:
                                            item.status == "fail"
                                              ? "1px solid red"
                                              : "1px solid green",
                                          color:
                                            item.status == "fail"
                                              ? "red"
                                              : "green",
                                          paddingLeft: ".35rem",
                                          paddingRight: ".35rem",
                                          borderRadius: "5px",
                                          backgroundColor:
                                            item.status == "fail"
                                              ? "#ffc9bb"
                                              : "lightgreen",
                                        }}
                                      >
                                        {item.status}
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#474747",
                                        fontFamily: "inter",
                                        fontSize: 14,
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item.marks}/{tot_marks}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#474747",
                                        fontFamily: "inter",
                                        fontSize: 14,
                                        fontWeight: 400,
                                      }}
                                    >
                                      <div
                                        style={{
                                          justifyContent: "space-around",
                                          alignItems: "center",
                                        }}
                                      >
                                        <button
                                          className="px-2"
                                          type="button"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvasWithBackdrop"
                                          aria-controls="offcanvasWithBackdrop"
                                          style={{
                                            backgroundColor: "#F2F2F2",
                                            color: "#2C62EE",
                                            fontFamily: "inter",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            border: "none",
                                            borderRadius: 5,
                                          }}
                                        >
                                          <AiOutlineEye
                                            style={{ fontSize: 16 }}
                                          />{" "}
                                          View Details
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Declared Offcanvas End */}

      {/* Exam Result Details Offcanvas Start  */}
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasWithBackdropLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="row d-flex align-items-center justify-content-between">
            <div
              className="col-6 text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
            >
              Exam Result Details
            </div>
            <div
              className="col-6"
              style={{
                height: 25,
                backgroundColor: "#ECF2FF",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "inter",
                fontSize: 13,
                fontWeight: 400,
                color: "#3D70F5",
              }}
            >
              Result Declared on 12:30 AM | 22 September 2023 &nbsp;
              <RiCheckDoubleLine style={{ fontSize: 18 }} />
            </div>
          </div>
          <div className="row d-flex align-items-center mt-4">
            <div className="col-2">
              <div style={{ width: 80, height: "auto", borderRadius: 100 }}>
                <img
                  src={myimg}
                  className="header"
                  style={{ width: "100%", borderRadius: 100 }}
                  alt="img"
                />
              </div>
            </div>
            <div className="col-4 text-start">
              <div
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 18 }}
              >
                Yogesh Jangid
              </div>
              <div
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 14 }}
              >
                Student ID : TIPSG5682
              </div>
              <NavLink to="/StudentProfile">
                <button
                  className="text-start"
                  type="button"
                  style={{
                    backgroundColor: "transparent",
                    color: "#2C62EE",
                    fontFamily: "inter",
                    fontSize: 12,
                    fontWeight: 400,
                    border: "none",
                    borderRadius: 5,
                  }}
                >
                  <AiOutlineEye style={{ fontSize: 16 }} /> View Details
                </button>
              </NavLink>
            </div>
          </div>
          <p
            className="text-start my-2"
            style={{ fontFamily: "inter", fontSize: 18, fontWeight: 400 }}
          >
            Articulate structure of C++ and Java in Semester 1
          </p>
          <div className="row">
            <div className="col-3">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 30,
                  border: "1px solid #D3D3D3",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "#989898",
                    fontFamily: "inter",
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  Batch 3CO - JVY
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-6 ">
                  <FiClock style={{ color: "#989898" }} />
                  <span
                    style={{
                      color: "#989898",
                      fontWeight: 400,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                    11:40 PM
                  </span>
                </div>
                <div className="col-6">
                  <FaRegCalendarAlt style={{ color: "#989898" }} />
                  <span
                    style={{
                      color: "#989898",
                      fontWeight: 400,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                    10 Feb 2023
                  </span>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div
                className=""
                style={{
                  height: 30,
                  backgroundColor: "#ECFFEE",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "#44CCA3",
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "inter",
                  }}
                >
                  Status : <span>Pass</span>
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div
              className="col-12 text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#474747",
                fontWeight: 400,
              }}
            >
              Course :{" "}
              <span>B.Tech Specialization in Health Informatics-1</span>{" "}
            </div>
            <div
              className="col-12 text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#474747",
                fontWeight: 400,
              }}
            >
              Subject : <span style={{}}>Networking</span>{" "}
            </div>
            <div
              className="col-12 text-start mt-2"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#474747",
                fontWeight: 600,
              }}
            >
              Total Marks : <span style={{}}>50</span>{" "}
            </div>
          </div>
          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#3C3C3C",
                }}
              >
                Passing Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3D70F5",
                }}
              >
                70 %
              </p>
            </div>
          </div>
          <div className="mt-2">
            <div
              className="text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
                color: "#434343",
              }}
            >
              Question Paper
            </div>
            <div className="row mt-2">
              {pdf.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-4 m-auto mb-2"
                    style={{
                      width: "30%",
                      height: 30,
                      border: "1px solid #D7D7D7",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      {item.pdfname}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <p
            className="text-start"
            style={{
              fontFamily: "inter",
              fontSize: 30,
              fontWeight: 600,
              color: "#3C3C3C",
            }}
          >
            Result
          </p>
          <div className="">
            <div
              className="text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
                color: "#434343",
              }}
            >
              Student’s Response
            </div>
            <div className="row mt-1">
              {response.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-4 m-auto mb-2"
                    style={{
                      width: "30%",
                      height: 30,
                      border: "1px solid #D7D7D7",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      {item.pdfname}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <p
            className="text-start"
            style={{
              fontFamily: "inter",
              fontSize: 16,
              fontWeight: 400,
              color: "#3C3C3C",
            }}
          >
            Student’s Score
          </p>
          <div
            style={{
              width: "15%",
              height: 40,
              border: "1px solid #D7D7D7",
              borderRadius: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontFamily: "inter",
                fontSize: 18,
                fontWeight: 400,
                color: "#3C3C3C",
              }}
            >
              32/50
            </div>
          </div>
          <div className="row mt-4">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#3C3C3C",
                }}
              >
                Student’s Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#44CCA3",
                }}
              >
                64 %
              </p>
            </div>
          </div>
          <p
            className="text-start mt-2"
            style={{
              fontFamily: "inter",
              fontSize: 28,
              fontWeight: 600,
              color: "#3C3C3C",
            }}
          >
            Instructor’s Notes
          </p>
          <p
            className="text-start"
            style={{
              fontFamily: "inter",
              fontWeight: 400,
              fontSize: 14,
              color: "#3C3C3C",
            }}
          >
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
            fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
            vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
            ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
            nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
            lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
            vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id. Praesent lorem orci, mattis non efficitur id,
            ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce
            vel tempor elit. Proin tempus, magna id scelerisque vestibulum,
            nulla ex pharetra sapien, tempor posuere massa neque nec felis.
            Aliquam sem ipsum, vehicula ac tortor vel, egestas ullamcorper dui.
            Curabitur at risus sodales, tristique est id, euismod justo. Mauris
            nec leo non libero sodales lobortis. Quisque a neque pretium, dictum
            tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies
            dignissim nibh ut cursus. Nam et quam sit amet turpis finibus
            maximus tempor eget augue. Aenean at ultricies lorem. Sed egestas
            ligula tortor, sit amet mattis ex feugiat non. Duis purus diam,
            dictum et ante at, commodo iaculis urna. Aenean lacinia, nisl id
            vehicula condimentum, enim massa tempor nibh, vitae volutpat sapien
            metus aliquet nisl. Etiam dui eros, tincidunt tristique blandit id,
            gravida vitae augue. Proin imperdiet mi nec justo convallis gravida.
            Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Mauris consectetur nibh in mattis commodo.
            Etiam in pellentesque eros. Etiam tempus.
          </p>
        </div>
      </div>
      {/* Exam Result Details Offcanvas End */}

      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div className="row">
            <p
              className="text-center"
              style={{ fontFamily: "inter", fontSize: 20, fontWeight: 600 }}
            >
              Publish Result of “{exam_viewdetail?.exam_name}” ?
            </p>
          </div>
          <div className="row">
            <p
              className="text-center"
              style={{ fontFamily: "inter", fontSize: 16, fontWeight: 600 }}
            >
              Result once published cannot be altered/reversed
            </p>
          </div>
          <div className="row mt-2">
            <div className="col-6 m-auto">
              <div className="row">
                <div className="col-6">
                  <button
                    onClick={handleClose}
                    type="button"
                    className="btn"
                    style={{
                      width: "100%",
                      height: 40,
                      backgroundColor: "#fff",
                      border: "1px solid #E9E9E9",
                      fontFamily: "inter",
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    No
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={() => declareResult(exam_id)}
                    type="button"
                    className="btn"
                    style={{
                      width: "100%",
                      height: 40,
                      backgroundColor: "#3D70F5",
                      color: "#fff",
                      border: "1px solid #E9E9E9",
                      fontFamily: "inter",
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

const LiveClass = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="col-4">
      <Card
        style={{
          backgroundColor: "#fff",
          border: "1px solid #E6E6E6",
          marginBottom: 20,
          width: "95%",
        }}
      >
        <Card.Body className="text-start">
          <span
            style={{
              fontWeight: 600,
              fontSize: 16,
              textAlign: "start",
              fontFamily: "inter",
            }}
          >
            {props.item.exam_name}
          </span>
          <div
            className="row g-0 mt-2"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p
              className="col-2 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 13,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Course
            </p>
            <p
              className="col-10 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 13,
                color: "#9A9A9A",
                fontWeight: 400,
                textAlign: "left ",
              }}
            >
              {" "}
              :&nbsp;&nbsp;{props.item.cou_name}
            </p>
          </div>
          <div
            className="row g-0 mt-2"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p
              className="col-2 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 13,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Subject
            </p>
            <p
              className="col-10"
              style={{
                fontFamily: "inter",
                fontSize: 13,
                color: "#9A9A9A",
                fontWeight: 400,
                textAlign: "left ",
              }}
            >
              {" "}
              :&nbsp;&nbsp;{props.item.sub_name}
            </p>
          </div>
          <div className="row g-0">
            <div className="col-5 d-flex align-items-center justify-content-start">
              <FaCalendar style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.created_at?.split("T")[0]}
              </span>
            </div>
            <div className="col-7 d-flex align-items-center justify-content-start">
              <FiClock style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.start_time.split("T")[1].split(".")[0]} -{" "}
                {props.item.end_time.split("T")[1].split(".")[0]}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <p
              className="col-12 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Marks : {props.item.tot_marks}
            </p>
          </div>
          <div className="row mt-2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#3C3C3C",
                }}
              >
                Passing Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3D70F5",
                }}
              >
                {props.item.pass_pers} %
              </p>
            </div>
          </div>
          <div
            className="mt-3"
            style={{
              width: "100%",
              height: 8,
              backgroundColor: "#D9D9D9",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                width: props.item.pass_pers + "%",
                height: 8,
                backgroundColor: "#3D70F5",
                borderRadius: 10,
              }}
            ></div>
          </div>
          {props.item.is_res_dec == null ? (
            <button
              onClick={() => {
                dispatch(getExamViewdetailfun(props.item.id));
                props.handleShowDec();
                props.getStudentsDetails(props.item.id);
                props.handle2(props.item.id);
                props.setExamId(props.item.id);
                props.setState("Details");
              }}
              type="button"
              className="btn mt-3"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              style={{
                width: "100%",
                height: 40,
                backgroundColor: "#3D70F5",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
              >
                Declare Result
              </span>
            </button>
          ) : (
            <div
              onClick={() => {
                dispatch(getExamViewdetailfun(props.item.id));
                props.getStudentsDetails(props.item.id);
                // props.getDeclaredresultByID(props.item.id);
                props.setState("Details2");
              }}
              className="mt-3"
              data-bs-toggle="offcanvas"
              data-bs-target="#declareResult"
              aria-controls="offcanvasRight"
              style={{
                width: "100%",
                height: 40,
                backgroundColor:
                  props.item.is_res_dec == 1 ? "#3D70F5" : "#fff",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #3D70F5",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: props.item.is_res_dec == 1 ? "#fff" : "#3D70F5",
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
              >
                 Declared
              </span>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
