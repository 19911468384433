import { useState, useEffect } from "react";
import { FiPlayCircle } from "react-icons/fi";
import { HiDotsVertical } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { FiClock } from "react-icons/fi";
import { FaCalendar } from "react-icons/fa";
import { RiCloseFill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiLeftArrowAlt } from "react-icons/bi";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import bannerImage from "../images/courseicon.png";
import { NavLink, useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { getParticularpreCoursefun } from "../redux/preRecord/prerecordActions";

export default function RecordedCourses() {
const {prerecordCourse,prerecordCourse_search,particular_prerecordCourse} = useSelector((store)=>store.prerecordcourse)
console.log(prerecordCourse,"prerecordCourse",particular_prerecordCourse);

  const [live, setLive] = useState();
  const [tab, setTab] = useState("0");
  const [video, setVideo] = useState([]);
  const [math, setMath] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [next, setNext] = useState("0");
  const [quiz, setQuiz] = useState();
  const [affairs, setAffairs] = useState();
  const [Reasoning, setReasoning] = useState();
  const [Computer, setComputer] = useState();
  const [rules, setRules] = useState([]);
  const [quizrules, setQuizRules] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [affairs2, setAffairs2] = useState("");
  const [reasoning2, setReasoning2] = useState("");
  const [computer2, setComputer2] = useState("");
  const [science2, setScience2] = useState("");
  const [affairs3, setAffairs3] = useState("");
  const [reasoning3, setReasoning3] = useState("");
  const [computer3, setComputer3] = useState("");
  const [science3, setScience3] = useState("");
  const [TimePerQuestion, setTimePerQuestion] = useState("");
  const [go, setGo] = useState(0);
  const [data, setData] = useState();
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const changeHandler = (event) => {
    setText(event.target.value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // array of words
    const words = text.split(" ");

    // update word count
    let wordCount = 0;
    words.forEach((word) => {
      if (word.trim() !== "") {
        wordCount++;
      }
    });
    setWordCount(wordCount);

    // update char count (including whitespaces)
    setCharCount(text.length);
  }, [text]);

  // const navigate = useNavigate();

  const classdata = [
    {
      img: require("../images/courseicon.png"),
      course: "BCA ( Specialization in Data Analytics )",
      duration: "3 Year",
      assignments: 576,
      upto: "25 LPA",
      price: "25,0000",
    },
    {
      img: require("../images/courseicon.png"),
      course: "BCA ( Specialization in Data Analytics )",
      duration: "3 Year",
      assignments: 576,
      upto: "25 LPA",
      price: "25,0000",
    },
    {
      img: require("../images/courseicon.png"),
      course: "BCA ( Specialization in Data Analytics )",
      duration: "3 Year",
      assignments: 576,
      upto: "25 LPA",
      price: "25,0000",
    },
    {
      img: require("../images/courseicon.png"),
      course: "BCA ( Specialization in Data Analytics )",
      assignments: 576,
      upto: "25 LPA",
      price: "25,0000",
    },
    {
      img: require("../images/courseicon.png"),
      course: "BCA ( Specialization in Data Analytics )",
      duration: "3 Year",
      assignments: 576,
      upto: "25 LPA",
      price: "25,0000",
    },
    {
      img: require("../images/courseicon.png"),
      course: "BCA ( Specialization in Data Analytics )",
      duration: "3 Year",
      assignments: 576,
      upto: "25 LPA",
      price: "25,0000",
    },
  ];

  const videodata = [
    {
      name: "Developing User Personas",
      time: "3 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
    {
      name: "Developing User Personas",
      time: "4 Min",
    },
  ];

  const quizdata = [
    {
      topic: "Articulate structure of C++ and Java in Semester 1",
      course: "B.Tech Specialization in Health Informatics",
      video: "video 1 -  Network Engineering basics",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      question: 50,
      width: 70,
    },
    {
      topic: "Articulate structure of C++ and Java in Semester 1",
      course: "B.Tech Specialization in Health Informatics",
      video: "video 1 -  Network Engineering basics",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      question: 50,
      width: 70,
    },
    {
      topic: "Articulate structure of C++ and Java in Semester 1",
      course: "B.Tech Specialization in Health Informatics",
      video: "video 1 -  Network Engineering basics",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      question: 50,
      width: 70,
    },
    {
      topic: "Articulate structure of C++ and Java in Semester 1",
      course: "B.Tech Specialization in Health Informatics",
      video: "video 1 -  Network Engineering basics",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      question: 50,
      width: 70,
    },
  ];
  const ruleslist = [
    {
      rules: "You must use a functioning webcam and microphone.",
    },
    {
      rules:
        "NO cell phones or other secondary device in the room or test area.",
    },
    {
      rules: "You must use a functioning webcam and microphone.",
    },
    {
      rules:
        "NO cell phones or other secondary device in the room or test area.",
    },
  ];
  const quizruleslist = [
    {
      rules: "You must use a functioning webcam and microphone.",
    },
    {
      rules:
        "NO cell phones or other secondary device in the room or test area.",
    },
    {
      rules: "You must use a functioning webcam and microphone.",
    },
    {
      rules:
        "NO cell phones or other secondary device in the room or test area.",
    },
  ];
  const videoList = [
    {
      name: "video Specialization in Health Informatics-1",
    },
    {
      name: "video Specialization in Health Informatics-2",
    },
    {
      name: "video Specialization in Health Informatics-3",
    },
    {
      name: "video Specialization in Health Informatics-4",
    },
    {
      name: "video Specialization in Health Informatics-5",
    },
    {
      name: "video Specialization in Health Informatics-6",
    },
  ];
  const course = [
    {
      name: "B.Tech Specialization in Health Informatics-1",
    },
    {
      name: "B.Tech Specialization in Health Informatics-2",
    },
    {
      name: "B.Tech Specialization in Health Informatics-3",
    },
    {
      name: "B.Tech Specialization in Health Informatics-4",
    },
    {
      name: "B.Tech Specialization in Health Informatics-5",
    },
    {
      name: "B.Tech Specialization in Health Informatics-6",
    },
  ];
  const selsub = [
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
    {
      name: "Mathematics",
    },
  ];

  useEffect(() => {
    setData(course);
    setLive(classdata);
    setVideo(videodata);
    setQuiz(quizdata);
    setQuizRules(quizruleslist);
    setRules(ruleslist);
    setVideoData(videoList);
    setMath(selsub);
    setTab("0");
    setGo(0);
  }, []);

  const [open, setOpen] = useState(false);
  const [on, setOn] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCross = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOn(true);
  };

  const handleCrossModal = () => {
    setOn(false);
  };

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ width: "45%" }}
      >
        {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-5 ms-auto">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-6">
                  <button
                    type="botton"
                    className=""
                    style={{
                      width: "100%",
                      height: 35,
                      backgroundColor: "#FFEFEF",
                      color: "#F93333",
                      borderRadius: 5,
                      fontFamily: "inter",
                      fontSize: 13,
                      fontWeight: 600,
                    }}
                  >
                    Delete Course
                  </button>
                </div>
                <div className="col-6">
                  <div
                    className="save-change"
                    style={{
                      width: "100%",
                      height: 35,
                      backgroundColor: "#fff",
                      color: "#2C62EE",
                      borderRadius: 5,
                      fontFamily: "inter",
                      fontSize: 13,
                      fontWeight: 600,
                      border: "1px solid #2C62EE",
                      cursor: "pointer",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    Save Changes
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mt-2"
            style={{
              fontFamily: "inter",
              fontWeight: "600",
              fontSize: 22,
              borderBottom: "1px solid #A5A5A5",
            }}
          >
            BCA ( Specialization in Data Analytics )
          </div>
          <div className="row mt-3  ">
            <div
              className="col-6 g-0"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={() => {
                    setTab("0");
                  }}
                  className="bg-transparent text-center"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 15,
                    fontWeight: 500,
                    color: tab === "0" ? "#2C62EE" : "#707070",
                    width: 80,
                    height: 25,
                    borderBottom: tab == "0" ? "1.5px solid #2C62EE" : "none",
                    cursor: "pointer",
                  }}
                >
                  Content
                </div>
                <div
                  onClick={() => {
                    setTab("1");
                  }}
                  className="bg-transparent text-center"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 15,
                    fontWeight: 500,
                    color: tab === "1" ? "#2C62EE" : "#707070",
                    width: 80,
                    height: 25,
                    borderBottom: tab == "1" ? "1.5px solid #2C62EE" : "none",
                    cursor: "pointer",
                  }}
                >
                  Quizzes
                </div>
                <div
                  onClick={() => {
                    setTab("2");
                  }}
                  className="bg-transparent text-center"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 15,
                    fontWeight: 500,
                    color: tab === "2" ? "#2C62EE" : "#707070",
                    width: 80,
                    height: 25,
                    borderBottom: tab == "2" ? "1.5px solid #2C62EE" : "none",
                    cursor: "pointer",
                  }}
                >
                  Details
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            {tab === "0" ? (
              <>
                <div>
                  <div className="row">
                    <div className="col-3 ms-auto">
                      <div
                        onClick={handleOpenModal}
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#fff",
                          border: "1px solid #2C62EE",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            fontSize: 14,
                            color: "#2C62EE",
                          }}
                        >
                          + Add videos
                        </span>
                      </div>
                    </div>
                  </div>
                  <Dialog
                    open={on}
                    //  TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCrossModal}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogActions>
                      <Button
                        onClick={handleCrossModal}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <MdOutlineClose style={{ fontSize: 20 }} />
                      </Button>
                    </DialogActions>
                    <DialogTitle
                      className=""
                      style={{
                        fontFamily: "inter",
                        fontSize: 25,
                        fontWeight: 600,
                      }}
                    ></DialogTitle>
                    <DialogContent>
                      <div className="row g-0">
                        <div className="col-12">
                          <Card className="border-0">
                            <p
                              className="text-start"
                              style={{
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Enter video Title
                            </p>
                            <form>
                              <div className="px-2">
                                <div className="input-group flex-nowrap">
                                  <span
                                    className="input-group-text"
                                    id="addon-wrapping"
                                    style={{
                                      borderRadius: 0,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                    }}
                                  ></span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Select Course"
                                    aria-label="Username"
                                    aria-describedby="addon-wrapping"
                                    style={{
                                      borderRadius: 0,
                                      border: "none",
                                      backgroundColor: "#F1F1F1",
                                      boxShadow: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </form>
                          </Card>
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                  <div className="bar3">
                    {video.map((item, index) => {
                      return (
                        <>
                          <div key={index} className="row m-0 pt-4">
                            <div
                              className="row g-0 px-2 py-2"
                              style={{
                                width: "100%",
                                border: "1px solid #000",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderRadius: 10,
                                borderWidth: 2,
                              }}
                            >
                              <div
                                className="col-3 g-0"
                                style={{ width: 80, height: 50 }}
                              >
                                <div className="text-center">
                                  <FiPlayCircle style={{ fontSize: 25 }} />
                                  <div
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 13,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.time}
                                  </div>
                                </div>
                              </div>
                              <div className="col-7" style={{}}>
                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 600,
                                    fontSize: 20,
                                  }}
                                >
                                  {item.name}
                                </div>
                              </div>
                              <div className="col-1" style={{}}>
                                <div className="dropdown">
                                  <button
                                    className="border-0"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <HiDotsVertical
                                      style={{
                                        fontSize: "1.5em",
                                        color: "#54536E",
                                      }}
                                    />
                                  </button>
                                  <ul
                                    className="dropdown-menu border-0"
                                    style={{
                                      backgroundColor: "#eee",
                                      width: 40,
                                    }}
                                  >
                                    <li
                                      className=""
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div
                                        onClick={handleClickOpen}
                                        className="dropdown-item d-inline-flex justify-content-between border-0"
                                      >
                                        Remove
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <Dialog
                  open={open}
                  //  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleCross();
                        setNext("0");
                      }}
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <MdOutlineClose style={{ fontSize: 20 }} />
                    </Button>
                  </DialogActions>
                  <DialogTitle
                    className="text-center"
                    style={{
                      fontFamily: "inter",
                      fontSize: 25,
                      fontWeight: 600,
                    }}
                  >
                    Remove Content
                  </DialogTitle>
                  <DialogContent>
                    <div style={{ display: next == "0" ? "block" : "none" }}>
                      <div className="row g-0">
                        <p
                          className="text-center col-6 text-center m-auto"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#000",
                          }}
                        >
                          Developing user personas will be removed from BCA (
                          Specialization in Data Analytics )
                        </p>
                      </div>
                      <div className="row mt-4">
                        <div className="col-6 m-auto">
                          <div className="row g-0 mt-2">
                            <div className="col-5">
                              <div
                                style={{
                                  width: "100%",
                                  height: 30,
                                  backgroundColor: "#fff",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 5,
                                  border: "1px solid #F93333",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#F93333",
                                    fontWeight: 600,
                                    fontSize: 14,
                                    fontFamily: "inter",
                                    paddingLeft: 10,
                                  }}
                                >
                                  Keep
                                </span>
                              </div>
                            </div>
                            <div className="col-5 mx-2">
                              <div
                                onClick={() => setNext("1")}
                                style={{
                                  width: "100%",
                                  height: 30,
                                  backgroundColor: "#ffff",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 5,
                                  border: "1px solid #4AAD1B",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#4AAD1B",
                                    fontWeight: 600,
                                    fontSize: 14,
                                    fontFamily: "inter",
                                  }}
                                >
                                  Yes, remove
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: next == "1" ? "block" : "none" }}>
                      <div className="row g-0">
                        <p
                          className="text-center col-6 text-center m-auto"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#000",
                          }}
                        >
                          Developing user personas will be removed from BCA (
                          Specialization in Data Analytics )
                        </p>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </>
            ) : (
              <></>
            )}
            {tab === "1" ? (
              <>
                <div>
                  <div className="row">
                    <div className="col-3 ms-auto">
                      <div
                        onClick={handleShow2}
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#fff",
                          border: "1px solid #2C62EE",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            fontSize: 14,
                            color: "#2C62EE",
                          }}
                        >
                          + Add Quizzes
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bar3">
                    <div className="row mt-3">
                      {quiz.map((item, index) => {
                        return (
                          <div className="col-6" key={index}>
                            <Card
                              style={{
                                backgroundColor: "#fff",
                                border: "1px solid #E6E6E6",
                                marginBottom: 20,
                                width: "100%",
                              }}
                            >
                              <Card.Body className="text-start">
                                <span
                                  style={{
                                    fontWeight: 600,
                                    fontSize: 14,
                                    textAlign: "start",
                                    fontFamily: "inter",
                                  }}
                                >
                                  {item.topic}
                                </span>
                                <div
                                  className="row g-0 mt-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <p
                                    className="col-2 m-0"
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 12,
                                      color: "#9A9A9A",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Course
                                  </p>
                                  <p
                                    className="col-10 m-0"
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 12,
                                      color: "#9A9A9A",
                                      fontWeight: 400,
                                      textAlign: "left ",
                                    }}
                                  >
                                    {" "}
                                    :&nbsp;&nbsp;{item.course}
                                  </p>
                                </div>
                                <div
                                  className="row g-0 mt-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <p
                                    className="col-3 m-0"
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 12,
                                      color: "#9A9A9A",
                                      fontWeight: 400,
                                    }}
                                  >
                                    video
                                  </p>
                                  <p
                                    className="col-7"
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 12,
                                      color: "#9A9A9A",
                                      fontWeight: 400,
                                      textAlign: "left ",
                                    }}
                                  >
                                    {" "}
                                    :&nbsp;&nbsp;{item.video}
                                  </p>
                                </div>
                                <div className="row g-0">
                                  <div className="col-5 d-flex align-items-center justify-content-start">
                                    <FaCalendar style={{ color: "#3C3C3C" }} />
                                    <span
                                      style={{
                                        color: "#3C3C3C",
                                        fontWeight: 400,
                                        fontSize: 12,
                                        paddingLeft: 5,
                                        fontFamily: "inter",
                                      }}
                                    >
                                      {item.duedate}
                                    </span>
                                  </div>
                                  <div className="col-7 d-flex align-items-center justify-content-start">
                                    <FiClock style={{ color: "#3C3C3C" }} />
                                    <span
                                      style={{
                                        color: "#3C3C3C",
                                        fontWeight: 400,
                                        fontSize: 12,
                                        paddingLeft: 5,
                                        fontFamily: "inter",
                                      }}
                                    >
                                      {item.duetime} - {item.duetime}
                                    </span>
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <p
                                    className="col-12 m-0"
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      color: "#9A9A9A",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Questions : {item.question}
                                  </p>
                                </div>
                                <div className="row mt-2">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: "#3C3C3C",
                                      }}
                                    >
                                      Passing Percentage
                                    </p>
                                    <p
                                      className="m-0"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: "#3D70F5",
                                      }}
                                    >
                                      {item.width} %
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="mt-3"
                                  style={{
                                    width: "100%",
                                    height: 8,
                                    backgroundColor: "#D9D9D9",
                                    borderRadius: 10,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: item.width + "%",
                                      height: 8,
                                      backgroundColor: "#3D70F5",
                                      borderRadius: 10,
                                    }}
                                  ></div>
                                </div>
                                <span
                                  onClick={() => {
                                    setShow(false);
                                    setShow1(true);
                                  }}
                                  className="mt-3"
                                  style={{
                                    width: "100%",
                                    height: 40,
                                    backgroundColor: "#3D70F5",
                                    borderRadius: 5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#fff",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      fontFamily: "inter",
                                    }}
                                  >
                                    View Details
                                  </span>
                                </span>
                              </Card.Body>
                            </Card>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {tab === "2" ? (
              <>
                <div>
                  <div className="row">
                    <div className="col-5">
                      <div
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Banner Image
                      </div>
                      <div className="mt-2" style={{ width: "100%" }}>
                        <img
                          src={particular_prerecordCourse.banner}
                          className=""
                          style={{ width: "100%" }}
                          alt="banerimg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-8">
                      <div
                        className=""
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Course Name
                      </div>
                      <div
                        className="mt-2"
                        style={{
                          width: "100%",
                          border: "1px solid #BFBFBF",
                          borderRadius: 5,
                        }}
                      >
                        <div
                          className="p-2"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#5B5B5B",
                          }}
                        >
                        {particular_prerecordCourse.course_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4">
                      <div
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Course Duration
                      </div>
                      <div
                        style={{
                          width: "50%",
                          height: 35,
                          border: "1px solid #BFBFBF",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5B5B5B",
                          }}
                        >
                        {particular_prerecordCourse.duration}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Course Price
                      </div>
                      <div
                        style={{
                          width: "50%",
                          height: 35,
                          border: "1px solid #BFBFBF",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 18,
                            fontWeight: 600,
                            color: "#000",
                          }}
                        >
                          ₹ {particular_prerecordCourse.raw_price}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4">
                      <div
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Discount percentage
                      </div>
                      <div
                        style={{
                          width: "50%",
                          height: 35,
                          border: "1px solid #BFBFBF",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5B5B5B",
                          }}
                        >
                          {particular_prerecordCourse.discount}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Number of enrollments
                      </div>
                      <div
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#5B5B5B",
                        }}
                      >
                        {particular_prerecordCourse.total_enrollments}
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      fontFamily: "inter",
                      fontSize: 22,
                      fontWeight: 500,
                    }}
                  >
                    Final amount :{" "}
                    <span
                      style={{
                        fontFamily: "inter",
                        fontSize: 24,
                        fontWeight: 600,
                      }}
                    >
                      ₹ {particular_prerecordCourse?.final_price}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div className="bar3">
        <div className="row">
          {prerecordCourse_search &&
            prerecordCourse_search?.map((item, index) => {
              return (
                <LiveClass item={item} key={index} handleShow={handleShow} getParticularpreCoursefun={getParticularpreCoursefun} dispatch={dispatch}/>
              );
            })}
        </div>
      </div>

      <Offcanvas
        show={show1}
        onHide={handleClose1}
        placement="end"
        style={{ width: "45%" }}
      >
        <div
          className="row px-4"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="col-3"
            onClick={() => setShow1(false)}
            style={{
              width: 40,
              height: 40,
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <RiCloseFill style={{}} />
          </div>
          <div
            className="col-9"
            style={{
              width: 100,
              height: 30,
              backgroundColor: "#fff",
              border: "1px solid #E72121",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#E72121",
              fontFamily: "inter",
              fontSize: 13,
              fontWeight: 600,
              borderRadius: 5,
              cursor: "pointer",
            }}
          >
            <RiDeleteBin6Line />
            <span style={{ paddingLeft: 10 }}>Delete</span>
          </div>
        </div>
        <Offcanvas.Body>
          <div className="">
            <div className="row">
              <div className="col-8 me-auto">
                <Card className="border-0">
                  <div
                    className="text-start py-2"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 25,
                    }}
                  >
                    Quiz Details
                  </div>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 15,
                    }}
                  >
                    Quiz Name : Networking for Beginners
                  </p>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    Number of Questions : 50
                  </p>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    Course B.Tech Spcl .in Health Information
                  </p>
                  <p
                    className="text-start"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    Subject Networking
                  </p>
                  <div className="row mt-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#3C3C3C",
                        }}
                      >
                        Passing Percentage
                      </p>
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#3D70F5",
                        }}
                      >
                        45 %
                      </p>
                    </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      width: "100%",
                      height: 8,
                      backgroundColor: "#D9D9D9",
                      borderRadius: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "45%",
                        height: 8,
                        backgroundColor: "#3D70F5",
                        borderRadius: 10,
                      }}
                    ></div>
                  </div>
                  <div className="row mt-3">
                    <span
                      className="px-3 text-start"
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        color: "#434343",
                        fontSize: 14,
                      }}
                    >
                      Quiz Schedule
                    </span>
                    <div className="col-4">
                      <div
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDF5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <FaCalendar />
                        <span
                          style={{
                            color: "#3C3C3C",
                            fontWeight: 400,
                            fontSize: 14,
                            fontFamily: "inter",
                            paddingLeft: 10,
                          }}
                        >
                          31-01-2023
                        </span>
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDF5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <FiClock />
                        <span
                          style={{
                            color: "#3C3C3C",
                            fontWeight: 400,
                            fontSize: 14,
                            fontFamily: "inter",
                            paddingLeft: 10,
                          }}
                        >
                          31-01-2023
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-start my-4"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 20,
                    }}
                  >
                    Tags Composition
                  </div>
                  <div
                    className=""
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        color: "#434343",
                        fontSize: 14,
                      }}
                    >
                      Current Affairs
                    </div>
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        color: "#3D70F5",
                        fontSize: 14,
                      }}
                    >
                      {affairs}%
                    </div>
                  </div>
                  <div className="">
                    <span>
                      <input
                        className="w-100 input-range"
                        type="range"
                        min="10"
                        max="100"
                        value={affairs}
                        onChange={(e) => {
                          setAffairs(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <div
                    className="mt-2"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        color: "#434343",
                        fontSize: 14,
                      }}
                    >
                      Logical Reasoning
                    </div>
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        color: "#3D70F5",
                        fontSize: 14,
                      }}
                    >
                      {Reasoning}%
                    </div>
                  </div>
                  <div className="">
                    <span>
                      <input
                        className="w-100 input-range"
                        type="range"
                        min="10"
                        max="100"
                        value={Reasoning}
                        onChange={(e) => {
                          setReasoning(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <div
                    className="mt-2"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        color: "#434343",
                        fontSize: 14,
                      }}
                    >
                      Basic Computer
                    </div>
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        color: "#3D70F5",
                        fontSize: 14,
                      }}
                    >
                      {Computer}%
                    </div>
                  </div>
                  <div className="">
                    <span>
                      <input
                        className="w-100 input-range"
                        type="range"
                        min="10"
                        max="100"
                        value={Computer}
                        onChange={(e) => {
                          setComputer(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <div className="row">
                    <ul className="list-group text-start border-0">
                      {rules.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="list-group-item d-flex align-items-center border-0"
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            <div
                              className="me-2"
                              style={{
                                width: 7,
                                height: 7,
                                backgroundColor: "#000",
                                borderRadius: 10,
                              }}
                            ></div>
                            {item.rules}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="row my-4">
                    <div className="col-8">
                      <div
                        style={{
                          height: 35,
                          backgroundColor: "#E6E6E6",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "inter",
                          fontWeight: 400,
                          fontSize: 14,
                          color: "#9E9E9E",
                          borderRadius: 5,
                        }}
                      >
                        Time Per Question : 75 Seconds
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={show2}
        onHide={handleClose2}
        placement="end"
        style={{ width: "45%" }}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <>
            <div>
              <div style={{ display: go === 0 ? "block" : "none" }}>
                <p
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Quiz
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>1</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "25%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Course
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Course"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                          />
                        </div>
                      </div>
                      <div className="barsubject">
                        {data &&
                          data.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="form-check d-flex align-items-center mx-3 m-1 py-1"
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  style={{ boxShadow: "none" }}
                                  onClick={() => {
                                    setGo(go + 1);
                                  }}
                                />
                                <label
                                  className="form-check-label p-0"
                                  for="flexRadioDefault1"
                                >
                                  <div
                                    className="my-1"
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#6A6A6A",
                                      paddingLeft: 10,
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </Card>
                    <div className="row mt-5">
                      <div
                        onClick={() => setGo(go + 1)}
                        className="col-4 m-auto py-1"
                        style={{
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontWeight: 500,
                            color: "#fff",
                            fontSize: 14,
                          }}
                        >
                          Proceed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: go === 1 ? "block" : "none" }}>
                <p
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Quiz
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Video
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Video"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                          />
                        </div>
                      </div>
                      <div className="barsubject">
                        {videoData &&
                          videoData.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="form-check d-flex align-items-center mx-3 m-1 py-1"
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  style={{ boxShadow: "none" }}
                                  onClick={() => {
                                    setGo(go + 1);
                                  }}
                                />
                                <label
                                  className="form-check-label p-0"
                                  for="flexRadioDefault1"
                                >
                                  <div
                                    className="my-1"
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#6A6A6A",
                                      paddingLeft: 10,
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setGo(go - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setGo(go + 1);
                        }}
                        style={{
                          width: "25%",
                          height: 30,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Proceed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: go === 2 ? "block" : "none" }}>
                <p
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Quiz
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "75%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="pt-2 mb-2"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Tag
                      </p>
                      <div className="input-group flex-nowrap">
                        <span
                          className="input-group-text"
                          id="addon-wrapping"
                          style={{
                            borderRadius: 0,
                            border: "none",
                            backgroundColor: "#F1F1F1",
                          }}
                        >
                          <BsSearch style={{ color: "#6A6A6A" }} />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Select Tags"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          style={{
                            borderRadius: 0,
                            border: "none",
                            backgroundColor: "#F1F1F1",
                            boxShadow: "none",
                          }}
                        />
                      </div>
                      <div className="barsubject">
                        {math &&
                          math.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="form-check d-flex align-items-center mx-3 my-2 m-1"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  style={{ boxShadow: "none" }}
                                />
                                <label
                                  className="form-check-label p-0"
                                  for="flexRadioDefault1"
                                >
                                  <div
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#6A6A6A",
                                      paddingLeft: 10,
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setGo(go - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setGo(go + 1);
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Proceed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: go === 3 ? "block" : "none" }}>
                <p
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Quiz
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="pt-2 mb-2"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Question Composition
                      </p>
                      <div className="barsubject">
                        <div className="row px-2">
                          <div
                            className="mt-4"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "inter",
                                fontWeight: 400,
                                color: "#434343",
                                fontSize: 14,
                              }}
                            >
                              Current Affairs
                            </div>
                            <div
                              style={{
                                fontFamily: "inter",
                                fontWeight: 600,
                                color: "#3D70F5",
                                fontSize: 14,
                              }}
                            >
                              {affairs2}%
                            </div>
                          </div>
                          <div className="">
                            <span>
                              <input
                                id="myinput"
                                className="w-100 input-affairs"
                                type="range"
                                min="0"
                                max="100"
                                value={affairs2}
                                onChange={(e) => {
                                  setAffairs2(e.target.value);
                                }}
                              />
                            </span>
                          </div>
                          <div
                            className="mt-4"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "inter",
                                fontWeight: 400,
                                color: "#434343",
                                fontSize: 14,
                              }}
                            >
                              Logical Reasoning
                            </div>
                            <div
                              style={{
                                fontFamily: "inter",
                                fontWeight: 600,
                                color: "#3D70F5",
                                fontSize: 14,
                              }}
                            >
                              {reasoning2}%
                            </div>
                          </div>
                          <div className="">
                            <span>
                              <input
                                className="w-100 input-range"
                                type="range"
                                min="10"
                                max="100"
                                value={reasoning2}
                                onChange={(e) => {
                                  setReasoning2(e.target.value);
                                }}
                              />
                            </span>
                          </div>
                          <div
                            className="mt-4"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "inter",
                                fontWeight: 400,
                                color: "#434343",
                                fontSize: 14,
                              }}
                            >
                              Basic Computers
                            </div>
                            <div
                              style={{
                                fontFamily: "inter",
                                fontWeight: 600,
                                color: "#3D70F5",
                                fontSize: 14,
                              }}
                            >
                              {computer2}%
                            </div>
                          </div>
                          <div className="">
                            <span>
                              <input
                                className="w-100 input-range"
                                type="range"
                                min="10"
                                max="100"
                                value={computer2}
                                onChange={(e) => {
                                  setComputer2(e.target.value);
                                }}
                              />
                            </span>
                          </div>
                          <div
                            className="mt-4"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "inter",
                                fontWeight: 400,
                                color: "#434343",
                                fontSize: 14,
                              }}
                            >
                              Basic Science
                            </div>
                            <div
                              style={{
                                fontFamily: "inter",
                                fontWeight: 600,
                                color: "#3D70F5",
                                fontSize: 14,
                              }}
                            >
                              {science2}%
                            </div>
                          </div>
                          <div className="">
                            <span>
                              <input
                                className="w-100 input-range"
                                type="range"
                                min="10"
                                max="100"
                                value={science2}
                                onChange={(e) => {
                                  setScience2(e.target.value);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setGo(go - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setGo(go + 1);
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Proceed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: go === 4 ? "block" : "none" }}>
                <p
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Quiz
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="pt-2 mb-2"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Quiz Rules
                      </p>
                      <textarea
                        className="p-2 shadow-none"
                        value={text}
                        maxLength="5000"
                        onChange={changeHandler}
                        placeholder="Start Typing the Rules"
                        style={{
                          width: "100",
                          height: 120,
                          backgroundColor: "#F5F5F5",
                          border: "none",
                          borderRadius: 5,
                          boxShadow: "none",
                        }}
                      ></textarea>
                      <div>
                        <p
                          className="char-count text-end"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#B1B1B1",
                          }}
                        >
                          {charCount} / 5000
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-9 px-2">
                          <div className="input-group flex-nowrap">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Time Per Question"
                              aria-label="Username"
                              aria-describedby="addon-wrapping"
                              style={{
                                borderRadius: 5,
                                border: "none",
                                backgroundColor: "#F1F1F1",
                                boxShadow: "none",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="input-group flex-nowrap">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Second"
                              aria-label="Username"
                              aria-describedby="addon-wrapping"
                              style={{
                                borderRadius: 5,
                                border: "none",
                                backgroundColor: "#F1F1F1",
                                boxShadow: "none",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setGo(go - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setGo(go + 1);
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Proceed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: go === 5 ? "block" : "none" }}>
                <p
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Quiz
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "75%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className=" pt-2 mb-2"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Quiz Specifications
                      </p>
                      <div className="">
                        <div className="input-group flex-nowrap">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Number of Questions"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            className="m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#3C3C3C",
                            }}
                          >
                            Passing Percentage
                          </p>
                          <p
                            className="m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#3D70F5",
                            }}
                          >
                            45 %
                          </p>
                        </div>
                      </div>
                      <div
                        className="mt-3"
                        style={{
                          width: "100%",
                          height: 8,
                          backgroundColor: "#D9D9D9",
                          borderRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "45%",
                            height: 8,
                            backgroundColor: "#3D70F5",
                            borderRadius: 10,
                          }}
                        ></div>
                      </div>
                      <div className="row mt-3">
                        <span
                          className="px-3 text-start"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          Quiz Schedule
                        </span>
                        <div className="col-4">
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              backgroundColor: "#EDEDF5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <FaCalendar />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                fontFamily: "inter",
                                paddingLeft: 10,
                              }}
                            >
                              31-01-2023
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              backgroundColor: "#EDEDF5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <FiClock />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 400,
                                fontSize: 14,
                                fontFamily: "inter",
                                paddingLeft: 10,
                              }}
                            >
                              31-01-2023
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => setGo(go + 1)}
                        className="mt-5"
                        style={{
                          width: "100%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#fff",
                          }}
                        >
                          Continue
                        </span>
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setGo(go - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      {/* <div onClick={()=>{setGo(go+1)}} style={{ width:"25%", height:35, backgroundColor:"#3D70F5", borderRadius:5, display:"flex", justifyContent:"center", alignItems:'center' }} >
                      <span style={{ fontFamily:"inter", fontSize:14, fontWeight:400, color:"#fff", cursor:"pointer" }} >Proceed</span>
                     </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: go === 6 ? "block" : "none" }}>
                <p
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Quiz
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "100%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="px-0 pt-2 mb-2"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 24,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Preview
                      </p>
                      <div className="barsubject">
                        <div
                          className="row g-0 mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <p
                            className="col-12 m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 16,
                              color: "#434343",
                              fontWeight: 600,
                              textAlign: "left ",
                            }}
                          >
                            Course : B.Tech Specialization in Health
                            Informatics-1
                          </p>
                        </div>
                        <div
                          className="row g-0 mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <p
                            className="col-12"
                            style={{
                              fontFamily: "inter",
                              fontSize: 16,
                              color: "#434343",
                              fontWeight: 600,
                              textAlign: "left ",
                            }}
                          >
                            Subject : Network
                          </p>
                        </div>
                        <div
                          className="row g-0 mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <p
                            className=" m-0"
                            style={{
                              fontFamily: "inter",
                              fontSize: 16,
                              color: "#434343",
                              fontWeight: 600,
                            }}
                          >
                            Number of Questions : 50
                          </p>
                        </div>
                        <div
                          className="mt-4"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            Passing Percentage
                          </div>
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 600,
                              color: "#3D70F5",
                              fontSize: 14,
                            }}
                          >
                            {TimePerQuestion}%
                          </div>
                        </div>
                        <div className="">
                          <span>
                            <input
                              className="w-100 input-range"
                              type="range"
                              min="10"
                              max="100"
                              value={TimePerQuestion}
                              onChange={(e) => {
                                setTimePerQuestion(e.target.value);
                              }}
                            />
                          </span>
                        </div>
                        <div className="row">
                          <span
                            className="px-3 text-start"
                            style={{
                              fontFamily: "inter",
                              fontWeight: 600,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            Quiz Schedule
                          </span>
                          <div className="col-4">
                            <div
                              style={{
                                width: "100%",
                                height: 30,
                                backgroundColor: "#EDEDF5",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 5,
                              }}
                            >
                              <FaCalendar />
                              <span
                                style={{
                                  color: "#3C3C3C",
                                  fontWeight: 400,
                                  fontSize: 14,
                                  fontFamily: "inter",
                                  paddingLeft: 10,
                                }}
                              >
                                31-01-2023
                              </span>
                            </div>
                          </div>
                          <div className="col-4">
                            <div
                              style={{
                                width: "100%",
                                height: 30,
                                backgroundColor: "#EDEDF5",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 5,
                              }}
                            >
                              <FiClock />
                              <span
                                style={{
                                  color: "#3C3C3C",
                                  fontWeight: 400,
                                  fontSize: 14,
                                  fontFamily: "inter",
                                  paddingLeft: 10,
                                }}
                              >
                                31-01-2023
                              </span>
                            </div>
                          </div>
                        </div>
                        <p
                          className="py-2"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            fontWeight: 600,
                            color: " #303030",
                          }}
                        >
                          Tags Composition
                        </p>
                        <div
                          className="mt-4"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            Current Affairs
                          </div>
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 600,
                              color: "#3D70F5",
                              fontSize: 14,
                            }}
                          >
                            {affairs3}%
                          </div>
                        </div>
                        <div className="">
                          <span>
                            <input
                              className="w-100 input-range"
                              type="range"
                              min="10"
                              max="100"
                              value={affairs3}
                              onChange={(e) => {
                                setAffairs3(e.target.value);
                              }}
                            />
                          </span>
                        </div>
                        <div
                          className="mt-4"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            Logical Reasoning
                          </div>
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 600,
                              color: "#3D70F5",
                              fontSize: 14,
                            }}
                          >
                            {reasoning3}%
                          </div>
                        </div>
                        <div className="">
                          <span>
                            <input
                              className="w-100 input-range"
                              type="range"
                              min="10"
                              max="100"
                              value={reasoning3}
                              onChange={(e) => {
                                setReasoning3(e.target.value);
                              }}
                            />
                          </span>
                        </div>
                        <div
                          className="mt-4"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            Basic Computers
                          </div>
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 600,
                              color: "#3D70F5",
                              fontSize: 14,
                            }}
                          >
                            {computer3}%
                          </div>
                        </div>
                        <div className="">
                          <span>
                            <input
                              className="w-100 input-range"
                              type="range"
                              min="10"
                              max="100"
                              value={computer3}
                              onChange={(e) => {
                                setComputer3(e.target.value);
                              }}
                            />
                          </span>
                        </div>
                        <div
                          className="mt-4"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            Basic Science
                          </div>
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 600,
                              color: "#3D70F5",
                              fontSize: 14,
                            }}
                          >
                            {science3}%
                          </div>
                        </div>
                        <div className="">
                          <span>
                            <input
                              className="w-100 input-range"
                              type="range"
                              min="10"
                              max="100"
                              value={science3}
                              onChange={(e) => {
                                setScience3(e.target.value);
                              }}
                            />
                          </span>
                        </div>
                        <div className="row">
                          <ul className="list-group text-start border-0">
                            {quizrules.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  className="list-group-item d-flex align-items-center border-0"
                                  style={{
                                    fontFamily: "inter",
                                    fontWeight: 400,
                                    fontSize: 12,
                                  }}
                                >
                                  {" "}
                                  <div
                                    className="me-2"
                                    style={{
                                      width: 7,
                                      height: 7,
                                      backgroundColor: "#000",
                                      borderRadius: 10,
                                    }}
                                  ></div>
                                  {item.rules}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="row g-0 my-3">
                          <div
                            className="col-7"
                            style={{
                              height: 40,
                              backgroundColor: "#F1F1F1",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "inter",
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#707070",
                              }}
                            >
                              Time per Question : 75 Second
                            </span>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setGo(go - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setGo(go + 1);
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Confirm
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: go === 7 ? "block" : "none" }}>
                <div className="row mt-5">
                  <p
                    className="col-8 m-auto mt-5"
                    style={{
                      fontFamily: "inter",
                      fontSize: 30,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Quiz Created Successfully
                  </p>
                  <p
                    className="col-8 m-auto mt-3"
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    New Quiz with in B.Tech spcl. in Health Informatics with
                    subject Network Engineering Added Successfully with the
                    following Tags : Mathematics, English, Hindi
                  </p>
                </div>
                <div className="row mt-4">
                  <div className="col-4 m-auto">
                    <div
                      style={{
                        width: "100%",
                        height: 35,
                        backgroundColor: "#3D70F5",
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <NavLink to="/Quizzes" className="text-decoration-none">
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                          }}
                        >
                          Back to Dashboard
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const LiveClass = (props) => {
  return (
    <>
      <div className="col-4">
        <Card className="mt-4" style={{ width: "100%" }}>
          <Card.Img
            variant="top"
            src={props.item.banner}
            style={{ width: "100%" }}
          />
          <Card.Body>
            <Card.Title
              className="text-start"
              style={{ fontFamily: "inter", fontWeight: "600", fontSize: 20 }}
            >
              {props.item.course_name}
            </Card.Title>
            <div className="row g-0">
              <p
                className="col-4 text-start"
                style={{ fontFamily: "inter", fontSize: 10, fontWeight: 600 }}
              >
                Duration : {props.item.duration}
              </p>
              <p
                className="col-4"
                style={{ fontFamily: "inter", fontSize: 10, fontWeight: 600 }}
              >
                Assignments : {props.item.total_enrollments}
              </p>
              <p
                className="col-4"
                style={{ fontFamily: "inter", fontSize: 10, fontWeight: 600 }}
              >
                Upto : ₹ {props.item.raw_price}
              </p>
            </div>
            <div
              className="text-start"
              style={{ fontFamily: "inter", fontWeight: "600", fontSize: 20 }}
            >
              Price : ₹ {props.item.raw_price}{" "}
            </div>
            <span
              className="mt-3"
              onClick={()=>{props.handleShow();
                props.dispatch(props.getParticularpreCoursefun(props.item.id))}}
              style={{
                width: "100%",
                height: 40,
                backgroundColor: "#3D70F5",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
              >
                View Details
              </span>
            </span>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
