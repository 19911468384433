export const getscheduleassignment = 'get/scheduleassignment';
export const gethistoryassignment = 'get/historyassignment';
export const gethistoryassignment_search = 'get/historyassignment_search';
export const getscheduleassignment_search = 'get/scheduleassignment_search';
export const getscheduleassignmentbyid = 'get/scheduleassignmentbyid';

export const add_assignment_loading  = 'add/assignmetn/loading'
export const add_assignment_error  = 'add/assignmetn/error'
export const add_assignment_success  = 'add/assignmetn/success'

export const get_assignment_result_success  = 'get/assignmetn/result/success'
export const get_assignment_result_error  = 'get/assignmetn/result/error'
export const get_assignment_result_loading  = 'get/assignmetn/result/loading'


export const get_studentprofile_success  = 'get/studentprofile/success'
export const get_studentprofile_error  = 'get/studentprofile/error'
export const get_studentprofile_loading  = 'get/studentprofile/loading'