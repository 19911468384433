
import { add_course_Error, add_course_Loading, add_course_Success, get_course_Error, get_course_Loading, get_course_Success, get_course_Success_search, get_particular_course_Error, get_particular_course_Loading, get_particular_course_Success, } from "./prerecordtype";

const initState = {
  isLoading: false,
  isError: false,
  addprecordcourse:[],
  prerecordCourse:[],
  prerecordCourse_search:[],
  particular_prerecordCourse:{},
  
};

export const prerecordReducer = (state = initState, { type, payload }) => {
  switch (type) {
       case add_course_Loading:{
            return{
                isLoading:true,
                isError :false,
            }
        }
       case add_course_Error:{
            return{
                isLoading:false,
                isError :true,
            }
        }
       case add_course_Success:{
            return{
                isLoading:false,
                isError :false,
                addprecordcourse:payload
            }
        }
       case get_course_Loading:{
            return{
                isLoading:true,
                isError :false,
            }
        }
       case get_course_Error:{
            return{
                isLoading:false,
                isError :true,
            }
        }
       case get_course_Success:{
            return{
                isLoading:false,
                isError :false,
                prerecordCourse:payload,
                prerecordCourse_search:payload,
            }
        }
        case get_course_Success_search:{
            return {
                ...state,
                prerecordCourse_search: [
                  ...state.prerecordCourse.filter((el) =>
                  el.course_name.toLowerCase().includes(payload.toLowerCase())
                  ),
                ],
              };
        }
        case get_particular_course_Loading:{
            return{
                isLoading:true,
                isError :false,
            }
        }
       case get_particular_course_Error:{
            return{
                isLoading:false,
                isError :true,
            }
        }
       case get_particular_course_Success:{
            return{
                isLoading:false,
                isError :false,
                particular_prerecordCourse:payload,
               
            }
        }
        default:{
            return state
        }
  }
};
