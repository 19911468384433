import React from "react";
import Well from "../images/well.png";
import User from "../images/user.png";
import { FaUser } from "react-icons/fa";
import { BiHistory, BiLogOutCircle } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import { LogoutFun } from "../redux/auth/authActions";
import { useDispatch } from "react-redux";

export default function Header() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: 65,
          backgroundColor: "#2C62EE",
          padding: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="container-fluid">
          <div className="row g-0 p-0 m-0 d-flex justify-content-between">
            <div className="col-8">
              <div
                style={{
                  width: "100%",
                  height: 80,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  className=""
                  style={{
                    fontWeight: 400,
                    fontSize: 20,
                    color: "#fff",
                    fontFamily: "inter",
                  }}
                >
                  Hii,{localStorage.getItem("name")}
                </span>
              </div>
            </div>
            <div className="col-2 d-flex justify-content-between">
              <div
                style={{
                  width: "70%",
                  height: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: 40,
                    height: 40,
                    backgroundColor: "#ffffff30",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 50,
                  }}
                >
                  <img src={Well} className="" style={{ width: "50%" }} alt="img" />
                  <div
                    style={{
                      position: "absolute",
                      width: 10,
                      height: 10,
                      backgroundColor: "#FF6C2D",
                      borderRadius: 10,
                      top: 5,
                      left: 22,
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: 60,
                    height: 35,
                    backgroundColor: "#ffffff30",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 40,
                  }}
                >
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle border-0 text-white"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={User} className="" style={{ width: "65%" }} alt="user img"/>
                    </button>
                    <ul className="dropdown-menu">
                      <NavLink
                        to="/myprofile"
                        className="text-decoration-none text-dark"
                      >
                        <li>
                          <div
                            className="row g-0 px-2 pb-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="col-3"
                              style={{
                                width: 30,
                                height: 30,
                                backgroundColor: "#FAEDFF",
                                borderRadius: 50,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FaUser style={{ color: "#9D1CCF" }} />
                            </div>
                            <div
                              className="col-9 px-2"
                              style={{
                                fontFamily: "inter",
                                fontWeight: "500",
                                fontSize: 14,
                              }}
                            >
                              My Profile
                            </div>
                          </div>
                        </li>
                      </NavLink>
                      <NavLink
                        to="/LiveClasses"
                        className="text-decoration-none text-dark"
                      >
                        <li>
                          <div
                            className="row g-0 px-2 pb-2"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="col-3"
                              style={{
                                width: 30,
                                height: 30,
                                backgroundColor: "#EEEDFF",
                                borderRadius: 50,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <BiHistory
                                style={{ color: "#4B1CCF", fontSize: 20 }}
                              />
                            </div>
                            <div
                              className="col-9 px-2"
                              style={{
                                fontFamily: "inter",
                                fontWeight: "500",
                                fontSize: 14,
                              }}
                            >
                              Class History
                            </div>
                          </div>
                        </li>
                      </NavLink>
                      
                      <li>
                        <div
                          onClick={() => {
                            dispatch(LogoutFun(Navigate))
                            
                          }}
                          className="row g-0 px-2"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="col-3"
                            style={{
                              width: 30,
                              height: 30,
                              backgroundColor: "#FFEDED",
                              borderRadius: 50,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <BiLogOutCircle
                              style={{ color: "#F93333", fontSize: 20 }}
                            />
                          </div>
                          <div
                            className="col-9 px-2"
                            style={{
                              fontFamily: "inter",
                              fontWeight: "500",
                              fontSize: 14,
                            }}
                          >
                            Log Out
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
