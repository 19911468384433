import { useState, useEffect } from "react";
import { MdModeEdit } from "react-icons/md";
import { BsCloudDownload } from "react-icons/bs";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ImArrowLeft2 } from "react-icons/im";
import { HiPlus } from "react-icons/hi";
import Button from "react-bootstrap/Button";
import { BiImageAlt } from "react-icons/bi";
import { HiCheck } from "react-icons/hi";
import Card from "react-bootstrap/Card";
import { Delete } from "./Svgicon";
import Lottie from "lottie-react";
import { NavLink } from "react-router-dom";
import animationData from "../images/graphmobile.json";
import fileData from "../images/file.json";
import humbsuBirdie from "../images/thumbs-up-birdie.json";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import { RiCloseFill } from "react-icons/ri";
import { API_URL } from "../env";
import { Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Group from "../images/Group 66.png";
import Any from "../images/Any.json";
import Firstt from "../images/79952-successful.json";
import searchLotti from "../images/27564-profile-searching.json";
import {
  addquestionByExcelFun,
  addSinglequestionFun,
  deleteQuestionFun,
} from "../redux/quizzes/quizzesActions";

export default function TagDetail({ ...props }) {
  const { topicdata_byid } = useSelector((store) => store.quizzes);

  const dispatch = useDispatch();

  console.log(topicdata_byid, "topicdata_byid");

  const navigate = useNavigate();
  // const {
  //   course_name,
  //   topic_name,
  //   subject_name,
  //   Number_of_questions,
  //   questions,
  //   tag_id,
  // } = props.tagbyid;

  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  const [optionButton, setOptionButton] = useState(0);
  const [textOption1, setTextOption1] = useState(null);
  const [textOption2, setTextOption2] = useState(null);
  const [textOption3, setTextOption3] = useState(null);
  const [textOption4, setTextOption4] = useState(null);

  console.log(
    textOption1,
    "textOption1",
    textOption2,
    "textOption2",
    textOption3,
    "textOption3",
    optionButton
  );
  const [state, setState] = useState(0);
  const [state2, setState2] = useState(0);

  const [excelfile, setExcelFile] = useState({ name: "" });
  console.log(excelfile, "excelfile");
  const [file, setFile] = useState(null);

  console.log(file, "question file ");
  const [fileprev, setFileprev] = useState(null);
  console.log(fileprev, "question file ");

  const [click, setclick] = useState(false);
  const [click2, setclick2] = useState(false);
  const [Hadding, setHadding] = useState("Please Wait While We");
  const [Hadding1, setHadding1] = useState("Analyse Your Questions");
  const [Pra, setPra] = useState("Do not referesh or Close this Screen");
  const [Lotties, setLotties] = useState("");
  const [Da, setDa] = useState(0);

  const [text, setText] = useState("");

  const [ans, setAns] = useState(null);
  console.log(text, "text");
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);

  const [inputBox, setInputBox] = useState([]);

  const question_sr = ["a", "b", "c", "d"];

  //function for inputlist validation
  const InputListvalidation = () => {
    if (inputList?.length === 0) return false;
    for (let i = 0; i < inputList?.length; i++) {
      if (inputList[i]?.length <= 1) {
        toast(`Please filled option ${question_sr[i]}`);
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  var percentage = 50;

  click ? (
    setTimeout(() => {
      if (Da === 0) {
        setPra("Do not referesh or Close this Screen");
        setHadding("Please wait, while we");
        setHadding1("Translate Questions");
        setLotties(animationData);
        setDa(1);
        // setTimeout
      } else if (Da === 1) {
        setPra("This can take a while, do not refresh or close this tab.");
        setHadding("Questions Analysed");
        setHadding1("Successfully, Preparing Report");
        setLotties(fileData);
        setDa(2);
      } else {
        setState(state + 1);

        setclick(false);
      }
    }, 3000)
  ) : (
    <></>
  );

  const [first, setfirst] = useState();

  const countdownTimer = (count) => {
    if (count > 0) {
      setTimeout(() => {
        countdownTimer(count - 1);
        setfirst(count - 1);
      }, 1000);
    } else {
      setState(0);
      setText("");
      // setAns([]);
      setInputList([]);
    }
  };

  function handleChange(e) {
    console.log("file", e.target.files);
    setFileprev(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  }

  // const Hans = () => {
  //   var update = [...inputList];
  //   update = [...inputList, " "];
  //   setInputList(update);
  // };

  // const Hans2 = () => {
  //   var update = [...inputList2];
  //   update = [...inputList2, " "];
  //   setInputList2(update);
  // };

  // const Addmore = () => {
  //   var update = [...inputBox];
  //   update = [...inputBox, "option"];
  //   setInputBox(update);
  // };

  const deleteInputBox = (key) => {
    const updatedInputBox = inputBox.filter((_, index) => index !== key);
    setInputBox(updatedInputBox);
  };

  const deleteInputList = (questionIndex, inputIndex) => {
    const updatedInputBoxList = [...inputList];
    updatedInputBoxList.splice(inputIndex, 1);
    setInputList(updatedInputBoxList);
  };

  return (
    <>
      <Offcanvas show={props.show} placement="end" style={{ width: "100%" }}>
        <Modal size="sm" show={show2} onHide={handleClose2}>
          <Modal.Body
            style={{ textAlign: "center", width: "280px", alignSelf: "center" }}
          >
            Tag <b>{"topic_name"}</b> will be Delete{" "}
          </Modal.Body>
          <div
            style={{ display: "flex", justifyContent: "space-around" }}
            className="pb-5"
          >
            <Button
              variant="danger"
              onClick={() => {
                handleClose2();
                // props.deleteTag(tag_id);
                props.handleClose();
                setState(0);
              }}
            >
              Yes,Delete it
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleClose2();
              }}
            >
              Don't Delete
            </Button>
          </div>
        </Modal>
        <div
          className="row px-4"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              props.handleClose();
              setState(0);
              setState2(0);
              setText("");
              setAns(null);
              setOptionButton(0);
              setTextOption1(null);
              setTextOption2(null);
              setTextOption3(null);
              setTextOption4(null);
              setInputList([]);
              setFile(null);
              setFileprev(null);
              setExcelFile({ name: "" });
            }}
            className="col-3"
            style={{
              width: 40,
              height: 40,
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <ImArrowLeft2 style={{ fontSize: 24 }} />
          </div>
        </div>
        <Offcanvas.Body>
          <div className="container-fluid px-5">
            <div style={{ display: state === 0 ? "block" : "none" }}>
              <div
                className="row"
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className="col-4 text-start"
                  style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
                >
                  Tag Details
                </p>
                <div className="col-8 me-auto d-flex justify-content-end">
                  {/*<button
                    className="btn"
                    style={{
                      height: 30,
                      // backgroundColor: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      color: "#3D70F5",
                      fontSize: 14,
                      fontFamily: "inter",
                      fontWeight: 400,
                      border: "none",
                    }}
                    onClick={handleShow2}
                  >
                    <span
                      style={{
                        border: "2px solid red",
                        backgroundColor: "#fff",
                        borderRadius: 5,
                        padding: "3px 10px",
                        color: "red",
                        fontSize: 14,
                        fontFamily: "inter",
                        fontWeight: 400,
                      }}
                    >
                      <MdOutlineDeleteOutline style={{ fontSize: 18 }} /> Delete
                      Tag
                    </span>
                  </button>
                  <button
                    className="btn mx-2"
                    style={{
                      height: 30,
                      backgroundColor: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      color: "#3D70F5",
                      fontSize: 14,
                      fontFamily: "inter",
                      fontWeight: 400,
                      border: "none",
                    }}
                  >
                    <span
                      style={{
                        border: "2px solid #3D70F5",
                        backgroundColor: "#fff",
                        padding: "3px 10px",
                        borderRadius: 5,
                        color: "#3D70F5",
                        fontSize: 14,
                        fontFamily: "inter",
                        fontWeight: 400,
                      }}
                    >
                      <MdOutlineModeEditOutline style={{ fontSize: 18 }} /> Edit
                      Tag
                    </span>
                    </button>*/}
                  <button
                    type="button"
                    onClick={() => {
                      setState(state + 1);
                      setState2(state2 + 1);
                    }}
                    className="btn"
                    style={{
                      height: 30,
                      backgroundColor: "#3D70F5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      color: "#fff",
                      fontSize: 14,
                      fontFamily: "inter",
                      fontWeight: 400,
                      border: "none",
                    }}
                  >
                    +Add Questions
                  </button>
                </div>
              </div>
              <div className="row text-start">
                <div
                  className="col-12"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    color: "#474747",
                    fontWeight: 400,
                  }}
                >
                  Name of Tag : <span>{topicdata_byid?.topic_name}</span>{" "}
                </div>
                <div
                  className="col-12"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    color: "#474747",
                    fontWeight: 400,
                  }}
                >
                  Number of questions :{" "}
                  <span style={{ color: "#FF0E0E" }}>
                    {topicdata_byid?.Number_of_questions}
                  </span>{" "}
                </div>
              </div>
              <div className="row mt-4 text-start">
                <div
                  className="col-12"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    color: "#474747",
                    fontWeight: 400,
                  }}
                >
                  Course : <span>{topicdata_byid?.course_name}</span>{" "}
                </div>
                <div
                  className="col-12"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    color: "#474747",
                    fontWeight: 400,
                  }}
                >
                  Subject :{" "}
                  <span style={{}}>{topicdata_byid?.subject_name}</span>{" "}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-8 me-auto">
                  <div className="d-flex justify-content-between">
                    <p
                      className="p-0 m-0 g-0"
                      style={{
                        fontFamily: "inter",
                        fontSize: 18,
                        fontWeight: 400,
                      }}
                    >
                      Questions
                    </p>
                    <p
                      className="p-0 m-0 g-0"
                      style={{
                        fontFamily: "inter",
                        fontSize: 18,
                        fontWeight: 400,
                      }}
                    >
                      Action
                    </p>
                  </div>
                  <Card className="rounded-1">
                    <div className="bar-question">
                      {topicdata_byid?.questions &&
                        topicdata_byid?.questions.map((item, index) => {
                          return (
                            <>
                              <QuestionList
                                item={item}
                                keys={index}
                                question_sr={question_sr}
                                // deleteQuestion={props.deleteQuestion}
                                deleteQuestionFun={deleteQuestionFun}
                                dispatch={dispatch}
                              />
                            </>
                          );
                        })}
                    </div>
                  </Card>
                </div>
              </div>
            </div>

            <div
              style={{
                display: state === 1 && state2 === 1 ? "block" : "none",
              }}
            >
              <div className="row d-flex justify-content-between">
                <p
                  className="col-5 text-start"
                  style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
                >
                  Add New Question
                </p>
              </div>
              <p
                className="text-start"
                style={{ fontFamily: "inter", fontSize: 16, fontWeight: 400 }}
              >
                Tag : {topicdata_byid?.topic_name}{" "}
              </p>
              <hr />
              <div className="row">
                <div className="col-5">
                  <button
                    type="button"
                    onClick={() => {
                      setState(state + 1);
                      // setState("singleQuestion")
                    }}
                    className="btn col-4 ms-auto"
                    style={{
                      height: 70,
                      width: 230,
                      backgroundColor: "#3D70F5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      color: "#fff",
                      fontSize: 14,
                      fontFamily: "inter",
                      fontWeight: 400,
                      border: "none",
                    }}
                  >
                    Add single Question
                  </button>
                </div>
                <div className="col-5">
                  <button
                    type="button"
                    onClick={() => {
                      // setState(state + 2);
                      setState2(state2 + 1);
                    }}
                    className="btn col-4 ms-auto"
                    style={{
                      height: 70,
                      width: 230,
                      backgroundColor: "#3D70F5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      color: "#fff",
                      fontSize: 14,
                      fontFamily: "inter",
                      fontWeight: 400,
                      border: "none",
                    }}
                  >
                    Excel file upload
                  </button>
                </div>
              </div>
            </div>
            <div style={{ display: state === 2 ? "block" : "none" }}>
              <div className="row d-flex justify-content-between">
                <p
                  className="col-5 text-start"
                  style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
                >
                  Add New Question
                </p>
                <div className="col-5">
                  <button
                    type="button"
                    onClick={() => {
                      text?.length === 0
                        ? toast("Please Enter Question")
                        : ans === null
                        ? toast("please select answer")
                        : textOption1 === null ||
                          textOption2 === null ||
                          textOption3 === null ||
                          textOption4 === null
                        ? toast("please fill option ")
                        : setState(state + 1) || setclick(true);
                    }}
                    className="btn col-4 ms-auto"
                    style={{
                      height: 30,
                      backgroundColor: "#3D70F5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      color: "#fff",
                      fontSize: 14,
                      fontFamily: "inter",
                      fontWeight: 400,
                      border: "none",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
              <p
                className="text-start"
                style={{ fontFamily: "inter", fontSize: 16, fontWeight: 400 }}
              >
                Tag : {topicdata_byid?.topic_name}{" "}
              </p>
              <hr />
              <div className="bar-option">
                <div className="row p-0 mb-4">
                  <div className="col-6">
                    <Card className="">
                      <div className="row pe-2 g-0 d-flex align-items-center">
                        <div className="col-10">
                          <div className="input-group flex-nowrap">
                            <input
                              type="text"
                              className="form-control m-2"
                              placeholder="Type your question here"
                              aria-label="Username"
                              aria-describedby="addon-wrapping"
                              value={text}
                              style={{
                                borderRadius: 0,
                                border: "none",
                                backgroundColor: "#F1F1F1",
                                boxShadow: "none",
                              }}
                              onChange={(e) => setText(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-2 d-flex justify-content-between">
                          <label>
                            <div
                              onChange={handleChange}
                              style={{
                                width: 40,
                                height: 40,
                                backgroundColor: "#F8EEFF",
                                borderRadius: 50,
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <input type="file" hidden />
                              <BiImageAlt style={{ fontSize: 20 }} />
                            </div>
                          </label>
                          <button
                            type="button"
                            className="btn"
                            style={{
                              width: 40,
                              height: 40,
                              backgroundColor: "#F3F3F3",
                              borderRadius: 50,
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Delete />
                          </button>
                        </div>
                      </div>
                      <div className="row pe-2 g-0 pt-2">
                        <div className="col-12">
                          <div className="row g-0">
                            <div className="col-12">
                              {fileprev === null ? (
                                <></>
                              ) : (
                                <img
                                  src={fileprev}
                                  alt="img"
                                  height="50px"
                                  width="50px"
                                  className="ms-4"
                                />
                              )}
                              {optionButton === 0 ? (
                                <div>
                                  <div className="row ps-2 g-0">
                                    <div className="col-10 d-flex align-items-center my-1">
                                      <input
                                        value={1}
                                        name="ans"
                                        onChange={(e) => {
                                          setAns(e.target.value);
                                        }}
                                        className="me-2 form-check-input"
                                        type="radio"
                                      />
                                      <input
                                        value={textOption1}
                                        onChange={(e) =>
                                          setTextOption1(e.target.value)
                                        }
                                        className="me-2 w-100"
                                        type="text"
                                      />
                                    </div>
                                    <div className="col-10 d-flex align-items-center my-1">
                                      <input
                                        value={2}
                                        name="ans"
                                        onChange={(e) => {
                                          setAns(e.target.value);
                                        }}
                                        className="me-2 form-check-input"
                                        type="radio"
                                      />
                                      <input
                                        value={textOption2}
                                        onChange={(e) =>
                                          setTextOption2(e.target.value)
                                        }
                                        className="me-2 w-100"
                                        type="text"
                                      />
                                    </div>
                                    <div className="col-10 d-flex align-items-center my-1">
                                      <input
                                        value={3}
                                        name="ans"
                                        onChange={(e) => {
                                          setAns(e.target.value);
                                        }}
                                        className="me-2 form-check-input"
                                        type="radio"
                                      />
                                      <input
                                        value={textOption3}
                                        onChange={(e) =>
                                          setTextOption3(e.target.value)
                                        }
                                        className="me-2 w-100"
                                        type="text"
                                      />
                                    </div>
                                    <div className="col-10 d-flex align-items-center my-1">
                                      <input
                                        value={4}
                                        name="ans"
                                        onChange={(e) => {
                                          setAns(e.target.value);
                                        }}
                                        className="me-2 form-check-input"
                                        type="radio"
                                      />
                                      <input
                                        value={textOption4}
                                        onChange={(e) =>
                                          setTextOption4(e.target.value)
                                        }
                                        className="me-2 w-100"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="row ps-2 g-0">
                                    <div className="col-10 d-flex align-items-center my-1">
                                      <input
                                        value={1}
                                        name="ans"
                                        onChange={(e) => {
                                          setAns(e.target.value);
                                        }}
                                        className="me-2 form-check-input"
                                        type="radio"
                                      />
                                      <label>
                                        <div
                                          onChange={(e) => {
                                            setTextOption1(e.target.files[0]);
                                          }}
                                          style={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: "#F8EEFF",
                                            borderRadius: 50,
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <input type="file" hidden />
                                          <BiImageAlt
                                            style={{ fontSize: 20 }}
                                          />
                                        </div>
                                      </label>
                                    </div>
                                    <div className="col-10 d-flex align-items-center my-1">
                                      <input
                                        value={2}
                                        name="ans"
                                        onChange={(e) => {
                                          setAns(e.target.value);
                                        }}
                                        className="me-2 form-check-input"
                                        type="radio"
                                      />
                                      <label>
                                        <div
                                          onChange={(e) => {
                                            setTextOption2(e.target.files[0]);
                                          }}
                                          style={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: "#F8EEFF",
                                            borderRadius: 50,
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <input type="file" hidden />
                                          <BiImageAlt
                                            style={{ fontSize: 20 }}
                                          />
                                        </div>
                                      </label>
                                    </div>
                                    <div className="col-10 d-flex align-items-center my-1">
                                      <input
                                        value={3}
                                        name="ans"
                                        onChange={(e) => {
                                          setAns(e.target.value);
                                        }}
                                        className="me-2 form-check-input"
                                        type="radio"
                                      />
                                      <label>
                                        <div
                                          onChange={(e) => {
                                            setTextOption3(e.target.files[0]);
                                          }}
                                          style={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: "#F8EEFF",
                                            borderRadius: 50,
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <input type="file" hidden />
                                          <BiImageAlt
                                            style={{ fontSize: 20 }}
                                          />
                                        </div>
                                      </label>
                                    </div>
                                    <div className="col-10 d-flex align-items-center my-1">
                                      <input
                                        value={4}
                                        name="ans"
                                        onChange={(e) => {
                                          setAns(e.target.value);
                                        }}
                                        className="me-2 form-check-input"
                                        type="radio"
                                      />
                                      <label>
                                        <div
                                          onChange={(e) => {
                                            setTextOption4(e.target.files[0]);
                                          }}
                                          style={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: "#F8EEFF",
                                            borderRadius: 50,
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <input type="file" hidden />
                                          <BiImageAlt
                                            style={{ fontSize: 20 }}
                                          />
                                        </div>
                                      </label>
                                    </div>
                                    {/*<div className="col-2 d-flex justify-content-between">
                                <label>
                                  <div
                                    onChange={(e)=>{handleChange}}
                                    style={{
                                      width: 40,
                                      height: 40,
                                      backgroundColor: "#F8EEFF",
                                      borderRadius: 50,
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <input type="file" hidden />
                                    <BiImageAlt
                                      style={{ fontSize: 20 }}
                                    />
                                  </div>
                                </label>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() => deleteInputList()}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    backgroundColor: "#F3F3F3",
                                    borderRadius: 50,
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Delete />
                                </button>
                                </div>*/}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <hr className="mx-2" />
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ justifyContent: "space-evenly" }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            inputList?.length >= 1
                              ? toast("Maximam Length of Option 4")
                              : setOptionButton(0);
                          }}
                          className=" border-0 col-5"
                          style={{
                            // width: 210,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            backgroundColor: "#F3F6FF",
                            borderRadius: 5,
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "inter",
                              fontSize: 18,
                              fontWeight: 400,
                              color: "#3D70F5",
                            }}
                          >
                            + Add Text Option
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            inputList2?.length >= 1
                              ? toast("Maximam Length of Option 4")
                              : setOptionButton(1);
                          }}
                          className=" border-0 col-5"
                          style={{
                            // width: 20,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            backgroundColor: "#F3F6FF",
                            borderRadius: 5,
                            // margin:"23px",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "inter",
                              fontSize: 18,
                              fontWeight: 400,
                              color: "#3D70F5",
                            }}
                          >
                            + Add Image Option
                          </span>
                        </button>
                      </div>
                    </Card>
                  </div>
                  {/*<div className="col-1 p-0">
                    <div style={{ width: "100%", height: 40 }}>
                      <button
                        type="button"
                        className="btn"
                        onClick={Addmore}
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#F3F3F3",
                          borderRadius: 50,
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <HiPlus style={{ color: "#696969" }} />
                      </button>
                    </div>
                      </div>*/}
                </div>
              </div>
            </div>
            <div style={{ display: state === 3 ? "block" : "none" }}>
              <div className="row">
                <div className="col-6 m-auto">
                  <div
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Lottie animationData={Lotties} style={{ width: "50%" }} />
                  </div>
                  <p
                    className="mt-3"
                    style={{
                      fontSize: "26px",
                      fontWeight: 600,
                      fontFamily: "inter",
                    }}
                  >
                    {Hadding}
                    <br />
                    {Hadding1}
                  </p>
                  <p
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#8A8A8A",
                    }}
                  >
                    {Pra}
                  </p>
                </div>
              </div>
            </div>
            {/*<div style={{ display: state === 4 ? "block" : "none" }}>
              <div className="row">
                <div className="col-6 m-auto">
                  <div
                    className="mt-5"
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "26px",
                        fontWeight: 600,
                        fontFamily: "inter",
                      }}
                    >
                      Report Generated
                    </p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ width: "20%", alignItems: "center" }}>
                        <CircularProgressbarWithChildren
                          value={percentage}
                          styles={{
                            root: {},
                            path: {
                              stroke: `#367CFF`,
                              strokeWidth: "10px",
                              transition: "stroke-dashoffset 0.5s ease 0s",
                              transform: "rotate(0.50turn)",
                              transformOrigin: "center center",
                            },
                            trail: {
                              stroke: "#FF8E09",
                              strokeWidth: "10px",
                              strokeLinecap: "round",
                              transform: "rotate(0.25turn)",
                              transformOrigin: "center center",
                            },
                            background: {
                              fill: "rgb(16, 227, 163)",
                              backgroundColor: "rgb(16, 227, 163)",
                              strokeWidth: "10px",
                            },
                          }}
                        ></CircularProgressbarWithChildren>
                      </div>
                    </div>
                    <p
                      className="mt-5 fs-500"
                      style={{
                        color: "#303030",
                        fontSize: 16,
                        fontFamily: "inter",
                        fontWeight: 600,
                      }}
                    >
                      Total Questions Uploaded{" "}
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 20,
                          fontWeight: "bolder",
                        }}
                      >
                        5689
                      </span>
                    </p>
                    <div className="row">
                      <div className="col-4"></div>
                      <div className="col-6 d-flex">
                        <span
                          className="col-1 mt-1"
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#367CFF",
                          }}
                        ></span>
                        <p
                          className="col-11"
                          style={{
                            marginLeft: "-40px",
                            fontFamily: "inter",
                            fontSize: 15,
                            fontWeight: 600,
                          }}
                        >
                          Unique Questions{" "}
                          <span style={{ color: "#367CFF" }}>4852</span>
                        </p>
                      </div>
                      <div className="col-4"></div>
                      <div className="col-6 d-flex">
                        <span
                          className="col-1 mt-1"
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "#FF8E09",
                          }}
                        ></span>
                        <p
                          className="col-11"
                          style={{
                            marginLeft: "-40px",
                            fontFamily: "inter",
                            fontSize: 15,
                            fontWeight: 600,
                          }}
                        >
                          Repeated Questions{" "}
                          <span style={{ color: "#FF8E09" }}>2785</span>
                        </p>
                      </div>
                    </div>
                    <div className="mt-5">
                      <button
                        onClick={() => {
                          setState(state + 1);
                        }}
                        className="border-0"
                        style={{
                          color: "#fff",
                          backgroundColor: "#2188E7",
                          borderRadius: 7,
                          width: "250px",
                          height: "40px",
                          fontFamily: "inter",
                          fontSize: 15,
                          fontWeight: 600,
                        }}
                      >
                        + Preview Questions
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: state === 5 ? "block" : "none" }}>
              <div className="row d-flex justify-content-between">
                <p
                  className="col-5 text-start"
                  style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
                >
                  Add New Question
                </p>
                <div className="col-4">
                  <button
                    type="button"
                    onClick={() => {
                      setState(state + 1);
                    }}
                    className="btn col-4 ms-auto"
                    style={{
                      height: 30,
                      backgroundColor: "#3D70F5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      color: "#fff",
                      fontSize: 14,
                      fontFamily: "inter",
                      fontWeight: 400,
                      border: "none",
                    }}
                  >
                    Save & Publish
                  </button>
                </div>
              </div>
              <p
                className="text-start"
                style={{ fontFamily: "inter", fontSize: 16, fontWeight: 400 }}
              >
                Tag : {topicdata_byid?.topic_name}
              </p>
              <hr />
              <div className="row">
                <div className="col-6 me-auto">
                  <Card className="border-0">
                    <div
                      className="px-2"
                      style={{
                        width: "100%",
                        height: 60,
                        backgroundColor: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#fff",
                          borderRadius: 50,
                          border: "1px solid #D9D9D9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setState(state - 3)}
                      >
                        <ImArrowLeft2
                          style={{ fontSize: 20, color: "#8A8A8A" }}
                        />
                      </div>
                      <div
                        className="text-start px-4"
                        style={{
                          fontFamily: "inter",
                          fontSize: 24,
                          fontWeight: 600,
                        }}
                      >
                        Preview Questions
                      </div>
                    </div>
                    <div className="barque">
                      <div className="row">
                        <div className="col-12" style={{}}>
                          <div
                            className="row px-2 mt-3"
                            style={{
                              height: 40,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              className="col-4 text-start"
                              style={{
                                fontFamily: "inter",
                                fontSize: 16,
                                fontWeight: 400,
                              }}
                            >
                              Q 1.
                            </p>
                            <div className="col-8 me-auto d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn"
                                style={{
                                  height: 30,
                                  backgroundColor: "#3D70F5",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 5,
                                  color: "#fff",
                                  fontSize: 14,
                                  fontFamily: "inter",
                                  fontWeight: 400,
                                  border: "none",
                                }}
                                onClick={() => setState(state - 3)}
                              >
                                <MdOutlineModeEditOutline
                                  style={{ fontSize: 16 }}
                                />{" "}
                                Edit
                              </button>

                              {/*<button
                                type="button"
                                className="btn mx-2"
                                style={{
                                  height: 30,
                                  backgroundColor: "#fff",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 5,
                                  color: "#3D70F5",
                                  fontSize: 14,
                                  fontFamily: "inter",
                                  fontWeight: 400,
                                  border: "1px solid #3D70F5",
                                }}
                              >
                                <RiDeleteBin6Line style={{ fontSize: 16 }} />
                                Delete
                              </button>
                            </div>
                          </div>
                          <p
                            className="px-2 text-start"
                            style={{
                              fontFamily: "inter",
                              fontSize: 16,
                              color: "#474747",
                              fontWeight: 400,
                            }}
                          >
                            {text}
                          </p>{
                            file === null?<></>:<img src="../images/backimg.png" alt="img" height="70px" width="70px"/>
                          }
                          
                          {optionButton === 0 ? (
                            <div className="row px-3">
                              <div className="col-12">
                                <div
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid #DADADA",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      height: 35,
                                      borderRadius: 0,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#474747",
                                      }}
                                    >
                                      (a)
                                    </div>
                                    <span
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 400,
                                        color: "#474747",
                                      }}
                                    >
                                      {textOption1}
                                    </span>
                                  </div>
                                  {ans == 1 ? (
                                    <button
                                      type="button"
                                      className="btn mx-2"
                                      style={{
                                        height: 30,
                                        backgroundColor: "#E9FFF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        color: "#2E8760",
                                        fontSize: 12,
                                        fontFamily: "inter",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <HiCheck style={{ fontSize: 18 }} />
                                      Marked as Correct
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid #DADADA",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      height: 35,
                                      borderRadius: 0,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#474747",
                                      }}
                                    >
                                      (b)
                                    </div>
                                    <span
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 400,
                                        color: "#474747",
                                      }}
                                    >
                                      {textOption2}
                                    </span>
                                  </div>
                                  {ans == 2 ? (
                                    <button
                                      type="button"
                                      className="btn mx-2"
                                      style={{
                                        height: 30,
                                        backgroundColor: "#E9FFF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        color: "#2E8760",
                                        fontSize: 12,
                                        fontFamily: "inter",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <HiCheck style={{ fontSize: 18 }} />
                                      Marked as Correct
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid #DADADA",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      height: 35,
                                      borderRadius: 0,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#474747",
                                      }}
                                    >
                                      (c)
                                    </div>
                                    <span
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 400,
                                        color: "#474747",
                                      }}
                                    >
                                      {textOption3}
                                    </span>
                                  </div>
                                  {ans == 3 ? (
                                    <button
                                      type="button"
                                      className="btn mx-2"
                                      style={{
                                        height: 30,
                                        backgroundColor: "#E9FFF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        color: "#2E8760",
                                        fontSize: 12,
                                        fontFamily: "inter",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <HiCheck style={{ fontSize: 18 }} />
                                      Marked as Correct
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid #DADADA",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      height: 35,
                                      borderRadius: 0,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#474747",
                                      }}
                                    >
                                      (d)
                                    </div>
                                    <span
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 400,
                                        color: "#474747",
                                      }}
                                    >
                                      {textOption4}
                                    </span>
                                  </div>
                                  {ans == 4 ? (
                                    <button
                                      type="button"
                                      className="btn mx-2"
                                      style={{
                                        height: 30,
                                        backgroundColor: "#E9FFF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        color: "#2E8760",
                                        fontSize: 12,
                                        fontFamily: "inter",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <HiCheck style={{ fontSize: 18 }} />
                                      Marked as Correct
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row px-3">
                              <div className="col-12">
                                <div
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid #DADADA",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      height: 35,
                                      borderRadius: 0,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#474747",
                                      }}
                                    >
                                      (a)
                                    </div>
                                    <img src={textOption1} alt="img" />
                                  </div>
                                  {ans == 1 ? (
                                    <button
                                      type="button"
                                      className="btn mx-2"
                                      style={{
                                        height: 30,
                                        backgroundColor: "#E9FFF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        color: "#2E8760",
                                        fontSize: 12,
                                        fontFamily: "inter",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <HiCheck style={{ fontSize: 18 }} />
                                      Marked as Correct
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid #DADADA",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      height: 35,
                                      borderRadius: 0,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#474747",
                                      }}
                                    >
                                      (b)
                                    </div>
                                    <img src={textOption2} alt="img" />
                                  </div>
                                  {ans == 2 ? (
                                    <button
                                      type="button"
                                      className="btn mx-2"
                                      style={{
                                        height: 30,
                                        backgroundColor: "#E9FFF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        color: "#2E8760",
                                        fontSize: 12,
                                        fontFamily: "inter",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <HiCheck style={{ fontSize: 18 }} />
                                      Marked as Correct
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid #DADADA",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      height: 35,
                                      borderRadius: 0,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#474747",
                                      }}
                                    >
                                      (c)
                                    </div>
                                    <img src={textOption3} alt="img" />
                                  </div>
                                  {ans == 3 ? (
                                    <button
                                      type="button"
                                      className="btn mx-2"
                                      style={{
                                        height: 30,
                                        backgroundColor: "#E9FFF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        color: "#2E8760",
                                        fontSize: 12,
                                        fontFamily: "inter",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <HiCheck style={{ fontSize: 18 }} />
                                      Marked as Correct
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid #DADADA",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      height: 35,
                                      borderRadius: 0,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="ps-2"
                                      style={{
                                        fontFamily: "inter",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#474747",
                                      }}
                                    >
                                      (d)
                                    </div>

                                    <img
                                      src={textOption4}
                                      alt="optionimg"
                                      height="50px"
                                      width="50px"
                                    />
                                  </div>
                                  {ans == 4 ? (
                                    <button
                                      type="button"
                                      className="btn mx-2"
                                      style={{
                                        height: 30,
                                        backgroundColor: "#E9FFF5",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        color: "#2E8760",
                                        fontSize: 12,
                                        fontFamily: "inter",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <HiCheck style={{ fontSize: 18 }} />
                                      Marked as Correct
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
                                  </div>*/}
            <div style={{ display: state === 4 ? "block" : "none" }}>
              <div className="row mt-5">
                <div className="col-3 m-auto mt-5">
                  <Card className="border-0 rounded-0 mt-5">
                    <p
                      className="text-center"
                      style={{
                        fontFamily: "inter",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#6D6D6D",
                      }}
                    >
                      Questions will be uploaded to “{" "}
                      <span style={{ color: "#000" }}>
                        {topicdata_byid?.topic_name}
                      </span>
                      ”
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          dispatch(
                            addSinglequestionFun({
                              question: text,
                              option1: textOption1,
                              option2: textOption2,
                              option3: textOption3,
                              option4: textOption4,
                              is_opt_img: optionButton,
                              topic_id: topicdata_byid.tag_id,
                              question_url: file,
                              ans: +ans,
                            },
                            setState,state)
                          );
                        }}
                        className="btn px-4"
                        style={{
                          height: 30,
                          backgroundColor: "#0CBC8B",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          color: "#fff",
                          fontSize: 14,
                          fontFamily: "inter",
                          fontWeight: 600,
                        }}
                      >
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="btn px-5"
                        style={{
                          height: 30,
                          backgroundColor: "#EFEFEF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          color: "#8A8A8A",
                          fontSize: 14,
                          fontFamily: "inter",
                          fontWeight: 600,
                          border: "none",
                        }}
                        onClick={() => setState(state - 3)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            <div style={{ display: state === 5 ? "block" : "none" }}>
              <div className="row mt-5">
                <div className="col-6 m-auto">
                  <Card className="border-0 rounded-0">
                    <p
                      className="text-center"
                      style={{
                        fontFamily: "inter",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#6D6D6D",
                      }}
                    >
                      <span style={{ color: "#000" }}>
                        {"Number_of_questions"}
                      </span>{" "}
                      Questions will be uploaded to “{" "}
                      <span style={{ color: "#000" }}>
                        {topicdata_byid?.topic_name}
                      </span>
                      ”
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        animationData={humbsuBirdie}
                        style={{ width: "50%" }}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="button"
                        className="btn"
                        style={{
                          width: "50%",
                          height: 35,
                          backgroundColor: "#0CBC8B",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          color: "#fff",
                          fontSize: 14,
                          fontFamily: "inter",
                          fontWeight: 600,
                          border: "none",
                        }}
                        onClick={() => {
                          props.handleClose();
                          setState(0);
                          setState2(0);
                          setText("");
                          setAns(null);
                          setOptionButton(0);
                          setTextOption1(null);
                          setTextOption2(null);
                          setTextOption3(null);
                          setTextOption4(null);
                          setDa(0);
                          setFile(null);
                          setFileprev(null);
                        }}
                      >
                        Back to Dashboard
                      </button>
                    </div>
                  </Card>
                </div>
              </div>
            </div>

            {/*Excel file upload start*/}
            <div style={{ display: state2 === 2 ? "block" : "none" }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 m-auto mt-5">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span>
                        <label for="upload-file"> </label>

                        <label>
                          <input
                            type="file"
                            hidden
                            onChange={(e) => {
                              setExcelFile(e.target.files[0]);
                            }}
                          />
                          <img
                            src={Group}
                            alt="img"
                            style={{ width: "100px" }}
                          />
                        </label>
                      </span>
                    </div>
                    <div>
                      <h5 className="text-center my-3">
                        Upload Bulk <br />
                        Questions
                      </h5>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {excelfile?.name?.length === 0 ? (
                          <span className="  " style={{ fontSize: "16px" }}>
                            Download Sample .xlx
                            <BsCloudDownload
                              className="mx-4"
                              style={{ fontSize: "20px", color: "#363673" }}
                            />
                          </span>
                        ) : (
                          <span>{excelfile?.name}</span>
                        )}
                      </div>
                    </div>

                    {/*<div
                  className="mt-5"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div class="form-check d-flex align-item-center">
                    <input
                      // onChange={() => setLan("en")}
                      // value={lan}
                      // checked={lan === "en"}
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      style={{ fontSize: "20px" }}
                    />
                    <label
                      class="form-check-label mx-2"
                      for="flexRadioDefault1"
                      style={{ fontSize: "18px" }}
                    >
                      English
                    </label>
                  </div>
                  <div class="form-check ms-5 d-flex align-item-center">
                    <input
                      // onChange={() => setLan("hi")}
                      // value={lan}
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      // checked={lan === "hi"}
                      style={{ fontSize: "20px" }}
                    />
                    <label
                      class="form-check-label mx-2 "
                      for="flexRadioDefault2"
                      style={{ fontSize: "18px" }}
                    >
                      Hindi
                    </label>
                  </div>
                    </div>*/}

                    <div className="mt-4 d-flex justify-content-center">
                      <button
                      disabled={excelfile?.name === ""}
                        onClick={() => {
                          dispatch(
                            addquestionByExcelFun({
                              topic_id: topicdata_byid.tag_id,
                              exl: excelfile,
                            }
                            , setState2,state2)
                          );
                         
                        }}
                        style={{
                          backgroundColor: "#2188E7",
                          color: "#fff",
                          padding: "8px 20px",
                          border: "none",
                          borderRadius: "8px",
                          fontWeight: "500",
                          width: "80%",
                        }}
                      >
                        + Add Questions
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: state2 === 3 ? "block" : "none" }}>
              <div className="row mt-5">
                <div className="col-6 m-auto">
                  <Card className="border-0 rounded-0">
                    <p
                      className="text-center"
                      style={{
                        fontFamily: "inter",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#6D6D6D",
                      }}
                    >
                      Questions will be uploaded to “{" "}
                      <span style={{ color: "#000" }}>
                        {topicdata_byid?.topic_name}
                      </span>
                      ”
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        animationData={humbsuBirdie}
                        style={{ width: "50%" }}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="button"
                        className="btn"
                        style={{
                          width: "50%",
                          height: 35,
                          backgroundColor: "#0CBC8B",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          color: "#fff",
                          fontSize: 14,
                          fontFamily: "inter",
                          fontWeight: 600,
                          border: "none",
                        }}
                        onClick={() => {
                          props.handleClose();
                          setState2(0);
                          setState(0);
                          setText("");

                          setInputList([]);
                          setExcelFile({ name: "" });
                        }}
                      >
                        Back to Dashboard
                      </button>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const QuestionList = (props) => {
  const optio = ["a", "b", "c", "d"];
  return (
    <div className="row" key={props.item.id}>
      <div className="col-10" style={{ borderRight: "1px solid #DDDDDD" }}>
        <div className="p-3">
          <div
            className="p-0 text-start"
            style={{
              fontFamily: "inter",
              fontSize: 16,
              color: "#474747",
              fontWeight: 400,
            }}
          >
            Q{props.keys + 1}.
          </div>
          <p
            className="p-0 text-start"
            style={{
              fontFamily: "inter",
              fontSize: 16,
              color: "#474747",
              fontWeight: 400,
            }}
          >
            {props.item.question}
          </p>{" "}
          {props.item.is_ques_img === 1 ? (
            <img src={props.item.question_url} alt="img" height="70px" />
          ) : (
            <></>
          )}
        </div>
        <div className="row px-3">
          {props.item.is_opt_img === 0 ? (
            <div className="col-12">
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: 35,
                  border:
                    `${props.item?.ans}` == 1
                      ? "1px solid #0CC818"
                      : "1px solid #DADADA",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    `${props.item?.ans}` == 1 ? "#EFFFF0" : "none",
                }}
              >
                <div
                  className="ps-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  {optio[0]}
                </div>
                <span
                  className="ps-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                >
                  {props.item.option1}
                </span>
              </div>
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: 35,
                  border:
                    `${props.item?.ans}` == 2
                      ? "1px solid #0CC818"
                      : "1px solid #DADADA",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    `${props.item?.ans}` == 2 ? "#EFFFF0" : "none",
                }}
              >
                <div
                  className="ps-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                  // {"props.question_sr[key]"}.
                >
                  {optio[1]}
                </div>
                <span
                  className="ps-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                >
                  {props.item.option2}
                </span>
              </div>
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: 35,
                  border:
                    `${props.item?.ans}` == 3
                      ? "1px solid #0CC818"
                      : "1px solid #DADADA",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    `${props.item?.ans}` == 3 ? "#EFFFF0" : "none",
                }}
              >
                <div
                  className="ps-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                  // {"props.question_sr[key]"}.
                >
                  {optio[2]}
                </div>
                <span
                  className="ps-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                >
                  {props.item.option3}
                </span>
              </div>
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: 35,
                  border:
                    `${props.item?.ans}` == 4
                      ? "1px solid #0CC818"
                      : "1px solid #DADADA",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    `${props.item?.ans}` == 4 ? "#EFFFF0" : "none",
                }}
              >
                <div
                  className="ps-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  {optio[3]}
                </div>
                <span
                  className="ps-2"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                >
                  {props.item.option4}
                </span>
              </div>
            </div>
          ) : (
            <div className="col-12">
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: "100px",
                  border:
                    `${props.item?.ans}` == 1
                      ? "1px solid #0CC818"
                      : "1px solid #DADADA",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    `${props.item?.ans}` == 1 ? "#EFFFF0" : "none",
                }}
              >
                <div
                  className="ps-2 me-3"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  {optio[0]}
                </div>

                <img
                  src={props.item.option1}
                  alt="img"
                  height="70px"
                  width="70px"
                />
              </div>
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: "100px",
                  border:
                    `${props.item?.ans}` == 2
                      ? "1px solid #0CC818"
                      : "1px solid #DADADA",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    `${props.item?.ans}` == 2 ? "#EFFFF0" : "none",
                }}
              >
                <div
                  className="ps-2 me-3"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  {optio[1]}
                </div>

                <img
                  src={props.item.option2}
                  alt="img"
                  height="70px"
                  width="70px"
                />
              </div>
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: "100px",
                  border:
                    `${props.item?.ans}` == 3
                      ? "1px solid #0CC818"
                      : "1px solid #DADADA",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    `${props.item?.ans}` == 3 ? "#EFFFF0" : "none",
                }}
              >
                <div
                  className="ps-2 me-3"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  {optio[2]}
                </div>

                <img
                  src={props.item.option3}
                  alt="img"
                  height="70px"
                  width="70px"
                />
              </div>
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: "100px",
                  border:
                    `${props.item?.ans}` == 4
                      ? "1px solid #0CC818"
                      : "1px solid #DADADA",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    `${props.item?.ans}` == 4 ? "#EFFFF0" : "none",
                }}
              >
                <div
                  className="ps-2 me-3"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  {optio[3]}
                </div>

                <img
                  src={props.item.option4}
                  alt="img"
                  height="70px"
                  width="70px"
                />
              </div>
            </div>
          )}

          {/*<div className="col-8">
            {props.item?.options?.map((res, key) => {
              return (
                <>
                  <div
                    className="mb-3"
                    style={{
                      width: "100%",
                      height: 35,
                      border:
                        key == `${props.item?.ans}`
                          ? "1px solid #0CC818"
                          : "1px solid #DADADA",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      backgroundColor:
                        key == `${props.item?.ans}` ? "#EFFFF0" : "none",
                    }}
                  >
                    <div
                      className="ps-2"
                      style={{
                        fontFamily: "inter",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#474747",
                      }}
                    >
                      {props.question_sr[key]}.
                    </div>
                    <span
                      className="ps-2"
                      style={{
                        fontFamily: "inter",
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#474747",
                      }}
                    >
                      {res}
                    </span>
                  </div>
                </>
              );
            })}
          </div>*/}
        </div>
      </div>
      <div className="col-2 d-flex justify-content-center">
        <div
          style={{
            width: "100%",
            height: 35,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {/*<MdModeEdit style={{ fontSize: 20 }} />*/}
          <MdOutlineDeleteOutline
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={() => {
            
              props.dispatch(props.deleteQuestionFun(props.item.id));
            }}
          />
        </div>
      </div>
    </div>
  );
};
