import { get_assign_particular_error, get_assign_particular_loading, get_assign_particular_success, get_batch_error, get_batch_loading, get_batch_success, get_classes_particular_error, get_classes_particular_loading, get_classes_particular_success, get_exam_particular_error, get_exam_particular_loading, get_exam_particular_success, get_quiz_particular_error, get_quiz_particular_loading, get_quiz_particular_success } from "./batchtype";


const initState = {
    isLoading: false,
    isError: false,
    batches:[],
    assign_particular:[],
    quiz_particular:[],
    exam_particular:[],
    classes_particular:[],
  };
  
  export const batchReducer = (state = initState, { type, payload }) => {
    switch(type){
        case get_batch_loading:{
            return{
                ...state,
                isLoading:true,
                isError:false,
            }
        }
        case get_batch_error:{
            return{
                ...state,
                isLoading:false,
                isError:true
            }
        }
        case get_batch_success:{
            return{
                ...state,
                isLoading:false,
                isError:false,
                batches:payload
            }
        }
        case get_assign_particular_loading:{
            return{
                ...state,
                isLoading:true,
                isError:false,
            }
        }
        case get_assign_particular_error:{
            return{
                ...state,
                isLoading:false,
                isError:true
            }
        }
        case get_assign_particular_success:{
            return{
                ...state,
                isLoading:false,
                isError:false,
                assign_particular:payload
            }
        }
        case get_quiz_particular_loading:{
            return{
                ...state,
                isLoading:true,
                isError:false,
            }
        }
        case get_quiz_particular_error:{
            return{
                ...state,
                isLoading:false,
                isError:true
            }
        }
        case get_quiz_particular_success:{
            return{
                ...state,
                isLoading:false,
                isError:false,
                quiz_particular:payload
            }
        }
        case get_exam_particular_loading:{
            return{
                ...state,
                isLoading:true,
                isError:false,
            }
        }
        case get_exam_particular_error:{
            return{
                ...state,
                isLoading:false,
                isError:true
            }
        }
        case get_exam_particular_success:{
            return{
                ...state,
                isLoading:false,
                isError:false,
                exam_particular:payload
            }
        }
        case get_classes_particular_loading:{
            return{
                ...state,
                isLoading:true,
                isError:false,
            }
        }
        case get_classes_particular_error:{
            return{
                ...state,
                isLoading:false,
                isError:true
            }
        }
        case get_classes_particular_success:{
            return{
                ...state,
                isLoading:false,
                isError:false,
                classes_particular:payload
            }
        }
        default :{
            return state
        }
    }
  }