import { toast } from "react-toastify";
import { instance } from "../../instance";
import {
  add_Quizess_error,
  add_Quizess_loading,
  add_Quizess_success,
  add_Singlequestion_loading,
  add_Singlequestion_success,
  add_Singlequestionl_error,
  add_questionbyExcel_error,
  add_questionbyExcel_loading,
  add_questionbyExcel_success,
  add_topic_error,
  add_topic_loading,
  add_topic_success,
  delete_question_error,
  delete_question_loading,
  delete_question_success,
  get_HistoryQuizess_error,
  get_HistoryQuizess_loading,
  get_HistoryQuizess_success,
  get_ScheduleQuizess_error,
  get_ScheduleQuizess_loading,
  get_ScheduleQuizess_success,
  get_ScheduleQuizess_success_search,
  get_topicById_error,
  get_topicById_loading,
  get_topicById_success,
  get_topic_error,
  get_topic_loading,
  get_topic_success,
} from "./quizzestype";

export const addTopicFun =
  (SubjectId, CourseName, tagName, setState, state) => async (dispatch) => {
    console.log(SubjectId, "SubjectId");
    dispatch({ type: add_topic_loading });
    try {
      let res = await instance
        .post(`/instructor/quizes/create/topic/for/quiz`, {
          subject_id: SubjectId,
          topic_name: tagName,
          course_name: CourseName,
        })
        .then((res) => {
          console.log(res, "add topic");
          if (res.data.status == 1) {
            dispatch({ type: add_topic_success, payload: res.data });
            toast(res.data.msg);
            setState(state + 1);
          } else {
            toast("Some thing went wrong");
          }
        });
    } catch (error) {
      dispatch({ type: add_topic_error });
    }
  };

export const getTopicFun = () => async (dispatch) => {
  dispatch({ type: get_topic_loading });
  try {
    let res = await instance
      .post(`/instructor/quizes/read/quiz/topic`)
      .then((res) => {
        console.log(res, "get topic");
        if (res.data.status == 1) {
          dispatch({ type: get_topic_success, payload: res.data.quiz_topics });
        } else {
          toast("Some thing went wrong");
        }
      });
  } catch (error) {
    dispatch({ type: get_topic_error });
  }
};

export const getTopicByIdFun = (id) => async (dispatch) => {
  dispatch({ type: get_topicById_loading });
  try {
    let res = await instance
      .post(`/instructor/quizes/read/quiz/topicbyid/${id}`)
      .then((result) => {
        // console.log(result, "get Topic by id");
        if (result.data.status == 1) {
          dispatch({ type: get_topicById_success, payload: result.data.data });
       
        } else {
        }
      });
  } catch (err) {
    dispatch({ type: get_topicById_error });
  }
};

export const addquestionByExcelFun = (form, setState2,state2) => async (dispatch) => {
  dispatch({ type: add_questionbyExcel_loading });
  const formdata = new FormData();
  Object.entries(form).map(([key, value]) => formdata.append(key, value));
  try {
    let res = await instance
      .post(`/instructor/quizes/upload/question/inquiztopic/byexcel`, formdata)
      .then((result) => {
        console.log(result, "add question excel");
        if (result.data.status == 1) {
          dispatch({
            type: add_questionbyExcel_success,
            payload: result.data,
          });
          toast(result.data.msg);
          setState2(state2 + 1);
          dispatch(getTopicFun());
        } else {
          toast(result.data.Backend_Error)
        }
      });
  } catch (err) {
    dispatch({ type: add_questionbyExcel_error });
  }
};

export const addSinglequestionFun = (form, setState,state) => async (dispatch) => {
  dispatch({ type: add_Singlequestion_loading });
  const formdata = new FormData();
  Object.entries(form).map(([key, value]) => formdata.append(key, value));
  try {
    let res = await instance
      .post(`instructor/quizes/upload/image/question/inquiztopic`, formdata)
      .then((result) => {
        console.log(result, "add single question ");
        if (result.data.status == 1) {
          dispatch({
            type: add_Singlequestion_success,
            payload: result.data,
          });
          toast(result.data.msg);
          setState(state + 1);
          dispatch(getTopicFun());
        } else {
          toast(result.data.Backend_Error)
        }
      });
  } catch (err) {
    dispatch({ type: add_Singlequestionl_error });
  }
};



export const addQuizessFun =
  (
    title,
    noofque,
    Percentage,
    queTime,
    DataForRules,
    CourseName,
    SubjectName,
    start_time,
    BatchId,
    ArrForm
  ) =>
  async (dispatch) => {
    console.log(title, "title");
    dispatch({ type: add_Quizess_loading });

    try {
      let res = await instance
        .post(`/instructor/quizes/create/quiz`, {
          title: title,
          numberofquestion: noofque,
          passingpersentage: Percentage,
          timeperquestion: queTime,
          quizrules: DataForRules,
          coursename: CourseName,
          subjectname: SubjectName,
          start_time: start_time,
          batchids: BatchId,
          tagwithpersentage: ArrForm,
        })
        .then((result) => {
          console.log(result, "add schudul quizzes ");
          if (result.data.status == 1) {
            dispatch({ type: add_Quizess_success, payload: result });
            toast(result.data.msg);
            dispatch(getSchudeledFun());
          } else{
            toast(result.data.Backend_Error)
          }
        });
    } catch (err) {
      dispatch({ type: add_Quizess_error });
    }
  };

export const getSchudeledFun = () => async (dispatch) => {
  dispatch({ type: get_ScheduleQuizess_loading });

  try {
    let res = await instance
      .post(`/instructor/quizes//read/quiz/upcom`)
      .then((result) => {
        console.log(result, "get schudul quizzes");
        if (result.data.status == 1) {
          dispatch({
            type: get_ScheduleQuizess_success,
            payload: result.data.quizall,
          });
        }
      });
  } catch (err) {
    dispatch({ type: get_ScheduleQuizess_error });
  }
};

export const getScheduleFun_search = (toSearch) => (dispatch) => {
  console.log(toSearch, "hello", "iam here");
  dispatch({ type: get_ScheduleQuizess_success_search, payload: toSearch });
};
export const getHistoryFun = () => async (dispatch) => {
  dispatch({ type: get_HistoryQuizess_loading });

  try {
    let res = await instance
      .post(`/instructor/quizes/read/quiz/completed`)
      .then((result) => {
        console.log(result, "get history quizzes");
        if (result.data.status == 1) {
          dispatch({
            type: get_HistoryQuizess_success,
            payload: result.data.quizall,
          });
        } else {
        }
      });
  } catch (err) {
    dispatch({ type: get_HistoryQuizess_error });
  }
};
export const deleteQuestionFun = (question_id) => async (dispatch) => {
  console.log(question_id,"question_id")
  dispatch({ type: delete_question_loading});

  try {
    let res = await instance
      .post(`/instructor/quizes/delete/quiz/question/by/id`,{
        question_id:question_id,
      })
      .then((result) => {
        console.log(result, "delete");
        if (result.data.status == 1) {
          dispatch({
            type: delete_question_success,
            payload: result.data,
          });
          toast(result.data.msg);
          dispatch(getTopicByIdFun())
        } else {
        }
      });
  } catch (err) {
    dispatch({ type: delete_question_error});
  }
};
