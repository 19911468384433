import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { FaCalendar } from "react-icons/fa";
import { ImArrowLeft2 } from "react-icons/im";
import { MdEdit } from "react-icons/md";
import { HiOutlineDownload } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import { instance } from "../instance";
import { API_URL } from "../env";
import { toast } from "react-toastify";

export default function QuestionPaper({ live }) {

  const [list, setList] = useState([]);
  const [selectedQuestionPaper, setSelectedQuestionPaper] = useState([]);
  const [dataStructure, setDataStructure] = useState("");
  const [question, setquestion] = useState([]);
  const [rules, setRules] = useState([]);
  const [topic_with_pers, settopic_with_pers] = useState([]);
  const [Ids, setIds] = useState();

 const savePDFFun=()=>{
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

var raw = JSON.stringify({
  "question_paper_id": Ids
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/instructor/exams/download/examquestionpaper/pdf`, requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result,"save pdf")
    if(result.status == 1){
      toast(result.msg)
    }else{
      toast(result.Backend_Error)
    }
  })
  .catch(error => console.log('error', error));
 }
  //function for parse the json format
  const jsonConvertorObject = (toStrigify) => {
    return toStrigify && toStrigify.length > 0 ? JSON.parse(toStrigify) : {};
  };
  const jsonConvertorArray = (toParsed) => {
    return toParsed && toParsed.length > 0 ? JSON.parse(toParsed) : [];
  };

  //function for get particular question paper
  const getQuestionPaperByID = async (id) => {
    try {
      let res = await instance.post(`instructor/exams/read/exams/questionpaper/${id}`);
      if (res.data.status == 1) {
        setSelectedQuestionPaper([res.data.data]);
        // setList([res.data.data])
        setquestion(res.data.questions)
        setRules([res.data.data.rules])
        // settopic_with_pers([res.data.data.topic_with_pers])
        console.log(res.data, "in stat 1");
      } else {
        console.log(res.data, "while st 0");
      }
    } catch (err) {
      console.log(err);
    }
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsStr = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${minutesStr} min : ${secondsStr} sec`;
  }


  useEffect(() => {

  }, []);

// console.log(     topic_with_pers,'selectedQuestionPaper')

  return (
    <>
      <div className="bar3">
        <div className="row g-0">

          {live.length > 0 ? (
            live?.map((item) => {
              return (
                <LiveClass
                  item={item}
                  getQuestionPaperByID={getQuestionPaperByID}
                  key={item.id}
                  formatTime={formatTime}
                  setIds={setIds}
                />
              );
            })
          ) : (
            <>
              {" "}
              <div className="mt-5">
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    fontFamily: "inter",
                    color: "red",
                  }}
                >
                  No data available
                </h3>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Question Paper Detail Offcanvas Start */}
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "50%" }}
      >
        <div className="offcanvas-header">
          <div
            className=""
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{
              width: 30,
              height: 30,
              backgroundColor: "#fff",
              borderRadius: 50,
              border: "1px solid #E1E1E1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ImArrowLeft2 style={{ color: "#E1E1E1" }} />
          </div>
        </div>
        {selectedQuestionPaper.map((item,index)=>{
          return(
            <>
            <div className="row mx-1" key={index}>
          <div
            className="col-7 text-start"
            style={{ fontFamily: "inter", fontSize: 24, fontWeight: 600 }}
          >
            {item.ques_pap_name}
          </div>
          <div className="col-5">
            <div className="row">
              {/* <div
                className="col-5 ms-auto"
                style={{
                  height: 30,
                  backgroundColor: "#fff",
                  border: "1px solid #3D70F5",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <MdEdit style={{ color: "#3D70F5" }} />
                <span
                  className="px-2"
                  style={{
                    color: "#3D70F5",
                    fontFamily: "inter",
                    fontWeight: 500,
                    fontSize: 13,
                  }}
                >
                  Edit
                </span>
              </div> */}
              <div
                className="col-5 ms-auto"
                style={{
                  height: 30,
                  backgroundColor: "#3D70F5",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={()=>{savePDFFun()}}
              >
                <HiOutlineDownload style={{ color: "#fff" }} />
                <span
                  className="px-1"
                  style={{
                    color: "#fff",
                    fontFamily: "inter",
                    fontWeight: 500,
                    fontSize: 13,
                  }}
                >
                  Save PDF 
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-body">
          <p
            className="text-start"
            style={{ fontFamily: "inter", fontSize: 16, fontWeight: 600 }}
          >
            Course : <span>{item.cou_name}</span>
          </p>
          <div className="row">
            <div className="col-8">
              <div className="row">
                <div
                  className="col-5 text-start"
                  style={{ fontFamily: "inter", fontSize: 14, fontWeight: 500 }}
                >
                  Subject :
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    {item.sub_name}
                  </span>
                </div>

              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-8">
              <div className="row g-0">
                {/* <div className="col-4 d-flex align-items-center justify-content-start">
                  <FaCalendar style={{ color: "#3C3C3C" }} />
                  <span
                    style={{
                      color: "#000",
                      fontWeight: 600,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                    {item.stdate}
                  </span>
                </div> */}
                <div className="col-5 d-flex align-items-center justify-content-start">
                  <FiClock style={{ color: "#3C3C3C" }} />
                  <span
                    style={{
                      color: "#000",
                      fontWeight: 600,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                  {formatTime(item.duration)}

                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row mt-3">
            <div className="col-6">
              <Card className="border-0">
                <p
                  className="pt-2 mb-0 text-start"
                  id="demo-radio-buttons-group-label"
                  style={{
                    fontFamily: "inter",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#000",
                  }}
                >
                  Topic Composition
                </p>
                {/* <div className='barsubject'> 
                <div className="">
                  {topic_with_pers?.map((key, value) => (
                    
                    <div className="row">
                      <div
                        className="mt-4"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          {key}
                        </div>
                        <div
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            color: "#3D70F5",
                            fontSize: 14,
                          }}
                        >
                          {value}%
                        </div>
                      </div>
                      <div className="">
                        <span>
                          <input
                            id="myinput"
                            className="w-100 input-affairs"
                            type="range"
                            min="0"
                            max="100"
                            value={value}
                            // value={dataStructure}
                            onChange={(e) => {
                              setDataStructure(e.target.value);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </Card>

              <Card className="border-0">
                <p
                  className="pt-2 mb-0 text-start"
                  id="demo-radio-buttons-group-label"
                  style={{
                    fontFamily: "inter",
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#000",
                  }}
                >
                  Rules:
                </p>
                {/* <div className='barsubject'> 
                <div className="">
                  {rules.map(
                    (el) => (
                      <div className="row">
                        <div
                          className="mt-4"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            {el}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </Card>
            </div>
          </div> */}
          {/* <div className="row">
            <div
              className="text-start"
              style={{ fontFamily: "inter", fontSize: 16, fontWeight: 600 }}
            >
              Rules
            </div>
            <ul className="list-group border-0 text-start">
              {list.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="list-group-item border-0 py-1"
                    style={{
                      fontFamily: "inter",
                      fontSize: 12,
                      fontWeight: 400,
                    }}
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </div> */}
          <hr></hr>
          <div>
            <div
              className="text-start"
              style={{ fontFamily: "inter", fontSize: 24, fontWeight: 600 }}
            >
              Questions
            </div>
            {question?.map((item, index) => {
              console.log(question,'in questions')
              return (
                <div className="row" key={index}>
                  <div className="col-10 pt-2" style={{}}>
                    <div
                      className="row"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="col-2 text-start"
                        style={{
                          fontFamily: "inter",
                          fontSize: 16,
                          color: "#474747",
                          fontWeight: 400,
                        }}
                      >
                       <span style={{fontWeight:"600"}}> Q{item.id}.</span>{item.question}
                      </span>
                      {/* <div className="col-10">
                        <div className="row d-flex justify-content-end">
                          <div className="col-6">
                            <div className="row">
                              <div
                                className="col-5 ms-auto"
                                style={{
                                  height: 30,
                                  backgroundColor: "#3D70F5",
                                  borderRadius: 5,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <NavLink
                                  to="/EditQuestion"
                                  className="text-decoration-none"
                                >
                                  <MdEdit style={{ color: "#fff" }} />
                                  <span
                                    className="px-2"
                                    style={{
                                      color: "#fff",
                                      fontFamily: "inter",
                                      fontWeight: 500,
                                      fontSize: 13,
                                    }}
                                  >
                                    Edit
                                  </span>
                                </NavLink>
                              </div>
                              <div
                                className="col-5 ms-auto"
                                style={{
                                  height: 30,
                                  backgroundColor: "#ffff",
                                  border: "1px solid #3D70F5",
                                  borderRadius: 5,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <RiDeleteBin6Line
                                  style={{ color: "#3D70F5" }}
                                />
                                <span
                                  className="px-1"
                                  style={{
                                    color: "#3D70F5",
                                    fontFamily: "inter",
                                    fontWeight: 500,
                                    fontSize: 13,
                                  }}
                                >
                                  Delete
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <p
                      className="p-0 "
                      style={{
                        fontFamily: "inter",
                        fontSize: 15,
                        color: "#474747",
                        fontWeight: 400,
                      }}
                    >
                      {/* {item.question} */}
                    </p>
                    <div
                      className="text-start"
                      style={{
                        fontFamily: "inter",
                        fontSize: 14,
                        color: "#474747",
                        fontWeight: 400,
                      }}
                    >
                      Mark :
                      <span
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          color: "#59E356",
                          fontWeight: 400,
                        }}
                      >
                        {item.marks}
                      </span>
                    </div>
                  </div>
                  <div className="row px-3">
                    <div className="col-8"></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
            </>
          )
        })}
      
      </div>
      {/* Question Paper Detail Offcanvas End */}
    </>
  );
}

const LiveClass = (props) => {
  const { getQuestionPaperByID } = props;
  // console.log(props,'props')
  const { formatTime } = props;
  return (
    <div className="col-4">
      <Card
        style={{
          backgroundColor: "#fff",
          border: "1px solid #E6E6E6",
          marginBottom: 10,
          width: "95%",
        }}
      >
        <Card.Body className="text-start">
          <span
            style={{
              fontWeight: 600,
              fontSize: 16,
              textAlign: "start",
              fontFamily: "inter",
            }}
          >
            {props.item?.ques_pap_name}
          </span>
          <div
            className="row g-0 mt-2"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p
              className="col-2 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 13,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Course
            </p>
            <p
              className="col-10 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 13,
                color: "#9A9A9A",
                fontWeight: 400,
                textAlign: "left ",
              }}
            >
              {" "}
              :&nbsp;&nbsp;{props.item?.cou_name}
            </p>
          </div>
          <div
            className="row g-0 mt-2"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p
              className="col-2 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 13,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Subject
            </p>
            <p
              className="col-10"
              style={{
                fontFamily: "inter",
                fontSize: 13,
                color: "#9A9A9A",
                fontWeight: 400,
                textAlign: "left ",
              }}
            >
              {" "}
              :&nbsp;&nbsp;{props.item?.sub_name}
            </p>
          </div>
          <div className="row g-0">
            <div className="col-5 d-flex align-items-center justify-content-start">

              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 400,
                  fontSize: 14,
                  // paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >

                Total Marks: {props.item?.tot_marks}
              </span>
            </div>
            <div className="col-7 d-flex align-items-center justify-content-start">
              <FiClock style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {formatTime(props.item?.duration)}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <p
              className="col-12 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Questions : {props.item?.numberofquestion}
            </p>
          </div>
          {/* <div className="row mt-2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#3C3C3C",
                }}
              >
                Passing Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3D70F5",
                }}
              >
                {props.item.width} %
              </p>
            </div>
          </div> */}
          <div
            className="mt-3"
            style={{
              width: "100%",
              height: 8,
              backgroundColor: "#D9D9D9",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                width: props.item.width + "%",
                height: 8,
                backgroundColor: "#3D70F5",
                borderRadius: 10,
              }}
            ></div>
          </div>
          <button
            onClick={() => {
              props.setIds(props.item.id)
              getQuestionPaperByID(props.item.id);
              console.log(props.item.id,'props.item?.id')
            }}
            type="button"
            className="mt-3 border-0"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            style={{
              width: "100%",
              height: 40,
              backgroundColor: "#3D70F5",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#fff",
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "inter",
              }}
            >
              View Details
            </span>
          </button>
        </Card.Body>
      </Card>
    </div>
  );
};
