import { useState, useEffect } from "react";
import QuizHisDetail from "./QuizHisDetail";
import QuizHistory from "./QuizHistory";
import { FiClock } from "react-icons/fi";
import { FaCalendar } from "react-icons/fa";
import { RiCheckDoubleLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { FaRegCalendarAlt } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import myimg from "../images/myimage.jpg";
import { NavLink, useLocation } from "react-router-dom";
import { API_URL } from "../env";
import { instance } from "../instance";
import { useSelector } from "react-redux";

const initLoader = {
  isLoading: false,
  isError: false,
};

export default function History() {
  const {historyQuiz} =useSelector((store)=>store.quizzes)
console.log(historyQuiz,"historyQuiz")
  const [live, setLive] = useState();
  const [status, setStatus] = useState(0);
  const [State, setState] = useState();
  const [loader, setLoader] = useState(initLoader);
  const [Offcanva_data, setOffCanvaData] = useState({});
  const [Offcanva_dataquizrules, setOffCanvaDataquizrules] = useState();
  const [tagdata, setTagdata] = useState({});
  const [tbldata, setTbldata] = useState();

  console.log(tagdata,"tagdata my ")
  const quizzHistory = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/read/quiz/com`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          console.log(result);
          setLive(result.quizall);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for get quize data for particular quize
  const getParticularQuize = async (id) => {
    console.log(id, "before hit api");
    setLoader({
      ...loader,
      isLoading: true,
    });
    try {
      let res = await instance
        .post(`/instructor/quizes/read/quizbyid/${id}`)
        .then((result) => {
          console.log(result,"result")
          if (result.data.status == 1) {
            // console.log(result.data.parsedrows, "parsedrows data particular quiz");
            setOffCanvaData(result.data.quiz_data);
            setOffCanvaDataquizrules(
              result?.data?.quiz_data?.quizrules
            );
            console.log(result?.data?.quiz_data,"my data")
            setTagdata(result?.data?.quiz_data?.tagwithpersentage);
            setLoader({
              ...loader,
              isLoading: false,
            });
            
          } 
        });
    } catch (err) {
      setLoader({
        ...loader,
        isLoading: false,
      });
      console.log(err);
    }
  };
console.log(API_URL,"API_URL")
  

  const getResult = (id) => {
    console.log(id,"id result")
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/quizes/read/quiz_result/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "result in getResult fun");
        setTbldata(result.result);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    quizzHistory();
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  useEffect(() => {
    setState("QuizHisDetail");
  }, []);

  return (
    <div>
      {/* Quiz History Offcanvas Start  */}
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div
            className="col-6 text-start"
            style={{ fontFamily: "inter", fontWeight: 600, fontSize: 26 }}
          >
            Quiz History
          </div>
          <p
            className="text-start my-2"
            style={{ fontFamily: "inter", fontSize: 16, fontWeight: 600 }}
          >
            Quiz Name : {Offcanva_data.title}
          </p>
          <div className="row mt-3">
            <p
              className="col-12 text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 16 }}
            >
              Course <span>&nbsp;{Offcanva_data.coursename}</span>
            </p>
            <p
              className="col-12 text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 16 }}
            >
              Subject <span>&nbsp;{Offcanva_data.subjectname}</span>
            </p>
          </div>
          <div className="row my-2">
            <div className="col-5">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 35,
                  backgroundColor: "#EEEFF9",
                  borderRadius: 5,
                  display: "flex",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    setState("QuizHisDetail");
                  }}
                  className=""
                  style={{
                    width: "100%",
                    height: 35,
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor:
                      State == "QuizHisDetail" ? "#3D70F5" : "#EEEFF9",
                    color: State == "QuizHisDetail" ? "#fff" : "#7E7E7E",
                    borderRadius: 5,
                  }}
                >
                  Details
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState("QuizHistory");
                  }}
                  className=""
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor:
                      State == "QuizHistory" ? "#3D70F5" : "#EEEFF9",
                    color: State == "QuizHistory" ? "#fff" : "#7E7E7E",
                    width: "100%",
                    height: 35,
                    borderRadius: 5,
                  }}
                >
                  Result
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2 mb-4">
            {State === "QuizHisDetail" ? (
              <>
                {" "}
                <QuizHisDetail
                  Offcanva_data={Offcanva_data}
                  Offcanva_dataquizrules={Offcanva_dataquizrules}
                  tagdata={tagdata}
                />{" "}
              </>
            ) : (
              <></>
            )}
            {State === "QuizHistory" ? (
              <>
                {" "}
                <QuizHistory  tbldata={tbldata} Offcanva_data={Offcanva_data} Offcanva_dataquizrules={Offcanva_dataquizrules}
                tagdata={tagdata}/>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Quiz History Offcanvas End  */}
      <div className="bar3">
        <div className="row mt-2">
          {historyQuiz?.map((item, index) => {
            return (
              <LiveClass
                item={item} 
                key={index}
                getParticularQuize={getParticularQuize}
                getResult={getResult}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

const LiveClass = (props) => {


  return (
    <div className="col-4">
      <Card
        style={{
          backgroundColor: "#fff",
          border: "1px solid #E6E6E6",
          marginBottom: 20,
          width: "100%",
        }}
      >
        <Card.Body className="text-start">
          <span
            style={{
              fontWeight: 600,
              fontSize: 16,
              textAlign: "start",
              fontFamily: "inter",
            }}
          >
            {props.item.title}
          </span>
          <div
            className="row g-0 mt-2"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p
              className="col-2 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Course
            </p>
            <p
              className="col-10 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                color: "#9A9A9A",
                fontWeight: 400,
                textAlign: "left ",
              }}
            >
              {" "}
              :&nbsp;&nbsp;{props.item.coursename}
            </p>
          </div>
          <div
            className="row g-0 mt-2"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p
              className="col-2 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Subject
            </p>
            <p
              className="col-10"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                color: "#9A9A9A",
                fontWeight: 400,
                textAlign: "left ",
              }}
            >
              {" "}
              :&nbsp;&nbsp;{props.item.subjectname}
            </p>
          </div>
          <div className="row g-0">
            <div className="col-5 d-flex align-items-center justify-content-start">
              <FaCalendar style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.start_time?.split("",10)}
              </span>
            </div>
            <div className="col-7 d-flex align-items-center justify-content-start">
              <FiClock style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.start_time?.split("T")[1]} - {props.item.entime}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <p
              className="col-12 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                color: "#9A9A9A",
                fontWeight: 400,
              }}
            >
              Questions : {props.item.numberofquestion}
            </p>
          </div>
          <div className="row mt-2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#3C3C3C",
                }}
              >
                Passing Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3D70F5",
                }}
              >
                {props.item.passingpersentage} %
              </p>
            </div>
          </div>
          <div
            className="mt-3"
            style={{
              width: "100%",
              height: 8,
              backgroundColor: "#D9D9D9",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                width: props.item.passingpersentage + "%",
                height: 8,
                backgroundColor: "#3D70F5",
                borderRadius: 10,
              }}
            ></div>
          </div>
          <button
            type="button"
            className="mt-3 border-0"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            style={{
              width: "100%",
              height: 40,
              backgroundColor: "#3D70F5",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              props.getParticularQuize(props.item.id);
              props.getResult(props.item.id);
            }}
          >
            <span
              style={{
                color: "#fff",
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "inter",
              }}
            >
              View Details
            </span>
          </button>
        </Card.Body>
      </Card>
    </div>
  );
};
