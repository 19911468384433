import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Sort } from "./Svgicon";
import Card from "react-bootstrap/Card";
import QuizDetails from "./QuizDetails";
import Response from "./Response";
import myimg from "../images/myimage.jpg";
import { FiClock } from "react-icons/fi";
import { ImArrowLeft2 } from "react-icons/im";
import { RiCheckDoubleLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { FaRegCalendarAlt } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { API_URL } from "../env";

export default function QuizHistory({
  tbldata,
  Offcanva_data,
  Offcanva_dataquizrules,
  tagdata,
}) {
  console.log(tbldata, "tabldata in quizHistory", Offcanva_data);

  const [State, setState] = useState("QuizDetails");

  const [studentrespons, setStudentrespons] = useState();
  const [studentdetail, setStudentdetail] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const compliteResult = (quiz_id, stu_id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var raw = JSON.stringify({
      quiz_id: quiz_id,
      stu_id: stu_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${API_URL}/instructor/quizes/read/stu/result/by/quizid`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "student result by quiz id");
        if (result.status == 1) {
          setStudentrespons(result.resp);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const studentdetailfun = (quiz_id, stu_id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var raw = JSON.stringify({
      quiz_id: quiz_id,
      stu_id: stu_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${API_URL}/instructor/quizes/view/student/detail/with/quiz_id`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) =>{
        // console.log(result,"student detail");
        if(result.status == 1){
          setStudentdetail(result?.detail)
        }else{
          console.log("something want worng") 
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {}, []);

  return (
    <>
      <div className="table-scroll">
        <div className="row mt-3">
          <div className="col-12" style={{ width: "100%" }}>
            <Table bordered>
              <thead>
                <tr>
                  <th
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      color: "#474747",
                      fontFamily: "inter",
                      fontSize: 15,
                      backgroundColor: "#F0F0F0",
                      fontWeight: 600,
                    }}
                  >
                    Name{" "}
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      style={{
                        color: "#474747",
                        fontFamily: "inter",
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      Sort <Sort />
                    </button>
                  </th>
                  <th
                    className=""
                    style={{
                      color: "#474747",
                      fontFamily: "inter",
                      fontSize: 15,
                      backgroundColor: "#F0F0F0",
                      fontWeight: 600,
                    }}
                  >
                    Marks
                  </th>
                  <th
                    className=""
                    style={{
                      color: "#474747",
                      fontFamily: "inter",
                      fontSize: 15,
                      backgroundColor: "#F0F0F0",
                      fontWeight: 600,
                    }}
                  >
                    Status
                  </th>
                  <th
                    className=""
                    style={{
                      color: "#474747",
                      fontFamily: "inter",
                      fontSize: 15,
                      backgroundColor: "#F0F0F0",
                      fontWeight: 600,
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {tbldata &&
                  tbldata?.map((item, index) => (
                    <BasicExample
                      item={item}
                      key={index}
                      handleShow={handleShow}
                      compliteResult={compliteResult}
                      studentdetailfun={studentdetailfun}
                      Offcanva_data={Offcanva_data}
                    />
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* Quiz History Offcanvas Start  */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ width: "55%" }}
      >
        <Offcanvas.Header>
          <button
            type="button"
            onClick={handleClose}
            className="btn border-0"
            style={{
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImArrowLeft2 style={{ fontSize: 24 }} />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row d-flex align-items-center justify-content-between">
            <div
              className="col-6 text-start"
              style={{ fontFamily: "inter", fontWeight: 400, fontSize: 24 }}
            >
              Quiz Result Details
            </div>
            {/*<div
              className="col-6"
              style={{
                height: 25,
                backgroundColor: "#ECF2FF",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "inter",
                fontSize: 13,
                fontWeight: 400,
                color: "#3D70F5",
              }}
            >
              Result Declared on 12:30 AM | 22 September 2023 &nbsp;
              <RiCheckDoubleLine style={{ fontSize: 18 }} />
            </div>*/}
          </div>
          <div className="row mt-4">
            <div className="col-5">
              <Card className="border-0" style={{}}>
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <div
                      style={{ width: 80, height: "auto", borderRadius: 100 }}
                    >
                      <img
                        src={myimg}
                        className="header"
                        style={{ width: "100%", borderRadius: "50px" }}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-8 text-start">
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 18,
                      }}
                    >
                  {studentdetail?.name}
                    </div>
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      Student ID : {studentdetail?.enrollment_id}
                    </div>
                    <NavLink to="/StudentProfile">
                      <button
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        className="btn border-0"
                        style={{
                          backgroundColor: "#fff",
                          fontFamily: "inter",
                          fontWeight: 400,
                          fontSize: 12,
                          color: "#2C62EE",
                        }}
                      >
                        <AiOutlineEye style={{ fontSize: 18 }} />
                        &nbsp;View Profile
                      </button>
                    </NavLink>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <p
            className="text-start my-4"
            style={{ fontFamily: "inter", fontSize: 16, fontWeight: 400 }}
          >
           {studentdetail?.title}
          </p>
          <div className="row">
            <div className="col-2">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 30,
                  border: "1px solid #D3D3D3",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "#989898",
                    fontFamily: "inter",
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  {studentdetail?.batch_name}
                </span>
              </div>
            </div>
            {/*<div className="col-5">
              <div className="row">
                <div className="col-6 ">
                  <FiClock style={{ color: "#989898" }} />
                  <span
                    style={{
                      color: "#989898",
                      fontWeight: 400,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                    11:40 P:M
                  </span>
                </div>
                <div className="col-6">
                  <FaRegCalendarAlt style={{ color: "#989898" }} />
                  <span
                    style={{
                      color: "#989898",
                      fontWeight: 400,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                    10 Feb 2023
                  </span>
                </div>
              </div>
                  </div>*/}
            <div className="col-3">
              {studentdetail?.status == "pass" ? (
                <div
                  className=""
                  style={{
                    width: "55%",
                    height: 30,
                    backgroundColor: "#ECFFEE",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#44CCA3",
                      fontSize: 12,
                      fontWeight: 400,
                      fontFamily: "inter",
                    }}
                  >
                    Status : <span>Pass</span>
                  </span>
                </div>
              ) : (
                <div
                  className=""
                  style={{
                    width: "55%",
                    height: 30,
                    backgroundColor: "#FFEFEF",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#F93333",
                      fontSize: 12,
                      fontWeight: 400,
                      fontFamily: "inter",
                    }}
                  >
                    Status : <span>Failed</span>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <p
              className="col-12 text-start"
              style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}
            >
              Course <span>&nbsp;{studentdetail?.coursename}</span>
            </p>
            <p
              className="col-12 text-start"
              style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}
            >
              Subject <span>&nbsp;{studentdetail?.subjectname}</span>
            </p>
          </div>
          <div className="row my-2">
            <div className="col-5">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 35,
                  backgroundColor: "#EEEFF9",
                  borderRadius: 5,
                  display: "flex",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    setState("QuizDetails");
                  }}
                  className=""
                  style={{
                    width: "100%",
                    height: 35,
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor:
                      State == "QuizDetails" ? "#3D70F5" : "#EEEFF9",
                    color: State == "QuizDetails" ? "#fff" : "#7E7E7E",
                    borderRadius: 5,
                  }}
                >
                  Details
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState("Response");
                  }}
                  className=""
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    backgroundColor:
                      State == "Response" ? "#3D70F5" : "#EEEFF9",
                    color: State == "Response" ? "#fff" : "#7E7E7E",
                    width: "100%",
                    height: 35,
                    borderRadius: 5,
                  }}
                >
                  Response
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2 mb-4">
            {State === "QuizDetails" ? (
              <>
                {" "}
                <QuizDetails
                  tagdata={tagdata}
                  Offcanva_data={Offcanva_data}
                  Offcanva_dataquizrules={Offcanva_dataquizrules}
                />{" "}
              </>
            ) : (
              <></>
            )}
            {State === "Response" ? (
              <>
                {" "}
                <Response studentrespons={studentrespons} />{" "}
              </>
            ) : (
              <></>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Quiz History Offcanvas End  */}
    </>
  );
}

function BasicExample(props) {

  return (
    <>
      <tr>
        <td
          style={{
            backgroundColor: "transparent",
            color: "#474747",
            fontFamily: "inter",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          {props.item.name}
        </td>
        <td
          style={{
            backgroundColor: "transparent",
            color: "#474747",
            fontFamily: "inter",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          {props.item.marks}/{props.item.totalmarks}
        </td>
        {props.item.status == "pass" ? (
          <td
            style={{
              backgroundColor: "transparent",
              color: "#0DC64C",
              fontFamily: "inter",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            Pass
          </td>
        ) : props.item.status == "unattemped" ? (
          <td
            style={{
              backgroundColor: "transparent",
              color: "#FB1818",
              fontFamily: "inter",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            unattemped
          </td>
        ) : (
          <td
            style={{
              backgroundColor: "transparent",
              color: "#FB1818",
              fontFamily: "inter",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            fail
          </td>
        )}
        <td
          style={{
            backgroundColor: "transparent",
            color: "#474747",
            fontFamily: "inter",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          <div style={{ justifyContent: "space-around", alignItems: "center" }}>
            <button
              className="px-2"
              type="button"
              onClick={() => {
                props.handleShow();
                props.compliteResult(props.Offcanva_data.id, props.item.stu_id);
                props.studentdetailfun(props.Offcanva_data.id, props.item.stu_id);
              }}
              style={{
                color: "#2C62EE",
                fontFamily: "inter",
                fontSize: 14,
                fontWeight: 400,
                border: "none",
                backgroundColor: "#fff",
              }}
              disabled={props.item.status == "unattemped"}
            >
              <AiOutlineEye style={{ fontSize: 18 }} /> Complete Result{" "}
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}
