import { useState, useEffect } from "react";
import { MdOutlineLocalPhone } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import Card from "react-bootstrap/Card";

export default function General({ProfileData,setState}) {
  console.log(ProfileData,"profile data in generl ");
  return (
    <div>
      <div className="row">
        <div className="col-5">
          <Card className="text-start border-0">
            <p style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Course : {ProfileData[0]?.course_name}
            </p>
            <p style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Full name : {ProfileData[0]?.name}
            </p>
            <p style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Mother’s name : {ProfileData[0]?.mother_name}
            </p>
            <p style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Father’s name : {ProfileData[0]?.father_name}
            </p>
            <p style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Course Start Date : {ProfileData[0]?.start_date}
            </p>
            <p style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Course End Date : {ProfileData[0]?.end_date}
            </p>
          </Card>
        </div>
        <div className="col-7">
          <Card
            className="text-start p-2 px-3"
            style={{ width: "70%", border: "1px solid #DADADA" }}
          >
            <div className="row">
              <div className="col-5">
                <div
                  style={{
                    fontFamily: "inter",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  Center Details
                </div>
                <div className="py-2">
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: 13,
                      fontWeight: 400,
                    }}
                  >
                    Pincode : 302021
                  </div>
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: 13,
                      fontWeight: 400,
                    }}
                  >
                    DIstrict : Jaipur
                  </div>
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: 13,
                      fontWeight: 400,
                    }}
                  >
                    State : Rajasthan
                  </div>
                </div>
                <div className="pe-5">
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: 13,
                      fontWeight: 400,
                    }}
                  >
                    Address : Plot no. 116, Lane number 4, Rathore nagar,
                    Vaishali nagar , Jaipur
                  </div>
                </div>
              </div>
              <div className="col-7">
                <div
                  className="text-start"
                  style={{
                    fontFamily: "inter",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  Center Contact
                </div>
                <div className="row">
                  <div
                    className="col-2"
                    style={{
                      height: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdOutlineLocalPhone style={{ fontSize: 20 }} />
                  </div>
                  <div
                    className="col-10"
                    style={{
                      height: 30,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: 15,
                    }}
                  >
                    +91 12345 69870
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-2"
                    style={{
                      height: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdOutlineEmail style={{ fontSize: 22 }} />
                  </div>
                  <div
                    className="col-10"
                    style={{
                      height: 30,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: 15,
                    }}
                  >
                    hanumannagar.center@tipsg.in
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div
            className="text-start py-1"
            style={{ fontFamily: "inter", fontWeight: 400, fontSize: 17 }}
          >
            Attendance
          </div>
          <Card
            className="text-start p-2 px-3"
            style={{ width: "100%", border: "1px solid #ECECEC" }}
          >
            <div style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Total Classes : {ProfileData[0]?.total_classes}
            </div>
            <div
              style={{
                fontFamily: "inter",
                fontWeight: 400,
                fontSize: 14,
                color: "#0CBC8B",
              }}
            >
              Attended : {ProfileData[0]?.attended}
            </div>
            <div
              style={{
                fontFamily: "inter",
                fontWeight: 400,
                fontSize: 14,
                color: "#F93333",
              }}
              
            >
              Missed :{ProfileData[0]?.missed}
            </div>
            <button
              type="button"
              className="btn my-2"
              style={{
                backgroundColor: "#2C62EE",
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontFamily: "inter",
                fontWeight: 400,
                fontSize: 12,
                borderRadius: 5,
              }}
              onClick={()=>setState("ProfileClasses")}
             
            >
              View Details 
            </button>
          </Card>
        </div>
        <div className="col-3">
          <div
            className="text-start py-1"
            style={{ fontFamily: "inter", fontWeight: 400, fontSize: 17 }}
          >
            Quiz
          </div>
          <Card
            className="text-start p-2 px-3"
            style={{ width: "100%", border: "1px solid #ECECEC" }}
          >
            <div style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Total Quizzes : 245
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#6B6B6B",
                }}
              >
                Attempted : 120
              </div>
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#9E9E9E",
                }}
              >
                Unattempt : 125
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#0CBC8B",
                }}
              >
                Passed : 25
              </div>
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#F93333",
                }}
              >
                Failed : 25
              </div>
            </div>
            <button
              type="button"
              className="btn my-2"
              style={{
                backgroundColor: "#2C62EE",
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontFamily: "inter",
                fontWeight: 400,
                fontSize: 12,
                borderRadius: 5,
              }}
            >
              View Details
            </button>
          </Card>
        </div>
        <div className="col-3">
          <div
            className="text-start py-1"
            style={{ fontFamily: "inter", fontWeight: 400, fontSize: 17 }}
          >
            Exams
          </div>
          <Card
            className="text-start p-2 px-3"
            style={{ width: "100%", border: "1px solid #ECECEC" }}
          >
            <div style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Total Quizzes : 245
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#6B6B6B",
                }}
              >
                Attempted : 120
              </div>
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#9E9E9E",
                }}
              >
                Unattempt : 125
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#0CBC8B",
                }}
              >
                Passed : 25
              </div>
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#F93333",
                }}
              >
                Failed : 25
              </div>
            </div>
            <button
              type="button"
              className="btn my-2"
              style={{
                backgroundColor: "#2C62EE",
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontFamily: "inter",
                fontWeight: 400,
                fontSize: 12,
                borderRadius: 5,
              }}
            >
              View Details
            </button>
          </Card>
        </div>
        <div className="col-3">
          <div
            className="text-start py-1"
            style={{ fontFamily: "inter", fontWeight: 400, fontSize: 17 }}
          >
            Assignment
          </div>
          <Card
            className="text-start p-2 px-3"
            style={{ width: "100%", border: "1px solid #ECECEC" }}
          >
            <div style={{ fontFamily: "inter", fontWeight: 400, fontSize: 16 }}>
              Total Quizzes : 245
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#6B6B6B",
                }}
              >
                Attempted : 120
              </div>
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#9E9E9E",
                }}
              >
                Unattempt : 125
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#0CBC8B",
                }}
              >
                Passed : 25
              </div>
              <div
                className="col-6"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#F93333",
                }}
              >
                Failed : 25
              </div>
            </div>
            <button
              type="button"
              className="btn my-2"
              style={{
                backgroundColor: "#2C62EE",
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontFamily: "inter",
                fontWeight: 400,
                fontSize: 12,
                borderRadius: 5,
              }}
            >
              View Details
            </button>
          </Card>
        </div>
      </div>
    </div>
  );
}
