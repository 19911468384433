export const get_upcoming_classes_success = 'get/upcoming/classes/success';
export const get_upcoming_classes_success_search = 'get/upcoming/classes/success/search';
export const get_history_classes_success = 'get/history/classes/success';
export const get_history_classes_success_search = 'get/history/classes/success/search';
export const add_classes_success = 'add/classes/success';

export const get_upcoming_classes_error = 'get/upcoming/classes/error';
export const get_history_classes_error = 'get/history/classes/error';
export const add_classes_error = 'add/classes/error';

export const get_upcoming_classes_loading = 'get/upcoming/classes/loading';
export const get_history_classes_loading = 'get/history/classes/loading';
export const add_classes_loading = 'add/classes/loading';