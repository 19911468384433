import { BiArrowBack, BiSolidUser, BiTask } from "react-icons/bi";
import avatar from "../images/profile-avatar-account-icon.png";
import { instance } from "../instance";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaPhone, FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import PersonalDetails from "./PersonalDetails";
import { circularProgressClasses } from "@mui/material";
import Salary from "./Salary";
import Tasks from "./Tasks";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const [personal_details, setpersonDetails] = useState({});
  const [my_profile, setMyProfile] = useState({});
  const [current_tab, setCurrentTab] = useState("personal_details");

  
  //function for profile details of instructor
  const getmyProfile = async () => {
    try {
      let res = await instance("/instructor/profile/get/profile/detail");
      if (res.data.status == 1) {
        setMyProfile(res.data.data);
      } else {
        toast("Something Went Wrong");
      }
    } catch (err) {
      console.log(err);
      toast("Internal server Error");
    }
  };

  //function for get personal details of instructor
  const getMyPersonDetails = async () => {
    try {
      let res = await instance("/instructor/profile/get/personal/detail");
      
      if (res.data.status == 1) {
        // console.log(res.data.result, "in status =1");
        setpersonDetails(res.data.result);
      } else {
        toast("Something went wrong!");
      }
    } catch (err) {
      toast("Internal Server Error!");
      console.log(err, "in case of err");
    }
  };
let navigate = useNavigate();
  useEffect(() => {
    getMyPersonDetails();
    getmyProfile();
  }, []);

  return (
    <>
    <div className="container-fluid">
      <div className="row py-3">
        <div className="col-1">
          <BiArrowBack  style={{fontSize:'26px',cursor:"pointer"}} onClick={()=>navigate(-1)}/>
        </div>

        <div className="col-7"></div>
        <div className="col-4 "> 
        <div style={{display:'flex', alignItems:'center', }}>

      <button className="me-3" style={{border:"1px solid black",color:"black",background: "transparent",}}
          >
            CREDITS:375
          </button>
        <button type="button" className="btn btn-primary" style={{border: "1px solid black"}}>
            BUY CREDITS
          </button> 
            </div>
        </div>
      </div>
      <div className="container mt-4 ">
        <div className="row">
          <div className="col-3">
            <img src={avatar} alt=""  style={{width:'70%'}}/>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="d-flex align-items-center gap-2">
                <FaUserAlt />
                <p className="mt-3">{my_profile?.name}</p>
              </div>
            </div>
            <div className="row">
              <div className="d-flex align-items-center gap-2">
                <FaPhone />
                <p className="mt-3">{my_profile?.phone}</p>
              </div>
            </div>
            <div className="row">
              <div className="d-flex align-items-center gap-2">
                <MdEmail />
                <p className="mt-3">
                  {my_profile.email}
                  {/* {personal_details?.phone} */}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="d-flex align-items-center gap-2">
                {" "}
                <BiTask />
                <p className="mt-3">
                  <span>{my_profile.completed_task}</span>/{my_profile.total_task}
                  {/* {personal_details?.phone} */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-6"></div>
        </div>

        <div className="row mt-4 pb-2">
          <div
            style={{

              color: current_tab === "personal_details" ? "black" : "#989898",
              fontWight: current_tab === "personal_details" ? "600" : "400",
              
            }}
            onClick={() => setCurrentTab("personal_details")}
            className="col-2"
          >
           <h6 style={{cursor:'pointer'}}> Personal details </h6>
          </div>
          <div
            style={{
              color: current_tab === "salary" ? "black" : "#989898",
              fontWight: current_tab === "salary" ? "600" : "400",
            }}
            onClick={() => setCurrentTab("salary")}
            className="col-1"
          >
            <h6 style={{cursor:'pointer'}}> Salary </h6>
          </div>
          <div
            style={{
              color: current_tab === "tasks" ? "black" : "#989898",
              fontWight: current_tab === "tasks" ? "600" : "400",
            }}
            onClick={() => setCurrentTab("tasks")}
            className="col-1"
          >
            <h6 style={{cursor:'pointer'}}> Tasks </h6>
          </div>
          <div
            style={{
              color: current_tab === "attendence" ? "black" : "#989898",
              fontWight: current_tab === "attendence" ? "600" : "400",
            }}
            onClick={() => setCurrentTab("attendence")}
            className="col-1"
          >
           <h6 style={{cursor:'pointer'}}>Attendence </h6>
          </div>
          <div className="col-7"></div>
        </div>

        {/* <hr className="mb-0" /> */}

        <div className="row"> 
          <div  className="col-2 mt-0     "
            style={{   
              borderTop:
                current_tab === "personal_details" ? "1px solid gray" : "",   
            }}
           
          ></div>
          <div
            style={{
              borderTop: current_tab === "salary" ? "1px solid gray" : "",
            }}
            className="col-1 mt-0"
          ></div>
          <div
            style={{
              borderTop: current_tab === "tasks" ? "1px solid black" : "",
            }}
            className="col-1 mt-0"
          ></div>
          <div
            style={{
              borderTop: current_tab === "attendence" ? "1px solid black" : "",
            }}
            className="col-1 mt-0"
          ></div>
        </div>

        <div className="row">
          <div className="col-12">
          {current_tab === "personal_details" ? (
            <PersonalDetails personal_details={personal_details}/>
          ) : current_tab === "salary" ? (
            <Salary/>
          ) : current_tab === "tasks" ? (
             <Tasks/>
          ) : (
            <h1>Attendence</h1>
          )}
        </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default MyProfile;
