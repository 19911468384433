import { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Topic from "./Topic";
import ScheduledExam from "./ScheduledExam";
import QuestionPaper from "./QuestionPaper";
import ExamHistory from "./ExamHistory";
import { BsSearch } from "react-icons/bs";
import { BiLeftArrowAlt } from "react-icons/bi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FaCalendar } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { HiPlus } from "react-icons/hi";
import { Delete } from "./Svgicon";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import { instance } from "../instance";
import { toast } from "react-toastify";
import { API_URL } from "../env";
import { Offcanvas } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import {
  getBatchFun,
  getBatchFun_search,
  getCourseFun,
  getCourseFun_search,
  getSubjectFun,
  getSubjectFun_search,
} from "../redux/comenapi/comenAction";
import {
  addExamfun,
  getExamfun,
  getexamfun_search,
  getexamhistoryfun_search,
  gethistoryexamfun,
  getupcomingexamfun,
} from "../redux/exam/examActions";
import Slider from "./Slider";

export default function Exam() {
  const { course_search, mysubject_search, mybatchs_search } = useSelector(
    (store) => store.comenapi
  );
  
  const dispatch = useDispatch();
  const colors = ["#2188E7", "#C922E4", "#CC1313", "#d7ac2d"];
  const [TotalData, setTotalData] = useState([]);
  const [State, setState] = useState();
  const [next, setNext] = useState(0);
  const [topic, setTopic] = useState([]);
  const [topic_search, setTopic_search] = useState([]);
  const [addRules, setAddRules] = useState([]);
  // console.log(addRules, "rule");
  const [sudDate, setDate] = useState("");
  const [start_time, setStart_time] = useState("");
  const [end_time, setEnd_time] = useState("");
  const [SubId, setSubId] = useState();

  //my states here

  const [course_to_add, setCourseToAdd] = useState({
    exam_name: "",
    tot_marks: "",
    pass_pers: 0,
    cou_name: "",
    sub_name: "",
    ques_pap: "",
  });

  const [subjects, setSubjects] = useState([]);
  const [examdata, setexamData] = useState([]);
  const [couser_id, setCourseID] = useState("");
  const [cou_nameTopic, setCou_name] = useState();
  const [sub_nameTopic, setSub_name] = useState();

  const [topic_to_add, setTopicToAdd] = useState({
    topic_name: "",
    cou_name: "",
    sub_name: "",
  });
  const [ques_pap_name,setQues_pap_name] = useState('')
  const [numberofquestion,setNumberofquestion] = useState('')
  const [duration,setDuration] = useState('')
  const [question_paper_to_add, setQuestionPaperToAdd] = useState({
    cou_name: "",
    sub_name: "",
    sub_id: "",
  });



  const [live, setLive] = useState([]);
  const [live_search, setLive_search] = useState([]);
  const [question, setQuestion] = useState([]);

  const [TagId, setTagId] = useState([]);
  const [tag_searchs, setTag_search] = useState([]);
  const [tag, setTag] = useState([]);

  //function for add topic
  const addTopic = async (radiovalu) => {
    try {
      let res = await instance.post("/instructor/exams/examtopic/create", {
        topic_name: topic_to_add?.topic_name,
        cou_name: topic_to_add?.cou_name,
        sub_name: topic_to_add?.sub_name,
        subject_id: SubId,
      });
    
      if (res.data.status == 1) {
        getTopic();
        toast(res.data.msg);
        setTopicToAdd({
          topic_name: "",
          cou_name: "",
          sub_name: "",
        });
        setNext(next + 1);
      } else {
        toast(res.data.msg);
      
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTopic = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/exams/read/exam/topics`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
         
          setTopic(result.examTopics);
          setTopic_search(result.examTopics);
          setTag(result.examTopics);
          setTag_search(result.examTopics);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const removeTagforTags = (name, id) => {
    setTagId([...TagId.filter((el) => el !== id)]);
    setTotalData([...TotalData.filter((res) => res.name !== name)]);
  };
  function handleChangeName(id, value) {
    const newtag = TotalData.map((tag) => {
      if (tag.id === id) {
        return { ...tag, value };
      } else {
        return tag;
      }
    });

    setTotalData(newtag);
  }

  const [ArrForm, setArrForm] = useState();
  const OnClickfun = () => {
    const outputObject = {};
    TotalData.forEach((item) => {
      outputObject[item.id] = Number(item.value);
    });

    setArrForm(outputObject);
  };

  //function for get question papers
  const getQuestionPapers = async () => {
    try {
      let res = await instance(`/instructor/exams/read/exam/questionpapers`);
      //  console.log(res,'res')
      if (res.data.status == 1) {
        // console.log(res.data, "res.data.QuestionPapers");
        setLive(res.data.question_papers);
        setLive_search(res.data.question_papers);
        setQuestion(res.data.question_papers);
      } else {
        console.log(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createQuestionpaper = async (question_paper_to_add) => {
    const QuestionPaper = {
      ques_pap_name: ques_pap_name,
      numberofquestion:numberofquestion,
      duration:duration,
      sub_id: SubId,
      rules: addRules,
      cou_name: question_paper_to_add?.cou_name,
      sub_name: question_paper_to_add?.sub_name,
      tagwithpersentage: ArrForm,
    };
    try {
      let res = await instance.post(
        `${API_URL}/instructor/exams/examquestionpaper/create`,
        QuestionPaper
      );
      // console.log(res, "add question ");
      if (res.data.status == 1) {
        toast(res.data.msg);
        setNext(next + 1);
        getQuestionPapers();
      } else {
        toast(res.data.Backend_Error);
      }
    } catch (err) {
      toast("Internal Server Error");
    }
  };

  const search_getQuestionPapers_fun = (toSearch) => {
    try {
      const new_data = live.filter((el) =>
        el.ques_pap_name.toLowerCase().includes(toSearch.toLowerCase())
      );
      setLive_search(new_data);
    } catch (err) {
      console.log(err);
    }
  };

  //function handle changes over the question paper to add;
  const handleAddQuestionPaperChange = (e, radiovalue = "") => {
    const { name, value, type } = e.target;
    type === "radio"
      ? setQuestionPaperToAdd({
          ...question_paper_to_add,
          [name]: radiovalue,
        })
      : setQuestionPaperToAdd({
          ...question_paper_to_add,
          [name]: value,
        });
  };

  //function for handel rules questipon paper
  const handleRulesForQuestionpaper = (e) => {
    const { value } = e.target;
    const index = e.target.getAttribute("index");
    const rulesArrey = [...addRules];
    rulesArrey[index] = value;
    setAddRules([...rulesArrey]);
  };

  //function for get topics
  const search_topic_fun = (toSearch) => {
    try {
      const new_data = topic.filter((el) =>
        el.topic_name.toLowerCase().includes(toSearch.toLowerCase())
      );
      setTopic_search(new_data);
    } catch (err) {
      console.log(err);
    }
  };

  const searched_tag = (toSearch) => {
    try {
      const new_data = tag?.filter((el) =>
        el.topic_name.toLowerCase().includes(toSearch.toLowerCase())
      );

      setTag_search(new_data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeTag = (name, id) => {
    return TotalData.some((el) => el.name === name)
      ? toast("Tag is already selected")
      : (setTotalData([
          ...TotalData,
          { name, id, value: TotalData.length === 0 ? 100 : 0 },
        ]),
        setTagId([...TagId, id]));
  };

  //function for handlechanges for add course
  const handleChangeToAddCourse = (e, radiovalue = "") => {
    const { name, value, type, checked, files } = e.target;
    // console.log(radiovalue, "value");
    if (type === "file") {
      setCourseToAdd({
        ...course_to_add,
        [name]: files[0],
      });
    } else if (type === "radio") {
      setCourseToAdd({
        ...course_to_add,
        [name]: radiovalue,
      });
    }
    //  else if (type === "checkbox") {
    //   // Check if the name property already exists in course_to_add, if not, initialize it as an empty array.
    //   setCourseToAdd((prevCourse) => {
    //     const updatedBatchids = checked
    //       ? [...prevCourse[name], radiovalue]
    //       : prevCourse[name].filter((el) => el !== radiovalue);

    //     return {
    //       ...prevCourse,
    //       [name]: updatedBatchids,
    //     };
    //   });
    // }
    else {
      setCourseToAdd({
        ...course_to_add,
        [name]: value,
      });
    }
  };

  const [slected_batches, setSelectedBatches] = useState([]);
  const [BatchId, setBatchId] = useState([]);

  const removeTag = (name, id) => {
    setBatchId([...BatchId.filter((el) => el !== id)]);
    setSelectedBatches([...slected_batches.filter((res) => res.name !== name)]);
  };

  //function for hnadling checked bvacked ids
  const handleChange1 = (name, id) =>
    slected_batches.some((el) => el.name === name)
      ? toast("batch is already selected")
      : (setSelectedBatches([...slected_batches, { name, id }]),
        setBatchId([...BatchId, id]));

  //function for getting subid by their name;
  const getubjectId = (name) => {
    let res = subjects.filter((el) => el.name === name);
    return res[0].id;
  };

  //  handleChangeAddTopic
  const handleChangeAddTopic = (e, radiovalue = "") => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      setTopicToAdd({
        ...topic_to_add,
        [name]: radiovalue,
      });
    } else {
      setTopicToAdd({
        ...topic_to_add,
        [name]: value,
      });
    }
  };

  // AddRule
  const AddRule = () => {
    var update = [...addRules];
    update = [...addRules, "   "];
    setAddRules([...update]);
  };

  //function for validation
  const handel = () => {
    const { tot_marks, pass_pers, exam_name, ques_pap } = course_to_add;

    if (String(tot_marks).length === 0) {
      toast("Total Marks is required");
    } else if (String(pass_pers).length === 0) {
      toast("Passing Percentage is Required");
    } else if (sudDate === "") {
      toast("Date Is Required");
    } else if (start_time === "") {
      toast("Start Time Is Required");
    } else if (end_time === "") {
      toast("End time is required");
    } else if (exam_name.length === 0) {
      toast("Exam Name Is Required");
    } else if (ques_pap?.name.length === 0) {
      toast("Questions Paper Is Required");
    } else {
      setNext(next + 1);
    }
  };

  const validations = () => {
    const { cou_name } = topic_to_add;

    if (cou_name.length === 0) {
      toast("Course name is required");
    } else {
      setNext(next + 1);
    }
  };

  const TopicValidations = () => {
    const { topic_name } = topic_to_add;

    if (topic_name.length === 0) {
      toast("Topic name is required");
    } else {
      setNext(next + 1);
    }
  };






  const ProceedDone = () => {
    if (addRules[0].length <= 1) {
      toast("Add Rules First ");
    } else {
      setNext(next + 1);
    }
  };

  useEffect(() => {
    getTopic();

    getQuestionPapers();
    setState("ScheduledExam");
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-lg-2 g-0" style={{}}>
          <Sidebar />
        </div>
        <div className="col-lg-10 g-0" style={{}}>
          <Header />
          <div
            className="mt-3 px-3"
            style={{
              width: "100%",
              height: 40,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}>
              Exams
            </div>
            <div
              style={{ display: State == "ScheduledExam" ? "block" : "none" }}
            >
              <button
                onClick={() => {
                  dispatch(getCourseFun());
                  // handleShow();
                }}
                type="button"
                className="btn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightExam"
                aria-controls="offcanvasRight"
                style={{
                  height: 30,
                  backgroundColor: "#3D70F5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "inter",
                  fontWeight: 400,
                  border: "none",
                }}
              >
                + Add Exam
              </button>
            </div>
            <div style={{ display: State == "Topic" ? "block" : "none" }}>
              <button
                onClick={() => {
                  dispatch(getCourseFun());
                }}
                type="button"
                className="btn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightTopic"
                aria-controls="offcanvasRight"
                style={{
                  height: 30,
                  backgroundColor: "#3D70F5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "inter",
                  fontWeight: 400,
                  border: "none",
                }}
              >
                + Add Topic
              </button>
            </div>
            <div
              style={{ display: State == "QuestionPaper" ? "block" : "none" }}
            >
              <button
                type="button"
                className="btn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightQuestion"
                aria-controls="offcanvasRight"
                onClick={() => {
                  dispatch(getCourseFun());
                }}
                style={{
                  height: 30,
                  backgroundColor: "#3D70F5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "inter",
                  fontWeight: 400,
                  border: "none",
                }}
              >
                + Add Question Paper
              </button>
            </div>
          </div>
          <div
            className="row px-3 mt-4 g-0"
            style={{
              height: 40,
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #E4E4E4",
            }}
          >
            <div
              className="col-8"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <div style={{ display: "flex" }}>
                <button
                  type="button"
                  onClick={() => {
                    dispatch(getupcomingexamfun());
                    setState("ScheduledExam");
                  }}
                  className="rounded-0 bg-transparent"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    borderBottom:
                      State == "ScheduledExam" ? "1px solid #3D70F5" : "none",
                    color: State == "ScheduledExam" ? "#3D70F5" : "#000",
                    width: 150,
                    height: 38,
                  }}
                >
                  Scheduled Exams
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState("ExamHistory");
                    dispatch(gethistoryexamfun());
                  }}
                  className="rounded-0 bg-transparent mx-4"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    borderBottom:
                      State == "ExamHistory" ? "1px solid #3D70F5" : "none",
                    color: State == "ExamHistory" ? "#3D70F5" : "#000",
                    width: 100,
                    height: 38,
                  }}
                >
                  History
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState("Topic");
                  }}
                  className="rounded-0 bg-transparent mx-4 text-start"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    borderBottom:
                      State == "Topic" ? "1px solid #3D70F5" : "none",
                    color: State == "Topic" ? "#3D70F5" : "#000",
                    width: 100,
                    height: 38,
                  }}
                >
                  Topic
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState("QuestionPaper");
                  }}
                  className="rounded-0 bg-transparent mx-4 text-start"
                  style={{
                    border: "none",
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    borderBottom:
                      State == "QuestionPaper" ? "1px solid #3D70F5" : "none",
                    color: State == "QuestionPaper" ? "#3D70F5" : "#000",
                    width: 130,
                    height: 38,
                  }}
                >
                  Question Paper
                </button>
              </div>
            </div>

            <div className="col-4">
              <div className="input-group flex-nowrap">
                <span
                  className="input-group-text"
                  id="addon-wrapping"
                  style={{
                    borerRadius: 5,
                    border: "none",
                    backgroundColor: "#F1F1F1",
                  }}
                >
                  <BsSearch style={{ color: "#6A6A6A" }} />
                </span>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                  style={{
                    border: "none",
                    backgroundColor: "#F1F1F1",
                    boxShadow: "none",
                    borerRadius: 5,
                  }}
                  onKeyUp={(e) =>
                    State === "ScheduledExam"
                      ? dispatch(getexamfun_search(e.target.value))
                      : State === "ExamHistory"
                      ? dispatch(getexamhistoryfun_search(e.target.value))
                      : State === "Topic"
                      ? search_topic_fun(e.target.value)
                      : search_getQuestionPapers_fun(e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="bar2 mt-2 mx-4">
            {State === "ScheduledExam" ? (
              <>
                {" "}
                <ScheduledExam />{" "}
              </>
            ) : (
              <></>
            )}
            {State === "ExamHistory" ? (
              <>
                {" "}
                <ExamHistory />{" "}
              </>
            ) : (
              <></>
            )}
            {State === "Topic" ? (
              <>
                {" "}
                <Topic topic={topic_search} getTopic={getTopic} />{" "}
              </>
            ) : (
              <></>
            )}
            {State === "QuestionPaper" ? (
              <>
                {" "}
                <QuestionPaper live={live_search} question={question} />{" "}
              </>
            ) : (
              <></>
            )}
          </div>
          {/* Add Exam Offcanvas Start   */}
          {/* <Offcanvas style={{ width: "50%" }} show={show} placement="end"> */}
          <div
            className="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRightExam"
            aria-labelledby="offcanvasRightLabel"
            style={{ width: "50%" }}
          >
            <div className="offcanvas-header">
              <button
                type="button"
                onClick={() => {
                  // handleClose();
                  setBatchId([]);
                  setSelectedBatches([]);
                  setDate("");
                  setStart_time("");
                  setEnd_time("");
                  setSubId();
                  setCourseToAdd({
                    exam_name: "",
                    tot_marks: "",
                    pass_pers: 0,
                    cou_name: "",
                    sub_name: "",
                    ques_pap: "",
                  });
                }}
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div style={{ display: next === 0 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Exam
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>1</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "25%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Course
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Course"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            onKeyUp={(e) => {
                              dispatch(getCourseFun_search(e.target.value));
                            }}
                          />
                        </div>
                      </div>

                      {/* data of course mapped here */}

                      {course_search &&
                        course_search?.map((itemd, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center mx-3 m-1"
                            >
                              <label
                                className="form-check-label p-0"
                                htmlFor={itemd.id}
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  checked={
                                    itemd.name === course_to_add?.cou_name
                                  }
                                  className="form-check-input"
                                  type="radio"
                                  name={`cou_name`}
                                  id={itemd.id}
                                  style={{
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }}
                                  onChange={(e) => {
                                    // setCourseid(itemd.id);
                                    dispatch(getSubjectFun(itemd.id));
                                    dispatch(getBatchFun(itemd.id));
                                    handleChangeToAddCourse(e, itemd.name);
                                  }}
                                />

                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {itemd.name}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        // onClick={() => {
                        //   setNext(next - 1);
                        // }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        {/* <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 12,
                            fontWeight: 600,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div> */}
                      </div>

                      {/* come return here */}
                      <div
                        className="mt-2"
                        onClick={() => {
                          course_to_add?.cou_name.length === 0
                            ? toast("Please Select course name")
                            : setNext(next + 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                            cursor: "pointer",
                          }}
                        >
                          Next
                        </div>
                        <HiOutlineArrowSmRight style={{ color: "#6B6B6B" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 1 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Exam
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <p
                      className="pt-2 mb-2 text-start"
                      id="demo-radio-buttons-group-label"
                      style={{
                        fontFamily: "inter",
                        fontSize: 18,
                        fontWeight: 600,
                        color: "#000",
                      }}
                    >
                      Optional
                    </p>
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Subject
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Subject"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            onKeyUp={(e) => {
                              dispatch(getSubjectFun_search(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                      {/*subjects_search &&
                        subjects_search.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center mx-3 m-1"
                            >
                              <input
                                onChange={(e) =>
                                  handleChangeToAddCourse(e, item.name)
                                }
                                className="form-check-input"
                                type="radio"
                                name="sub_name"
                                id="flexRadioDefault1"
                                style={{ boxShadow: "none" }}
                              />
                              <label
                                className="form-check-label p-0"
                                for="flexRadioDefault1"
                              >
                                <div
                                  className="py-1"
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {item.name}
                                </div>
                              </label>
                            </div>
                          );
                        })*/}
                      {mysubject_search &&
                        mysubject_search.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center mx-3 m-1"
                            >
                              <label
                                className="form-check-label p-0"
                                htmlFor={item.name}
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  checked={
                                    item.name === course_to_add?.sub_name
                                  }
                                  className="form-check-input"
                                  type="radio"
                                  name="sub_name"
                                  id={item.name}
                                  value={course_to_add?.sub_name}
                                  style={{
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }}
                                  onChange={(e) => {
                                    setSubId(item.id);
                                    handleChangeToAddCourse(e, item.name);
                                  }}
                                />

                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {item.name}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt
                          className="mt-1"
                          style={{ color: "#6B6B6B" }}
                        />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                            cursor: "pointer",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        className="mt-2"
                        onClick={() => {
                          // setNext(next + 1);
                          // getBatches(couser_id);
                          // console.log(couser_id, " getBatches(couser_id);");
                          course_to_add?.sub_name.length === 0
                            ? toast("Please Select Subject name")
                            : setNext(next + 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                          }}
                        >
                          Next
                        </div>
                        <HiOutlineArrowSmRight
                          className="mt-1"
                          style={{ color: "#6B6B6B" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* page for batch */}
              <div style={{ display: next === 2 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Exam
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>1</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "25%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Batches
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Select Batch"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            onKeyUp={(e) => {
                              dispatch(getBatchFun_search(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          overflowX: "scroll",
                          height: "50px",
                          maxHeight: "100%",
                          display: "flex",
                          alignItems: "center",
                          color: "green",
                        }}
                      >
                        {slected_batches?.map((el) => (
                          <div
                            key={el.id}
                            className="px-2 me-2"
                            style={{
                              height: 35,
                              backgroundColor: "#daffda",
                              display: "flex",
                              alignItems: "center",

                              justifyContent: "center",
                              borderRadius: 5,
                            }}
                          >
                            <span
                              style={{ marginRight: "20px", width: "103px" }}
                            >
                              {el?.name}
                            </span>

                            <AiOutlineClose
                              style={{ cursor: "pointer" }}
                              onClick={() => removeTag(el?.name, el.id)}
                            />
                          </div>
                        ))}
                      </div>

                      <div className="barsubject">
                        {mybatchs_search &&
                          mybatchs_search.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="form-check d-flex align-items-center  my-2 "
                              >
                                <label
                                  onClick={() =>
                                    handleChange1(item.batch_name, item.id)
                                  }
                                  className="form-check-label p-0"
                                  htmlFor={`flexRadioDefault${index}`}
                                  style={{
                                    backgroundColor: "rgb(234 237 234)",
                                    border: "1px solid green",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontFamily: "inter",
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#6A6A6A",
                                      cursor: "pointer",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    {item.batch_name}
                                  </div>
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                            cursor: "pointer",
                          }}
                        >
                          Back
                        </div>
                      </div>

                      <div
                        className="mt-2"
                        onClick={() => {
                          BatchId?.length === 0
                            ? toast("Please Select batch name")
                            : setNext(next + 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                            cursor: "pointer",
                          }}
                        >
                          Next
                        </div>
                        <HiOutlineArrowSmRight style={{ color: "#6B6B6B" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: next === 3 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Exam
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "75%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Exam Specifications
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <input
                            value={course_to_add?.tot_marks}
                            onChange={handleChangeToAddCourse}
                            type="text"
                            name="tot_marks"
                            className="form-control"
                            placeholder="Total Marks"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row px-2 mt-4">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 600,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            Passing Percentage
                          </div>
                          <div
                            style={{
                              fontFamily: "inter",
                              fontWeight: 600,
                              color: "#3D70F5",
                              fontSize: 14,
                            }}
                          >
                            {course_to_add?.pass_pers || 0}%
                          </div>
                        </div>
                        <div className="px-2">
                          <span>
                            <input
                              defaultValue={course_to_add?.pass_pers}
                              name="pass_pers"
                              className="w-100 input-range"
                              type="range"
                              min="10"
                              max="100"
                              onChange={handleChangeToAddCourse}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="row mt-2 mx-1">
                        <span
                          className="p-0 text-start"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 400,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          Class Schedule
                        </span>
                        <div className="my-4">
                          <span
                            className="col-4 d-flex rounded-1"
                            style={{
                              backgroundColor: "#EDEDF5",
                              height: "30px",
                            }}
                          >
                            <input
                              value={sudDate}
                              type="date"
                              onChange={(e) => {
                                setDate(e.target.value);
                              }}
                              className="border-0 bg-transparent"
                              style={{ color: "#8A8A8A" }}
                            />
                          </span>
                        </div>
                        <span
                          className="col-3 d-flex rounded-1"
                          style={{ backgroundColor: "#EDEDF5", height: "30px" }}
                        >
                          <input
                            type="time"
                            className="border-0 bg-transparent"
                            style={{ color: "#8A8A8A" }}
                            value={start_time}
                            onChange={(e) => {
                              setStart_time(e.target.value);
                            }}
                          />
                        </span>
                        <span className="col-1 d-flex  mx-2">to</span>
                        <span
                          className="col-3 d-flex rounded-1 "
                          style={{ backgroundColor: "#EDEDF5", height: "30px" }}
                        >
                          <input
                            value={end_time}
                            onChange={(e) => {
                              setEnd_time(e.target.value);
                            }}
                            type="time"
                            className="border-0 bg-transparent"
                            style={{ color: "#8A8A8A" }}
                          />
                        </span>
                      </div>
                      <div className="px-2 mt-3">
                        <div className="input-group flex-nowrap">
                          <input
                            value={course_to_add?.exam_name}
                            onChange={handleChangeToAddCourse}
                            name="exam_name"
                            type="text"
                            className="form-control"
                            placeholder="Name of Exam"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mx-2 g-0 mt-2">
                        <label>
                          <div
                            name="ques_pap"
                            className="my-2"
                            onChange={handleChangeToAddCourse}
                            style={{
                              width: "100%",
                              height: 40,
                              backgroundColor: "#fff",
                              borderRadius: 5,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid #000",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              style={{
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: "inter",
                                color: "#000",
                              }}
                            >
                              Upload Question Paper
                            </span>
                            <input
                              name="ques_pap"
                              onChange={handleChangeToAddCourse}
                              type="file"
                              accept=".pdf"
                              hidden
                            />
                          </div>
                        </label>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "cneter",
                            justifyContent: "cneter",
                          }}
                        >
                          <span>{course_to_add?.ques_pap?.name}</span>
                        </div>
                      </div>
                      <div className="row mx-2">
                        <div
                          className="my-2"
                          onClick={() => {
                            // setNext(next + 1);
                            handel();
                          }}
                          style={{
                            width: "100%",
                            height: 40,
                            backgroundColor: "#3D70F5",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: 16,
                              fontWeight: 400,
                              fontFamily: "inter",
                            }}
                          >
                            Continue
                          </span>
                        </div>
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      onClick={() => {
                        setNext(next - 1);
                      }}
                      style={{
                        width: 50,
                        height: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                      <div
                        style={{
                          fontFamily: "inter",
                          fontSize: 12,
                          fontWeight: 600,
                          color: "#6B6B6B",
                        }}
                      >
                        Back
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 4 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Exam
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>4</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "100%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="px-0 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 24,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Preview
                      </p>
                      <div>
                        <div
                          className="text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#434343",
                          }}
                        >
                          Name
                        </div>
                        <div
                          className="text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#434343",
                          }}
                        >
                          {course_to_add?.exam_name}
                        </div>
                      </div>
                      <div
                        className="row g-0 text-start mt-2"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          className="col-2"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            color: "#434343",
                            fontWeight: 600,
                          }}
                        >
                          Course
                        </span>
                        <span
                          className="col-10 text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            color: "#434343",
                            fontWeight: 800,
                          }}
                        >
                          {course_to_add?.cou_name}
                        </span>
                      </div>
                      <div
                        className="row g-0 text-start"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          className="col-2 m-0"
                          style={{
                            fontFamily: "inter",
                            fontSize: 15,
                            color: "#434343",
                            fontWeight: 600,
                          }}
                        >
                          Subject
                        </span>
                        <span
                          className="col-10 m-0"
                          style={{
                            fontFamily: "inter",
                            fontSize: 15,
                            color: "#434343",
                            fontWeight: 800,
                            textAlign: "left ",
                          }}
                        >
                          {course_to_add?.sub_name}
                        </span>
                      </div>
                      <div
                        className="row g-0 mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          className="m-0 text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 15,
                            color: "#434343",
                            fontWeight: 600,
                          }}
                        >
                          Total Marks : {course_to_add?.tot_marks}
                        </span>
                      </div>
                      {/*<div
                        className="row g-0 mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          className="m-0 text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 15,
                            color: "#434343",
                            fontWeight: 600,
                          }}
                        >
                          Batch
                        </span>
                        <ul className="list-group">
                          {course_to_add?.batchids?.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className="list-group-item border-0 text-start p-0"
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                        </div>*/}
                      <div
                        className="mt-4"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          Passing Percentage{" "}
                        </div>
                        <div
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            color: "#3D70F5",
                            fontSize: 14,
                          }}
                        >
                          &nbsp;&nbsp;{course_to_add?.pass_pers} %
                        </div>
                      </div>
                      <div className="mt-2">
                        <div
                          className="text-start"
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#434343",
                          }}
                        >
                          Question Paper
                        </div>
                        <div className="row">
                          <div className="col-8">
                            <div
                              style={{
                                width: "100%",
                                height: 30,
                                border: "1px solid #D7D7D7",
                                borderRadius: 5,
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "inter",
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {course_to_add?.ques_pap?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3 mb-4">
                        <span
                          className="px-3 text-start"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            color: "#434343",
                            fontSize: 14,
                          }}
                        >
                          Exam Schedule
                        </span>
                        <div className="col-4">
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              backgroundColor: "#EDEDF5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <FaCalendar />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 600,
                                fontSize: 14,
                                fontFamily: "inter",
                                paddingLeft: 10,
                              }}
                            >
                              {sudDate}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* start time */}
                      <div className="row mt-3 mb-4">
                        <div className="col-4">
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              backgroundColor: "#EDEDF5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <FiClock />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 600,
                                fontSize: 14,
                                fontFamily: "inter",
                                paddingLeft: 10,
                              }}
                            >
                              {start_time}
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              backgroundColor: "#EDEDF5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <FiClock />
                            <span
                              style={{
                                color: "#3C3C3C",
                                fontWeight: 600,
                                fontSize: 14,
                                fontFamily: "inter",
                                paddingLeft: 10,
                              }}
                            >
                              {end_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 12,
                            fontWeight: 600,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          dispatch(
                            addExamfun(
                              {
                                exam_name: course_to_add?.exam_name,
                                tot_marks: course_to_add?.tot_marks,
                                pass_pers: course_to_add?.pass_pers,
                                sub_id: SubId,
                                sub_name: course_to_add?.sub_name,
                                cou_name: course_to_add?.cou_name,
                                start_time: sudDate + "T" + start_time,
                                end_time: sudDate + "T" + end_time,
                                batchids: JSON.stringify(BatchId),
                                ques_pap: course_to_add?.ques_pap,
                              },
                              setNext,
                              next
                            )
                          );
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#fff",
                          }}
                        >
                          Confirm
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 5 ? "block" : "none" }}>
                <div className="row mt-5">
                  <p
                    className="col-10 m-auto mt-5"
                    style={{
                      fontFamily: "inter",
                      fontSize: 30,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Exam Created Successfully
                  </p>
                </div>
                <div className="row">
                  <div
                    className="col-8 m-auto mt-4 px-5"
                    style={{
                      fontFamily: "inter",
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    New Exam within {course_to_add?.cou_name} in Health
                    Informatics with subject {course_to_add?.sub_name} Added
                    Succesfully with the following Name :
                    <span style={{ color: "#000", fontWeight: "bold" }}>
                      {course_to_add?.exam_name}
                    </span>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-4 m-auto">
                    <button
                      type="button"
                      onClick={() => {
                        setNext(0);
                        setBatchId([]);
                        setSelectedBatches([]);
                        setDate("");
                        setStart_time("");
                        setEnd_time("");
                        setSubId();
                        setCourseToAdd({
                          exam_name: "",
                          tot_marks: "",
                          pass_pers: 0,

                          cou_name: "",
                          sub_name: "",
                          ques_pap: "",
                        });
                      }}
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      style={{
                        width: "100%",
                        height: 35,
                        backgroundColor: "#3D70F5",
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        Back to Dashboard
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </Offcanvas> */}
          {/* Add Exam Offcanvas End   */}

          {/* Add New Topic Offcanvas Start  */}
          <div
            className="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRightTopic"
            aria-labelledby="offcanvasRightLabel"
            style={{ width: "50%" }}
          >
            <div className="offcanvas-header">
              <button
                type="button"
                onClick={() => {
                  setNext(0);
                }}
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div style={{ display: next === 0 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Topic
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>1</span>/3 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "65%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="65"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Course
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Select Course"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            onKeyUp={(e) => {
                              dispatch(getCourseFun_search(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                      {/* 
                      come here */}

                      {course_search &&
                        course_search?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center mx-3 m-1"
                            >
                              <label
                                className="form-check-label p-0"
                                htmlFor={item.id}
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  checked={item.name === topic_to_add?.cou_name}
                                  className="form-check-input"
                                  type="radio"
                                  name={`cou_name`}
                                  id={item.id}
                                  style={{
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }}
                                  onChange={(e) => {
                                    dispatch(getSubjectFun(item.id));
                                    handleChangeAddTopic(e, item.name);
                                  }}
                                />

                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {item.name}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        // onClick={() => {
                        //   setNext(next - 1);
                        // }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      ></div>

                      {/* come return here */}
                      <div
                        className="mt-2"
                        onClick={() => {
                          topic_to_add?.cou_name.length === 0
                            ? toast("Please Select course name")
                            : setNext(next + 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                            cursor: "pointer",
                          }}
                        >
                          Next
                        </div>
                        <HiOutlineArrowSmRight style={{ color: "#6B6B6B" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: next === 1 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Topic
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>3</span>/3 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "100%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Subject
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Subject"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            onKeyUp={(e) => {
                              dispatch(getSubjectFun_search(e.target.value));
                            }}
                          />
                        </div>
                      </div>

                      {/* come here....*/}
                      {mysubject_search &&
                        mysubject_search.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center mx-3 m-1"
                            >
                              <label
                                className="form-check-label p-0"
                                htmlFor={item.name}
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  checked={item.name === topic_to_add?.sub_name}
                                  className="form-check-input"
                                  type="radio"
                                  name="sub_name"
                                  id={item.name}
                                  value={topic_to_add?.sub_name}
                                  style={{
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }}
                                  onChange={(e) => {
                                    setSubId(item.id);
                                    setSub_name(item?.name);
                                    handleChangeAddTopic(e, item.name);
                                  }}
                                />

                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {item.name}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt
                          className="mt-1"
                          style={{ color: "#6B6B6B" }}
                        />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                            cursor: "pointer",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        className="mt-2"
                        onClick={() => {
                          topic_to_add?.sub_name.length === 0
                            ? toast("Please Select Subject name")
                            : setNext(next + 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                          }}
                        >
                          Next
                        </div>
                        <HiOutlineArrowSmRight
                          className="mt-1"
                          style={{ color: "#6B6B6B" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 2 ? "block" : "none" }}>
                <div className="row d-flex justify-content-between">
                  <p
                    className="col-5"
                    style={{
                      fontFamily: "inter",
                      fontSize: 26,
                      fontWeight: 600,
                    }}
                  >
                    Create New Topic
                  </p>
                </div>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/3 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "75%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p
                  className="text-start pt-5"
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Enter Topic
                </p>
                <hr />
                <div className="que-sec">
                  <div className="row p-0 mb-4">
                    <div className="col-11">
                      <Card className="">
                        <div className="row pe-2 g-0 d-flex align-items-center">
                          <div className="col-12 p-2">
                            <div
                              className="mb-2"
                              style={{
                                width: "100%",
                                backgroundColor: "#F3F3F3",
                                border: "1px solid #F3F3F3",
                                borderRadius: 5,
                              }}
                            ></div>
                            <div
                              className=""
                              style={{
                                width: "100%",
                                backgroundColor: "#F3F3F3",
                                border: "1px solid #F3F3F3",
                                borderRadius: 5,
                              }}
                            >
                              <div className="input-group flex-nowrap">
                                <input
                                  onChange={handleChangeAddTopic}
                                  type="text"
                                  name="topic_name"
                                  className="form-control"
                                  placeholder="Enter Topic name"
                                  aria-label="Username"
                                  aria-describedby="addon-wrapping"
                                  style={{
                                    borderRadius: 0,
                                    border: "none",
                                    boxShadow: "none",
                                    backgroundColor: "#DDDDDD",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6"></div>
                          </div>
                        </div>
                        <div className="row pe-2 g-0 pt-2">
                          <div className="col-12">
                            <div className="row g-0">
                              <div className="col-12"></div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <button
                            type="button"
                            onClick={() => {
                              // setNext(next + 1);
                              TopicValidations();
                            }}
                            className="btn col-3 mx-auto my-3"
                            style={{
                              height: 30,
                              backgroundColor: "#3D70F5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                              color: "#fff",
                              fontSize: 14,
                              fontFamily: "inter",
                              fontWeight: 400,
                              border: "none",
                            }}
                          >
                            Proceed
                          </button>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 3 ? "block" : "none" }}>
                <div className="row mt-5">
                  <div className="col-6 m-auto text-center">
                    <div
                      style={{
                        fontFamily: "inter",
                        fontSize: 24,
                        fontWeight: 600,
                      }}
                    >
                      New Topic "{topic_to_add?.topic_name} In{" "}
                      {topic_to_add?.sub_name}"
                    </div>
                    <div className="row mt-5">
                      <div
                        className="btn col-4 me-auto"
                        style={{
                          height: 30,
                          backgroundColor: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          color: "#FD2727",
                          fontSize: 14,
                          fontFamily: "inter",
                          fontWeight: 400,
                          border: "1px solid #FD2727",
                        }}
                      >
                        Delete
                      </div>
                      <div
                        onClick={() => {
                          addTopic();
                        }}
                        className="btn col-4 ms-auto"
                        style={{
                          height: 30,
                          backgroundColor: "#3D70F5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          color: "#fff",
                          fontSize: 14,
                          fontFamily: "inter",
                          fontWeight: 400,
                          border: "none",
                        }}
                      >
                        Confirm
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 4 ? "block" : "none" }}>
                <div className="row mt-5">
                  <p
                    className="col-7 m-auto mt-5"
                    style={{
                      fontFamily: "inter",
                      fontSize: 30,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    New Tag {topic_to_add?.topic_name} Created Succesfully
                  </p>
                </div>
                <div className="row mt-4">
                  <div className="col-4 m-auto">
                    <NavLink to="/Exam" className="text-decoration-none">
                      <div
                        style={{
                          width: "100%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setNext(0);
                          setTopicToAdd({
                            topic_name: "",
                            cou_name: "",
                            sub_name: "",
                          });
                        }}
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Back to Dashboard
                        </span>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Add New Topic Offcanvas End  */}

          {/* Add New Question Paper Offcanvas Start */}
          <div
            className="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRightQuestion"
            aria-labelledby="offcanvasRightLabel"
            style={{ width: "50%" }}
          >
            <div className="offcanvas-header">
              <button
                type="button"
                onClick={() => {
                  setAddRules([]);
                  setSubId();
                  setArrForm();
                  setDuration('')
                  setNumberofquestion('')
                  setQues_pap_name('')
                  setQuestionPaperToAdd({
                    cou_name: "",
                    sub_name: "",
                    sub_id: "",
                  });
                  setNext(0);
                  setTag_search([]);
                  setTotalData([])
                  setTag([]);
                }}
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div style={{ display: next === 0 ? "block" : "none" }}>
                <p
                  className="text-start"
                  style={{ fontFamily: "inter", fontSize: 26, fontWeight: 600 }}
                >
                  Create New Question Paper
                </p>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>1</span>/3 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "25%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-5 mt-5 m-auto">
                    <Card className="p-2 border-0">
                      <span
                        className="text-start"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        Name
                      </span>
                      <div className="input-group flex-nowrap mt-3">
                        <input
                        value={ques_pap_name}
                          onChange={(e)=>{setQues_pap_name(e.target.value)}}
                          name="ques_pap_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter The Title"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          style={{
                            borderRadius: 5,
                            border: "none",
                            backgroundColor: "#F4F4F4",
                            boxShadow: "none",
                          }}
                        />
                      </div>
                      <div className="input-group flex-nowrap mt-3">
                        <input
                        value={duration}
                          onChange={(e)=>{setDuration(e.target.value)}}
                          name="duration"
                          type="text"
                          className="form-control"
                          placeholder="Enter duration"
                          aria-label="duration"
                          aria-describedby="addon-wrapping"
                          style={{
                            borderRadius: 5,
                            border: "none",
                            backgroundColor: "#F4F4F4",
                            boxShadow: "none",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          ques_pap_name === ""?toast("Titleis required"):duration === "" ?toast("duration required"):setNext(next + 1);
                        }}
                        className="mt-3"
                        style={{
                          width: "100%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                          }}
                        >
                          Proceed
                        </span>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 1 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Question Paper
                </h3>

                {/* come here */}

                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/3 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "65%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="65"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="py-3">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Course
                      </p>
                      <div className="px-2 py-2">
                        <div className="input-group flex-nowrap ">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: "8px 0px 0px 8px",
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="text"
                            className="form-control py-2"
                            placeholder="Select Course Name "
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: "0px 8px 8px 0px",
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            onKeyUp={(e) => {
                              dispatch(getCourseFun_search(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                      {course_search &&
                        course_search?.map((itemd, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center mx-3 m-1"
                            >
                              <label
                                className="form-check-label p-0"
                                htmlFor={itemd.id}
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  checked={
                                    itemd.name ===
                                    question_paper_to_add?.cou_name
                                  }
                                  className="form-check-input"
                                  type="radio"
                                  name={`cou_name`}
                                  id={itemd.id}
                                  style={{
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }}
                                  onChange={(e) => {
                                    dispatch(getSubjectFun(itemd.id));
                                    handleAddQuestionPaperChange(e, itemd.name);
                                  }}
                                />

                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {itemd.name}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        // onClick={() => {
                        //   setNext(next - 1);
                        // }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      ></div>

                      {/* come return here */}
                      <div
                        className="mt-2"
                        onClick={() => {
                          question_paper_to_add?.cou_name.length === 0
                            ? toast("Please Select course name")
                            : setNext(next + 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                            cursor: "pointer",
                          }}
                        >
                          Next
                        </div>
                        <HiOutlineArrowSmRight style={{ color: "#6B6B6B" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* page added for select subject */}

              <div style={{ display: next === 2 ? "block" : "none" }}>
                <h3
                  className="offcanvas-title text-start"
                  id="offcanvasRightLabel"
                  style={{ fontFamily: "inter", fontWeight: 600 }}
                >
                  Create New Exam
                </h3>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <p
                      className="pt-2 mb-2 text-start"
                      id="demo-radio-buttons-group-label"
                      style={{
                        fontFamily: "inter",
                        fontSize: 18,
                        fontWeight: 600,
                        color: "#000",
                      }}
                    >
                      Optional
                    </p>
                    <Card className="">
                      <p
                        className="px-3 pt-2 mb-2 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Subject
                      </p>
                      <div className="px-2">
                        <div className="input-group flex-nowrap">
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                            }}
                          >
                            <BsSearch style={{ color: "#6A6A6A" }} />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Subject"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            style={{
                              borderRadius: 0,
                              border: "none",
                              backgroundColor: "#F1F1F1",
                              boxShadow: "none",
                            }}
                            onKeyUp={(e) => {
                              dispatch(getSubjectFun_search(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                      {mysubject_search &&
                        mysubject_search.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center mx-3 m-1"
                            >
                              <label
                                className="form-check-label p-0"
                                htmlFor={item.name}
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  checked={
                                    item.name ===
                                    question_paper_to_add?.sub_name
                                  }
                                  className="form-check-input"
                                  type="radio"
                                  name="sub_name"
                                  id={item.name}
                                  value={question_paper_to_add?.sub_name}
                                  style={{
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }}
                                  onChange={(e) => {
                                    setSubId(item.id);
                                    setSub_name(item?.name);
                                    handleAddQuestionPaperChange(e, item.name);
                                  }}
                                />

                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    paddingLeft: 10,
                                  }}
                                >
                                  {item.name}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt
                          className="mt-1"
                          style={{ color: "#6B6B6B" }}
                        />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                            cursor: "pointer",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        className="mt-2"
                        onClick={() => {
                          getTopic()
                          question_paper_to_add?.sub_name.length === 0
                            ? toast("Please Select Subject name")
                            : setNext(next + 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#6B6B6B",
                          }}
                        >
                          Next
                        </div>
                        <HiOutlineArrowSmRight
                          className="mt-1"
                          style={{ color: "#6B6B6B" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: next === 3 ? "block" : "none" }}>
                <p
                  style={{
                    fontFamily: "inter",
                    fontSize: 22,
                    fontWeight: 600,
                  }}
                >
                  Create New Quiz
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>3</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "75%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="px-3 pt-2 mb-2"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Select Tag
                      </p>
                      <div className="input-group flex-nowrap">
                        <span
                          className="input-group-text"
                          id="addon-wrapping"
                          style={{
                            borderRadius: 0,
                            border: "none",
                            backgroundColor: "#F1F1F1",
                          }}
                        >
                          <BsSearch style={{ color: "#6A6A6A" }} />
                        </span>
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search Tags"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          style={{
                            borderRadius: 0,
                            border: "none",
                            backgroundColor: "#F1F1F1",
                            boxShadow: "none",
                          }}
                          onKeyUp={(e) => searched_tag(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          overflowX: "scroll",
                          height: "50px",
                          maxHeight: "100%",
                          display: "flex",
                          alignItems: "center",
                          color: "green",
                        }}
                      >
                        {TotalData?.map((el) => (
                          <div
                            key={el.id}
                            className="px-2 me-2"
                            style={{
                              height: 35,
                              backgroundColor: "#daffda",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 5,
                            }}
                          >
                            <span style={{ marginRight: "20px" }}>
                              {el?.name}
                            </span>

                            <AiOutlineClose
                              style={{ cursor: "pointer" }}
                              onClick={() => removeTagforTags(el?.name, el.id)}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="barsubject">
                        {tag_searchs?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="form-check d-flex align-items-center  my-2 "
                            >
                              <label
                                onClick={(e) => {
                                  handleChangeTag(item.topic_name, item.id);
                                }}
                                className="form-check-label p-0"
                                htmlFor={`flexRadioDefault${index}`}
                                style={{
                                  backgroundColor: "rgb(234 237 234)",
                                  border: "1px solid green",
                                  borderRadius: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    fontFamily: "inter",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#6A6A6A",
                                    cursor: "pointer",
                                    padding: "5px 20px",
                                  }}
                                >
                                  {item.topic_name}
                                </div>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          TagId?.length === 0
                            ? toast("please select Tag name")
                            : setNext(next + 1);
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Proceed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 4 ? "block" : "none" }}>
                <p
                  style={{
                    fontFamily: "inter",
                    fontSize: 22,
                    fontWeight: 600,
                  }}
                >
                  Create New Quiz
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <p
                      className="px-3 pt-2 mb-2"
                      id="demo-radio-buttons-group-label"
                      style={{
                        fontFamily: "inter",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#000",
                      }}
                    >
                      Select Question Composition
                    </p>

                    <Card
                      className="p-2"
                      style={{ height: "300px", overflowY: "scroll" }}
                    >
                      {TotalData?.map((Value, index) => {
                        return (
                          <>
                            <div
                              className="mt-3"
                              style={{ textAlign: "start" }}
                            >
                              <div
                                className="d-flex"
                                style={{
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  className="ms-3 fw-bolder"
                                  style={{ fontSize: 14 }}
                                >
                                  {Value.name}
                                </span>
                                <span style={{ marginLeft: "190px" }}>
                                  {parseInt(Value.value)}%
                                </span>
                                <br />
                              </div>

                              <Slider
                                style={{
                                  accentColor: colors[index % 4],
                                  cursor: "pointer",
                                }}
                                key={Value.id}
                                id={Value.id}
                                name={Value.name}
                                value={Value.value}
                                total={TotalData.reduce(
                                  (total, Value) => total + Value.value,
                                  0
                                )}
                                onChange={handleChangeName}
                              />
                            </div>
                          </>
                        );
                      })}
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="mt-2"
                        onClick={() => {
                          setNext(next - 1);
                        }}
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                            cursor: "pointer",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setNext(next + 1);
                          OnClickfun();
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Proceed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 5 ? "block" : "none" }}>
                <p
                  className="text-start"
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Question Paper
                </p>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/4 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="row mt-5">
                  <div className="col-8 m-auto">
                    <Card className="p-2">
                      <p
                        className="px-1 pt-2 mb-0 text-start"
                        id="demo-radio-buttons-group-label"
                        style={{
                          fontFamily: "inter",
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#000",
                        }}
                      ></p>
                      <div className="barsubject">
                        {/* div for no of question */}

                        <div className="row mt-2 mx-1">
                          <span
                            className="p-0 text-start"
                            style={{
                              fontFamily: "inter",
                              fontWeight: 400,
                              color: "#434343",
                              fontSize: 14,
                            }}
                          >
                            Number of Questions
                          </span>
                          <span
                            className="col-5 d-flex rounded-0 me-2"
                            style={{
                              backgroundColor: "#EDEDF5",
                              height: "30px",
                            }}
                          >
                            <input
                              type="number"
                              className="border-0 bg-transparent"
                              style={{ color: "#8A8A8A" }}
                              name="numberofquestion"
                              value={numberofquestion}
                              onChange={(e)=>setNumberofquestion(e.target.value)}
                            />
                          </span>
                        </div>

                      
                      </div>
                    </Card>
                    <div
                      className="mt-2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        onClick={() => setNext(next - 1)}
                        className="mt-2"
                        style={{
                          width: 50,
                          height: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <BiLeftArrowAlt style={{ color: "#6B6B6B" }} />
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#6B6B6B",
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          numberofquestion === "" ?toast(" number of question required"):setNext(next + 1);
                        }}
                        style={{
                          width: "25%",
                          height: 35,
                          backgroundColor: "#3D70F5",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Proceed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 6 ? "block" : "none" }}>
                <p
                  className="text-start"
                  style={{ fontFamily: "inter", fontSize: 22, fontWeight: 600 }}
                >
                  Create New Question Paper
                </p>
                <p
                  className="m-0 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#787878",
                  }}
                >
                  <span style={{ color: "#3D70F5" }}>2</span>/3 Steps
                </p>
                <div
                  className="progress"
                  style={{ height: "8px", borderRadius: 0 }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "70%",
                      background: "#3D70F5",
                      borderRadius: 0,
                    }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="add-rules mt-4">
                  <div className="row p-0 mb-4">
                    <div className="col-11">
                      <Card className="">
                        {/* <div className="row pe-2 g-0 d-flex align-items-center">
                          <div className="col-12 p-2">
                            <div
                              className="mb-2"
                              style={{
                                width: "100%",
                                backgroundColor: "#F3F3F3",
                                border: "1px solid #F3F3F3",
                                borderRadius: 5,
                              }}
                            >
                              <div className="input-group flex-nowrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type Here..."
                                  aria-label="Username"
                                  aria-describedby="addon-wrapping"
                                  style={{
                                    borderRadius: 0,
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className=""
                              style={{
                                width: "100%",
                                backgroundColor: "#F3F3F3",
                                border: "1px solid #F3F3F3",
                                borderRadius: 5,
                              }}
                            >
                              <div className="input-group flex-nowrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Rules..."
                                  aria-label="Username"
                                  aria-describedby="addon-wrapping"
                                  style={{
                                    borderRadius: 0,
                                    border: "none",
                                    boxShadow: "none",
                                    backgroundColor: "#DDDDDD",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="row pe-2 g-0 pt-2">
                          <div className="col-12">
                            <div className="row g-0">
                              <div className="col-12">
                                {addRules.map((el, ind) => {
                                  return (
                                    <>
                                      <div key={ind}>
                                        <div className="row g-0">
                                          <div className="col-12 p-2">
                                            <div
                                              className=""
                                              style={{
                                                width: "100%",
                                                backgroundColor: "#F3F3F3",
                                                border: "1px solid #F3F3F3",
                                                borderRadius: 5,
                                              }}
                                            >
                                              <div className="input-group flex-nowrap">
                                                <input
                                                  index={ind}
                                                  onChange={
                                                    handleRulesForQuestionpaper
                                                  }
                                                  name="rules"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Rules..."
                                                  aria-label="Username"
                                                  aria-describedby="addon-wrapping"
                                                  style={{
                                                    borderRadius: 0,
                                                    border: "none",
                                                    boxShadow: "none",
                                                    backgroundColor: "#DDDDDD",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                    <div className="col-1 p-0">
                      <div style={{ width: "100%", height: 40 }}>
                        <button
                          type="button"
                          className="btn"
                          onClick={AddRule}
                          style={{
                            width: 40,
                            height: 40,
                            backgroundColor: "#F3F3F3",
                            borderRadius: 50,
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <HiPlus style={{ color: "#696969" }} />
                        </button> 
                        <button
                          type="button"
                          className="btn mt-1"
                          style={{
                            width: 40,
                            height: 40,
                            backgroundColor: "#F3F3F3",
                            borderRadius: 50,
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Delete />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <button
                    type="button"
                    onClick={() => {
                      ProceedDone();
                    }}
                    className="btn col-3 mx-auto"
                    style={{
                      height: 30,
                      backgroundColor: "#3D70F5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      color: "#fff",
                      fontSize: 14,
                      fontFamily: "inter",
                      fontWeight: 400,
                      border: "none",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
              <div style={{ display: next === 7 ? "block" : "none" }}>
                <div className="row mt-5">
                  <div className="col-6 m-auto text-center">
                    <div
                      style={{
                        fontFamily: "inter",
                        fontSize: 24,
                        fontWeight: 600,
                      }}
                    >
                      New Question Paper {question_paper_to_add?.ques_pap_name}
                    </div>
                    <div
                      style={{
                        fontFamily: "inter",
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      Will be created with in {question_paper_to_add?.cou_name}
                    </div>
                    <div className="row mt-5">
                      <div
                        onClick={() => {
                          setAddRules([]);
                          setSubId();
                          setArrForm();
                          setQuestionPaperToAdd({
                        
                            cou_name: "",
                            sub_name: "",
                            sub_id: "",
                          });
                          setDuration('')
                          setNumberofquestion("")
                          setQues_pap_name('')
                          setTotalData([])
                          setNext(0);
                          setTag_search([]);
                          setTag([]);
                        }}
                        className="btn col-4 me-auto"
                        style={{
                          height: 30,
                          backgroundColor: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          color: "#FD2727",
                          fontSize: 14,
                          fontFamily: "inter",
                          fontWeight: 400,
                          border: "1px solid #FD2727",
                        }}
                      >
                        Delete
                      </div>

                      {/* return back here */}
                      <div
                        onClick={() =>
                          createQuestionpaper(
                            question_paper_to_add,
                            setNext,
                            next
                          )
                        }
                        className="btn col-4 ms-auto"
                        style={{
                          height: 30,
                          backgroundColor: "#3D70F5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          color: "#fff",
                          fontSize: 14,
                          fontFamily: "inter",
                          fontWeight: 400,
                          border: "none",
                        }}
                      >
                        Confirm
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: next === 8 ? "block" : "none" }}>
                <div className="row mt-5">
                  <div className="col-6 m-auto text-center">
                    <div
                      style={{
                        fontFamily: "inter",
                        fontSize: 24,
                        fontWeight: 600,
                      }}
                    >
                      New Question Paper {question_paper_to_add?.ques_pap_name}
                    </div>
                    <div className="row mt-5">
                      <div
                        onClick={() => {
                          setAddRules([]);
                          setSubId();
                          setArrForm();
                          setQuestionPaperToAdd({
                          cou_name: "",
                            sub_name: "",
                            sub_id: "",
                          });
                          setDuration('')
                          setNumberofquestion('')
                          setQues_pap_name('')
                          setTotalData([])
                          setNext(0);
                          setTag_search([]);
                          setTag([]);
                        }}
                        className=" btn col-6 m-auto"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        style={{
                          height: 30,
                          backgroundColor: "#3D70F5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          color: "#fff",
                          fontSize: 14,
                          fontFamily: "inter",
                          fontWeight: 400,
                          border: "none",
                        }}
                      >
                        Back to Dashboard
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Add New Question Paper Offcanvas End */}
        </div>
      </div>
    </div>
  );
}
