import { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { API_URL } from "../env";
import TagDetail from "./TagDetail";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getTopicByIdFun, getTopicFun } from "../redux/quizzes/quizzesActions";

export default function Quizzbank({ ...props }) {

  const { topicdata } = useSelector(
    (store) => store.quizzes
  );

  const dispatch = useDispatch();

  console.log(topicdata,"topicdata")
  const [live, setLive] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const quizData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/quiz/readQuestionbank`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          // console.log(result.questionbank);
          setLive(result.questionbank);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const deleteTag = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/delete/tag/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result)
        toast(result.message);
        quizData();
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    dispatch(getTopicFun())
    quizData();
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  return (
    <>
      <div className="bar3">
        <div className="row mt-2" style={{}}>
          {topicdata &&
            topicdata?.map((item,) => {
              return (
                
                <div className="col-4" key={item.id}>
            
              <Card
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid #E6E6E6",
                    marginBottom: 20,
                    width: "95%",
                  }}
                >
                  <Card.Body className="text-start">
                    <div className="row">
                      <span
                        className="col-6 bg-light py-2 text-center ms-2"
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          textAlign: "start",
                          fontFamily: "inter",
                        }}
                      >
                        {item.topic_name}
                      </span>
                    </div>
                    <div className="row mt-2">
                      <div
                        className="col-12 m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          color: "#9A9A9A",
                          fontWeight: 400,
                        }}
                      >
                        Questions :{" "}
                        <span
                          style={{
                            fontFamily: "inter",
                            fontSize: 14,
                            color: "#F93333",
                            fontWeight: 600,
                          }}
                        >
                          {item.tot_no_ques}
                        </span>
                      </div>
                    </div>
                    <div
                      className="row g-0 mt-2"
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <p
                        className="col-2 m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 13,
                          color: "#9A9A9A",
                          fontWeight: 400,
                        }}
                      >
                        Course
                      </p>
                      <p
                        className="col-10 m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 13,
                          color: "##000000",
                          fontWeight: 400,
                          textAlign: "left ",
                        }}
                      >
                        {" "}
                        :&nbsp;&nbsp;{item.course_name}
                      </p>
                    </div>
                    <div
                      className="row g-0 mt-2"
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <p
                        className="col-2 m-0"
                        style={{
                          fontFamily: "inter",
                          fontSize: 13,
                          color: "#9A9A9A",
                          fontWeight: 400,
                        }}
                      >
                        Subject
                      </p>
                      <p
                        className="col-10"
                        style={{
                          fontFamily: "inter",
                          fontSize: 13,
                          color: "##000000",
                          fontWeight: 400,
                          textAlign: "left ",
                        }}
                      >
                        {" "}
                        :&nbsp;&nbsp;{item.subject_name}
                      </p>
                    </div>
        
                    <div
                      className="mt-3"
                      style={{
                        width: "100%",
                        height: 40,
                        backgroundColor: "#3D70F5",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // setTopic_id(props.item.id);
                        // tagreadbyid(props.item.id);
                        dispatch(getTopicByIdFun(item.id))
                        handleShow();
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          fontSize: 14,
                          fontWeight: 400,
                          fontFamily: "inter",
                        }}
                      >
                        View Details
                      </span>
                    </div>
                  </Card.Body>
                      </Card>
              </div>
                
              );
            })}
        </div>
      </div>

      <TagDetail
      show={show}
      handleClose={handleClose}
   
    />
    </>
  );
}

// const LiveClass = (props) => {


//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const [tagbyid, setTagbyid] = useState([]);
//   const [topic_id, setTopic_id] = useState([]);

//   const AddQuestion = async (text,inputList,ans,setState,state,countdownTimer) => {
//     var myHeaders = new Headers();
//     myHeaders.append(
//       "Authorization",
//       `Bearer ${localStorage.getItem("token")}`
//     );

//     var formdata = new FormData();
//     formdata.append("question", text);
//     formdata.append("options", JSON.stringify(inputList));
//     formdata.append("ans", +ans);
//     formdata.append("topic_id",topic_id);
//     // formdata.append("questionimage", file);

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch(`${API_URL}/upload/question`, requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status == 1) {
//           console.log(result, "add question in function st 1");
//           props.quizData();
//           tagreadbyid(props.item.id);
//           setState(state + 1);
//           setTimeout(() => {
//               setState(state + 1);
//               countdownTimer(15);
//             }, 1000);
//             toast(result.message)
//         } else {
//           console.log(result, "add question in function st 0");
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };


//   const tagreadbyid = (id) => {
    
//     var myHeaders = new Headers();
//     myHeaders.append(
//       "Authorization",
//       `Bearer ${localStorage.getItem("token")}`
//     );

//     var requestOptions = {
//       method: "GET",
//       headers: myHeaders,
//       redirect: "follow",
//     };

//     fetch(`${API_URL}/readtagbyid/${+id}`, requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status == "1") {
//           console.log(result, "Read by id tag");
//           setTagbyid(result.data);
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };

//   const deleteQuestion = (id) => {
//     var myHeaders = new Headers();
//     myHeaders.append(
//       "Authorization",
//       `Bearer ${localStorage.getItem("token")}`
//     );

//     var requestOptions = {
//       method: "DELETE",
//       headers: myHeaders,
//       redirect: "follow",
//     };

//     fetch(`${API_URL}/deletequestionbyid/${id}`, requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status == 1) {
//           console.log(result);
//           toast(result.message);
//           tagreadbyid(props.item.id);
//         } else {
//           console.log(result);
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };

//   return (
//     <>
     

//       <TagDetail
//         show={show}
//         handleClose={handleClose}
//         tagbyid={tagbyid}
        
//         quizData={props.quizData}
//         deleteQuestion={deleteQuestion}
//         deleteTag={props.deleteTag}
//         AddQuestion={AddQuestion}
//       />
//     </>
//   );
// };
