import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { FaCalendar } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Card from "react-bootstrap/Card";
import { API_URL } from "../env";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getScheduleassignmentFun,
  getScheduleassignmentFunById,
} from "../redux/assignments/assignmentActions";

export default function ScheduledAssignments(props) {
  const { scheduleassignmetnbyid, scheduleassignmetn_search } = useSelector(
    (store) => store.assignment
  );

  const dispatch = useDispatch();
  console.log(
    scheduleassignmetn_search,
    "scheduleassignmetn in search",
    scheduleassignmetnbyid?.assignment_name
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [assignData, setassignData] = useState([]);
  const navigate = useNavigate();

  

  
  const removesudel = (id) => {
    console.log(id, "id in removesudel");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${API_URL}/instructor/assignments/delete/assignment/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "delete assignment function");
        if (result.status == 1) {
          toast(result.message);
          handleClose();
          dispatch(getScheduleassignmentFun());
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    
    dispatch(getScheduleassignmentFun());
    if (!localStorage.getItem("token")) {
    } else {
    }
  }, []);

  return (
    <>
      <div className="bar3">
        <div className="row">
          {scheduleassignmetn_search?.length > 0 ? (
            scheduleassignmetn_search &&
            scheduleassignmetn_search?.map((item, index) => (
              <div className="col-4" key={item.id}>
                <LiveClass item={item} />
              </div>
            ))
          ) : (
            <>
              {" "}
              <div className="mt-5">
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    fontFamily: "inter",
                    color: "red",
                  }}
                >
                  No data available
                </h3>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "50%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          <button
            type="button"
            className="btn"
            style={{
              height: 30,
              backgroundColor: "#fff",
              border: "1px solid #E72121",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#E72121",
              fontFamily: "inter",
              fontSize: 13,
              fontWeight: 600,
            }}
            onClick={handleShow}
          >
            <RiDeleteBin6Line />
            <span style={{ paddingLeft: 10 }}>Delete</span>
          </button>
        </div>

        <div className="offcanvas-body">
          <div>
            <Modal size="sm" show={show} onHide={handleClose}>
              <Modal.Body
                style={{
                  textAlign: "center",
                  width: "280px",
                  alignSelf: "center",
                }}
              >
                Assignment <b>{scheduleassignmetnbyid?.assignment_name}</b> will
                be Delete{" "}
              </Modal.Body>
              <div
                style={{ display: "flex", justifyContent: "space-around" }}
                className="pb-5"
              >
                <Button
                  variant="danger"
                  onClick={() => {
                    removesudel(scheduleassignmetnbyid?.id);
                   
                  }}
                >
                  Yes,Delete it
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Don't Delete
                </Button>
              </div>
            </Modal>
            <p
              className="text-start"
              style={{
                fontFamily: "inter",
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {scheduleassignmetnbyid?.assignment_name}
            </p>
            <p
              className="text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Course : <span>{scheduleassignmetnbyid?.cou_name}</span>
            </p>
            <p
              className="text-start"
              style={{
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Subject : <span>{scheduleassignmetnbyid?.sub_name}</span>
            </p>
            <div
              className="row mt-4"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <span
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontSize: 18,
                  color: "#434343",
                  fontWeight: 800,
                }}
              >
                Total Marks : {scheduleassignmetnbyid?.tot_mark}
              </span>
            </div>
            <div className="mt-2">
              <div
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#434343",
                }}
              >
                Uploaded Filed
              </div>
              <div className="row mt-2">
                <div
                  className="col-4 me-auto mb-2 mx-3"
                  style={{
                    height: 30,
                    border: "1px solid #D7D7D7",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  >
                    
                    {scheduleassignmetnbyid?.inst_attach?.split("/")[4]}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-4">
              <span
                className="px-3 text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  color: "#434343",
                  fontSize: 14,
                }}
              >
                Exam Schedule
              </span>
              <div className="col-5">
                <div
                  style={{
                    width: "100%",
                    height: 30,
                    backgroundColor: "#EDEDF5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  <FaCalendar />
                  <span
                    style={{
                      color: "#3C3C3C",
                      fontWeight: 400,
                      fontSize: 14,
                      fontFamily: "inter",
                      paddingLeft: 10,
                    }}
                  >
                    {scheduleassignmetnbyid?.startTime?.split("T")?.join(" ")}
                  </span>
                </div>
              </div>
              {/*<div className="col-3">
                      <div
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDF5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <FiClock />
                        <span
                          style={{
                            color: "#3C3C3C",
                            fontWeight: 400,
                            fontSize: 14,
                            fontFamily: "inter",
                            paddingLeft: 10,
                          }}
                        >
                          {scheduleassignmetnbyid?.sttime}
                        </span>
                      </div>
                        </div>*/}
            </div>
            <div className="row mt-3 mb-4">
              <span
                className="px-3 text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  color: "#434343",
                  fontSize: 14,
                }}
              >
                Due Date
              </span>
              <div className="col-5">
                <div
                  style={{
                    width: "100%",
                    height: 30,
                    backgroundColor: "#EDEDF5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  <FaCalendar />
                  <span
                    style={{
                      color: "#3C3C3C",
                      fontWeight: 400,
                      fontSize: 14,
                      fontFamily: "inter",
                      paddingLeft: 10,
                    }}
                  >
                    {scheduleassignmetnbyid?.dueTime?.split("T")?.join(" ")}
                  </span>
                </div>
              </div>
              {/*<div className="col-3">
                      <div
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDF5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <FiClock />
                        <span
                          style={{
                            color: "#3C3C3C",
                            fontWeight: 400,
                            fontSize: 14,
                            fontFamily: "inter",
                            paddingLeft: 10,
                          }}
                        >
                          {scheduleassignmetnbyid?.duetime}
                        </span>
                      </div>
                        </div>*/}
            </div>
            <div className="row">
              <p
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Batches
              </p>
              <ul className="list-grou border-0 text-start">
                {scheduleassignmetnbyid?.batches_name?.length > 0 ? (
                  scheduleassignmetnbyid?.batches_name?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="list-group-item"
                        style={{
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {item}
                      </li>
                    );
                  })
                ) : (
                  <>
                    {" "}
                    <Card
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #9E9E9E",
                        marginBottom: 20,
                      }}
                    >
                      <Card.Body className="">
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: 18,
                            fontFamily: "inter",
                            color: "red",
                          }}
                        >
                          No data available
                        </span>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const LiveClass = (props) => {
  const dispatch = useDispatch();
  return (
    <div
      className="card"
      style={{
        backgroundColor: "#fff",
        border: "1px solid #E6E6E6",
        marginBottom: 20,
        width: "100%",
      }}
    >
      <div className="text-start p-3">
        <span
          style={{
            fontWeight: 600,
            fontSize: 16,
            textAlign: "start",
            fontFamily: "inter",
          }}
        >
          {props.item?.assignment_name}
        </span>
        <div
          className="row g-0 mt-2"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <p
            className="col-3 m-0"
            style={{
              fontFamily: "inter",
              fontSize: 14,
              color: "#9A9A9A",
              fontWeight: 400,
            }}
          >
            Course
          </p>
          <p
            className="col-9 m-0"
            style={{
              fontFamily: "inter",
              fontSize: 14,
              color: "#9A9A9A",
              fontWeight: 400,
              textAlign: "left ",
            }}
          >
            {" "}
            :&nbsp;&nbsp;{props.item?.cou_name}
          </p>
        </div>
        <div
          className="row g-0 mt-2"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <p
            className="col-3 m-0"
            style={{
              fontFamily: "inter",
              fontSize: 14,
              color: "#9A9A9A",
              fontWeight: 400,
            }}
          >
            Subject
          </p>
          <p
            className="col-9"
            style={{
              fontFamily: "inter",
              fontSize: 14,
              color: "#9A9A9A",
              fontWeight: 400,
              textAlign: "left ",
            }}
          >
            {" "}
            :&nbsp;&nbsp;{props.item?.sub_name}
          </p>
        </div>
        <div className="row g-0">
          <div className="col-8 d-flex align-items-center justify-content-start">
            <FaCalendar style={{ color: "#3C3C3C" }} />
            <span
              style={{
                color: "#3C3C3C",
                fontWeight: 400,
                fontSize: 14,
                paddingLeft: 5,
                fontFamily: "inter",
              }}
            >
              {props.item?.startTime?.split("T")?.join(" ")}
            </span>
          </div>
          {/*<div className="col-7 d-flex align-items-center justify-content-start">
              <FiClock style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 400,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item?.sttime} - {props.item?.entime}
              </span>
              </div>*/}
        </div>
        <div className="row mt-2">
          <p
            className="col-12 m-0"
            style={{
              fontFamily: "inter",
              fontSize: 16,
              color: "#9A9A9A",
              fontWeight: 400,
            }}
          >
            Total marks : {props.item?.tot_mark}
          </p>
        </div>
        <div className="row mt-2">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              className="m-0"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                fontWeight: 400,
                color: "#3C3C3C",
              }}
            >
              Passing Percentage
            </p>
            <p
              className="m-0"
              style={{
                fontFamily: "inter",
                fontSize: 14,
                fontWeight: 600,
                color: "#3D70F5",
              }}
            >
              {props.item.pass_pers} %
            </p>
          </div>
        </div>
        <div
          className="mt-3"
          style={{
            width: "100%",
            height: 8,
            backgroundColor: "#D9D9D9",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              width: props.item.pass_pers + "%",
              height: 8,
              backgroundColor: "#3D70F5",
              borderRadius: 10,
            }}
          ></div>
        </div>
        <div
          className="mt-3"
          onClick={() => {
          
            dispatch(getScheduleassignmentFunById(props.item.id));
          }}
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          style={{
            width: "100%",
            height: 40,
            backgroundColor: "#3D70F5",
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              color: "#fff",
              fontSize: 14,
              fontWeight: 400,
              fontFamily: "inter",
            }}
          >
            View Details
          </span>
        </div>
      </div>
    </div>
  );
};
