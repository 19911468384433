// import axios from "axios";
import {
  add_assignment_error,
  add_assignment_loading,
  add_assignment_success,
  get_assignment_result_error,
  get_assignment_result_loading,
  get_assignment_result_success,
  get_studentprofile_error,
  get_studentprofile_loading,
  get_studentprofile_success,
  gethistoryassignment,
  gethistoryassignment_search,
  getscheduleassignment,
  getscheduleassignment_search,
  getscheduleassignmentbyid,
} from "./assignmenttypes";
// import { API_URL } from "../../env";
import { instance } from "../../instance";
import { toast } from "react-toastify";



export const assignmentCreateFun = (form,setNext,next) =>async(dispatch)=>{
  dispatch({type:add_assignment_loading});
  const formdata = new FormData();
  Object.entries(form).map(([key, value]) => formdata.append(key, value));
  try {
    let res = await instance
      .post(`/instructor/assignments/assignment/create`,formdata)
      .then((res) => {
        if (res.data.status == 1) {
          console.log(res,"assignment create")
          dispatch({ type: add_assignment_success, payload: res.data.assignmentAll });
          setNext(next + 1);
          getScheduleassignmentFun();
          toast(res.data.message);
         } else {
          toast("Some thing went wrong");
        }
      })
      .catch((err) => console.log(err));
  } catch (err) {
    dispatch({ type: add_assignment_error });
  }
}
// get data scheduleassignment
export const getScheduleassignmentFun = () => async (dispatch) => {
  try {
    let res = instance
      .get(`/instructor/assignments/assignment/read/upcoming`)
      .then((res) => {
        console.log(res,"get assignment")
        dispatch({
          type: getscheduleassignment,
          payload: res.data.assignmentAll,
        });
      })
      .catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
};


export const getScheduleassignmentFun_search = (toSearch) => (dispatch) => {
  console.log(toSearch, "hello", "iam here");
  dispatch({ type: getscheduleassignment_search, payload: toSearch });
};

export const getScheduleassignmentFunById = (id) => async (dispatch) => {
  console.log(id,"id")
  try {
    let res = instance
      .get(`/instructor/assignments/assignment/readbyid/${id}`)
      .then((res) => {
        console.log(res.data.parsedrows, "res");
        dispatch({ type: getscheduleassignmentbyid, payload: res.data.parsedrows });
      })
      .catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
};


// get data history assignment function
export const getreadHistoryAssignmentFun = () => (dispatch) => {
  try {
    let res = instance
      .get(`/instructor/assignments/assignment/read/completed`)
      .then((res) => {
        dispatch({
          type: gethistoryassignment,
          payload: res.data.assignmentAll,
        });
      })
      .catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
};

export const getHistoryassignmentFun_search = (toSearch) => (dispatch) => {
  console.log(toSearch, "hello", "iam here");
  dispatch({ type: gethistoryassignment_search, payload: toSearch });
};


//  get result Student Fun
export const getresultStudentFun = (assign_id) => (dispatch) => {
  
  dispatch({type:get_assignment_result_loading})
  try {
    let res = instance
      .post(`/instructor/assignments/view/assignment/result`,assign_id)
      .then((res) => {
        console.log(res,"res student");
        dispatch({
          type: get_assignment_result_success,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  } catch (err) {
   dispatch({type:get_assignment_result_error})
  }
};

export const getStudentprofileFun = (assign_id,stu_id) => (dispatch) => {
  console.log(assign_id,"assin and student id",stu_id)
  dispatch({type:get_studentprofile_loading})
  try {
    let res = instance
      .post(`/instructor/assignments/view/student/result/inAssignmemt`,{assign_id,stu_id})
      .then((res) => {
        console.log(res,"res student profile");
        dispatch({
          type: get_studentprofile_success,
          payload: res.data.send_data,
        });
      })
      .catch((err) => console.log(err));
  } catch (err) {
   dispatch({type:get_studentprofile_error})
  }
};