import { toast } from "react-toastify";
import { instance } from "../../instance";
import {
    get_assign_particular_error,
    get_assign_particular_loading,
  get_assign_particular_success,
  get_batch_error,
  get_batch_loading,
  get_batch_success,
  get_classes_particular_error,
  get_classes_particular_loading,
  get_classes_particular_success,
  get_exam_particular_error,
  get_exam_particular_loading,
  get_exam_particular_success,
  get_quiz_particular_error,
  get_quiz_particular_loading,
  get_quiz_particular_success,
} from "./batchtype";

export const getbatchesFun = () => async (dispatch) => {
  dispatch({ type: get_batch_loading });
  try {
    let res = await instance
      .get(`/instructor/batches/read/batches/of/instructor`)
      .then((result) => {
        console.log(result, "get batches");
        if (result.data.status == 1) {
          dispatch({ type: get_batch_success, payload: result.data.batches });
        } else {
          toast("somthing want worng");
        }
      });
  } catch (err) {
    dispatch({ type: get_batch_error });
  }
};
export const getassign_particularFun = (id) => async (dispatch) => {
  dispatch({ type: get_assign_particular_loading });
  try {
    let res = await instance
      .get(`/instructor/batches/read/batchassignment/${id}`)
      .then((result) => {
        console.log(result, "get assignmentAll");
        if (result.data.status == 1) {
          dispatch({ type: get_assign_particular_success, payload: result.data.assignmentAll });
        } else {
          toast("somthing want worng");
        }
      });
  } catch (err) {
    dispatch({ type: get_assign_particular_error });
  }
};
export const getquiz_particularFun = (id) => async (dispatch) => {
  dispatch({ type: get_quiz_particular_loading });
  try {
    let res = await instance
      .get(`/instructor/batches/read/batchquiz/${id}`)
      .then((result) => {
        console.log(result, "get quiz in batch");
        if (result.data.status == 1) {
          dispatch({ type: get_quiz_particular_success, payload: result.data.quizall });
        } else {
          toast("somthing want worng");
        }
      });
  } catch (err) {
    dispatch({ type: get_quiz_particular_error });
  }
};
export const getexam_particularFun = (id) => async (dispatch) => {
  dispatch({ type: get_exam_particular_loading });
  try {
    let res = await instance
      .get(`/instructor/batches/read/batchexam/${id}`)
      .then((result) => {
        console.log(result, "get exam in batch");
        if (result.data.status == 1) {
          dispatch({ type: get_exam_particular_success, payload: result.data.queryy });
        } else {
          toast("somthing want worng");
        }
      });
  } catch (err) {
    dispatch({ type: get_exam_particular_error });
  }
};
export const getclasses_particularFun = (id) => async (dispatch) => {
  dispatch({ type: get_classes_particular_loading });
  try {
    let res = await instance
      .get(`/instructor/batches/read/batchclass/${id}`)
      .then((result) => {
        console.log(result, "get classes in batch");
        if (result.data.status == 1) {
          dispatch({ type: get_classes_particular_success, payload: result.data.classes });
        } else {
          toast("somthing want worng");
        }
      });
  } catch (err) {
    dispatch({ type: get_classes_particular_error });
  }
};
