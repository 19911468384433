import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { AiTwotoneCalendar } from "react-icons/ai";
import { AiOutlineClockCircle } from "react-icons/ai";
import ReactApexChart from "react-apexcharts";
import "../App.css";
import { API_URL } from "../env";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { instance } from "../instance";

const Tasks = () => {
  const chartData = {
    series: [44, 55, 13],
    options: {
      chart: {
        type: "donut",
      },

      labels: ["148", "148", "148", "148"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  // semi chart

  const options = {
    chart: {
      type: "pie",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        expandOnClick: false,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: 0,
        },
      },
    },

    // labels: ['25.71%'],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "top",
            top: 50,
          },
        },
      },
    ],
  };
  const series = [44, 55]; // Your data values here

  const data = [
    {
      hed: "Assigned",
      time: "12:40 P:M",
      date: "July 3rd 2020",
      due: "Due",
      time1: "12:40 P:M",
      date1: "July 3rd 2020",
      btnn: "Not Started",
    },
  ];

  const Assignment = [
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
    {
      Assignment: "New Assignment .pdf",
    },
  ];
  const [taskdata, setTaskdata] = useState({});
  console.log(taskdata, "taskdata");
  const get_task = async () => {
    instance(`${API_URL}/instructor/profile/get/task/detail`);

    try {
      let result = await instance.get(`/instructor/profile/get/task/detail`);

      console.log(result.data, "get tasks");
      if (result.data.status == "1") {
        setTaskdata(result.data);
      } else {
        toast(result.data.Backend_Error);
      }
    } catch (err) {
      toast("Internal Server Error!");
      console.log(err, "in case of err");
    }
  };
  useEffect(() => {
    get_task();
  }, []);
  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-lg-3"
                    style={{ marginLeft: "71px", marginTop: "81px" }}
                  >
                    {/*<ReactApexChart
                      options={chartData.options}
                      series={chartData.series}
                      type="donut"
  />*/}

                    <div style={{ lineHeight: "0", textAlign: "left" }}>
                      <p style={{ fontSize: "40px", fontWeight: 700 }}>
                        Total Tasks
                      </p>
                      <h5 style={{ color: "#9747FF", fontSize: "40px" }}>
                        {taskdata?.total_task}
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="row mb-3">
                      <div className="col-3">
                        <div>
                          <h6>Incompleted Task</h6>
                          {/*<ReactApexChart
                            // options={options}
                            series={series}
                            // type="donut"
                            style={{ width: "100%" }}
/>*/}
                          {taskdata?.not_completed?.notcompleted_persentage} %
                        </div>
                      </div>

                      <div className="col-3">
                        <div>
                          <h6>Ongoing Task</h6>
                          {/*<ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            style={{ width: "100%" }}
/>*/}
                          {taskdata?.ongoing?.ongoing_persentage} %
                        </div>
                      </div>

                      <div className="col-3">
                        <div>
                          <h6>Completed Task</h6>
                          
                          {taskdata?.completed?.commpleted_persentage} %
                          {/*<ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            style={{ width: "100%" }}
/>*/}
                        </div>
                      </div>

                      <div className="col-3">
                        <div>
                          <h6>Missed Task</h6>
                          {taskdata?.missed?.missed_persentage} %
                          {/*<ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            style={{ width: "100%" }}
/>*/}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-5">
                        <div
                          className="card p-0  "
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          style={{ border: "1px solid #9747FF" }}
                        >
                          <div className="card-body pb-0">
                            <div className="row">
                              <div className="col-8">
                                <div
                                  style={{ lineHeight: "0", textAlign: "left" }}
                                >
                                  <p>Total Not completed Tasks</p>
                                  <h5 style={{ color: "#9747FF" }}>
                                    {
                                      taskdata?.not_completed
                                        ?.total_notcompleted
                                    }
                                  </h5>
                                </div>
                              </div>
                              <div className="col-4">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <IoIosArrowForward
                                    style={{ fontSize: "25px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1"></div>

                      <div className="col-lg-5">
                        <div
                          className="card p-0 "
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight3"
                          aria-controls="offcanvasRight"
                          style={{ border: "1px solid #FF6060" }}
                        >
                          <div className="card-body pb-0">
                            <div className="row">
                              <div className="col-7">
                                <div
                                  style={{ lineHeight: "0", textAlign: "left" }}
                                >
                                  <p>Total missed Tasks</p>
                                  <h5 style={{ color: "#FF6060" }}>
                                    {taskdata?.missed?.total_missed}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-5">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <IoIosArrowForward
                                    style={{ fontSize: "25px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg-5">
                        <div
                          className="card p-0 "
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight4"
                          aria-controls="offcanvasRight"
                          style={{ border: "1px solid #FBB214" }}
                        >
                          <div className="card-body pb-0">
                            <div className="row">
                              <div className="col-7">
                                <div
                                  style={{ lineHeight: "0", textAlign: "left" }}
                                >
                                  <p>Total Ongoing Tasks</p>
                                  <h5 style={{ color: "#FBB214" }}>
                                    {taskdata?.ongoing?.total_ongoing}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-5">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <IoIosArrowForward
                                    style={{ fontSize: "25px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1"></div>

                      <div className="col-lg-5">
                        <div
                          className="card p-0 "
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight5"
                          aria-controls="offcanvasRight"
                          style={{ border: "1px solid #1BCB80" }}
                        >
                          <div className="card-body pb-0">
                            <div className="row">
                              <div className="col-7">
                                <div
                                  style={{ lineHeight: "0", textAlign: "left" }}
                                >
                                  <p>Total Completed Tasks</p>
                                  <h5 style={{ color: "#1BCB80" }}>
                                    {taskdata?.completed?.total_completed}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-5">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <IoIosArrowForward
                                    style={{ fontSize: "25px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* first offcanvash start  */}

      <div
        class="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "40%", backdropFilter: "blur(15px)" }}
      >
        <div class="offcanvas-header">
          {/* <h5 class="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5> */}
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="pt-3  ">
          <h5
            style={{
              textAlign: "left",
              paddingLeft: "10px",
              fontWeight: "600",
            }}
          >
            All Tasks
          </h5>
          <hr style={{ color: "#a5a5a5", border: "1px solid #a5a5a5" }} />
        </div>
        <div class="offcanvas-body">
          {data.map((res, key) => {
            return (
              <div className="card mt-4 " key={key}>
                <div className="card-body text-left">
                  <h6 style={{ textAlign: "left", color: "#989898" }}>
                    {" "}
                    Add a Quiz for Students of 3CO - JVY on the subject
                    Fundamentals of Programming{" "}
                  </h6>

                  <div className="row">
                    <div className="col-8">
                      <div
                        className="mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {res.hed}{" "}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          <AiOutlineClockCircle
                            className="mb-1 "
                            style={{ color: "#989898" }}
                          />{" "}
                          {res.time}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <AiTwotoneCalendar className="mb-1 me-2" /> {res.date}
                        </span>
                      </div>

                      <div
                        className="mt-2 "
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <pre style={{ fontSize: "16px" }}>{res.due} </pre>
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          <AiOutlineClockCircle
                            className="mb-1 "
                            style={{ color: "#989898" }}
                          />{" "}
                          {res.time1}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <AiTwotoneCalendar className="mb-1 me-2" />
                          {res.date1}
                        </span>
                      </div>

                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <button
                          type="btn"
                          className="demo"
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid red",
                          }}
                        >
                          {res.btnn}
                        </button>
                      </div>

                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <button
                          type="btn"
                          className="demo2"
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid red",
                          }}
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight2"
                          aria-controls="offcanvasRight"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* offcanvash end */}

      {/* second offcanvas start View  details */}

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight2"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "50%", backdropFilter: "blur(15px)" }}
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h5>Task Details</h5>

              <select
                class="form-select "
                aria-label="Default select example"
                style={{ width: "20%" }}
              >
                <option selected>Not Started</option>
                <option
                  value="1"
                  className="py-2"
                  style={{ backgroundColor: "#EDF7FF", color: "#2188E7" }}
                >
                  ongoing
                </option>

                <option
                  value="2"
                  className="py-2"
                  style={{ backgroundColor: "#E8FFF3", color: "#0FD76B" }}
                >
                  Completed
                </option>
              </select>

              <button
                style={{
                  backgroundColor: "#FFEBEB",
                  color: "#FD2727",
                  fontWeight: "600",
                  padding: "5px 14px",
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                Due Date : 12 Sep 2023
              </button>

              <button
                style={{
                  backgroundColor: "#2188e7",
                  color: "#fff",
                  fontWeight: "600",
                  padding: "5px 20px",
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                Save
              </button>
            </div>

            <div className="mt-5" style={{ textAlign: "left" }}>
              <h6 style={{ color: "#787878" }}>Title </h6>
              <h6 className=" " style={{ color: "#3c3c3c", fontSize: "18px" }}>
                Add a Quiz for Students of 3CO - JVY on the subject Fundamentals
                of
                <br /> Programming{" "}
              </h6>

              <h6 className="mt-4" style={{ color: "#787878" }}>
                {" "}
                Assigned by
              </h6>
              <h6 className="" style={{ color: "#3c3c3c", fontSize: "18px" }}>
                Harsh Kadyan
              </h6>

              <h5 className="mt-4" style={{ color: "#787878" }}>
                Description
              </h5>
              <p style={{ color: "#3c3c3c", fontSize: "14px" }}>
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
                Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                Curabitur pellentesque nibh nibh, at maximus ante fermentum sit
                amet. Pellentesque commodo lacus at sodales sodales. Quisque
                sagittis orci ut diam condimentum, vel euismod erat placerat. In
                iaculis arcu eros, eget tempus orci facilisis id. Praesent lorem
                orci, mattis non efficitur id, ultricies vel nibh. Sed volutpat
                lacus vitae gravida viverra. Fusce vel tempor elit. Proin
                tempus, magna id scelerisque vestibulum, nulla ex pharetra
                sapien, tempor posuere massa neque nec felis. Aliquam sem ipsum,
                vehicula ac tortor vel, egestas ullamcorper dui. Curabitur at
                risus sodales, tristique est id, euismod justo. Mauris nec leo
                non libero sodales lobortis. Quisque a neque pretium, dictum
                tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies
                dignissim nibh ut cursus. Nam et quam sit amet turpis finibus
                maximus tempor eget augue. Aenean at ultricies lorem. Sed
                egestas ligula tortor, sit amet mattis ex feugiat non. Duis
                purus diam, dictum et ante at, commodo iaculis urna. Aenean
                lacinia, nisl id vehicula condimentum, enim massa tempor nibh,
                vitae volutpat sapien metus aliquet nisl. Etiam dui eros,
                tincidunt tristique blandit id, gravida vitae augue. Proin
                imperdiet mi nec justo convallis gravida. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. Mauris consectetur nibh in mattis commodo. Etiam in
                pellentesque eros. Etiam tempus.
              </p>

              <p className="pt-2" style={{ color: "#3c3c3c" }}>
                Attachments
              </p>

              <div className="row">
                {Assignment.map((res, key) => {
                  return (
                    <div className="col-4">
                      <p
                        style={{
                          border: "1px solid #3c3c3c",
                          borderRadius: "8px",
                          padding: "5px 4px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {res.Assignment}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* second offcanvas end View  details*/}

      {/* second card start */}

      {/* first offcanvash start  */}

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight3"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "40%", backdropFilter: "blur(15px)" }}
      >
        <div class="offcanvas-header">
          {/* <h5 class="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5> */}
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="pt-3  ">
          <h5
            style={{
              textAlign: "left",
              paddingLeft: "10px",
              fontWeight: "600",
            }}
          >
            My Remaining Tasks
          </h5>
          <hr style={{ color: "#a5a5a5", border: "1px solid #a5a5a5" }} />
        </div>
        <div class="offcanvas-body">
          {data.map((res, key) => {
            return (
              <div className="card mt-4 " key={key}>
                <div className="card-body text-left">
                  <h6 style={{ textAlign: "left", color: "#989898" }}>
                    {" "}
                    Add a Quiz for Students of 3CO - JVY on the subject
                    Fundamentals of Programming{" "}
                  </h6>

                  <div className="row">
                    <div className="col-8">
                      <div
                        className="mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {res.hed}{" "}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          <AiOutlineClockCircle
                            className="mb-1 "
                            style={{ color: "#989898" }}
                          />{" "}
                          {res.time}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <AiTwotoneCalendar className="mb-1 me-2" /> {res.date}
                        </span>
                      </div>

                      <div
                        className="mt-2 "
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <pre style={{ fontSize: "16px" }}>{res.due} </pre>
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          <AiOutlineClockCircle
                            className="mb-1 "
                            style={{ color: "#989898" }}
                          />{" "}
                          {res.time1}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <AiTwotoneCalendar className="mb-1 me-2" />
                          {res.date1}
                        </span>
                      </div>

                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <button
                          type="btn"
                          className="demo"
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid red",
                          }}
                        >
                          {res.btnn}
                        </button>
                      </div>

                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <button
                          type="btn"
                          className="demo2"
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid red",
                          }}
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight2"
                          aria-controls="offcanvasRight"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* offcanvash end */}

      {/*  offcanvas start View  details */}

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight3"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "50%", backdropFilter: "blur(15px)" }}
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h5>Task Details</h5>

              <select
                class="form-select "
                aria-label="Default select example"
                style={{ width: "20%" }}
              >
                <option selected>Not Started</option>
                <option
                  value="1"
                  className="py-2"
                  style={{ backgroundColor: "#EDF7FF", color: "#2188E7" }}
                >
                  ongoing
                </option>

                <option
                  value="2"
                  className="py-2"
                  style={{ backgroundColor: "#E8FFF3", color: "#0FD76B" }}
                >
                  Completed
                </option>
              </select>

              <button
                style={{
                  backgroundColor: "#FFEBEB",
                  color: "#FD2727",
                  fontWeight: "600",
                  padding: "5px 14px",
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                Due Date : 12 Sep 2023
              </button>

              <button
                style={{
                  backgroundColor: "#2188e7",
                  color: "#fff",
                  fontWeight: "600",
                  padding: "5px 20px",
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                Save
              </button>
            </div>

            <div className="mt-5" style={{ textAlign: "left" }}>
              <h6 style={{ color: "#787878" }}>Title </h6>
              <h6 className=" " style={{ color: "#3c3c3c", fontSize: "18px" }}>
                Add a Quiz for Students of 3CO - JVY on the subject Fundamentals
                of
                <br /> Programming{" "}
              </h6>

              <h6 className="mt-4" style={{ color: "#787878" }}>
                {" "}
                Assigned by
              </h6>
              <h6 className="" style={{ color: "#3c3c3c", fontSize: "18px" }}>
                Harsh Kadyan
              </h6>

              <h5 className="mt-4" style={{ color: "#787878" }}>
                Description
              </h5>
              <p style={{ color: "#3c3c3c", fontSize: "14px" }}>
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
                Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                Curabitur pellentesque nibh nibh, at maximus ante fermentum sit
                amet. Pellentesque commodo lacus at sodales sodales. Quisque
                sagittis orci ut diam condimentum, vel euismod erat placerat. In
                iaculis arcu eros, eget tempus orci facilisis id. Praesent lorem
                orci, mattis non efficitur id, ultricies vel nibh. Sed volutpat
                lacus vitae gravida viverra. Fusce vel tempor elit. Proin
                tempus, magna id scelerisque vestibulum, nulla ex pharetra
                sapien, tempor posuere massa neque nec felis. Aliquam sem ipsum,
                vehicula ac tortor vel, egestas ullamcorper dui. Curabitur at
                risus sodales, tristique est id, euismod justo. Mauris nec leo
                non libero sodales lobortis. Quisque a neque pretium, dictum
                tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies
                dignissim nibh ut cursus. Nam et quam sit amet turpis finibus
                maximus tempor eget augue. Aenean at ultricies lorem. Sed
                egestas ligula tortor, sit amet mattis ex feugiat non. Duis
                purus diam, dictum et ante at, commodo iaculis urna. Aenean
                lacinia, nisl id vehicula condimentum, enim massa tempor nibh,
                vitae volutpat sapien metus aliquet nisl. Etiam dui eros,
                tincidunt tristique blandit id, gravida vitae augue. Proin
                imperdiet mi nec justo convallis gravida. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. Mauris consectetur nibh in mattis commodo. Etiam in
                pellentesque eros. Etiam tempus.
              </p>

              <p className="pt-2" style={{ color: "#3c3c3c" }}>
                Attachments
              </p>

              <div className="row">
                {Assignment.map((res, key) => {
                  return (
                    <div className="col-4">
                      <p
                        style={{
                          border: "1px solid #3c3c3c",
                          borderRadius: "8px",
                          padding: "5px 4px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {res.Assignment}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  offcanvas end View  details*/}

      {/* second card end */}

      {/* third card start */}

      {/* first offcanvash start  */}

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight4"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "40%", backdropFilter: "blur(15px)" }}
      >
        <div class="offcanvas-header">
          {/* <h5 class="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5> */}
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="pt-3  ">
          <h5
            style={{
              textAlign: "left",
              paddingLeft: "10px",
              fontWeight: "600",
            }}
          >
            My Overdue Tasks
          </h5>
          <hr style={{ color: "#a5a5a5", border: "1px solid #a5a5a5" }} />
        </div>
        <div class="offcanvas-body">
          {data.map((res, key) => {
            return (
              <div className="card mt-4 " key={key}>
                <div className="card-body text-left">
                  <h6 style={{ textAlign: "left", color: "#989898" }}>
                    {" "}
                    Add a Quiz for Students of 3CO - JVY on the subject
                    Fundamentals of Programming{" "}
                  </h6>

                  <div className="row">
                    <div className="col-8">
                      <div
                        className="mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {res.hed}{" "}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          <AiOutlineClockCircle
                            className="mb-1 "
                            style={{ color: "#989898" }}
                          />{" "}
                          {res.time}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <AiTwotoneCalendar className="mb-1 me-2" /> {res.date}
                        </span>
                      </div>

                      <div
                        className="mt-2 "
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <pre style={{ fontSize: "16px" }}>{res.due} </pre>
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          <AiOutlineClockCircle
                            className="mb-1 "
                            style={{ color: "#989898" }}
                          />{" "}
                          {res.time1}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <AiTwotoneCalendar className="mb-1 me-2" />
                          {res.date1}
                        </span>
                      </div>

                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <button
                          type="btn"
                          className="demo"
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid red",
                          }}
                        >
                          {res.btnn}
                        </button>
                      </div>

                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <button
                          type="btn"
                          className="demo2"
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid red",
                          }}
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight10"
                          aria-controls="offcanvasRight"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* offcanvash end */}

      {/*  offcanvas start View  details */}

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight10"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "50%", backdropFilter: "blur(15px)" }}
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h5>Task Details</h5>

              <select
                class="form-select "
                aria-label="Default select example"
                style={{ width: "20%" }}
              >
                <option selected>Not Started</option>
                <option
                  value="1"
                  className="py-2"
                  style={{ backgroundColor: "#EDF7FF", color: "#2188E7" }}
                >
                  ongoing
                </option>

                <option
                  value="2"
                  className="py-2"
                  style={{ backgroundColor: "#E8FFF3", color: "#0FD76B" }}
                >
                  Completed
                </option>
              </select>

              <button
                style={{
                  backgroundColor: "#FFEBEB",
                  color: "#FD2727",
                  fontWeight: "600",
                  padding: "5px 14px",
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                Due Date : 12 Sep 2023
              </button>

              <button
                style={{
                  backgroundColor: "#2188e7",
                  color: "#fff",
                  fontWeight: "600",
                  padding: "5px 20px",
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                Save
              </button>
            </div>

            <div className="mt-5" style={{ textAlign: "left" }}>
              <h6 style={{ color: "#787878" }}>Title </h6>
              <h6 className=" " style={{ color: "#3c3c3c", fontSize: "18px" }}>
                Add a Quiz for Students of 3CO - JVY on the subject Fundamentals
                of
                <br /> Programming{" "}
              </h6>

              <h6 className="mt-4" style={{ color: "#787878" }}>
                {" "}
                Assigned by
              </h6>
              <h6 className="" style={{ color: "#3c3c3c", fontSize: "18px" }}>
                Harsh Kadyan
              </h6>

              <h5 className="mt-4" style={{ color: "#787878" }}>
                Description
              </h5>
              <p style={{ color: "#3c3c3c", fontSize: "14px" }}>
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
                Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                Curabitur pellentesque nibh nibh, at maximus ante fermentum sit
                amet. Pellentesque commodo lacus at sodales sodales. Quisque
                sagittis orci ut diam condimentum, vel euismod erat placerat. In
                iaculis arcu eros, eget tempus orci facilisis id. Praesent lorem
                orci, mattis non efficitur id, ultricies vel nibh. Sed volutpat
                lacus vitae gravida viverra. Fusce vel tempor elit. Proin
                tempus, magna id scelerisque vestibulum, nulla ex pharetra
                sapien, tempor posuere massa neque nec felis. Aliquam sem ipsum,
                vehicula ac tortor vel, egestas ullamcorper dui. Curabitur at
                risus sodales, tristique est id, euismod justo. Mauris nec leo
                non libero sodales lobortis. Quisque a neque pretium, dictum
                tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies
                dignissim nibh ut cursus. Nam et quam sit amet turpis finibus
                maximus tempor eget augue. Aenean at ultricies lorem. Sed
                egestas ligula tortor, sit amet mattis ex feugiat non. Duis
                purus diam, dictum et ante at, commodo iaculis urna. Aenean
                lacinia, nisl id vehicula condimentum, enim massa tempor nibh,
                vitae volutpat sapien metus aliquet nisl. Etiam dui eros,
                tincidunt tristique blandit id, gravida vitae augue. Proin
                imperdiet mi nec justo convallis gravida. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. Mauris consectetur nibh in mattis commodo. Etiam in
                pellentesque eros. Etiam tempus.
              </p>

              <p className="pt-2" style={{ color: "#3c3c3c" }}>
                Attachments
              </p>

              <div className="row">
                {Assignment.map((res, key) => {
                  return (
                    <div className="col-4">
                      <p
                        style={{
                          border: "1px solid #3c3c3c",
                          borderRadius: "8px",
                          padding: "5px 4px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {res.Assignment}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  offcanvas end View  details*/}

      {/* third card end */}

      {/* third card start */}

      {/* first offcanvash start  */}

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight5"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "40%", backdropFilter: "blur(15px)" }}
      >
        <div class="offcanvas-header">
          {/* <h5 class="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5> */}
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="pt-3  ">
          <h5
            style={{
              textAlign: "left",
              paddingLeft: "10px",
              fontWeight: "600",
            }}
          >
            My Overdue Tasks
          </h5>
          <hr style={{ color: "#a5a5a5", border: "1px solid #a5a5a5" }} />
        </div>
        <div class="offcanvas-body">
          {data.map((res, key) => {
            return (
              <div className="card mt-4 " key={key}>
                <div className="card-body text-left">
                  <h6 style={{ textAlign: "left", color: "#989898" }}>
                    {" "}
                    Add a Quiz for Students of 3CO - JVY on the subject
                    Fundamentals of Programming{" "}
                  </h6>

                  <div className="row">
                    <div className="col-8">
                      <div
                        className="mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {res.hed}{" "}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          <AiOutlineClockCircle
                            className="mb-1 "
                            style={{ color: "#989898" }}
                          />{" "}
                          {res.time}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <AiTwotoneCalendar className="mb-1 me-2" /> {res.date}
                        </span>
                      </div>

                      <div
                        className="mt-2 "
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <pre style={{ fontSize: "16px" }}>{res.due} </pre>
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          <AiOutlineClockCircle
                            className="mb-1 "
                            style={{ color: "#989898" }}
                          />{" "}
                          {res.time1}
                        </span>
                        <span
                          style={{
                            textAlign: "left",
                            color: "#989898",
                            fontWeight: "600",
                          }}
                        >
                          <AiTwotoneCalendar className="mb-1 me-2" />
                          {res.date1}
                        </span>
                      </div>

                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <button
                          type="btn"
                          className="demo"
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid red",
                          }}
                        >
                          {res.btnn}
                        </button>
                      </div>

                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <button
                          type="btn"
                          className="demo2"
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid red",
                          }}
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight11"
                          aria-controls="offcanvasRight"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* offcanvash end */}

      {/*  offcanvas start View  details */}

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight11"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "50%", backdropFilter: "blur(15px)" }}
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h5>Task Details</h5>

              <select
                class="form-select "
                aria-label="Default select example"
                style={{ width: "20%" }}
              >
                <option selected>Not Started</option>
                <option
                  value="1"
                  className="py-2"
                  style={{ backgroundColor: "#EDF7FF", color: "#2188E7" }}
                >
                  ongoing
                </option>

                <option
                  value="2"
                  className="py-2"
                  style={{ backgroundColor: "#E8FFF3", color: "#0FD76B" }}
                >
                  Completed
                </option>
              </select>

              <button
                style={{
                  backgroundColor: "#FFEBEB",
                  color: "#FD2727",
                  fontWeight: "600",
                  padding: "5px 14px",
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                Due Date : 12 Sep 2023
              </button>

              <button
                style={{
                  backgroundColor: "#2188e7",
                  color: "#fff",
                  fontWeight: "600",
                  padding: "5px 20px",
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                Save
              </button>
            </div>

            <div className="mt-5" style={{ textAlign: "left" }}>
              <h6 style={{ color: "#787878" }}>Title </h6>
              <h6 className=" " style={{ color: "#3c3c3c", fontSize: "18px" }}>
                Add a Quiz for Students of 3CO - JVY on the subject Fundamentals
                of
                <br /> Programming{" "}
              </h6>

              <h6 className="mt-4" style={{ color: "#787878" }}>
                {" "}
                Assigned by
              </h6>
              <h6 className="" style={{ color: "#3c3c3c", fontSize: "18px" }}>
                Harsh Kadyan
              </h6>

              <h5 className="mt-4" style={{ color: "#787878" }}>
                Description
              </h5>
              <p style={{ color: "#3c3c3c", fontSize: "14px" }}>
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
                Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                Curabitur pellentesque nibh nibh, at maximus ante fermentum sit
                amet. Pellentesque commodo lacus at sodales sodales. Quisque
                sagittis orci ut diam condimentum, vel euismod erat placerat. In
                iaculis arcu eros, eget tempus orci facilisis id. Praesent lorem
                orci, mattis non efficitur id, ultricies vel nibh. Sed volutpat
                lacus vitae gravida viverra. Fusce vel tempor elit. Proin
                tempus, magna id scelerisque vestibulum, nulla ex pharetra
                sapien, tempor posuere massa neque nec felis. Aliquam sem ipsum,
                vehicula ac tortor vel, egestas ullamcorper dui. Curabitur at
                risus sodales, tristique est id, euismod justo. Mauris nec leo
                non libero sodales lobortis. Quisque a neque pretium, dictum
                tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies
                dignissim nibh ut cursus. Nam et quam sit amet turpis finibus
                maximus tempor eget augue. Aenean at ultricies lorem. Sed
                egestas ligula tortor, sit amet mattis ex feugiat non. Duis
                purus diam, dictum et ante at, commodo iaculis urna. Aenean
                lacinia, nisl id vehicula condimentum, enim massa tempor nibh,
                vitae volutpat sapien metus aliquet nisl. Etiam dui eros,
                tincidunt tristique blandit id, gravida vitae augue. Proin
                imperdiet mi nec justo convallis gravida. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. Mauris consectetur nibh in mattis commodo. Etiam in
                pellentesque eros. Etiam tempus.
              </p>

              <p className="pt-2" style={{ color: "#3c3c3c" }}>
                Attachments
              </p>

              <div className="row">
                {Assignment.map((res, key) => {
                  return (
                    <div className="col-4">
                      <p
                        style={{
                          border: "1px solid #3c3c3c",
                          borderRadius: "8px",
                          padding: "5px 4px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {res.Assignment}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  offcanvas end View  details*/}

      {/* third card end */}
    </>
  );
};

export default Tasks;
