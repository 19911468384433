export const add_course_Loading = "add/course/loading";
export const add_course_Error = "add/course/error";
export const add_course_Success = "add/course/success";

export const get_course_Loading = "get/course/loading";
export const get_course_Error = "get/course/error";
export const get_course_Success = "get/course/success";
export const get_course_Success_search = "get/course/success/search";

export const get_particular_course_Loading = "get/particular/course/loading";
export const get_particular_course_Error = "get/particular/course/error";
export const get_particular_course_Success = "get/particular/course/success";