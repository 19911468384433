import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import Card from "react-bootstrap/Card";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { RiCheckDoubleLine } from "react-icons/ri";
import Table from "react-bootstrap/Table";
import Details2 from "./Details2";
import { useSelector } from "react-redux";

export default function Classes({ props }) {
  const {classes_particular} = useSelector((store)=>store.batch)
console.log(classes_particular,"classes_particular in classes")

  useEffect(() => {
  
  }, []);

  const [attdata, setAttdata] = useState([]);
  const [State, setState] = useState();
  const [tbldata, setTbldata] = useState([]);

  // console.log(props.On);

  return (
    <div>
      <div className="bar3">
        <div className="row">
          {classes_particular &&
            classes_particular.map((item, index) => {
              return (
                <div key={index} className="col-6">
                  <Card
                    style={{
                      backgroundColor: "#00000010",
                      border: "1px solid #000000",
                      marginBottom: 20,
                    }}
                  >
                    <Card.Body className="text-start">
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: 18,
                          textAlign: "start",
                          fontFamily: "inter",
                        }}
                      >
                        {item.class_topic}
                      </span>
                      <div
                        className="my-2"
                        style={{
                          width: "50%",
                          height: 30,
                          border: "1px solid #D3D3D3",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#989898",
                            fontFamily: "inter",
                            fontWeight: 400,
                            fontSize: 12,
                          }}
                        >
                          {item.batch_name}
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <FiClock style={{ color: "#989898" }} />
                          <span
                            style={{
                              color: "#989898",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 5,
                              fontFamily: "inter",
                            }}
                          >
                            {item.s_time}
                          </span>
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-start">
                          <FaRegCalendarAlt style={{ color: "#989898" }} />
                          <span
                            style={{
                              color: "#989898",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 5,
                              fontFamily: "inter",
                            }}
                          >
                            {item.date}
                          </span>
                        </div>
                      </div>
                      <div
                        className="my-2"
                        style={{
                          width: "50%",
                          height: 30,
                          backgroundColor: "#F1F1F1",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#989898",
                            fontSize: 12,
                            fontWeight: 600,
                            fontFamily: "inter",
                          }}
                        >
                          Status : <span>{item.status}</span>
                        </span>
                      </div>
                      <button
                      type="button"
                      className="mt-2"
                      style={{
                        width: "100%",
                        height: 40,
                        backgroundColor: "#000000",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          fontSize: 14,
                          fontWeight: 600,
                          fontFamily: "inter",
                        }}
                      >
                        Join Now 
                      </span>
                    </button>
                      {/*item.status == 0 ? (
                        <button
                          type="button"
                          className="mt-2"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasWithBackdrop2"
                          aria-controls="offcanvasWithBackdrop2"
                          style={{
                            width: "100%",
                            height: 40,
                            backgroundColor: "#000000",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: 600,
                              fontFamily: "inter",
                            }}
                          >
                            Attendance 
                          </span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="mt-2"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasWithBackdrop"
                          aria-controls="offcanvasWithBackdrop"
                          style={{
                            width: "100%",
                            height: 40,
                            backgroundColor: "#000000",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: 600,
                              fontFamily: "inter",
                            }}
                          >
                            Attendance Report 
                          </span>
                        </button>
                          )*/}
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
