import { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { TbGridDots } from "react-icons/tb";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../env";
import { toast } from "react-toastify";
import { instance } from "../instance";

export default function Schedule() {
  const [notstart, setNotstart] = useState();
  const [ongoing, setOngoing] = useState();
  const [complete, setComplete] = useState();
  const [missed, setMissed] = useState();
  const [type, setType] = useState(0);
  const [pdf, setPdf] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [statusvalue, setStatusvalue] = useState("");
  const [assign, setAssign] = useState();
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [duedate, setDuedate] = useState();
  const [Date1, setDate1] = useState("");
  const [task_id, setTask_Id] = useState();

  //function for schedule data
  const scheduleData = (date) => {
   

    instance.get(`${API_URL}/instructor/schedule/read/task`)
   
      .then((result) => {
        if (result.data.status == "1") {
          setNotstart(result.data.not_completed);
          setMissed(result.data.missed);
          setOngoing(result.data.ongoing);
          setComplete(result.data.completed);
        } else {
          toast(result.data.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for tag details
  const tagDetail = async (id) => {
    console.log(id,"id in tag")
    try {
      let result = await instance.get(`/instructor/schedule/read/taskbyid/${id}`);

      console.log(result.data, "tag details");
      if (result.data.status == "1") {
        setTask_Id(result.data.data?.id);
        setAssign(result.data.data?.assigned_by);
        setTitle(result.data.data?.title);
        setDesc(result.data.data?.des);
        setDuedate(result.data.data?.due_date);
        setPdf(result.data.data?.files);
      } else {
        toast(result.data.Backend_Error);
      }
    } catch (err) {
      toast("Internal Server Error!");
      console.log(err, "in case of err");
    }
  };

  //function for update task
  const updateTask = (id) => {
    console.log(id, "id", statusvalue, "status hello");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var raw = {
      task_id: task_id,
      status: statusvalue,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/schedule/update/task`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          toast(result.msg);
          scheduleData();
        } else {
          console.log(result);
          toast(result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    scheduleData();
    if (!localStorage.getItem("token")) {
    } else {
    }
    const Value = new Date();
    scheduleData(
      Value.getFullYear() +
        "-" +
        ((Value.getMonth() >= 10
          ? Value.getMonth() + 1
          : "0" + (Value.getMonth() + 1)) +
          "-" +
          Value.getDate())
    );
  }, []);

  return (
    <>
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="row g-0">
          <div className="col-lg-2 g-0" style={{}}>
            <Sidebar />
          </div>
          <div className="col-lg-10 g-0 p-0">
            <Header />
            <div className="row mt-5">
              <div className="col-3 ms-3">
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "inter",
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    My Schedule
                  </p>
                  {/*<div
                    style={{
                      width: "50%",
                      height: 35,
                      backgroundColor: "#EDEDF5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <TbGridDots
                      style={{ marginLeft: 10, color: "#8D8D8D", fontSize: 30 }}
                    />

                    <DatePicker
                      className="DatePicker1"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        scheduleData(
                          date.getFullYear() +
                            "-" +
                            ((date.getMonth() >= 10
                              ? date.getMonth() + 1
                              : "0" + (date.getMonth() + 1)) +
                              "-" +
                              date.getDate())
                        );
                      }}
                    />
                    </div>*/}
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-between align-items-center m-auto">
              <div className="col-3">
                <Card
                  style={{
                    backgroundColor: "#3A3A3A",
                    border: "none",
                    width: "90%",
                  }}
                >
                  <div
                    className="ps-3"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      color: "#fff",
                      fontSize: 15,
                      height: 40,
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Not Started
                  </div>
                  <Card.Body className="p-0">
                    <div className="bar4">
                      {notstart &&
                        notstart.map((item, index) => {
                          return (
                            <Notstart
                              item={item}
                              key={index}
                              tagDetail={tagDetail}
                            />
                          );
                        })}
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-3">
                <Card
                  style={{
                    backgroundColor: "#F4BA24",
                    border: "none",
                    width: "90%",
                  }}
                >
                  <div
                    className="ps-3"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      color: "#fff",
                      fontSize: 15,
                      height: 40,
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Ongoing
                  </div>
                  <Card.Body className="p-0">
                    <div className="bar5">
                      {ongoing &&
                        ongoing.map((item, index) => {
                          return (
                            <Ongoing
                              item={item}
                              key={index}
                              tagDetail={tagDetail}
                            />
                          );
                        })}
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-3">
                <Card
                  style={{
                    backgroundColor: "#0FD76B",
                    border: "none",
                    width: "90%",
                  }}
                >
                  <div
                    className="ps-3"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      color: "#fff",
                      fontSize: 15,
                      height: 40,
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Completed
                  </div>
                  <Card.Body className="p-0">
                    <div className="bar6">
                      {complete &&
                        complete.map((item, index) => {
                          return (
                            <Completed
                              item={item}
                              key={index}
                              tagDetail={tagDetail}
                            />
                          );
                        })}
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-3">
                <Card
                  style={{
                    backgroundColor: "#D73F0F",
                    border: "none",
                    width: "90%",
                  }}
                >
                  <div
                    className="ps-3"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      color: "#fff",
                      fontSize: 15,
                      height: 40,
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Missed
                  </div>
                  <Card.Body className="p-0">
                    <div className="bar7">
                      {type == 1 ? (
                        <div className="row p-4">
                          <Card
                            className="bg-transparent"
                            style={{ border: "none" }}
                          >
                            <p
                              style={{
                                color: "#D73F0F",
                                fontFamily: "inter",
                                fontSize: 14,
                                fontWeight: 600,
                              }}
                            >
                              Any task beyond Due Date
                            </p>
                            <p
                              style={{
                                color: "#878787",
                                fontFamily: "inter",
                                fontSize: 12,
                                fontWeight: 600,
                              }}
                            >
                              Nothing to show
                            </p>
                          </Card>
                        </div>
                      ) : (
                        missed &&
                        missed.map((item) => {
                          return (
                            <div key={item.id}
      className="row g-0 mx-2 my-3 p-1"
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="p-2 py-2">
        <p
          style={{
            fontFamily: "inter",
            fontSize: 13,
            color: "#000",
            fontWeight: 400,
            textAlign: "justify",
          }}
        >
          {item?.des}
        </p>
      </div>
      <div className="row g-0">
        <button
          type="button"
          className="btn btn-link text-decoration-none"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          style={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: 14,
            cursor: "pointer",
          }}
          onClick={() => {
            tagDetail(item?.id);
          }}
        >
          View Detail
        </button>
      </div>
    </div>
                          );
                        })
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Tag Detail Offcanvas Start  */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "60%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <div className="row">
              <div
                className="col-3 text-start m-auto"
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
              >
                Task Details
              </div>
              <div className="col-2 m-auto">
                <select
                  value={statusvalue}
                  onChange={(e) => {
                    setStatusvalue(e.target.value);
                  }}
                  className="form-select border-0"
                  aria-label="Default select example"
                  style={{
                    height: 35,
                    backgroundColor: "#EFEFEF",
                    borderRadius: 5,
                    fontFamily: "inter",
                    fontWeight: 400,
                    fontSize: 12,
                    boxShadow: "none",
                    outline: "none",
                  }}
                >
                  <option
                    className=""
                    value="not_completed"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  >
                    selete option
                  </option>
                  <option
                    className=""
                    value="ongoing"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  >
                    Ongoing
                  </option>
                  <option
                    className=""
                    value="completed"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  >
                    Completed
                  </option>
                </select>
              </div>
              <div
                className="col-4 m-auto"
                style={{
                  height: 35,
                  backgroundColor: "#FFEBEB",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#FD2727",
                }}
              >
                Due Date : {duedate}
              </div>
              <div className="col-2 m-auto">
                <button
                  type="button"
                  className="border-0"
                  style={{
                    width: "100%",
                    height: 35,
                    backgroundColor: "#3D70F5",
                    borderRadius: 5,
                    color: "#fff",
                    fontFamily: "inter",
                    fontWeight: 400,
                    fontSize: 14,
                  }}
                  onClick={() => updateTask(task_id)}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="row">
              <div
                className="text-start mt-4"
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#787878",
                }}
              >
                Title
              </div>
              <p
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 20,
                  color: "#3C3C3C",
                }}
              >
                {title}
              </p>
              <div
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#787878",
                }}
              >
                Assigned by
              </div>
              <p
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 20,
                  color: "#3C3C3C",
                }}
              >
                {assign}
              </p>
              <p
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 20,
                  color: "#787878",
                }}
              >
                Description
              </p>
              <p
                className="text-start"
                style={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#3C3C3C",
                }}
              >
                {desc}
              </p>
            </div>
            <div
              className="text-start mt-4"
              style={{
                fontFamily: "inter",
                fontWeight: 600,
                fontSize: 16,
                color: "#787878",
              }}
            >
              Attachments
            </div>
            <div className="row mt-2 g-0">
            {pdf?.split("/")[4]}
              
            </div>
          </div>
        </div>
      </div>
      {/* Tag Detail Offcanvas End  */}
    </>
  );
}

const Notstart = ({ tagDetail, item }) => {
  return (
    <div
      className="row g-0 mx-2 my-3 p-1"
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="p-2 py-2">
        <p
          style={{
            fontFamily: "inter",
            fontSize: 13,
            color: "#000",
            fontWeight: 400,
            textAlign: "justify",
          }}
        >
          {item.des}
        </p>
      </div>
      <div className="row g-0">
        <button
          type="button"
          className="btn btn-link text-decoration-none"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          style={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: 14,
            cursor: "pointer",
          }}
          onClick={() => {
            tagDetail(item?.id);
          }}
        >
          View Detail
        </button>
      </div>
    </div>
  );
};

const Ongoing = ({ tagDetail, item }) => {
  return (
    <div
      className="row g-0 mx-2 my-3 p-1"
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="p-2 py-2">
        <p
          style={{
            fontFamily: "inter",
            fontSize: 13,
            color: "#000",
            fontWeight: 400,
            textAlign: "justify",
          }}
        >
          {item.des}
        </p>
      </div>
      <div className="row g-0">
        <button
          type="button"
          className="btn btn-link text-decoration-none"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          style={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: 14,
            cursor: "pointer",
          }}
          onClick={() => {
            tagDetail(item?.id);
          }}
        >
          View Detail
        </button>
      </div>
    </div>
  );
};

const Completed = ({ tagDetail, item }) => {
  return (
    <div
      className="row g-0 mx-2 my-3 p-1"
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="p-2 py-2">
        <p
          style={{
            fontFamily: "inter",
            fontSize: 13,
            color: "#000",
            fontWeight: 400,
            textAlign: "justify",
          }}
        >
          {item.des}
        </p>
      </div>
      <div className="row g-0">
        <button
          type="button"
          className="btn btn-link text-decoration-none"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          style={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: 14,
            cursor: "pointer",
          }}
          onClick={() => {
            tagDetail(item?.id);
          }}
        >
          View Detail
        </button>
      </div>
    </div>
  );
};


