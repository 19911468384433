import { useState, useEffect } from "react";
import { MdModeEdit } from "react-icons/md";
import { MdOutlineDeleteOutline } from "react-icons/md";
import Card from "react-bootstrap/Card";

export default function Response({ studentrespons }) {
  return (
    <div>
      <div className="row">
        <div className="col-8">
          <Card className="border-0">
            <div className="bar-question">
              {studentrespons &&
                studentrespons?.map((item, index) => {
                  return (
                    <QuestionList
                      item={item}
                      id={index}
                      question={studentrespons}
                    />
                  );
                })}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

const QuestionList = (props) => {
  return (
    <div className="row">
      <div className="p-3 text-start">
        <span
          className="p-0"
          style={{
            fontFamily: "inter",
            fontSize: 14,
            color: "#474747",
            fontWeight: 400,
          }}
        >
          Q{props.id + 1}.
        </span>
        <p
          className="p-0"
          style={{
            fontFamily: "inter",
            fontSize: 14,
            color: "#474747",
            fontWeight: 400,
          }}
        >
          {props.item.question}
        </p>
        {props.item.is_ques_img === 1 ? (
          <img
            src={props.item.question_url}
            alt="img"
            height="70px"
            width="70px"
          />
        ) : (
          <></>
        )}
      </div>

      <div className="row px-3">
        <div className="col-8">
          {props.item.is_opt_img === 0 ? (
            <>
              <div
                className="mb-2"
                style={{
                  width: "100%",
                  // height: 30,
                  padding: "5px 15px",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    (props.item.stu_ans == props.item.cor_ans) == 1
                      ? "gray"
                      : props.item.stu_ans == 1
                      ? "red"
                      : props.item.cor_ans == 1
                      ? "green"
                      : "pink",

                  border: "1px solid #3D70F5",
                }}
              >
                {
                  <div
                    className="ps-2"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                  >
                    a
                  </div>
                }
                <div
                  className="ps-2 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                >
                  {props.item.option1}
                </div>
              </div>
              <div
                className="mb-2"
                style={{
                  width: "100%",
                  // height: 30,
                  padding: "5px 15px",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    (props.item.stu_ans == props.item.cor_ans) == 2
                      ? "gray"
                      : props.item.stu_ans == 2
                      ? "red"
                      : props.item.cor_ans == 2
                      ? "green"
                      : "pink",

                  border: "1px solid #3D70F5",
                }}
              >
                {
                  <div
                    className="ps-2"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                  >
                    b
                  </div>
                }
                <div
                  className="ps-2 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                >
                  {props.item.option2}
                </div>
              </div>
              <div
                className="mb-2"
                style={{
                  width: "100%",
                  // height: 30,
                  padding: "5px 15px",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    (props.item.stu_ans == props.item.cor_ans) == 3
                      ? "gray"
                      : props.item.stu_ans == 3
                      ? "red"
                      : props.item.cor_ans == 3
                      ? "green"
                      : "pink",

                  border: "1px solid #3D70F5",
                }}
              >
                {
                  <div
                    className="ps-2"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                  >
                    c
                  </div>
                }
                <div
                  className="ps-2 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                >
                  {props.item.option3}
                </div>
              </div>
              <div
                className="mb-2"
                style={{
                  width: "100%",
                  // height: 30,
                  padding: "5px 15px",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    (props.item.stu_ans == props.item.cor_ans) == 4
                      ? "gray"
                      : props.item.stu_ans == 4
                      ? "red"
                      : props.item.cor_ans == 4
                      ? "green"
                      : "pink",

                  border: "1px solid #3D70F5",
                }}
              >
                {
                  <div
                    className="ps-2"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#474747",
                    }}
                  >
                    d
                  </div>
                }
                <div
                  className="ps-2 text-start"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                >
                  {props.item.option4}
                </div>
              </div>
            </>
          ) : (
            <div className="col-12">
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: "100px",
                  border: "1px solid #3D70F5",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    (props.item.stu_ans == props.item.cor_ans) == 1
                      ? "gray"
                      : props.item.stu_ans == 1
                      ? "red"
                      : props.item.cor_ans == 1
                      ? "green"
                      : "pink",
                }}
              >
                <div
                  className="ps-2 me-3"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  a
                </div>

                <img
                  src={props.item.option1}
                  alt="img"
                  height="70px"
                  width="70px"
                />
              </div>
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: "100px",
                  border: "1px solid #3D70F5",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    (props.item.stu_ans == props.item.cor_ans) == 2
                      ? "gray"
                      : props.item.stu_ans == 2
                      ? "red"
                      : props.item.cor_ans == 2
                      ? "green"
                      : "pink",
                }}
              >
                <div
                  className="ps-2 me-3"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  b
                </div>

                <img
                  src={props.item.option2}
                  alt="img"
                  height="70px"
                  width="70px"
                />
              </div>
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: "100px",
                  border: "1px solid #3D70F5",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    (props.item.stu_ans == props.item.cor_ans) == 3
                      ? "gray"
                      : props.item.stu_ans == 3
                      ? "red"
                      : props.item.cor_ans == 3
                      ? "green"
                      : "pink",
                }}
              >
                <div
                  className="ps-2 me-3"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  c
                </div>

                <img
                  src={props.item.option3}
                  alt="img"
                  height="70px"
                  width="70px"
                />
              </div>
              <div
                className="mb-3"
                style={{
                  width: "100%",
                  height: "100px",
                  border: "1px solid #3D70F5",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    (props.item.stu_ans == props.item.cor_ans) == 4
                      ? "gray"
                      : props.item.stu_ans == 4
                      ? "red"
                      : props.item.cor_ans == 4
                      ? "green"
                      : "pink",
                }}
              >
                <div
                  className="ps-2 me-3"
                  style={{
                    fontFamily: "inter",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#474747",
                  }}
                >
                  d
                </div>

                <img
                  src={props.item.option4}
                  alt="img"
                  height="70px"
                  width="70px"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
