
import {  Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
   return localStorage.getItem("url") ? <Outlet /> : <Navigate to="/code" />;
};

export default ProtectedRoute;


