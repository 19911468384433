export const getCourse_success = "get/course/success";
export const getCourse_loading = "get/course/loading";
export const getCourse_error = "get/course/error";
export const getCourse_search = "get/course_search";

export const getSubject_success = "get/subject/success";
export const getSubject_loading = "get/subject/loading";
export const getSubject_error = "get/subject/error";
export const getSubject_search = "get/subject_search";

export const getBatch_success = "get/batch/success";
export const getBatch_loading = "get/batch/loading";
export const getBatch_error = "get/batch/error";
export const getBatch_search = "get/batch_search";

export const getLogo_success = 'get/logo/success';
export const getLogo_loading = 'get/logo/loading';
export const getLogo_error = 'get/logo/error';