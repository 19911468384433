import { instance } from "../../instance";
import {
  getBatch_error,
  getBatch_loading,
  getBatch_search,
  getBatch_success,
  getCourse_error,
  getCourse_loading,
  getCourse_search,
  getCourse_success,
  getLogo_error,
  getLogo_loading,
  getLogo_success,
  getSubject_error,
  getSubject_loading,
  getSubject_search,
  getSubject_success,
} from "./comentypes";

export const getCourseFun = () => (dispatch) => {
  dispatch({ type: getCourse_loading });
  try {
    const res = instance
      .get(`/instructor/formfill/getcourses`)
      .then((res) => {
        console.log(res, "res of getcoures");

        dispatch(
          {
            type: getCourse_success,
            payload: res.data.courses,
          },
         
        );
      })
      .catch((err) => console.log(err));
  } catch (error) {
    dispatch({ type: getCourse_error });
  }
};

export const getCourseFun_search = (toSearch,setState, state) => (dispatch) => {
  console.log(toSearch, "to search");
  dispatch({ type: getCourse_search, payload: toSearch });
};

export const getSubjectFun = (courseId) => (dispatch) => {
  dispatch({ type: getSubject_loading });
  try {
    const res = instance
      .get(`/instructor/formfill/getsubjects/${courseId}`)
      .then((res) => {
        console.log(res, "res subject");
        dispatch({
          type: getSubject_success,
          payload: res.data.sub,
        });
      })
      .catch((err) => console.log(err));
  } catch (error) {
    dispatch({ type: getSubject_error });
  }
};

export const getSubjectFun_search = (toSearch) => (dispatch) => {
  console.log(toSearch, "to search");
  dispatch({ type: getSubject_search, payload: toSearch });
};

export const getBatchFun = (courseId) => (dispatch) => {
  dispatch({ type: getBatch_loading });
  try {
    const res = instance.get(`/instructor/formfill/getbatches/${courseId}`).then((res) => {
      console.log(res, "res to bactch");
      dispatch({
        type: getBatch_success,
        payload: res.data.batches,
      });
    });
  } catch (err) {
    dispatch({ type: getBatch_error });
  }
};

export const getBatchFun_search = (toSearch) => (dispatch) => {
  console.log(toSearch, "to search");
  dispatch({ type: getBatch_search, payload: toSearch });
};

export const getLogoFun = () => (dispatch) => {
  dispatch({ type: getLogo_loading });
  try {
    const res = instance.get(`/get/logo`).then((res) => {
      dispatch({
        type: getLogo_success,
        payload: res.data.logo,
      });
    });
  } catch (err) {
    dispatch({ type: getLogo_error });
  }
};
