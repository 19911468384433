import { toast } from "react-toastify";
import { instance } from "../../instance";
import {
  add_exam_Error,
  add_exam_Loading,
  add_exam_Success,
  get_exam_Error,
  get_exam_Loading,
  get_exam_Success,
  get_exam_Success_search,
  get_examhistory_Error,
  get_examhistory_Loading,
  get_examhistory_Success,
  get_examhistory_Success_search,
  get_examviewdetail_Error,
  get_examviewdetail_Loading,
  get_examviewdetail_Success,
} from "./examtype";

export const addExamfun = (form, setNext, next) => async (dispatch) => {
  dispatch({ type: add_exam_Loading });
  const formdata = new FormData();
  Object.entries(form).map(([key, value]) => formdata.append(key, value));
  try {
    let res = await instance
      .post(`/instructor/exams/create/exam`, formdata)
      .then((res) => {
        console.log(res, "add exam");
        if (res.data.status == 1) {
          dispatch({ type: add_exam_Success, payload: res.data });
          toast(res.data.msg);
          setNext(next + 1);
          dispatch(getupcomingexamfun())
        } else {
          toast(res.data.Backend_Error);
        }
      });
  } catch (err) {
    dispatch({ type: add_exam_Error });
  }
};




export const getupcomingexamfun = () => async (dispatch) => {
  dispatch({ type: get_exam_Loading });

  try {
    let res = await instance
      .get(`/instructor/exams/exams/upcoming/read`)
      .then((res) => {
        console.log(res, "get exam");
        if (res.data.status == 1) {
          dispatch({ type: get_exam_Success, payload: res.data.queryy });
          toast(res.data.msg);
        
        } else {
          toast(res.data.Backend_Error);
        }
      });
  } catch (err) {
    dispatch({ type:get_exam_Error });
  }
};


export const getexamfun_search = (toSearch) =>(dispatch)=>{
  console.log(toSearch, "hello", "iam here");
  dispatch({ type: get_exam_Success_search, payload: toSearch });
}

export const getExamViewdetailfun = (id) => (dispatch) => {
  dispatch({ type: get_examviewdetail_Loading });

  try {
    let res = instance
      .get(`instructor/exams/read/exams/${id}`)
      .then((res) => {
        console.log(res, "get view detail exam");
        if (res.data.status == 1) {
          dispatch({ type: get_examviewdetail_Success, payload: res.data.data });
        } else {
          toast(res.data.Backend_Error);
        }
      });
  } catch (err) {
    dispatch({ type: get_examviewdetail_Error });
  }
};




export const gethistoryexamfun = () => async (dispatch) => {
  dispatch({ type: get_examhistory_Loading });

  try {
    let res = await instance
      .get(`/instructor/exams/exams/completed/read`)
      .then((res) => {
        console.log(res, "get history exam");
        if (res.data.status == 1) {
          dispatch({ type: get_examhistory_Success, payload: res.data.queryy });
          toast(res.data.msg);
        
        } else {
          toast(res.data.Backend_Error);
        }
      });
  } catch (err) {
    dispatch({ type:get_examhistory_Error });
  }
};


export const getexamhistoryfun_search = (toSearch) =>(dispatch)=>{
  console.log(toSearch, "hello", "iam here");
  dispatch({ type: get_examhistory_Success_search, payload: toSearch });
}