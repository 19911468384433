import { add_exam_Error, add_exam_Loading, add_exam_Success, get_exam_Error, get_exam_Loading, get_exam_Success, get_exam_Success_search, get_examhistory_Error, get_examhistory_Loading, get_examhistory_Success, get_examhistory_Success_search, get_examviewdetail_Error, get_examviewdetail_Loading, get_examviewdetail_Success } from "./examtype";


const initState = {
    isLoading: false,
    isError: false,
    addexam:[],
    upcomingexam:[],
    upcomingexam_search:[],
    historyexam:[],
    historyexam_search:[],
    exam_viewdetail:{},
  };

export const examReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case add_exam_Loading:{
            return{
                isLoading:true,
                isError :false,
            }
        }
       case add_exam_Error:{
            return{
                isLoading:false,
                isError :true,
            }
        }
       case add_exam_Success:{
            return{
                isLoading:false,
                isError :false,
                addexam:payload
            }
        }
        case get_exam_Loading:{
            return{
                isLoading:true,
                isError :false,
            }
        }
       case get_exam_Error:{
            return{
                isLoading:false,
                isError :true,
            }
        }
       case get_exam_Success:{
            return{
                isLoading:false,
                isError :false,
                upcomingexam:payload,
                upcomingexam_search:payload
            }
        }
        case get_exam_Success_search:{
            return {
                ...state,
                upcomingexam_search: [
                  ...state.upcomingexam.filter((el) =>
                  el.exam_name.toLowerCase().includes(payload.toLowerCase())
                  ),
                ],
              };
        }
        case get_examviewdetail_Loading:{
            return{
                isLoading:true,
                isError :false,
            }
        }
       case get_examviewdetail_Error:{
            return{
                isLoading:false,
                isError :true,
            }
        }
       case get_examviewdetail_Success:{
            return{
                isLoading:false,
                isError :false,
                exam_viewdetail:payload
            }
        }

        case get_examhistory_Loading:{
            return{
                isLoading:true,
                isError :false,
            }
        }
       case get_examhistory_Error:{
            return{
                isLoading:false,
                isError :true,
            }
        }
       case get_examhistory_Success:{
            return{
                isLoading:false,
                isError :false,
                historyexam:payload,
                historyexam_search:payload
            }
        }
        case get_examhistory_Success_search:{
            return {
                ...state,
                historyexam_search: [
                  ...state.historyexam.filter((el) =>
                  el.exam_name.toLowerCase().includes(payload.toLowerCase())
                  ),
                ],
              };
        }
        default:{
            return state
        }
    }}
