import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { FaCalendar } from "react-icons/fa";
import { RiCheckDoubleLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { FaRegCalendarAlt } from "react-icons/fa";
import myimg from "../images/myimage.jpg";
import { NavLink, useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";

export default function ProfileExam() {
  const [live, setLive] = useState();
  const [status, setStatus] = useState(0);

  const classdata = [
    {
      topic:
        "Add a Quiz for Students of 3CO - JVY on the subject Fundamentals of Programming",
      course: "B.Tech Specialization in Health Informatics",
      subject: "Network Engineering",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      passingpercentage: 70,
      scoredpercentage: 75,
      status: 0,
    },
    {
      topic:
        "Add a Quiz for Students of 3CO - JVY on the subject Fundamentals of Programming",
      course: "B.Tech Specialization in Health Informatics",
      subject: "Network Engineering",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      passingpercentage: 70,
      scoredpercentage: 60,
      status: 1,
    },
    {
      topic:
        "Add a Quiz for Students of 3CO - JVY on the subject Fundamentals of Programming",
      course: "B.Tech Specialization in Health Informatics",
      subject: "Network Engineering",
      duetime: "11:40 P:M",
      duedate: "28-01-2023",
      passingpercentage: 70,
      scoredpercentage: 65,
      status: 3,
    },
  ];

  useEffect(() => {
    setLive(classdata);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <div className="row" style={{}}>
            <div className="col-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#3C3C3C",
                }}
              >
                Total Exams : 245
              </div>
            </div>
            <div className="col-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#6B6B6B",
                }}
              >
                Attempted : 120
              </div>
            </div>
            <div className="col-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#9E9E9E",
                }}
              >
                Unattempt : 125
              </div>
            </div>
            <div className="col-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#0CBC8B",
                }}
              >
                Passed : 25
              </div>
            </div>
            <div className="col-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#F93333",
                }}
              >
                Failed : 25
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bar3 mt-4">
        <div className="row g-0">
          {live &&
            live.map((item, index) => {
              return <LiveClass item={item} key={index} />;
            })}
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          {/* <h5 className="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5> */}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="row d-flex align-items-center justify-content-between">
            <div
              className="col-6 text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
            >
              Exam Result Details
            </div>
            <div
              className="col-6"
              style={{
                height: 25,
                backgroundColor: "#ECF2FF",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "inter",
                fontSize: 13,
                fontWeight: 600,
                color: "#3D70F5",
              }}
            >
              Result Declared on 12:30 AM | 22 September 2023 &nbsp;
              <RiCheckDoubleLine style={{ fontSize: 18 }} />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-5">
              <Card className="border-0" style={{}}>
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <div
                      style={{ width: 80, height: "auto", borderRadius: 100 }}
                    >
                      <img
                        src={myimg}
                        className="header"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="col-8 text-start">
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      Yogesh Jangid
                    </div>
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      Student ID : TIPSG5682
                    </div>
                    <NavLink to="/StudentProfile">
                      <button
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        className="btn border-0"
                        style={{
                          backgroundColor: "#fff",
                          fontFamily: "inter",
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#2C62EE",
                        }}
                      >
                        <AiOutlineEye style={{ fontSize: 18 }} />
                        &nbsp;View Profile
                      </button>
                    </NavLink>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <p
            className="text-start my-4"
            style={{ fontFamily: "inter", fontSize: 16, fontWeight: 600 }}
          >
            BHI Health Informatics mid semester Exam.
          </p>
          <div className="row">
            <div className="col-2">
              <div
                className=""
                style={{
                  width: "100%",
                  height: 30,
                  border: "1px solid #D3D3D3",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "#989898",
                    fontFamily: "inter",
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  Batch 3CO - JVY
                </span>
              </div>
            </div>
            <div className="col-5">
              <div className="row">
                <div className="col-6 ">
                  <FiClock style={{ color: "#989898" }} />
                  <span
                    style={{
                      color: "#989898",
                      fontWeight: 600,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                    11:40 P:M
                  </span>
                </div>
                <div className="col-6">
                  <FaRegCalendarAlt style={{ color: "#989898" }} />
                  <span
                    style={{
                      color: "#989898",
                      fontWeight: 600,
                      fontSize: 14,
                      paddingLeft: 5,
                      fontFamily: "inter",
                    }}
                  >
                    10 Feb 2023
                  </span>
                </div>
              </div>
            </div>
            <div className="col-3">
              {status == 0 ? (
                <div
                  className=""
                  style={{
                    width: "55%",
                    height: 30,
                    backgroundColor: "#ECFFEE",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#44CCA3",
                      fontSize: 12,
                      fontWeight: 600,
                      fontFamily: "inter",
                    }}
                  >
                    Status : <span>Pass</span>
                  </span>
                </div>
              ) : (
                <div
                  className=""
                  style={{
                    width: "55%",
                    height: 30,
                    backgroundColor: "#FFEFEF",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#F93333",
                      fontSize: 12,
                      fontWeight: 600,
                      fontFamily: "inter",
                    }}
                  >
                    Status : <span>Failed</span>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <p
              className="col-12 text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 16 }}
            >
              Course <span>&nbsp;B.Tech Spcl. in Health Informatics</span>
            </p>
            <p
              className="col-12 text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 16 }}
            >
              Subject <span>&nbsp;Networking</span>
            </p>
          </div>
          <div className="row">
            <p
              className="col-12 text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 20 }}
            >
              Marks :<span>&nbsp;50</span>
            </p>
          </div>
          <div className="row">
            <div style={{ display: "flex" }}>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3C3C3C",
                }}
              >
                Passing Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3D70F5",
                  paddingLeft: 40,
                }}
              >
                70 %
              </p>
            </div>
          </div>
          <div className="row mt-2">
            <p
              className="text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 16 }}
            >
              Question Paper
            </p>
            <div
              className="col-5 mx-2"
              style={{
                height: 30,
                border: "1px solid #D7D7D7",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 14 }}
              >
                Networking Mid Sem Question Paper.pdf
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div
              className="text-start"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 16 }}
            >
              Student’s Score
            </div>
            <div
              className="mx-2"
              style={{
                width: 100,
                height: 35,
                border: "1px solid #D7D7D7",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 14 }}
              >
                32/50
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div style={{ display: "flex" }}>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#3C3C3C",
                }}
              >
                Student’s Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#44CCA3",
                  paddingLeft: 20,
                }}
              >
                64 %
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div
              className="text-start"
              style={{
                fontFamily: "inter",
                fontWeight: 600,
                fontSize: 24,
                color: "#434343",
              }}
            >
              Instructor’s Notes
            </div>
            <div
              className="text-start my-2"
              style={{ fontFamily: "inter", fontWeight: 600, fontSize: 14 }}
            >
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
              fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
              vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
              auctor ornare leo, non suscipit magna interdum eu. Curabitur
              pellentesque nibh nibh, at maximus ante fermentum sit amet.
              Pellentesque commodo lacus at sodales sodales.{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const LiveClass = (props) => {
  return (
    <div className="col-4">
      <Card
        style={{
          backgroundColor: "#fff",
          border: "1px solid #E6E6E6",
          marginBottom: 20,
          width: "90%",
        }}
      >
        <div className="row">
          {props.item.status == 0 ? (
            <div className="col-3 ms-auto">
              <div
                style={{
                  width: "100%",
                  height: 25,
                  backgroundColor: "#E6E6E6",
                  color: "#9A9A9A",
                  borderBottomLeftRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                Missed
              </div>
            </div>
          ) : props.item.status == 1 ? (
            <div className="col-3 ms-auto">
              <div
                style={{
                  width: "100%",
                  height: 25,
                  backgroundColor: "#FFEFEF",
                  color: "#F93333",
                  borderBottomLeftRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 13,
                }}
              >
                Failed
              </div>
            </div>
          ) : (
            <div className="col-3 ms-auto">
              <div
                style={{
                  width: "100%",
                  height: 25,
                  backgroundColor: "#EFFFF5",
                  color: "#11A529",
                  borderBottomLeftRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "inter",
                  fontWeight: 600,
                  fontSize: 13,
                }}
              >
                Passed
              </div>
            </div>
          )}
        </div>
        <Card.Body className="text-start">
          <span
            style={{
              fontWeight: 600,
              fontSize: 16,
              textAlign: "start",
              fontFamily: "inter",
            }}
          >
            {props.item.topic}
          </span>
          <div
            className="row g-0 mt-2"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <p
              className="col-2 m-0"
              style={{
                fontFamily: "inter",
                fontSize: 15,
                color: "#9A9A9A",
                fontWeight: 600,
              }}
            >
              Subject
            </p>
            <p
              className="col-10"
              style={{
                fontFamily: "inter",
                fontSize: 15,
                color: "#9A9A9A",
                fontWeight: 600,
                textAlign: "left ",
              }}
            >
              {" "}
              :&nbsp;&nbsp;{props.item.subject}
            </p>
          </div>
          <div className="row g-0">
            <div className="col-5 d-flex align-items-center justify-content-start">
              <FaCalendar style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 600,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.duedate}
              </span>
            </div>
            <div className="col-7 d-flex align-items-center justify-content-start">
              <FiClock style={{ color: "#3C3C3C" }} />
              <span
                style={{
                  color: "#3C3C3C",
                  fontWeight: 600,
                  fontSize: 14,
                  paddingLeft: 5,
                  fontFamily: "inter",
                }}
              >
                {props.item.duetime} - {props.item.duetime}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <div style={{ display: "flex" }}>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3C3C3C",
                }}
              >
                Passing Percentage
              </p>
              <p
                className="m-0"
                style={{
                  fontFamily: "inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#3D70F5",
                  paddingLeft: 40,
                }}
              >
                {props.item.passingpercentage} %
              </p>
            </div>
          </div>
          {props.item.status == 0 ? (
            <div className="row mt-2">
              <div style={{ display: "flex" }}>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#3C3C3C",
                  }}
                >
                  Passing Percentage
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#9A9A9A",
                    paddingLeft: 40,
                  }}
                >
                  {" "}
                  0 %
                </p>
              </div>
            </div>
          ) : (
            <div className="row mt-2">
              <div style={{ display: "flex" }}>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#3C3C3C",
                  }}
                >
                  Passing Percentage
                </p>
                <p
                  className="m-0"
                  style={{
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 600,
                    color:
                      props.item.scoredpercentage > 60 ? "#11A529" : "#F93333",
                    paddingLeft: 40,
                  }}
                >
                  {props.item.scoredpercentage} %
                </p>
              </div>
            </div>
          )}
          <button
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            className="mt-3 border-0"
            style={{
              width: "100%",
              height: 40,
              backgroundColor: "#3D70F5",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#fff",
                fontSize: 14,
                fontWeight: 600,
                fontFamily: "inter",
              }}
            >
              View Details
            </span>
          </button>
        </Card.Body>
      </Card>
    </div>
  );
};
