import {
  add_classes_success,
  get_history_classes_error,
  get_history_classes_loading,
  get_history_classes_success,
  get_history_classes_success_search,
  get_upcoming_classes_error,
  get_upcoming_classes_loading,
  get_upcoming_classes_success,
  get_upcoming_classes_success_search,
} from "./liveClassestype";

const initState = {
  isLoading: false,
  isError: false,
  upcomingClasses: [],
  upcomingClasses_search: [],
  classHistory_data: [],
  classHistory_search_data: [],
};

export const liveClassesReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case add_classes_success: {
      return {
        ...state,
        upcomingClasses: payload,
      };
    }

    case get_upcoming_classes_loading: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case get_upcoming_classes_error: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case get_upcoming_classes_success: {
      return {
        ...state,
        isLoading: false,
        upcomingClasses: payload,
        upcomingClasses_search: payload,
      };
    }
    case get_upcoming_classes_success_search: {
      return {
        ...state,
        upcomingClasses_search: [
          ...state.upcomingClasses.filter((el) =>
            el.class_topic.toLowerCase().includes(payload.toLowerCase())
          ),
        ],
      };
    }
    case get_history_classes_loading: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case get_history_classes_error: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case get_history_classes_success: {
      return {
        ...state,
        isLoading: false,
        classHistory_data: payload,
        classHistory_search_data: payload,
      };
    }
    case get_history_classes_success_search: {
      return {
        ...state,
        classHistory_search_data: [
          ...state.classHistory_data.filter((el) =>
            el.class_topic.toLowerCase().includes(payload.toLowerCase())
          ),
        ],
      };
    }
    default: {
      return state;
    }
  }
};
