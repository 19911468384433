import { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import { Excel } from "./Svgicon";
import Table from "react-bootstrap/Table";
import * as XLSX from "xlsx";
import { API_URL } from "../env";
import { useDispatch, useSelector } from "react-redux";
import { gethistoryClassesFun } from "../redux/liveClasses/liveClassesActions";

export default function ClassHistory() {
  const { classHistory_search_data, classHistory_data } = useSelector(
    (store) => store.liveClass
  );
  const dispatch = useDispatch();

  const [state, setState] = useState(0);
  const [id2, setId2] = useState();

  const [firstDetail, setFirstDetail] = useState();
  const [firstDetail_submit, setFirstDetail_submit] = useState();
  const [attendanceDetail, setAttendanceDetail] = useState([]);

  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Map the array to include only the desired properties and convert status values
    const selectiveDataArray = attendanceDetail.map((deatils) => ({
      Student_ID: deatils.stu_id,
      Name: deatils.stu_name,
      Attendence: deatils.attend_status == "Absent" ? "Absent" : "Present",
    }));

    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a file name
    const fileName = "Attendence_Report.xlsx";

    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a blob object from the binary data
    const blob = new Blob([excelData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    // Simulate a click to trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  const showAttendance = async (id) => {
    console.log(id, "id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var raw = JSON.stringify({
      class_id: +id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/classes/get/classattendence`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          console.log(result, "attendence report");
          setFirstDetail(result.firstdet);
          setAttendanceDetail(result.attenddetails);
        } else {
          console.log(result.err);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    dispatch(gethistoryClassesFun());
    if (!localStorage.getItem("token")) {
    } 
  }, [dispatch]);

  const attSubmit = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    var raw = JSON.stringify({
      class_id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/classes/submit/attendence`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          setState(state + 1);
          console.log(result, "submit data");
          setFirstDetail_submit(result.firstdet);
          dispatch(gethistoryClassesFun());
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="bar3">
        <div className="row mt-4">
          {classHistory_search_data?.length > 0 ? (
            classHistory_search_data &&
            classHistory_search_data?.map((item, index) => {
              return (
                <div className="col-4">
                  <Card
                    style={{
                      backgroundColor: "#F7F7F7",
                      border: "1px solid #A0A0A0",
                      marginBottom: 20,
                    }}
                  >
                    <Card.Body className="text-start">
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: 18,
                          textAlign: "start",
                          fontFamily: "inter",
                        }}
                      >
                        {item.class_topic}
                      </span>
                      <div
                        className="my-2"
                        style={{
                          width: "50%",
                          height: 30,
                          border: "1px solid #D3D3D3",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#989898",
                            fontFamily: "inter",
                            fontWeight: 400,
                            fontSize: 12,
                          }}
                        >
                          {item.batch_name}
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <FiClock style={{ color: "#989898" }} />
                          <span
                            style={{
                              color: "#989898",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 5,
                              fontFamily: "inter",
                            }}
                          >
                            {item.s_time}
                          </span>
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-start">
                          <FaRegCalendarAlt style={{ color: "#989898" }} />
                          <span
                            style={{
                              color: "#989898",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 5,
                              fontFamily: "inter",
                            }}
                          >
                            {item.date}
                          </span>
                        </div>
                      </div>
                      <div
                        className="my-2"
                        style={{
                          width: "50%",
                          height: 30,
                          backgroundColor: "#EDEDED",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#989898",
                            fontSize: 12,
                            fontWeight: 400,
                            fontFamily: "inter",
                          }}
                        >
                          Status : <span>{item.status}</span>
                        </span>
                      </div>
                      {item.is_att_dec == 0 ? (
                        <button
                          type="button"
                          onClick={() => {
                            showAttendance(item.id);
                            setId2(item.id);
                          }}
                          className="mt-2"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRightAttendance"
                          aria-controls="offcanvasRight"
                          style={{
                            width: "100%",
                            height: 40,
                            backgroundColor: "#000000",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: 400,
                              fontFamily: "inter",
                            }}
                          >
                            Attendance
                          </span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            showAttendance(item.id);
                            setId2(item.id);
                          }}
                          className="mt-2"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          style={{
                            width: "100%",
                            height: 40,
                            backgroundColor: "#000000",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: 400,
                              fontFamily: "inter",
                            }}
                          >
                            Attendance Report
                          </span>
                        </button>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              );
            })
          ) : (
            <>
              <div className="mt-5" style={{ color: "red" }}>
                <h6>No Data Founds</h6>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Attendance Offcanvas Report Start  */}
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <Card className="border-0">
            <div className="row d-flex align-items-center justify-content-between">
              <div
                className="col-6 text-start"
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
              >
                {" "}
                Attendance Report
              </div>
              <div
                className="col-6"
                style={{
                  height: 25,
                  backgroundColor: "#E5FFF7",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "inter",
                  fontSize: 13,
                  fontWeight: 400,
                  color: "#0CBC8B",
                }}
              >
                Report Submitted on {firstDetail?.subtime} |{" "}
                {firstDetail?.subdate}
              </div>
            </div>
            <p
              className="py-2 text-start"
              style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
            >
              {firstDetail?.class_topic}
            </p>
            <div className="row">
              <div className="col-2">
                <div
                  className=""
                  style={{
                    width: "100%",
                    height: 30,
                    border: "1px solid #D3D3D3",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#989898",
                      fontFamily: "inter",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {firstDetail?.batch_name}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col-6 ">
                    <FiClock style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 13,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      {firstDetail?.sttime}
                    </span>
                  </div>
                  <div className="col-6">
                    <FaRegCalendarAlt style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 13,
                        paddingLeft: 5,
                        fontFamily: "inter",
                      }}
                    >
                      {firstDetail?.stdate}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div
                  className=""
                  style={{
                    height: 30,
                    backgroundColor: "#EDEDED",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#989898",
                      fontSize: 12,
                      fontWeight: 400,
                      fontFamily: "inter",
                    }}
                  >
                    Status : <span>{firstDetail?.status}</span>
                  </span>
                </div>
              </div>
              <div className="col-3">
                <button
                  type="button"
                  style={{
                    width: "100%",
                    height: 30,
                    backgroundColor: "#fff",
                    border: "1px solid #33C481",
                    borderRadius: 5,
                    color: "#21A366",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                  onClick={(e) => convertToExcel()}
                >
                  <Excel />
                  &nbsp;&nbsp;Download Report
                </button>
              </div>
            </div>
            <div className="row mt-4 text-start">
              <div className="col-5">
                <p
                  style={{ fontFamily: "inter", fontWeight: 600, fontSize: 18 }}
                >
                  Total Students : {firstDetail?.totalstudents}
                </p>
                <div className="row">
                  <p
                    className="col-6"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#11A529",
                    }}
                  >
                    Present : {firstDetail?.persent}
                  </p>
                  <p
                    className="col-6"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#F93333",
                    }}
                  >
                    Absent : {firstDetail?.absent}
                  </p>
                </div>
              </div>
            </div>
            <p
              className="py-2 text-start"
              style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
            >
              Attendance Details
            </p>
            <div className="table-scroll">
              <div className="row">
                <div className="col-11">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Student ID
                        </th>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Student Name
                        </th>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Attendance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendanceDetail &&
                        attendanceDetail?.map((item, index) => (
                          <BasicExample key={index} item={item} />
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      {/* Attendance Offcanvas Report End  */}

      {/* Attendance Offcanvas Start  */}
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRightAttendance"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "55%" }}
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div style={{ display: state == 0 ? "block" : "none" }}>
            <Card className="border-0">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="c text-start m-0"
                  style={{ fontFamily: "inter", fontWeight: 600, fontSize: 26 }}
                >
                  Attendance Report
                </div>
                <button
                  type="button"
                  onClick={() => {
                    attSubmit(id2);
                  }}
                  className="btn"
                  style={{
                    height: 35,
                    backgroundColor: "#2C62EE",
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "inter",
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#fff",
                  }}
                >
                  Submit Attendance
                </button>
              </div>
              <p
                className="py-2 text-start"
                style={{ fontFamily: "inter", fontSize: 16, fontWeight: 600 }}
              >
                {firstDetail?.class_topic}
              </p>
              <div className="row">
                <div className="col-2">
                  <div
                    className=""
                    style={{
                      width: "100%",
                      height: 30,
                      border: "1px solid #D3D3D3",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#989898",
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: 12,
                      }}
                    >
                      {firstDetail?.batch_name}
                    </span>
                  </div>
                </div>
                <div className="col-5">
                  <div className="row">
                    <div className="col-6 ">
                      <FiClock style={{ color: "#989898" }} />
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        {firstDetail?.sttime}
                      </span>
                    </div>
                    <div className="col-6">
                      <FaRegCalendarAlt style={{ color: "#989898" }} />
                      <span
                        style={{
                          color: "#989898",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 5,
                          fontFamily: "inter",
                        }}
                      >
                        {firstDetail?.stdate}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div
                    className=""
                    style={{
                      height: 30,
                      backgroundColor: "#EDEDED",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#989898",
                        fontSize: 12,
                        fontWeight: 400,
                        fontFamily: "inter",
                      }}
                    >
                      Status : <span>{firstDetail?.status}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="table-scroll mt-5">
                <div className="row">
                  <div className="col-11">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th
                            className=""
                            style={{
                              color: "#474747",
                              fontFamily: "inter",
                              fontSize: 15,
                              backgroundColor: "#F0F0F0",
                              fontWeight: 600,
                            }}
                          >
                            Student ID
                          </th>
                          <th
                            className=""
                            style={{
                              color: "#474747",
                              fontFamily: "inter",
                              fontSize: 15,
                              backgroundColor: "#F0F0F0",
                              fontWeight: 600,
                            }}
                          >
                            Student Name
                          </th>
                          <th
                            className=""
                            style={{
                              color: "#474747",
                              fontFamily: "inter",
                              fontSize: 15,
                              backgroundColor: "#F0F0F0",
                              fontWeight: 600,
                            }}
                          >
                            Attendance
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendanceDetail &&
                          attendanceDetail?.map((item, index) => (
                            <StudentAttendence
                              key={index}
                              item={item}
                              ClassId={id2}
                              call={showAttendance}
                            />
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div style={{ display: state == 1 ? "block" : "none" }}>
            <div className="row">
              <div className="col-8 m-auto">
                <Card className="border-0">
                  <p
                    className="py-2 text-center"
                    style={{
                      fontFamily: "inter",
                      fontSize: 36,
                      fontWeight: 600,
                    }}
                  >
                    Attendance Report Submitted Successfuly
                  </p>
                  <p
                    className="py-2 text-start"
                    style={{
                      fontFamily: "inter",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    {firstDetail_submit?.class_topic}
                  </p>
                  <div className="row">
                    <div className="col-3">
                      <div
                        className=""
                        style={{
                          width: "100%",
                          height: 30,
                          border: "1px solid #D3D3D3",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#989898",
                            fontFamily: "inter",
                            fontWeight: 400,
                            fontSize: 12,
                          }}
                        >
                          {firstDetail_submit?.batch_name}
                        </span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-6 ">
                          <FiClock style={{ color: "#989898" }} />
                          <span
                            style={{
                              color: "#989898",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 5,
                              fontFamily: "inter",
                            }}
                          >
                            {firstDetail_submit?.time}
                          </span>
                        </div>
                        <div className="col-6">
                          <FaRegCalendarAlt style={{ color: "#989898" }} />
                          <span
                            style={{
                              color: "#989898",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 5,
                              fontFamily: "inter",
                            }}
                          >
                            {firstDetail_submit?.date}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className=""
                        style={{
                          width: "100%",
                          height: 30,
                          backgroundColor: "#EDEDED",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            color: "#989898",
                            fontSize: 12,
                            fontWeight: 400,
                            fontFamily: "inter",
                          }}
                        >
                          Status : <span>{firstDetail_submit?.status}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 text-start">
                    <div className="col-6">
                      <p
                        style={{
                          fontFamily: "inter",
                          fontWeight: 600,
                          fontSize: 18,
                        }}
                      >
                        Total Students : {firstDetail_submit?.totalstudents}
                      </p>
                      <div className="row">
                        <p
                          className="col-6"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#11A529",
                          }}
                        >
                          Present : {firstDetail_submit?.persent}
                        </p>
                        <p
                          className="col-6"
                          style={{
                            fontFamily: "inter",
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#F93333",
                          }}
                        >
                          Absent : {firstDetail_submit?.absent}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-6 m-auto">
                      <button
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        type="button"
                        className="btn"
                        style={{
                          width: "100%",
                          height: 35,
                          backgroundColor: "#2C62EE",
                          color: "#fff",
                          fontFamily: "inter",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          {/*<div style={{ display: state == 2 ? "block" : "none" }}>
            <div className="row d-flex align-items-center justify-content-between">
              <div
                className="col-6 text-start"
                style={{ fontFamily: "inter", fontWeight: 600, fontSize: 24 }}
              >
                {" "}
                7Attendance Report
              </div>
              <div
                className="col-6"
                style={{
                  height: 25,
                  backgroundColor: "#E5FFF7",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "inter",
                  fontSize: 13,
                  fontWeight: 400,
                  color: "#0CBC8B",
                }}
              >
                Report Submitted on {subtime} | {subdate}
              </div>
            </div>
            <p
              className="py-2 text-start"
              style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
            >
              {class_topic}
            </p>
            <div className="row">
              <div className="col-2">
                <div
                  className=""
                  style={{
                    width: "100%",
                    height: 30,
                    border: "1px solid #D3D3D3",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#989898",
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  >
                    {batch_name}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col-5">
                    <FiClock style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 0,
                        fontFamily: "inter",
                      }}
                    >
                      {sttime}
                    </span>
                  </div>
                  <div className="col-5">
                    <FaRegCalendarAlt style={{ color: "#989898" }} />
                    <span
                      style={{
                        color: "#989898",
                        fontWeight: 400,
                        fontSize: 13,
                        paddingLeft: 0,
                        fontFamily: "inter",
                      }}
                    >
                      {stdate}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div
                  className=""
                  style={{
                    height: 30,
                    backgroundColor: "#EDEDED",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#989898",
                      fontSize: 12,
                      fontWeight: 400,
                      fontFamily: "inter",
                    }}
                  >
                    Status : <span>{status}</span>
                  </span>
                </div>
              </div>
              <div className="col-3">
                <button
                  type="button"
                  style={{
                    width: "100%",
                    height: 30,
                    backgroundColor: "#fff",
                    border: "1px solid #33C481",
                    borderRadius: 5,
                    color: "#21A366",
                    fontFamily: "inter",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Excel />
                  &nbsp;&nbsp;Download Report
                </button>
              </div>
            </div>
            <div className="row mt-4 text-start">
              <div className="col-5">
                <p
                  style={{ fontFamily: "inter", fontWeight: 600, fontSize: 18 }}
                >
                  Total Students : {totalstudents}
                </p>
                <div className="row">
                  <p
                    className="col-6"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#11A529",
                    }}
                  >
                    Present : {persent}
                  </p>
                  <p
                    className="col-6"
                    style={{
                      fontFamily: "inter",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#F93333",
                    }}
                  >
                    Absent : {absent2}
                  </p>
                </div>
              </div>
            </div>
            <p
              className="py-2 text-start"
              style={{ fontFamily: "inter", fontSize: 18, fontWeight: 600 }}
            >
              Attendance Details-111
            </p>
            <div className="table-scroll">
              <div className="row">
                <div className="col-11">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Student ID
                        </th>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Student Name
                        </th>
                        <th
                          className=""
                          style={{
                            color: "#474747",
                            fontFamily: "inter",
                            fontSize: 15,
                            backgroundColor: "#F0F0F0",
                            fontWeight: 600,
                          }}
                        >
                          Attendance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendance.length > 0 ? (
                        attendance &&
                        attendance?.map((item, index) => (
                          <BasicExample key={index} item={item} />
                        ))
                      ) : (
                        <>
                          <div style={{ color: "red" }}>
                            <h6>No Data founds</h6>
                          </div>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
                      </div>*/}
        </div>
      </div>
      {/* Attendance Offcanvas End  */}
    </>
  );
}

// const ClassHistoryItem = (props) => {

//   return (
//     <div className='col-4'>
//       <Card style={{ backgroundColor: "#F7F7F7", border: "1px solid #A0A0A0", marginBottom: 20, }}>
//         <Card.Body className='text-start'>
//           <span style={{ fontWeight: 600, fontSize: 18, textAlign: "start", fontFamily: "inter" }}>{props.item.class_topic}</span>
//           <div className='my-2' style={{ width: "50%", height: 30, border: '1px solid #D3D3D3', borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }} >
//             <span style={{ color: "#989898", fontFamily: "inter", fontWeight: 400, fontSize: 12 }}>{props.item.batch_name}</span>
//           </div>
//           <div className='row'>
//             <div className='col-6 d-flex align-items-center'>
//               <FiClock style={{ color: "#989898", }} />
//               <span style={{ color: "#989898", fontWeight: 400, fontSize: 14, paddingLeft: 5, fontFamily: "inter" }}>{props.item.time}</span>
//             </div>
//             <div className='col-6 d-flex align-items-center justify-content-start'>
//               <FaRegCalendarAlt style={{ color: "#989898", }} />
//               <span style={{ color: "#989898", fontWeight: 400, fontSize: 14, paddingLeft: 5, fontFamily: "inter" }}>{props.item.date}</span>
//             </div>
//           </div>
//           <div className='my-2' style={{ width: "50%", height: 30, backgroundColor: "#EDEDED", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }} >
//             <span style={{ color: "#989898", fontSize: 12, fontWeight: 400, fontFamily: "inter" }}>Status : <span>{props.item.status}</span></span>
//           </div>
//           {
//             props.item.is_att_dec == 0 ? (
//               <button type='button' onClick={() => { props.showAttendance(props.item.id); props.Id(props.item.id) }} className='mt-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightAttendance" aria-controls="offcanvasRight" style={{ width: "100%", height: 40, backgroundColor: "#000000", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }} >
//                 <span style={{ color: "#fff", fontSize: 14, fontWeight: 400, fontFamily: "inter" }}>Attendance</span>
//               </button>
//             ) : (
//               <button type='button' onClick={() => { props.showAttendance(props.item.id); props.Id(props.item.id) }} className='mt-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ width: "100%", height: 40, backgroundColor: "#000000", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }} >
//                 <span style={{ color: "#fff", fontSize: 14, fontWeight: 400, fontFamily: "inter" }}>Attendance Report</span>
//               </button>
//             )
//           }
//         </Card.Body>
//       </Card>
//     </div>
//   )
// }

function BasicExample(props) {
  return (
    <tr>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.stu_id}
      </td>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.stu_name}
      </td>
      <td style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="border-0"
          style={{
            color: props.item.attend_status == "Absent" ? "#F93333" : "green",
            width: "50%",
            height: 25,
            backgroundColor:
              props.item.attend_status == "Absent" ? "#FFEFEF" : "#E5FFF7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            fontFamily: "inter",
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          {props.item.attend_status}
        </button>
      </td>
    </tr>
  );
}

function StudentAttendence(props) {
  const setAttendenceFun = (status) => {
    console.log("xszfdxgbjm,.", props.ClassId, props.item.stu_id);

    console.log(status);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var raw = JSON.stringify({
      class_id: props.ClassId,
      stu_id: props.item.stu_id,
      attend_status: status,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/instructor/classes//set/attendence`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setTimeout(() => {
          props.call(props.ClassId);
        }, 1);
      });
  };

  return (
    <tr>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.stu_id}
      </td>
      <td
        style={{
          backgroundColor: "transparent",
          color: "#474747",
          fontFamily: "inter",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {props.item.stu_name}
      </td>
      <td style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="border-0"
          onClick={() => {
            setAttendenceFun(
              props.item.attend_status == "Present" ? "Absent" : "Present"
            );
          }}
          style={{
            color: props.item.attend_status == "Absent" ? "red" : "green",
            width: "50%",
            height: 25,
            backgroundColor:
              props.item.attend_status == "Absent" ? "#FFEFEF" : "#E5FFF7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            fontFamily: "inter",
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          {props.item.attend_status}
        </button>
      </td>
    </tr>
  );
}
