import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UrlFun } from "../redux/auth/authActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const EnteryNumber = () => {
    const [schCode,setSchCode] = useState('')
    const dispatch =useDispatch()
  const navigate = useNavigate();
  return (
    <div className="container " style={{ marginTop: "182px" }}>
      <div className="row ">
        <div
          className="col-lg-6 col-md-6 col-sm-1 m-auto p-5 rounded"
          style={{ backgroundColor: "#e9ecef" }}
        >
          <h2 className="heading"> Master </h2>
          <p className="form-text">School Code</p>
          <div className="form-group">
            <p className="mt-0 text-center">
              Please enter your School Code to proceed
            </p>
            <input
              type="text"
              className="form-control"
              placeholder="Enter School Code"
              required=""
              value={schCode}
              style={{
                background: "none",
                fontWeight: 400,
                height: "40px",
                margin: 0,
                outline: "none",
                width: "100%",
              }}
              onChange={(e)=>{setSchCode(e.target.value)}}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary mt-5"
            onClick={() => {
                schCode == "" ? toast("Plese Enter Your School Code"):
              // navigate("/Login");
              dispatch(UrlFun(schCode,navigate,setSchCode))
            
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnteryNumber;
