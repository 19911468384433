export const login_pending = "login/pending";
export const login_error = "login/error";
export const login_success = "login/success";

export const logout_success = "logut/success";
export const logout_pending = "logout/pending";
export const logout_error = "logout/error";

export const url_success = "url/success";
export const url_pending = "url/pending";
export const url_error = "url/error";