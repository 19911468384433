import { useState, useEffect } from "react";
import General from "./General";
import ProfileQuiz from "./ProfileQuiz";
import ProfileExam from "./ProfileExam";
import ProfileAssignments from "./ProfileAssignments";
import ProfileClasses from "./ProfileClasses";
import myimg from "../images/profile-avatar-account-icon.png";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";


export default function StudentProfile({ProfileData}) {
  const [State, setState] = useState();
// console.log(ProfileData,"profile in studentprofile")
//   const navigate = useNavigate();
//  return console.log(ProfileData,"here ")

  // useEffect(() => {
  //   setState("General");
  // }, []);

  return (
    
    <>
   
 
    <div>
      <div className="row mx-5 my-5">
        <div className="col-3">
          <Card className="border-0" style={{}}>
            <div className="row d-flex align-items-center">
              <div className="col-4">
                <div style={{ width: 80, height: "auto", borderRadius: 100 }}>
                  <img
                    src={myimg}
                    className="header"
                    style={{ width: "100%", borderRadius: 100 }}
                    alt="profile img"
                  />
                </div>
              </div>
              <div className="col-8 text-start">
                <div
                  style={{ fontFamily: "inter", fontWeight: 400, fontSize: 18 }}
                >
                  {"ProfileData[0]?.name"}
                </div>
                <div
                  style={{ fontFamily: "inter", fontWeight: 400, fontSize: 14 }}
                >
                  Student ID : {"ProfileData[0]?.enrollment_id"}
                </div>
                <div
                  style={{ fontFamily: "inter", fontWeight: 400, fontSize: 14 }}
                >
                  Gender : Male
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-2"></div>
        <div className="col-3">
          <Card
            className="border-0"
            style={{ backgroundColor: "#FFF1FD", width: "80%" }}
          >
            <div className="p-2 px-4 row text-start">
              <span
                className="pt-2"
                style={{ fontFamily: "inter", fontWeight: 400, fontSize: 22 }}
              >
                Attendance
              </span>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  style={{
                    fontFamily: "inter",
                    fontWeight: 400,
                    fontSize: 28,
                    color: "#AC71A4",
                  }}
                >
                  {"ProfileData[0]?.percentage_of_attendence"}%
                </div>
                <div
                  style={{ fontFamily: "inter", fontWeight: 400, fontSize: 18 }}
                >
                  <span style={{ color: "#00CF91" }}>{"ProfileData[0]?.attended"}</span>/{"ProfileData[0]?.total_classes"}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div
        className="row mt-4"
        style={{
          height: 40,
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <div
          className="col-8"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <div style={{ display: "flex" }}>
            <button
              type="button"
              onClick={() => {
                setState("General");
              }}
              className="rounded-0 bg-transparent"
              style={{
                border: "none",
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
                borderBottom: State == "General" ? "1px solid #3D70F5" : "none",
                color: State == "General" ? "#3D70F5" : "#000",
                width: 80,
                height: 38,
              }}
            >
              General
            </button>
            <button
              type="button"
              onClick={() => {
                setState("ProfileQuiz");
              }}
              className="rounded-0 bg-transparent mx-4"
              style={{
                border: "none",
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
                borderBottom:
                  State == "ProfileQuiz" ? "1px solid #3D70F5" : "none",
                color: State == "ProfileQuiz" ? "#3D70F5" : "#000",
                width: 70,
                height: 38,
              }}
            >
              Quiz
            </button>
            <button
              type="button"
              onClick={() => {
                setState("ProfileExam");
              }}
              className="rounded-0 bg-transparent mx-4"
              style={{
                border: "none",
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
                borderBottom:
                  State == "ProfileExam" ? "1px solid #3D70F5" : "none",
                color: State == "ProfileExam" ? "#3D70F5" : "#000",
                width: 70,
                height: 38,
              }}
            >
              Exams
            </button>
            <button
              type="button"
              onClick={() => {
                setState("ProfileAssignments");
              }}
              className="rounded-0 bg-transparent mx-4"
              style={{
                border: "none",
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
                borderBottom:
                  State == "ProfileAssignments" ? "1px solid #3D70F5" : "none",
                color: State == "ProfileAssignments" ? "#3D70F5" : "#000",
                width: 100,
                height: 38,
              }}
            >
              Assignments
            </button>
            <button
              type="button"
              onClick={() => {
                setState("ProfileClasses");
              }}
              className="rounded-0 bg-transparent mx-4"
              style={{
                border: "none",
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
                borderBottom:
                  State == "ProfileClasses" ? "1px solid #3D70F5" : "none",
                color: State == "ProfileClasses" ? "#3D70F5" : "#000",
                width: 120,
                height: 38,
              }}
            >
              Classes
            </button>
          </div>
        </div>
      </div>
      <div className="bar2 mt-4 mx-4">
        {State === "General" ? (
          <>
            {" "}
            <General  setState={setState}/>{" "}
          </>
        ) : (
          <></>
        )}
        {State === "ProfileQuiz" ? (
          <>
            {" "}
            <ProfileQuiz />{" "}
          </>
        ) : (
          <></>
        )}
        {State === "ProfileExam" ? (
          <>
            {" "}
            <ProfileExam />{" "}
          </>
        ) : (
          <></>
        )}
        {State === "ProfileAssignments" ? (
          <>
            {" "}
            <ProfileAssignments />{" "}
          </>
        ) : (
          <></>
        )}
        {State === "ProfileClasses" ? (
          <>
            {" "}
            <ProfileClasses  ProfileData={ProfileData} />{" "}
          </>
        ) : (
          <></>
        )}
        </div>
        </div>
        </>
  );
}
