export const add_topic_loading = 'add/topic/loading';
export const add_topic_error = 'add/topic/error';
export const add_topic_success = 'add/topic/success';

export const get_topic_loading = 'get/topic/loading';
export const get_topic_error = 'get/topic/error';
export const get_topic_success = 'get/topic/success';

export const get_topicById_loading = 'get/topicById/loading';
export const get_topicById_error = 'get/topicById/error';
export const get_topicById_success = 'get/topicById/success';

export const add_questionbyExcel_loading = 'add/questionbyExcel/loading';
export const add_questionbyExcel_error = 'add/questionbyExcel/error';
export const add_questionbyExcel_success = 'add/questionbyExcel/success';

export const add_Singlequestion_loading = 'add/Singlequestion/loading';
export const add_Singlequestionl_error = 'add/Singlequestion/error';
export const add_Singlequestion_success = 'add/Singlequestion/success';

export const add_Quizess_loading = 'add/Quizess/loading';
export const add_Quizess_error = 'add/Quizess/error';
export const add_Quizess_success = 'add/Quizess/success';

export const get_ScheduleQuizess_loading = 'get/Schedule/Quizess/loading';
export const get_ScheduleQuizess_error = 'get/Schedule/Quizess/error';
export const get_ScheduleQuizess_success = 'get/Schedule/Quizess/success';
export const get_ScheduleQuizess_success_search = 'get/Schedule/Quizess/success/search';

export const get_HistoryQuizess_loading = 'get/History/Quizess/loading';
export const get_HistoryQuizess_error = 'get/History/Quizess/error';
export const get_HistoryQuizess_success = 'get/History/Quizess/success';

export const delete_question_loading = 'delete/question/loading';
export const delete_question_error = 'delete/question/error';
export const delete_question_success = 'delete/question/success';