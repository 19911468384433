export const add_exam_Loading = "add/exam/loading";
export const add_exam_Error = "add/exam/error";
export const add_exam_Success = "add/exam/success";

export const get_exam_Loading = "get/exam/loading";
export const get_exam_Error = "get/exam/error";
export const get_exam_Success = "get/exam/success";
export const get_exam_Success_search = "get/exam/success/search";

export const get_examviewdetail_Loading = "get/examviewdetail/loading";
export const get_examviewdetail_Error = "get/examviewdetail/error";
export const get_examviewdetail_Success = "get/examviewdetail/success";

export const get_examhistory_Loading = "get/examhistory/loading";
export const get_examhistory_Error = "get/examhistory/error";
export const get_examhistory_Success = "get/examhistory/success";
export const get_examhistory_Success_search = "get/examhistory/success/search";